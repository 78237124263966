import React from "react";
import "../../style-sheets/dashboard.css";
import { Table } from "antd";
export default function RecentTransactions({ data, columns, columnsAr }) {
  const lang = localStorage.getItem("i18nextLng");
  return (
    <div>
      <Table
        dataSource={data}
        columns={lang === "en" ? columns : columnsAr}
        pagination={false}
      />
    </div>
  );
}
