import React, { useState } from "react";
import { Form, Button, Input, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import axiosInstance from "../../../api/axios";

export default function CreateEntityTypeModal({ show, hide, isName, getAll }) {
  const { t } = useTranslation();
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  const onFinish = async (values) => {
    values.operation_type = "add";

    console.log(values);
    let result = await axiosInstance
      .post(
        `${
          isName && selectedType !== "bank"
            ? "/EntityTemp/"
            : isName && selectedType == "bank"
            ? "/BankTemp/"
            : "/EntityTypeTemp/"
        }`,
        values
      )
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      hide();
      getAll();
    }
  };
  const handleSelect = (set, e) => {
    set(e);
    console.log(e);
  };
  const getTypes = async () => {
    let result = await axiosInstance.get(`/EntityType/?page_size=500`);
    console.log(result);
    setTypes(result.data.data);
  };

  return (
    <Modal
      title={`${t("form.enterName")}`}
      footer={false}
      visible={show}
      onCancel={hide}
      destroyOnClose
    >
      <Container className="p-4 entityModal">
        <Form onFinish={onFinish} layout="vertical">
          <Row>
            <Col sm="12">
              <Form.Item
                label={t("form.nameAr")}
                name="name_ar"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                  {
                    pattern:
                      "[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]",
                    message: "wrong format",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Form.Item
                label={t("form.nameEn")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {isName ? (
            <>
              <Row>
                <Col sm="12">
                  <Form.Item
                    name="type"
                    label={t("form.entityType")}
                    rules={[
                      {
                        required: true,
                        message: t("form.requiredtype"),
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) => handleSelect(setSelectedType, e)}
                      value={selectedType}
                      onClick={getTypes}
                    >
                      <Select.Option key={"bank"} value={"bank"}>
                        Bank
                      </Select.Option>
                      {types.map((type) => {
                        return (
                          <>
                            <Select.Option key={type.id} value={type.id}>
                              {type.name} / {type.name_ar}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>{" "}
              <Row>
                <Col sm="12">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t("form.requiredField"),
                      },
                    ]}
                    label={t("form.providerID")}
                    name="provider_id"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}

          <Row>
            <Col className="text-end" sm="12">
              {" "}
              <>
                <Button
                  onClick={hide}
                  style={{ borderRadius: "7px" }}
                  className="cancelBtn mx-3"
                >
                  {t("cancel")}
                </Button>
                <Button
                  style={{ borderRadius: "7px" }}
                  className="formBtn"
                  htmlType="submit"
                >
                  {t("form.add")}
                </Button>
              </>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}
