import React from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck } from '@fortawesome/free-solid-svg-icons'
export default function SuccessMsg({
  text,
  subTxt,
  btnTxt,
  show,
  hide,
  password
}) {
  return (
    <Modal show={show} onHide={hide}>
      <Container className="p-4">
        <Row>
          <Col sm="12">
            <p className="title1">
              {" "}
              <FontAwesomeIcon className="pe-1" icon={faCheck} /> {text}
            </p>
            <p>{subTxt}</p>
            <p>{password}</p>
          </Col>
        </Row>
        <Row>
          <Col className="text-end" sm="12">
            <Button
              onClick={hide}
              style={{ borderRadius: "7px" }}
              className="resetBtn2"
            >
              {btnTxt}
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
