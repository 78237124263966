import React, { useEffect, useState } from "react";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Select, Skeleton } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import SuccessMsg from "../../layout/SuccessMsg";
import CheckAuth from "../../HOC/CheckAuth";
import ConfirmRequest from "../../layout/modals/confirmRequest";
import CommentDisputeModal from "./CommentDisputeModal";
import UnSuccessMsg from "../../layout/UnSuccessMsg";
import { type } from "@testing-library/user-event/dist/type";
import { text } from "@fortawesome/fontawesome-svg-core";
import { useSelector } from "react-redux";
import RejectionReasonCheque from "./RejectionReasonCheque";
import RejectionReasonName from "./RejectionReasonName";

function RejectionReasonsForm({ chequeData }) {
    const permission = useSelector((state) => state.auth.user.permissions);
    const { t } = useTranslation();
    const [showAccept, setShowAccept] = useState(false);
    const [details, setDetails] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedReason, setSelectedReason] = useState("");
    const [showComment, setShowComment] = useState(false);
    const [stateType, setStateType] = useState("");
    const navigate = useNavigate();
    let params = useParams();

    const getDisputeDetails = async () => {
        setLoading(true);
        let result = await axiosInstance
            .get(`/Dispute/${params.id}`)
            .catch((err) => {
                console.log(err.response);
            });
        console.log({ result: result.data });
        setDetails(result.data);
        setLoading(false);
    };
    useEffect(() => {
        permission.includes("dispute_maker")
            ? setStateType("state_1")
            : setStateType("state_2");
        getDisputeDetails();
    }, []);

    const handleNewReason = (e) => {
        setSelectedReason(e);
    };

    const disputeAction = async (state, maker_state = null) => {
        let data;
        if (maker_state) {
            data = { state_1: state };
        } else {
            if (state == "1") {
                data = { state_1: "1", new_reason: selectedReason };
            } else {
                data = { state_1: "2" };
            }
        }
        let result = await axiosInstance
            .put(`/Dispute/${params.id}`, data)
            .catch((err) => {
                console.log(err.response);
            });
        console.log(result);
        if (result && state == "1") {
            setShowAccept(true);
        }
        // else {
        //   setShowComment(true);
        // }
    };

    return (
        <>
            <div className="my-5">
                <p className="title mb-1">{t("viewDisputeDetails")}</p>
                {loading ? (
                    <Skeleton active />
                ) : (
                    <>
                        {" "}
                        <div className="formWrapper my-4">
                            <Row>
                                <Col span={12}>
                                    <p className="subText">
                                        {t("form.disputeID")}{" "}
                                        <span className="idText">
                                            #{details.id}
                                        </span>
                                    </p>
                                </Col>

                                {details[stateType] !== "3" ? null : (
                                    <>
                                        {permission.includes(
                                            "dispute_checker"
                                        ) ||
                                        permission.includes("dispute_maker") ? (
                                            <Col className="text-end" span={12}>
                                                <Button
                                                    style={{
                                                        backgroundColor:
                                                            "#52C41A",
                                                    }}
                                                    className="disputeInfoBtn mx-3"
                                                    disabled={
                                                        selectedReason == "" &&
                                                        details.type == "3" &&
                                                        permission.includes(
                                                            "dispute_maker"
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        permission.includes(
                                                            "dispute_checker"
                                                        ) &&
                                                        details.state_1 === "1"
                                                            ? disputeAction(
                                                                  "1",
                                                                  details.state_1
                                                              )
                                                            : permission.includes(
                                                                  "dispute_checker"
                                                              ) &&
                                                              details.state_1 !==
                                                                  "1"
                                                            ? setShowComment(
                                                                  true
                                                              )
                                                            : disputeAction(
                                                                  "1"
                                                              );
                                                    }}
                                                >
                                                    {t("accept")}
                                                </Button>
                                                <Button
                                                    style={{
                                                        backgroundColor:
                                                            "#FF4D4F",
                                                    }}
                                                    className="disputeInfoBtn"
                                                    onClick={() => {
                                                        permission.includes(
                                                            "dispute_checker"
                                                        ) &&
                                                        details.state_1 === "2"
                                                            ? disputeAction(
                                                                  "2",
                                                                  details.state_1
                                                              )
                                                            : setShowComment(
                                                                  true
                                                              );
                                                    }}
                                                >
                                                    {t("reject")}
                                                </Button>
                                            </Col>
                                        ) : null}
                                    </>
                                )}
                            </Row>
                            <Row className="my-3">
                                <Col span={12}>
                                    <p className="subTitle">
                                        {details.type === "1"
                                            ? t("name_Dispute")
                                            : details.type === "2"
                                            ? t("invalidCheque")
                                            : t("disputeType3")}

                                        <Button className="pendingBtn mx-2">
                                            {details[stateType] === "1"
                                                ? t("accepted")
                                                : details[stateType] === "2"
                                                ? t("rejected")
                                                : t("pending")}
                                        </Button>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <p className="subText no-margin">
                                        {t("form.dispute_date")}
                                    </p>
                                    <p>{details?.create_date?.split("T")[0]}</p>
                                </Col>
                            </Row>
                            {details.type === "3" && (
                                <Row>
                                    <Col span={24}>
                                        <p className="subText">
                                            {t("form.disputeReason")}
                                            <strong
                                                className="mx-2"
                                                style={{
                                                    color: "#000",
                                                }}
                                            >
                                                {details.dispute_reason?.name}
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>
                            )}
                            <Row className="disputeInfoRow">
                                <Col
                                    className="d-flex justify-content-center align-items-center"
                                    span={12}
                                >
                                    <div className="w-50 d-flex align-items-center">
                                        <p className="no-margin bold">
                                            {t("status")}
                                        </p>
                                    </div>
                                    <div className="w-50 d-flex align-items-center">
                                        <p className="no-margin">
                                            {details[stateType] === "1"
                                                ? t("accepted")
                                                : details[stateType] === "2"
                                                ? t("rejected")
                                                : t("pending")}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            {details.type === "1" ? (
                                <RejectionReasonName
                                    details={details}
                                    permission={permission}
                                    stateType={stateType}
                                />
                            ) : (
                                <RejectionReasonCheque
                                    details={details}
                                    permission={permission}
                                    stateType={stateType}
                                />
                            )}
                        </div>
                        {details.type == "3" &&
                        permission.includes("dispute_maker") ? (
                            <div className="formWrapper">
                                <Form
                                    className="rejectionForm"
                                    layout="vertical"
                                >
                                    <p className="subTitle">
                                        {t("updateRjec")}
                                    </p>
                                    <Form.Item
                                        name="new_rejection_reason"
                                        rules={[
                                            {
                                                required: false,
                                                message: t("form.requiredDate"),
                                            },
                                        ]}
                                    >
                                        <Select
                                            value={selectedReason}
                                            onChange={handleNewReason}
                                            placeholder="Choose a replaced reason or select new"
                                        >
                                            {details?.new_reasons?.map(
                                                (reason) => {
                                                    return (
                                                        <Select.Option
                                                            value={`${reason.id}`}
                                                        >
                                                            {reason.name}
                                                        </Select.Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </div>
                        ) : null}
                        {details.comments ? (
                            <div className="formWrapper my-4">
                                {details.comments.map((comment) => {
                                    return (
                                        <Row className="disputeInfoRow">
                                            <Col
                                                className="d-flex justify-content-center align-items-center"
                                                span={8}
                                            >
                                                <div className="w-50 d-flex align-items-center">
                                                    <p className="no-margin bold">
                                                        {t("form.comment")}
                                                    </p>
                                                </div>
                                                <div className="w-50 d-flex align-items-center">
                                                    <p className="no-margin">
                                                        {comment.comment}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-center align-items-center"
                                                span={8}
                                            >
                                                <div className="w-50 d-flex align-items-center">
                                                    <p className="no-margin bold">
                                                        {t("createdBy")}
                                                    </p>
                                                </div>
                                                <div className="w-50 d-flex align-items-center">
                                                    <p className="no-margin">
                                                        {
                                                            comment.changed_by
                                                                .name
                                                        }
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col
                                                className="d-flex justify-content-center align-items-center"
                                                span={8}
                                            >
                                                <div className="w-50 d-flex align-items-center">
                                                    <p className="no-margin bold">
                                                        {t("form.date")}
                                                    </p>
                                                </div>
                                                <div className="w-50 d-flex align-items-center">
                                                    <p className="no-margin">
                                                        {comment.create_date}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </div>
                        ) : null}
                    </>
                )}

                <ConfirmRequest
                    text={t("subDisAccepted")}
                    subTxt={t("feedbackmsg4")}
                    show={showAccept}
                    hide={() => setShowAccept(false)}
                    btnTxt={t("form.done")}
                    color="#52C41A"
                    request={() => navigate("/home")}
                />
                <CommentDisputeModal
                    show={showComment}
                    hide={() => setShowComment(false)}
                    disputeID={details.id}
                    checker={
                        permission.includes("dispute_checker") ? true : false
                    }
                />
                {/* <UnSuccessMsg
        show={true}
        text={"Subject dispute Rejected"}
        subTxt={"Subjet dispute Rejected successfully"}
        btnTxt={"Done"}
      /> */}
            </div>
        </>
    );
}

export default ErrorHandler(RejectionReasonsForm, axiosInstance);
