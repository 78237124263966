import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import CheckAuth from "../../HOC/CheckAuth";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
function CreateBranchModal({ show, hide, type, getAll }) {
  const { t } = useTranslation();

  const [entities, setEntities] = useState([]);
  let params = useParams();

  const onFinish = async (values) => {
    console.log(type, params.id);
    if (type === "bank") {
      values.bank = params.id;
    } else {
      values.entity = params.id;
    }
    values.operation_type = "add";

    console.log(values);
    let result = await axiosInstance.post(
      `/${type == "bank" ? "BankBranchTemp" : "EntityBranchTemp"}/`,
      values
    );
    console.log(result);
    if (result) {
      hide();
      getAll();
    }
  };

  const getEntities = async () => {
    let result = await axiosInstance.get(
      `/${type == "bank" ? "Bank" : "Entity"}/`
    );
    console.log(result);
    setEntities(result.data.data);
  };

  useEffect(() => {
    getEntities();
  }, []);
  return (
    <>
      <CheckAuth
        Permissions={["account_manager", "param_editor"]}
        userType={["iscore"]}
      >
        <Modal
          title={`${t("form.enterName")}`}
          footer={false}
          visible={show}
          onCancel={hide}
          destroyOnClose
        >
          <Container className="p-4 entityModal">
            <Form onFinish={onFinish} layout="vertical">
              <Form.Item
                label={t("form.branchNameEnglish")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                  {
                    pattern: `^[a-zA-Z ]+$`,
                    message: `${t("form.wrongFormat")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.branchNameArabic")}
                name="name_ar"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                  {
                    pattern: `^[\u0621-\u064A ]+$`,
                    message: `${t("form.wrongFormat")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("form.branchCode")}
                name="branch_code"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredField"),
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Row>
                <Col className="text-end" sm="12">
                  {" "}
                  <>
                    <Button
                      onClick={hide}
                      style={{ borderRadius: "7px" }}
                      className="cancelBtn mx-3"
                    >
                      {t("form.cancel")}
                    </Button>
                    <Button
                      style={{ borderRadius: "7px" }}
                      className="formBtn"
                      htmlType="submit"
                    >
                      {t("form.save")}
                    </Button>
                  </>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal>
      </CheckAuth>
    </>
  );
}

export default ErrorHandler(CreateBranchModal, axiosInstance);
