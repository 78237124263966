import axios from "axios";
//Div development for backend
const BASE_URL = "https://back-cheq.div-systems.com";

// local development for backend
// const BASE_URL = "http://192.168.1.142:1111";

//Testing development for backend
//const BASE_URL = "http://cheq-test-back.div-systems.com";
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
export { BASE_URL };
export default axiosInstance;
