import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/loginStyle.css";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo1.svg";
import "react-toastify/dist/ReactToastify.css";
import { LockOutlined } from "@ant-design/icons";
import SuccessMsg from "../layout/SuccessMsg";

const SetNewPassword = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const onFinish = async (data) => {
    console.log(data);
    setShow(true);
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };
  const hideModal = () => {
    setShow(false);
  };
  return (
    <div className="loginContainer container-fluid">
      <Row>
        <Col className="first" lg={4} sm={6}>
          <div className="wrapper">
            <div className="welcome-text">
              <p className="welcome">{t("welcome")}</p>
              <p className="welcome">{t("welcomeText")}</p>
            </div>
            <img className="logo" src={logo} />
          </div>
        </Col>
        <Col className="second" lg={8} sm={6}>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <p className="title">Enter New password</p>
            <p className="subText">
              Enter your new password and repeat it to confirm{" "}
            </p>
            <Form.Item
              name="password"
              rules={[{ required: true, message: `${t("passwordPolicy")}` }]}
              label="New Password"
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={t("password")}
                className=""
              />
            </Form.Item>

            <Form.Item
              name="confirm_password"
              // rules={[{ required: true, message: `${t("passwordError")}` }]}
              label="Confirm new Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={t("password")}
                className=""
              />
            </Form.Item>
            <Form.Item>
              <Button className="loginBtn" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <SuccessMsg
        show={show}
        hide={hideModal}
        text={"Some task has completed!"}
        subTxt={"Some contents..."}
        btnTxt={"Done"}
      />
    </div>
  );
};

export default SetNewPassword;
