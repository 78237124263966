import React from "react";
import axiosInstance from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import fileDownload from "js-file-download";
import { Row, Spin, Alert } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useNavigate } from "react-router-dom";
const HistoricalReportSession = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    let session = window.location.pathname.split("/")[2];
    getReport(session);
  }, []);
  const getReport = async (session) => {
    let result = await axiosInstance
      .get(`/HistoricalReportSession/${session}`)
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response?.status !== 500) {
          for (const error in err.response.data) {
            setTimeout(() => {
              toast.error(`${error + " : " + err.response.data[error]}`);
            }, 500);
          }
        }
      });
    if (result) {
      console.log(result.data);
      handleDownload(result.data?.file, "HistoricalReport.pdf");
    }
  };
  const handleDownload = async (url, filename) => {
    let result = await axiosInstance
      .get(url, {
        responseType: "blob",
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 404) {
          setTimeout(() => {
            toast.error(
              "Report not found check your reference ID or go back and create new one"
            );
          }, 500);
        }
      });
    if (result) {
      setLoading(false);
      fileDownload(result.data, filename);
      setTimeout(() => {
        navigate("/home");
      });
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading && (
        <Spin size="large" tip="Trying to get the report...">
          {/* <Alert
            message="Loading report..."
            description="Please wait we trying to get the historical report."
            type="info"
          /> */}
        </Spin>
      )}
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ErrorHandler(HistoricalReportSession, axiosInstance);
