import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import CheckAuth from "../HOC/CheckAuth";
import DataTabel from "../layout/DataTable";

export default function Invoice() {
  // const lang =
  const { t } = useTranslation();

  const [data, setData] = useState([{ name: "Marwan", change: "radwa" }]);
  const [columns] = useState([
    {
      title: "Bank ID",
      dataIndex: "name",
      render: (row) => <span className="id-data">{row}</span>,
    },
    {
      title: "Branch ID",
      dataIndex: "name",
      render: (row) => <span className="id-data">{row}</span>,
    },
    {
      title: "Type of service",
      dataIndex: "change",
      render: (row) => {
        return (
          <>
            <p className="my-1">{row}</p>
            <p className="my-0">{row}</p>
          </>
        );
      },
    },
    {
      title: "Service Code",
      dataIndex: "change",
      render: (row) => {
        return (
          <>
            <p className="id-data my-1">{row}</p>
            <p className="id-data my-0">{row}</p>
          </>
        );
      },
    },
    {
      title: "User",
      dataIndex: "name",
    },
    {
      title: "Inquiry Date",
      dataIndex: "change",
    },
  ]);

  const [columnsAr] = useState([
    {
      title: "رقم البنك",
      dataIndex: "name",
      render: (row) => <span className="id-data">{row}</span>,
    },
    {
      title: "رقم الفرع",
      dataIndex: "name",
      render: (row) => <span className="id-data">{row}</span>,
    },
    {
      title: "نوع الخدمة",
      dataIndex: "change",
      render: (row) => {
        return (
          <>
            <p className="my-1">{row}</p>
            <p className="my-0">{row}</p>
          </>
        );
      },
    },
    {
      title: "كود الخدمة",
      dataIndex: "change",
      render: (row) => {
        return (
          <>
            <p className="id-data my-1">{row}</p>
            <p className="id-data my-0">{row}</p>
          </>
        );
      },
    },
    {
      title: "اسم المسخدم",
      dataIndex: "name",
    },
    {
      title: "تاريخ الإستعلام",
      dataIndex: "change",
    },
  ]);

  const getInvoices = async () => {
    let result = await axiosInstance.get(``);
    if (result) {
      setData(result.data.data);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <div>
      <CheckAuth
        Permissions={["report_viewer"]}
        userType={["iscore", "entity", "bank"]}
      >
        <p className="title">{t("invoice")}</p>
        <div className="my-5 invoice-table">
          {" "}
          <DataTabel data={data} columns={columns} columnsAr={columnsAr} />
        </div>
      </CheckAuth>
    </div>
  );
}
