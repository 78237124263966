import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Form, Input, Button } from "antd";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { useNavigate } from "react-router-dom";
function CommentDisputeModal({ show, hide, disputeID, checker }) {
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const { TextArea } = Input;
  const navigate = useNavigate();
  const onFinish = async (values) => {
    console.log(values);
    let commentResult = await axiosInstance
      .post("/Comment/", { ...values, dispute: disputeID })
      .catch((err) => console.log(err));

    let rejectResult = await axiosInstance
      .put(`Dispute/${disputeID}`, { state_1: checker ? "3" : "2" })
      .catch((err) => console.log(err));
    if (commentResult && rejectResult) navigate("/home");
  };
  return (
    <Modal dir={lang === "en" ? "ltr" : "rtl"} show={show} onHide={hide}>
      <Container className="p-4">
        <Row>
          <Col sm="12">
            <div className="d-flex align-items-center">
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />{" "}
              <span className="title1 mx-2">{t("form.disputeReject")}</span>
            </div>

            <p className="ps-4">{t("form.rejectNote")}</p>
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm="12">
            <Form
              onFinish={onFinish}
              className="rejectionForm"
              layout="vertical"
            >
              <Form.Item
                name="comment"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredComment"),
                  },
                ]}
                label={t("form.addNote")}
              >
                <TextArea placeholder={t("form.noteHolders")} rows={6} />
              </Form.Item>
              <Row>
                <Col className="text-end" sm="12">
                  <Button
                    onClick={hide}
                    style={{ borderRadius: "7px" }}
                    className="resetBtn1 mx-2"
                  >
                    {t("form.cancel")}
                  </Button>
                  <Button
                    style={{ borderRadius: "7px" }}
                    className="resetBtn2"
                    htmlType="submit"
                  >
                    {t("reject")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default ErrorHandler(CommentDisputeModal, axiosInstance);
