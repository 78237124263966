import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../style-sheets/forms.css";
import { Steps, Button, Form } from "antd";
import PersonalData from "./personalData";
import ChequeData from "./chequeData";
import BeneficiaryData from "./beneficiaryData";
import img from "../../../images/archive.svg";
import useExitPrompt from "../../Custom Hooks/useExitPrompt";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckAuth from "../../HOC/CheckAuth";
import SuccessMsg from "../../layout/SuccessMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
const { Step } = Steps;
let link;

function Wrapper() {
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [form] = Form.useForm();

  const [dueDate, setDueDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [presentedDate, setPresentedDate] = useState("");
  const [rejectionDate, setRejectionDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthDateBene, setBirthDateBene] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [drawerType, setDrawerType] = useState(1);
  const [beneficiaryType, setBeneficiaryType] = useState(1);
  const [governorates, setGovernorates] = useState([]);
  const [isRejected, setIsRejected] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [show, setShow] = useState(false);
  const [offices, setOffices] = useState([]);
  const [id, setID] = useState("");
  const [message, setMessage] = useState("");

  const { t } = useTranslation();
  const onChangeType = (e, typeFunction) => {
    typeFunction(parseInt(e.target.value));
  };
  const handleDate = (_date, dateString, setFunction) => {
    console.log(dateString);
    setFunction(dateString);
  };

  const getGovernorates = async () => {
    try {
      let result = await axiosInstance.get("/Governorate/");
      setGovernorates(result.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getOffices = async () => {
    try {
      let result = await axiosInstance.get("/Office/");
      setOffices(result.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values, link) => {
    console.log(form.getFieldsValue());

    const obj = {
      drawer_bank: values.drawer_bank,
      drawer_branch: values.drawer_branch,
      debit_account: values.debit_account,
      cheque_number: values.cheque_number,
      currency: values.currency,
      cheque_amount: values.cheque_amount,
      due_date: dueDate,
      presented_date: presentedDate,
      cheque_type: values.cheque_type,
      is_rejected: isRejected,
      credit_account: values.credit_account,
      beneficiary_bank: values.beneficiary_bank,
      beneficiary_branch: values.beneficiary_branch,
      rejection_times: values.rejection_times,
      payment_date: paymentDate,
      paid_amount: values.paid_amount,
    };

    const draweeEgyptian = {
      national_id: values.national_id,
      name: values.name,
      mobile: values.phone,
      email: values.email,
      address: values.address,
      birth_date: birthDate,
      gender: values.gender,
    };

    const beneficiaryEgyptian = {
      national_id: values.national_id_bene,
      name: values.name_bene,
      mobile: values.phone_bene,
      email: values.email_bene,
      address: values.address_bene,
      birth_date: birthDateBene,
      gender: values.gender_bene,
    };

    const draweeForeign = {
      passport_id: values.passport_id,
      name: values.name,
      birth_date: birthDate,
      mobile: values.phone,
      email: values.email,
      address: values.address,
      gender: values.gender,
      nationality: values.nationality,
      issue_date: issueDate,
    };

    const beneficiaryForeign = {
      passport_id: values.passport_id_bene,
      name: values.name_bene,
      birth_date: birthDateBene,
      mobile: values.phone_bene,
      email: values.email_bene,
      address: values.address_bene,
      gender: values.gender_bene,
      nationality: values.nationality_bene,
      issue_date: issueDate,
    };

    const draweeEntity = {
      tax_id: values.tax_id,
      name: values.entity_name,
      commercial_no: values.commercial_no,
      governorate: values.governorate,
      office: values.office,
      mobile: values.phone,
      email: values.email,
      address: values.address,
    };

    const beneficiaryEntity = {
      tax_id: values.tax_id_bene,
      name: values.entity_name_bene,
      commercial_no: values.commercial_no_bene,
      governorate: values.governorate_bene,
      office: values.office_bene,
      mobile: values.phone_bene,
      email: values.email_bene,
      address: values.address_bene,
    };

    let data = {
      ...obj,
    };
    if (drawerType == 1) {
      data.drawee_egyptian = draweeEgyptian;
    } else if (drawerType == 2) {
      data.drawee_foreign = draweeForeign;
    } else {
      data.drawee_entity = draweeEntity;
    }
    if (beneficiaryType == 1 && birthDateBene !== "") {
      data.beneficiary_egyptian = beneficiaryEgyptian;
    } else if (beneficiaryType == 2 && birthDateBene !== "") {
      data.beneficiary_foreign = beneficiaryForeign;
    } else if (beneficiaryType === 3) {
      data.beneficiary_entity = beneficiaryEntity;
    } else {
      //
    }
    if (isRejected) {
      console.log(rejectionDate);
      data.rejection_reason = values.rejection_reason;
      data.reject_date = rejectionDate;
    }
    console.log(data);
    let result = await axiosInstance.post(`${link}`, data).catch((err) => {
      console.log(err.response);
      if (err.response) {
        for (const error in err.response.data) {
          console.log(err.response.data[error]);
          for (const [key, value] of Object.entries(err.response.data[error])) {
            console.log(`${key} ${value}`);
            setTimeout(() => {
              toast.error(`${key + " : " + value} inside ${error} form`);
            }, 1000);
          }
        }
      }
    });
    if (result) {
      setID(result.data.id);
      if (link === "/Cheque/") {
        setMessage(t("transactionCreated"));
      } else {
        setMessage(t("draftCreated"));
      }
      setShow(true);

      form.resetFields();
      console.log(result);
    }
  };

  useEffect(() => {
    if (isDraft) {
      onFinish();
    }
  }, [isDraft]);

  const onFinishFailed = (values) => {
    console.log(values);
  };

  const handleIsRejected = (e) => {
    console.log(e.target);
    setIsRejected(e);
  };

  useEffect(() => {
    getGovernorates();
    getOffices();
  }, []);
  useEffect(() => {
    console.log(showExitPrompt);
    return () => {
      setShowExitPrompt(false);
    };
  }, []);
  const onReset = () => {
    form.resetFields();
  };
  return (
    <CheckAuth
      Permissions={["entity_maker", "account_manager"]}
      userType={["entity", "iscore", "bank"]}
    >
      <div>
        {/* <button  onClick={handleClick}>Show/Hide the prompt</button> */}

        <p className="title">{t("chequeDataEntry")}</p>
        <Steps size="small" current={0}>
          <Step
            icon={<FontAwesomeIcon icon={faCircle} />}
            title={t("draweeData")}
          />
          <Step
            icon={<FontAwesomeIcon icon={faCircle} />}
            title={t("chequeData")}
          />
          <Step
            icon={<FontAwesomeIcon icon={faCircle} />}
            title={t("beneficiaryData")}
          />
        </Steps>
        <Form
          layout="vertical"
          onFinishFailed={onFinishFailed}
          form={form}
          scrollToFirstError
        >
          <PersonalData
            title={t("draweeData")}
            handleDate={handleDate}
            birthDate={birthDate}
            issueDate={issueDate}
            setIssueDate={setIssueDate}
            setBirthDate={setBirthDate}
            changeType={onChangeType}
            setType={setDrawerType}
            type={drawerType}
            offices={offices}
            governorates={governorates}
            draft=""
            form={form}
          />
          <ChequeData
            handleDate={handleDate}
            setDueDate={setDueDate}
            setPaymentDate={setPaymentDate}
            setPresentedDate={setPresentedDate}
            setRejectionDate={setRejectionDate}
            paymentDate={paymentDate}
            rejectionDate={rejectionDate}
            dueDate={dueDate}
            presentedDate={presentedDate}
            handleIsRejected={handleIsRejected}
            form={form}
            draft=""
          />
          <BeneficiaryData
            title={t("beneficiaryData")}
            handleDate={handleDate}
            birthDate={birthDateBene}
            setBirthDate={setBirthDateBene}
            issueDate={issueDate}
            setIssueDate={setIssueDate}
            changeType={onChangeType}
            setType={setBeneficiaryType}
            type={beneficiaryType}
            offices={offices}
            governorates={governorates}
            form={form}
            draft=""
          />
          <div className="btnDiv mt-3">
            <p className="subTitle my-3">{t("transactionSubmission")}</p>
            <Button
              color="primary"
              htmlType="submit"
              className="submitBtn"
              onClick={() => onFinish(form.getFieldsValue(), "/Cheque/")}
            >
              <FontAwesomeIcon className="pe-2 ps-2" icon={faCheck} />
              {t("formSubmission")}
            </Button>
            <Button
              htmlType="submit"
              className="draftBtn"
              onClick={() => onFinish(form.getFieldsValue(), "/DraftCheque/")}
            >
              <img src={img} className="img-fluid pe-2 ps-2" alt="Draft icon" />
              {t("formDraft")}
            </Button>
            <Button htmlType="button" onClick={onReset} className="deleteBtn">
              <FontAwesomeIcon className="pe-2 ps-2" icon={faTrash} />
              {t("deleteTransaction")}
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer position="bottom-right" />
      <SuccessMsg
        show={show}
        hide={() => {
          setShow(false);
        }}
        text={message + id}
        btnTxt={t("form.done")}
      />
    </CheckAuth>
  );
}

export default ErrorHandler(Wrapper, axiosInstance);
