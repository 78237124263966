import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

export default function RejectionReasonCheque({
    details,
    permission,
}) {
    const { t } = useTranslation();
    return (
        <>

            <Row className="disputeInfoRow">
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">
                            {t("form.chequeNumber")}
                        </p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.cheque?.cheque_number}
                        </p>
                    </div>
                </Col>
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{t("form.dueDate")}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.cheque?.due_date.split("T")[0]}
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="disputeInfoRow">
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{t("form.bankName")}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.cheque?.drawer_bank?.name} -{" "}
                            {details?.cheque?.drawer_bank?.name_ar}
                        </p>
                    </div>
                </Col>
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{t("branchName")}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.cheque?.drawer_branch?.name} -
                            {details?.cheque?.drawer_branch?.name_ar}
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="disputeInfoRow">
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{t("amount")}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.cheque?.cheque_amount}
                        </p>
                    </div>
                </Col>
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{t("form.currency")}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.cheque?.currency?.name}
                        </p>
                    </div>
                </Col>
            </Row>
            {details.type == "3" ? (
                <Row className="disputeInfoRow">
                    <Col
                        className="d-flex justify-content-center align-items-center"
                        span={12}
                    >
                        <div className="w-50 d-flex align-items-center">
                            <p className="no-margin bold">
                                {t("form.rejectionHistory")}
                            </p>
                        </div>
                        <div className="w-50 d-flex align-items-center">
                            <div>
                                {" "}
                                {details?.old_reasons?.map((reason) => {
                                    return (
                                        <p className="no-margin">
                                            {reason.name}
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    </Col>
                    {permission.includes("dispute_checker") && (
                        <Col
                            className="d-flex justify-content-center align-items-center"
                            span={12}
                        >
                            <div className="w-50 d-flex align-items-center">
                                <p className="no-margin bold">
                                    {t("updateRjec")}
                                </p>
                            </div>
                            <div className="w-50 d-flex align-items-center">
                                <p className="no-margin">
                                    {details?.new_reason?.name}
                                </p>
                            </div>
                        </Col>
                    )}
                </Row>
            ) : null}
        </>
    );
}
