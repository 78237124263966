import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import DisputeDetails from "../../layout/modals/disputes/disputeDetails";
export default function ConflictRow({ data, issue }) {
  const { t } = useTranslation();

  const [solved, setSolved] = useState(false);
  const [conflictedData, setConflictedData] = useState("");
  const [showDispute, setShowDispute] = useState(false);

  const showDisputeModal = (value) => {
    console.log(value);
    setConflictedData(value);
    setShowDispute(true);
  };
  return (
    <>
      <Row style={{ display: solved ? "none" : "flex" }} className="tableRow">
        <Col span={6}>
          <p>{issue}</p>
        </Col>
        <Col span={6}>
          {data.conflicts?.data[issue].map((field) => {
            return (
              <>
                <p className="error-icon">{field.field}</p>{" "}
              </>
            );
          })}
        </Col>

        <Col className="pe-2" span={6}>
          {data.conflicts?.data[issue].map((field) => {
            return (
              <>
                <p
                  onClick={() => showDisputeModal(field)}
                  className="more-details"
                >
                  {t("moreDetails")}
                </p>{" "}
              </>
            );
          })}
        </Col>

        <Col span={6}>
          <Button className="warning-btn">{t("conflicts")}</Button>
        </Col>
      </Row>
      <DisputeDetails
        show={showDispute}
        hide={() => {
          setShowDispute(false);
        }}
        data={conflictedData}
        setSolved={setSolved}
      />
    </>
  );
}
