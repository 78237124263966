import "./CurrencyPages.css";
import { useTranslation } from "react-i18next";
import CurrencyForm from "./CurrencyForm";
import { useEffect, useState } from "react";
import axiosInstance from "../../../api/axios";
import { ToastContainer, toast } from "react-toastify";

import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
function AddCurrency() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState(false);
  const [data, setData] = useState("");
  const [loading2, setLoading2] = useState("");

  let navigate = useNavigate();
  const addCurrency = async (formData) => {
    let newCurrency = {
      currency: data.id,
      rate: formData.currencyExchangeRate,
      operation_type: "add",
    };
    try {
      setLoading(true);
      // Currency
      let result = await axiosInstance.post(
        `/ConversionRateTemp/`,
        newCurrency
      );
      console.log(result);
      toast.success(t("success"), {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });

      setLoading(false);
      navigate("/currency_configuration");
    } catch {
      toast.error(t("failed"), {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });

      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    // Currency
    try {
      let result = await axiosInstance.get(`/Currency/?page_size=200`);
      console.log("currencies  ", result.data);
      setCurrencies(result.data.data);
      setData(result.data.data[0]);

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getSingleData = async (id) => {
    setLoading2(true);
    // Currency
    try {
      let result = await axiosInstance.get(`/Currency/${id}`);
      console.log("Currency  ", result.data);
      setData(result.data);
      setLoading2(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section>
      <ToastContainer />

      <h1 className="pageTitle"> {t("addNewCurrency")}</h1>

      <div className="sectionHolder p-5">
        <h2 className="currancyFormTitle">{t("currencySettings")}</h2>
        <p className="currancyFormsubTitle">{t("currencySettingsSubtitle")} </p>

        {!loading2 ? (
          <CurrencyForm
            formId="addNewCurrency"
            func={addCurrency}
            currencies={currencies}
            edit={false}
            data={data}
            getSingleData={getSingleData}
            loading={loading2}
          />
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <Spin />
          </div>
        )}
      </div>
      <div className="formBtnsHolders">
        <button className="formBtn" disabled>
          <i className="fas fa-trash px-1"></i> {t("discard")}
        </button>
        <button
          className="formBtn"
          form="addNewCurrency"
          key="submit"
          htmlType="submit"
        >
          {loading ? (
            <Spin />
          ) : (
            <>
              <FontAwesomeIcon className="px-2" icon={faCheck} />
              {t("saveThisCurrency")}
            </>
          )}
        </button>
      </div>
    </section>
  );
}

export default ErrorHandler(AddCurrency, axiosInstance);
