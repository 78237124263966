import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight,faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export default function ReportCard({
  icon,
  title,
  route,
}) {
  return (
      <div className="report-card">
        <img src={icon} alt="report icon" />
        <p>{title}</p>
        <Link to={route} className="cardArrow">
         
          <FontAwesomeIcon icon={localStorage.getItem("i18nextLng") === "en" ?faAngleRight:faAngleLeft} />
        </Link>
      </div>
  );
}
