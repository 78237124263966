import React, { useState, useEffect } from "react";
import PaymentForm from "./PaymentForm";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import CheckAuth from "../../HOC/CheckAuth";

function PaymentConfiguration() {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const getPaymentConfig = async () => {
    let result = await axiosInstance.get(`/PaymentConfigs/`);
    console.log(result);
    setData(result.data.data);
  };
  useEffect(() => {
    getPaymentConfig();
  }, []);
  return (
    <CheckAuth Permissions={["account_manager","param_editor"]} userType={["iscore"]}>
      <div>
        <p className="title">{t("paymentConfiguration")}</p>
        {data.map((info) => {
          return <PaymentForm info={info} />;
        })}
      </div>
    </CheckAuth>
  );
}

export default ErrorHandler(PaymentConfiguration, axiosInstance);
