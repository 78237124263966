import React, { useEffect, useMemo, useState } from "react";
import { Button, Row, Col, Form, Switch, Spin, Select } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance, { BASE_URL } from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import arrow from "../../images/arrow.svg";
import SuccessMsg from "../layout/SuccessMsg";
import CheckAuth from "../HOC/CheckAuth";
import {toast, ToastContainer} from "react-toastify";
// function FormField (){
//   return (

//   )
// }
function ReportsSettings() {
    const { t } = useTranslation();

    const [selectedReasons, setSelectedReasons] = useState([]);
    const [data, setData] = useState({});
    const [initialData, setInitialData] = useState({});
    const disableSave = useMemo(
        () => JSON.stringify(initialData) === JSON.stringify(data),
        [data, initialData]
    );
    const [showSuccess, setShowSuccess] = useState(false);
    const rejectionReasons = [
        "1(الرصيد غير كاف/ ويسمح بالدفع الجزئى خارج غرفة المقاصة)",
        "2(مضت المده القانونيه للتقديم)",
        "3(الحساب مغلق)",
        "4(الحساب محل نزاع قانوني)",
        "5(الورقة غير مكودة)",
        "6(الحساب مغلق / مجمد كتعليمات البنك المركزي الرجاء الرجوع على الساحب)",
        "7(خاتم المقاصة غير موجود)",
        "8(غير محرر على نماذج البنك)",
        "9(ورقة مزورة/مزيفة)",
        "10(الفرع مغلق مؤقتا)",
        "11(الحساب محجوز عليه طرفنا)",
        "12(بيان ناقص)",
        "13(بيان تالف بالورقه ( خدش - ممزق - مبتل - مسح - كشط))",
        "14(ينقص توقيع)",
        "15(التظهير ناقص)",
        "16(التوقيع غير صالح / غير مطابق)",
        "17(الشيك غير قابل للتظهير)",
        "18(التظهير غير صحيح)",
        "19(اسم المستفيد غير موجود)",
        "20(الشيك محرر بمداد يسهل إزالته)",
        "21(البيان المعدل يحتاج توقيع/التوقيع علي التعديل غير مطابق)",
        "22(هناك بيان خطأ أو غير واضح - تواريخ متعددة أو مسح أو كشط بالكتابة)",
        "23(اسم المستفيد غير واضح)",
        "24(إختلاف البيانات المدرجة بالملف الالكتروني عن بيانات الشيك)",
        "25(تاريخ الشيك لاحق)",
        "26(إقراركم بإضافة القيمة لحساب المستفيد الاول طرفكم مطلوب)",
        "27(الشيك يحمل اكثر من ختم مقاصة)",
        "28(تاريخ - رقم - مبلغ (بالأرقام / بالحروف) غير واضح)",
        "29(اسباب تقنيه داخل البنك)",
    ];

    const onFinish = async (values) => {
        // values.exclude_rejected = selectedReasons.join(",");
        // console.log(values);

        let result = await axiosInstance
            .post("GlobalVariablesTemp/", {
                real_id: 1,
                operation_type: "edit",
                ...data,
            })
            .catch((err) => {
                if(err.response?.status == "400") {
                    toast.error(t("globalEditError"))
                }
            });

        if (result) {
            setShowSuccess(true);
        }
    };

    const getSettings = async () => {
        try {
            let result = await axiosInstance.get("GlobalVariables/1");
            delete result.data.id;
            delete result.data.changed_by;
            delete result.data.create_date;
            delete result.data.modify_date;
            delete result.data.created_by;
            setSelectedReasons(result?.data?.exclude_rejected?.split(","));
            delete result.data.exclude_rejected;
            setInitialData(result?.data ?? {});
            setData(result?.data ?? {});
        } catch (err) {
            console.log(err);
        }
    };

    const selectReasons = (e) => {
        console.log(e);
        setSelectedReasons(e);
    };

    useEffect(() => {
        console.log(selectedReasons);
    }, [selectedReasons]);

    useEffect(() => {
        getSettings();
    }, []);
    return (
        <>
            <CheckAuth
                Permissions={[
                    "param_editor",
                    "dispute_monitor",
                    "transaction_mointor",
                    "report_viewer",
                    "user_create_checker",
                    "user_create_maker",
                    "account_manager",
                ]}
                userType={["iscore"]}
            >
                <div className="my-5">
                    <p className="title mb-4">{t("sidemenu.settings")}</p>
                    <div className="formWrapper settings">
                        <Row>
                            <Col span={12}>
                                <p className="title1">{t("parameters")}</p>
                            </Col>
                            <Col className="flex-end" span={12}>
                                <p className="title1">{t("action")}</p>
                            </Col>
                        </Row>
                        <Form onFinish={onFinish}>
                            {data ? (
                                Object.entries(data).map((field) => {
                                    return (
                                        <>
                                            {field[0] !== "exclude_rejected" ? (
                                                <Form.Item
                                                    name={field[0]}
                                                    label={t(
                                                        `${field[0]
                                                            .split("_")
                                                            .map(
                                                                (word) =>
                                                                    word
                                                                        .substring(
                                                                            0,
                                                                            1
                                                                        )
                                                                        .toUpperCase() +
                                                                    word.substring(
                                                                        1
                                                                    )
                                                            )
                                                            .join("")}`
                                                    )}
                                                    valuePropName="checked"
                                                    initialValue={
                                                        data !== ""
                                                            ? data[field[0]]
                                                            : false
                                                    }
                                                >
                                                    <Switch
                                                        onChange={(v) =>
                                                            setData((prev) => {
                                                                return {
                                                                    ...prev,
                                                                    [field[0]]:
                                                                        v,
                                                                };
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            ) : (
                                                <>
                                                    {/* <Form.Item
                          name="exclude_rejected"
                          label={t("form.rejectionReasons")}
                          initialValue={selectedReasons}
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            onChange={selectReasons}
                            value={selectedReasons}
                          >
                            {rejectionReasons.map((reason) => {
                              return (
                                <Select.Option key={reason} value={reason}>
                                  {reason}
                                </Select.Option>
                              );
                            })}
                          </Select>
                          <img src={arrow} alt="arrow" />
                        </Form.Item> */}
                                                </>
                                            )}
                                        </>
                                    );
                                })
                            ) : (
                                <div className="flex-center">
                                    <Spin />
                                </div>
                            )}

                            <Row className="text-end">
                                {" "}
                                <Button
                                    disabled={disableSave}
                                    color="primary"
                                    htmlType="submit"
                                    style={{ marginBottom: "1rem" }}
                                    className="formBtn mt-3"
                                >
                                    {t("form.save")}
                                </Button>
                            </Row>
                        </Form>
                    </div>
                    <SuccessMsg
                        show={showSuccess}
                        hide={() => setShowSuccess(false)}
                        text={t("successfulRequest")}
                        btnTxt={t("ok")}
                        subTxt={t("reportsSettings")}
                    />
                    <ToastContainer position="bottom-right" />
                </div>
            </CheckAuth>
        </>
    );
}

export default ErrorHandler(ReportsSettings, axiosInstance);
