import React from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
export default function ConfirmRequest({
  show,
  hide,
  subTxt,
  text,
  btnTxt,
  request,
  color,
  stateLoading,
}) {
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();

  return (
    <Modal dir={lang === "en" ? "ltr" : "rtl"} show={show} onHide={hide}>
      <Container className="p-4">
        <Row>
          <Col sm="12">
            <p className="title1">
              <ExclamationCircleOutlined
                style={{ color: color ? color : "red", padding: "5px" }}
              />
              {text}
            </p>
            <p className="ps-4">{subTxt}</p>
          </Col>
        </Row>
        <Row>
          <Col className="text-end" sm="12">
            <Button
              onClick={hide}
              style={{ borderRadius: "7px" }}
              className="resetBtn2 mx-2"
            >
              {t("form.cancel")}
            </Button>
            <Button
              onClick={request}
              style={{ borderRadius: "7px" }}
              className="resetBtn2"
              disabled={stateLoading}
            >
              {btnTxt}
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
