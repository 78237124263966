import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function CheckAuth({ children, Permissions, userType }) {
  const [auth, setAuth] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const userPermissions = user.permissions;
  const modalPermissions = Permissions;
  const loginUserType = user.user_type;
  const modalUserType = userType;

  function intersect(a, b) {
    return a.filter(Set.prototype.has, new Set(b));
  }

  function found(a, b) {
    return a?.includes(b);
  }
  useEffect(() => {
    let authFlag = intersect(userPermissions, modalPermissions);
    let authFlag2 = found(modalUserType, loginUserType);

    if (authFlag.length !== 0 && authFlag2) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [location.pathname]);

  return <>{auth ? children : null}</>;
}
