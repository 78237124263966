import React, { useEffect, useState } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import DataTabel from "../layout/DataTable";
import { Link } from "react-router-dom";
import CheckAuth from "../HOC/CheckAuth";
import { useSelector } from "react-redux";
import { Space, Pagination, Select, Switch } from "antd";
import ConfirmRequest from "../layout/modals/confirmRequest";
import SuccessMsg from "../layout/SuccessMsg";

function AllUsers() {
    const { t } = useTranslation();
    const userType = useSelector((state) => state.auth.user.user_type);
    const userId = useSelector((state) => state.auth.user.id);
    const permission = useSelector((state) => state.auth.user.permissions);

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedUserType, setSelectedUserType] = useState(
        userType === "entity" ? "3" : userType == "bank" ? "2" : "1"
    );
    const [showConfirm, setShowConfirm] = useState(false);

    // const getUsers = async () => {
    //   let link = "";
    //   if (
    //     (permission.includes("user_create_maker") ||
    //       permission.includes("user_create_checker")) &&
    //     (userType == "bank" || userType == "iscore")
    //   ) {
    //     link = "ListBankUser";
    //   } else if (
    //     (permission.includes("user_create_maker") ||
    //       permission.includes("user_create_checker")) &&
    //     userType == "entity"
    //   ) {
    //     link = "ListEntityUser";
    //   } else {
    //     link = "ListIscoreUser";
    //   }
    //   let result = await axiosInstance.get(
    //     `${link}/?page=${current}&permission=${selectedRole}`
    //   );
    //   if (result) {
    //     console.log(result);
    //     setData(result.data.data);
    //     setCount(result.data.count);
    //   }
    // };
    // useEffect(() => {
    //   getUsers();
    // }, [selectedRole, current ,]);

    const getUsersByType = async () => {
        let result = await axiosInstance.get(
            `${
                selectedUserType == "1"
                    ? "/ListIscoreUser"
                    : selectedUserType == "3"
                    ? "ListEntityUser"
                    : selectedUserType == "2"
                    ? "ListBankUser"
                    : ""
            }/?page=${current}&permission=${selectedRole}`
        );
        if (result) {
            console.log(result);
            setData(result.data.data);
            setCount(result.data.count);
        }
    };
    useEffect(() => {
        if (selectedUserType.length > 0) {
            getUsersByType();
        }
    }, [selectedUserType, current, selectedRole]);

    const columns = [
        {
            title: t("id"),
            dataIndex: "id",
        },
        {
            title: t("form.name"),
            dataIndex: "user",
            render: (row) => <span className="tableCol1">{row?.name}</span>,
        },
        {
            title: t("form.userRole"),
            dataIndex: "permissions",
            render: (row) => row.map((Permission) => Permission.name).join(","),
        },
        {
            title: t("form.email"),
            dataIndex: "user",
            width: 150,
            render: (row) => <span className="tableCol1">{row?.email}</span>,
        },
        {
            title: t("createdBy"),
            dataIndex: "created_by",
            render: (row) => row?.name,
        },
        {
            title: t("dateCreated"),
            dataIndex: "create_date",
            render: (row) => row.split("T")[0],
        },
        {
            title: t("actions"),
            key: "action",
            render: (row) => (
                <Space size="middle">
                    <p className="my-0">
                        <Link
                            to={`/${
                                selectedUserType == "1"
                                    ? "profile"
                                    : selectedUserType == "3"
                                    ? "viewEntityUser"
                                    : selectedUserType == "2"
                                    ? "viewBankUser"
                                    : ""
                            }/${row.id}`}
                        >
                            {t("Details")}
                        </Link>
                    </p>
                    {/* <CheckAuth
            Permissions={["user_create_maker", "user_create_checker"]}
            userType={["iscore", "entity", "bank"]}
          >
            {row.user.id === userId ? null : (
              <Button
                danger
                onClick={() => {
                  setShow(true);
                  setSelectedUser(row?.user?.id);
                }}
              >
                Delete
              </Button>
            )}
          </CheckAuth> */}

                    {/* <CheckAuth
            Permissions={["iscore_account_manager"]}
            userType={["iscore"]}
          >
            {row.user.is_verified ? null : (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowConfirm(true);
                    setSelectedUser(row?.user?.id);
                  }}
                >
                  Verify
                </Button>
              </>
            )}
          </CheckAuth> */}
                    <CheckAuth
                        Permissions={["iscore_account_manager"]}
                        userType={["iscore"]}
                    >
                        <>
                            {row.user.id === userId ? null : (
                                <>
                                    <Switch
                                        checked={row.user.is_active}
                                        onChange={(e) =>
                                            toggleActive(e, row?.id, row)
                                        }
                                    />
                                </>
                            )}
                        </>
                    </CheckAuth>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        console.log("userType", userType);
    }, []);

    // const toggleVerify = async (id) => {
    //   let result = await axiosInstance
    //     .put(`/User/${id}`, { is_verified: true })
    //     .catch((err) => console.log(err));

    //   if (result) {
    //     setShowConfirm(false);
    //     getUsers();
    //   }
    // };

    const toggleActive = async (e, id) => {
        const endpoint =
            selectedUserType == "1"
                ? "ListIscoreUser"
                : selectedUserType == "3"
                ? "ListEntityUser"
                : selectedUserType == "2"
                ? "ListBankUser"
                : "";
        console.log({ endpoint });
        setData((prev) =>
            prev.map((it) =>
                it.id === id
                    ? { ...it, user: { ...it.user, is_active: e } }
                    : it
            )
        );
        await axiosInstance
            .put(`/${endpoint}/${id}`, { is_active: e })
            .catch((err) => console.log(err));
    };

    // const deleteUser = async (id) => {
    //   console.log(id);
    //   let result = await axiosInstance.delete(
    //     userType === "iscore"
    //       ? `/ListIscoreUser/${id}`
    //       : userType === "entity"
    //       ? `/ListEntityUser/${id}`
    //       : `/ListBankUser/${id}`
    //   );

    //   if (result) {
    //     setShow(false);
    //     setShowSuccess(true);
    //     getUsers();
    //   }
    // };
    const handleChange = async (page) => {
        setCurrent(page);
    };
    const handleSelect = (set, e) => {
        setCurrent(1);
        set(e);
    };

    const getRoles = async () => {
        let result;

        result = await axiosInstance.get(
            `${
                selectedUserType == "1"
                    ? "/IscorePermission"
                    : selectedUserType == "3"
                    ? "/EntityPermission"
                    : "/BankPermission"
            }/?page_size=500`
        );

        console.log(result);
        setRoles(result.data.data);
    };

    return (
        <div>
            <CheckAuth
                Permissions={[
                    "user_create_checker",
                    "iscore_account_manager",
                    "user_create_maker",
                ]}
                userType={["iscore", "entity", "bank"]}
            >
                <p className="title mb-5">{t("users")}</p>
                <CheckAuth
                    Permissions={[
                        "iscore_account_manager",
                        // "user_create_maker"
                        // "param_editor",
                    ]}
                    userType={["iscore"]}
                >
                    <Select
                        mode="multiple"
                        onChange={(e) => handleSelect(setSelectedRole, e)}
                        value={selectedRole}
                        onClick={getRoles}
                        style={{ width: "300px" }}
                        placeholder={t("filterByRole")}
                    >
                        {roles.map((role) => {
                            return (
                                <Select.Option key={role.id} value={role.key}>
                                    {role.name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </CheckAuth>
                <CheckAuth
                    Permissions={[
                        "user_create_checker",
                        "user_create_maker",
                        "iscore_account_manager",
                    ]}
                    userType={["iscore"]}
                >
                    <Select
                        onChange={(e) => {
                            handleSelect(setSelectedUserType, e);
                            setSelectedRole([]);
                        }}
                        value={selectedUserType}
                        style={{ width: "300px", margin: "15px" }}
                        placeholder={t("filterByuserType")}
                    >
                        {/* {permission.includes(
              "iscore_account_manager",
              "user_create_maker",
              "user_create_checker"
            ) ? ( */}
                        <Select.Option value="1">Iscore Users</Select.Option>
                        {/* ) : null} */}
                        <Select.Option value="2">Bank Users</Select.Option>
                        <Select.Option value="3">Entity Users</Select.Option>
                    </Select>
                </CheckAuth>
                <DataTabel data={data} columns={columns} columnsAr={columns} />
                {data.length > 0 ? (
                    <Pagination
                        onChange={handleChange}
                        total={count}
                        defaultCurrent={current}
                        current={current}
                    />
                ) : null}
            </CheckAuth>
            {/* <ConfirmRequest
        show={show}
        hide={() => setShow(false)}
        btnTxt={t("delete")}
        text={t("deleteUser")}
        subTxt={t("userDeletion")}
        // request={() => deleteUser(selectedUser)}
      /> */}
            {/* <SuccessMsg
        show={showSuccess}
        hide={() => setShowSuccess(false)}
        btnTxt={t("Ok")}
        text={t("deleteUserSuccess")}
        subTxt={t("userDeletion")}
      /> */}
            {/* <ConfirmRequest
        show={showConfirm}
        hide={() => setShowConfirm(false)}
        btnTxt={t("form.confirm")}
        text={t("verifyMsg")}
        color="#1a5785"
        request={() => toggleVerify(selectedUser)}
      /> */}
        </div>
    );
}

export default ErrorHandler(AllUsers, axiosInstance);
