export const  rejectionReasons = [
  "1(الرصيد غير كاف/ ويسمح بالدفع الجزئى خارج غرفة المقاصة)",
  "2(مضت المده القانونيه للتقديم)",
  "3(الحساب مغلق)",
  "4(الحساب محل نزاع قانوني)",
  "5(الورقة غير مكودة)",
  "6(الحساب مغلق / مجمد كتعليمات البنك المركزي الرجاء الرجوع على الساحب)",
  "7(خاتم المقاصة غير موجود)",
  "8(غير محرر على نماذج البنك)",
  "9(ورقة مزورة/مزيفة)",
  "10(الفرع مغلق مؤقتا)",
  "11(الحساب محجوز عليه طرفنا)",
  "12(بيان ناقص)",
  "13(بيان تالف بالورقه ( خدش - ممزق - مبتل - مسح - كشط))",
  "14(ينقص توقيع)",
  "15(التظهير ناقص)",
  "16(التوقيع غير صالح / غير مطابق)",
  "17(الشيك غير قابل للتظهير)",
  "18(التظهير غير صحيح)",
  "19(اسم المستفيد غير موجود)",
  "20(الشيك محرر بمداد يسهل إزالته)",
  "21(البيان المعدل يحتاج توقيع/التوقيع علي التعديل غير مطابق)",
  "22(هناك بيان خطأ أو غير واضح - تواريخ متعددة أو مسح أو كشط بالكتابة)",
  "23(اسم المستفيد غير واضح)",
  "24(إختلاف البيانات المدرجة بالملف الالكتروني عن بيانات الشيك)",
  "25(تاريخ الشيك لاحق)",
  "26(إقراركم بإضافة القيمة لحساب المستفيد الاول طرفكم مطلوب)",
  "27(الشيك يحمل اكثر من ختم مقاصة)",
  "28(تاريخ - رقم - مبلغ (بالأرقام / بالحروف) غير واضح)",
  "29(اسباب تقنيه داخل البنك)"
];
