import { React, useEffect, useState } from "react";
import CustomSelect from "./CustomSelect";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, DatePicker, Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";

const { RangePicker } = DatePicker;

function UsageReport({ title, endpoint }) {
    const location = useLocation();
    const { t } = useTranslation();
    const userType = useSelector((state) => state.auth.user.user_type);

    const [date, setDate] = useState("");

    const [branches, setBranches] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [entities, setEntities] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [makerUsers, setMakerUsers] = useState([]);
    const [selectedMakers, setSelectedMakers] = useState([]);
    const [checkerUsers, setCheckerUsers] = useState([]);
    const [selectedCheckers, setSelectedCheckers] = useState([]);
    const [next, setNext] = useState("");
    const [data, setData] = useState("");

    const [usageUsers, setUsageUsers] = useState([]);
    const [selectedUsageUsers, setSelectedUsageUsers] = useState([]);
    const [selectedType, SetSelectedType] = useState(
        userType === "bank" ? "bank" : userType === "entity" ? "entity" : ""
    );

    const getData = async (link, setFunction, next) => {
        let result = await axiosInstance.get(`${link}`);
        if (result) {
            setFunction(result.data.data);
            setNext(result.data.next);
        }
    };

    const handleOnChange = (datee, dateString) => {
        console.log(datee);
        setDate(dateString);
    };

    const getReport = async () => {
        console.log(date, selectedBranches);
        let result = await axiosInstance
            .get(
                `${endpoint}?entity_type=${selectedType}&entity=${selectedEntities.join(
                    ","
                )}&branch=${selectedBranches.join(
                    ","
                )}&user=${selectedMakers.join(",")},${selectedCheckers.join(
                    ","
                )}&start_date=${date[0] ?? ""}&end_date=${date[1] ?? ""}`
            )
            .catch((err) => console.log("getReportError", err));

        if (result) {
            console.log("getReport", result);
            setData(result.data);
            document.getElementById("downloadBtn").click();
        }
        console.log("out", document.getElementById("downloadBtn"));
    };

    useEffect(() => {
        setSelectedBranches([]);
        setSelectedEntities([]);

        console.log("location.pathname", location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        console.log("userType", userType);
        console.log("entities", entities);

        console.log("selectedEntities", selectedEntities);
        console.log("selectedEntities2", selectedEntities.join(","));

        console.log(entities.map((u) => u.name).join(", "));
    }, [entities, userType]);

    return (
        <div>
            <p className="report-title">{title}</p>
            <div className="formWrapper">
                <p className="report-filters">{t("reportFilters")}</p>
                <p className="filters-subtext">{t("filterReportText")}</p>

                {userType === "iscore" ? (
                    <>
                        <div className="mb-3">
                            <label>{t("form.entityType")} </label>

                            <Select
                                suffixIcon={""}
                                style={{ width: "100%" }}
                                onChange={(e) => SetSelectedType(e)}
                                value={selectedType}
                                placeholder={t("filterByRole")}
                            >
                                <Select.Option key={"Bank"} value={"bank"}>
                                    Bank
                                </Select.Option>
                                <Select.Option key={"Entity"} value={"entity"}>
                                    Entity
                                </Select.Option>
                            </Select>
                        </div>
                        <CustomSelect
                            disabled={selectedType === "" ? true : false}
                            getData={() =>
                                selectedType !== ""
                                    ? getData(
                                          `/${
                                              selectedType === "bank"
                                                  ? "Bank"
                                                  : "Entity"
                                          }/`,
                                          setEntities
                                      )
                                    : null
                            }
                            label={t("nameof")}
                            data={entities}
                            setData={setEntities}
                            setSelected={setSelectedEntities}
                            selected={selectedEntities}
                            next={next}
                            setNext={setNext}
                        />
                    </>
                ) : null}

                <CustomSelect
                    disabled={selectedType === "" ? true : false}
                    getData={() =>
                        getData(
                            `/${
                                selectedType === "bank"
                                    ? `BankBranch/?bank=${selectedEntities.join(
                                          ","
                                      )}`
                                    : `EntityBranch/?entity=${selectedEntities.join(
                                          ","
                                      )}`
                            }`,
                            setBranches
                        )
                    }
                    label={t("branchName")}
                    data={branches}
                    multiple
                    setData={setBranches}
                    setSelected={setSelectedBranches}
                    selected={selectedBranches}
                    next={next}
                    setNext={setNext}
                />
                <CustomSelect
                    getData={() =>
                        getData(
                            `/${
                                selectedType === "bank"
                                    ? "ListBankUser"
                                    : "ListEntityUser"
                            }/?permission=${
                                selectedType === "bank"
                                    ? "entity_maker"
                                    : "drawer_inquiry"
                            }&entity=${selectedEntities.join(
                                ","
                            )}&branch=${selectedBranches.join(",")}`,
                            setMakerUsers
                        )
                    }
                    label={
                        location.pathname === "/usage_inquiry_report"
                            ? t("drawerInquiry")
                            : t("userMaker")
                    }
                    data={makerUsers}
                    setData={setMakerUsers}
                    setSelected={setSelectedMakers}
                    selected={selectedMakers}
                    next={next}
                    setNext={setNext}
                />
                <CustomSelect
                    getData={() =>
                        getData(
                            `/${
                                selectedType === "bank"
                                    ? "ListBankUser"
                                    : "ListEntityUser"
                            }/?permission=${
                                selectedType === "bank"
                                    ? "entity_checker"
                                    : "beneficiary_inquiry"
                            }&entity=${selectedEntities.join(
                                ","
                            )}&branch=${selectedBranches.join(",")}`,
                            setCheckerUsers
                        )
                    }
                    label={
                        location.pathname === "/usage_inquiry_report"
                            ? t("beneficiaryInquiry")
                            : t("userChecker")
                    }
                    data={checkerUsers}
                    setData={setCheckerUsers}
                    setSelected={setSelectedCheckers}
                    selected={selectedCheckers}
                    next={next}
                    setNext={setNext}
                />

                <Row className="mb-3">
                    <label className="mb-3">{t("SelectReportDate")}</label>
                    <Col span={24}>
                        <RangePicker
                            allowClear
                            placeholder={[t("startDate"), t("endDate")]}
                            onChange={handleOnChange}
                            style={{ height: "40px" }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex text-end" span={24}>
                        <Button
                            onClick={getReport}
                            style={{ fontSize: "16px" }}
                            className="formBtn"
                        >
                            + {t("generateReport")}
                        </Button>
                    </Col>
                </Row>

                <CSVLink
                    data={data}
                    filename={"report.csv"}
                    id="downloadBtn"
                    style={{ display: "none" }}
                >
                    Download me
                </CSVLink>
            </div>
        </div>
    );
}

export default ErrorHandler(UsageReport, axiosInstance);
