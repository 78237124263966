import React, {useState, useEffect} from "react";
import {Row, Col} from "antd";
import {PaperClipOutlined} from "@ant-design/icons";
import axiosInstance from "../../../api/axios";
import {useTranslation} from "react-i18next";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import {Link} from "react-router-dom";

function UploadedHistory({uploaded, filterValue}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    useEffect(() => {
        getHistory();
    }, [uploaded, filterValue]);
    const getHistory = async () => {
        let result = await axiosInstance.get(`/UploadSession/?filter=${filterValue}?page_size=3`);
        console.log(result);
        setData(result.data.data);
    };
    return (
        <div className="my-3">
            {data?.map((info) => {
                return (
                    <Row>
                        <Col span={12}>
                            {/* <a target="_blank" href={`${BASE_URL}` + `${info.file}`}> */}
                            <PaperClipOutlined/>
                            <span className="uploadedFileName">
                {info.file.split("/")[5]}
              </span>
                            {/* </a>{" "} */}
                            <span className="uploadDate">
                Uploaded ({info.create_date.split("T")[0].split(/-/g).reverse().join("-")})
              </span>
                        </Col>
                        <Col className="text-end" span={12}>
                            <p>
                                <a target="_blank" download="" href={`${info.file}`}>
                                    {t("download")}
                                </a>
                            </p>
                        </Col>
                    </Row>
                );
            })}
            <Link to="/upload_history">{t("uploadHistory")}</Link>
        </div>
    );
}

export default ErrorHandler(UploadedHistory, axiosInstance);
