import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout, ConfigProvider, Empty } from "antd";
import Sidemenu from "./components/layout/sidemenu";
import Header from "./components/layout/HeaderComp";
import Login from "./components/pages/Login";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "./components/contexts/LanguageContext";
import Wrapper from "./components/pages/ChequeData/wrapper";
import BulkUpload from "./components/pages/Bulk_Upload/bulkUpload";
import InquiryReport from "./components/reports/InquiryReport";
import Home from "./components/pages/Home";
import { useSelector } from "react-redux";
import ForgetPassword from "./components/pages/ForgetPassword";
import SetNewPassword from "./components/pages/SetNewPassword";
import ChangePassword from "./components/pages/ChangePassword/changePassword";
import OldNewPassword from "./components/pages/ChangePassword/oldNewPassword";
import UsageReport from "./components/reports/usageReport";
import AllDisputes from "./components/pages/Disputes/allDisputes";
import ChequeData from "./components/pages/ChequeData/chequeData";
import ReportsSettings from "./components/reports/reportsSettings";
import UserCreationEntity from "./components/pages/userCreationEntity";
import EmailConfig from "./components/pages/emailConfig";
import BranchesConfiguration from "./components/pages/branchesConfiguration";
import Logs from "./components/pages/Logs";
import Invoice from "./components/pages/invoice";
import PaymentConfiguration from "./components/pages/payment_configurations/PaymentConfiguration";
import InvoiceMechanism from "./components/pages/invoiceMechanism";
import AllUsers from "./components/pages/AllUsers";
import Profile from "./components/pages/profile";
import CreateDispute from "./components/pages/Disputes/createDispute";
import UserCreationIscore from "./components/pages/UserCreationIscore";
import DataVerification from "./components/pages/DataVerification";
import AllEntityTypes from "./components/pages/AllEntityTypes";
import AllBranches from "./components/pages/AllBranches";
import DataList from "./components/dataList";
import DraftedData from "./components/pages/ChequeData/DraftedData";
import UploadHistoryTable from "./components/pages/Bulk_Upload/uploadHistoryTable";
import FooterComp from "./components/layout/FooterComp";
import CustomizeReport from "./components/pages/CustomizeReport";
import Notfound from "./components/Notfound";
import AllCurrencies from "./components/pages/CurrencyConfiguration/AllCurrencies";
import EditCurrency from "./components/pages/CurrencyConfiguration/EditCurrency";
import AddCurrency from "./components/pages/CurrencyConfiguration/AddCurrency";
import CurrenciesLogs from "./components/pages/CurrencyConfiguration/CurrenciesLogs";
import EscalationUsers from "./components/pages/EscalationConfiguration/EscalationUsers";
import BankerEscalationUsers from "./components/pages/EscalationConfiguration/BankerEscalationUsers";
import AddEscalation from "./components/pages/EscalationConfiguration/AddEscalation";
import EditEscalation from "./components/pages/EscalationConfiguration/EditEscalation";
import RejectionReasonConfig from "./components/pages/rejection_config/RejectionReasonConfig";
import RejectionLogs from "./components/pages/rejection_config/RejectionLogs";
import RejectionReasonsForm from "./components/pages/Disputes/RejectionReasonsForm";
import DisputeReport from "./components/reports/DisputeReport";
import BulkFilesView from "./components/pages/BulkFilesView";
import ParamEditorHomeMaker from "./components/pages/ParamEditorPages/ParamEditorHomeMaker";
import ParamEditorHomeChecker from "./components/pages/ParamEditorPages/ParamEditorHomeChecker";

import ReportConfigurationsPage from "./components/pages/ParamEditorPages/ReportConfigurationsPage";
import ReportParametersPage from "./components/pages/ParamEditorPages/ReportParametersPage";
import ActionsStateChecker from "./components/pages/ParamEditorPages/ActionsStateChecker";
import ActionsStateMaker from "./components/pages/ParamEditorPages/ActionsStateMaker";
import HistoricalReportSession from "./components/pages/HistoricalReportSession";
import ar_EG from "antd/es/locale/ar_EG";
import en_US from "antd/es/locale/en_US";

const { Content, Footer, Sider } = Layout;

function App() {
    const [language, setLanguage] = useState(
        localStorage.getItem("i18nextLng") ?? "ar"
    );
    const [direction, setDirection] = useState(
        localStorage.getItem("i18nextLng") === "en" ? "ltr" : "rtl"
    );
    const { t, i18n } = useTranslation();
    const isAuth = useSelector((state) => state.auth.isAuth);
    const user = useSelector((state) => state.auth.user);
    const permissions = user.permissions;
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, direction]);

    const customizeRenderEmpty = () => (
        <div style={{ textAlign: "center" }}>
            <Empty description={t("noData")} />
        </div>
    );

    return (
        <LanguageContext.Provider value={{ setLanguage, language }}>
            <ConfigProvider
                direction={i18n.language === "ar" ? "rtl" : "ltr"}
                locale={i18n.language === "ar" ? ar_EG : en_US}
                renderEmpty={customizeRenderEmpty}
            >
                <Router>
                    <Layout
                        dir={
                            localStorage.getItem("i18nextLng") === "ar"
                                ? "rtl"
                                : "ltr"
                        }
                        className={
                            localStorage.getItem("i18nextLng") === "ar"
                                ? "rtl"
                                : "ltr"
                        }
                        style={{ minHeight: "100vh" }}
                    >
                        {/* <div dir="ltr" className="App pagePadding"> */}
                        {isAuth ? (
                            <>
                                <Sidemenu direction={direction} />

                                <Layout className="site-layout">
                                    <Header
                                        direction={direction}
                                        lang={language}
                                        setDirection={setDirection}
                                        setLanguage={setLanguage}
                                    />
                                    <>
                                        <Content className="pagePadding">
                                            <div className="">
                                                <Routes>
                                                    {/* start of currencies routes */}
                                                    <Route
                                                        exact
                                                        path="/currency_configuration"
                                                        element={
                                                            <AllCurrencies />
                                                        }
                                                    />
                                                    <Route
                                                        exact
                                                        path="/escalation_users"
                                                        element={
                                                            <EscalationUsers />
                                                        }
                                                    />
                                                    <Route
                                                        exact
                                                        path="/banker_escalation_users"
                                                        element={
                                                            <BankerEscalationUsers />
                                                        }
                                                    />

                                                    <Route
                                                        exact
                                                        path="/edit_currency/:id"
                                                        element={
                                                            <EditCurrency />
                                                        }
                                                    />
                                                    <Route
                                                        exact
                                                        path="/currencies_logs"
                                                        element={
                                                            <CurrenciesLogs />
                                                        }
                                                    />

                                                    <Route
                                                        exact
                                                        path="/add_currency"
                                                        element={
                                                            <AddCurrency />
                                                        }
                                                    />
                                                    {/* end of currencies routes */}

                                                    <Route
                                                        exact
                                                        path="/transaction"
                                                        element={<Wrapper />}
                                                    />
                                                    <Route
                                                        path="/bulk_upload"
                                                        element={
                                                            <BulkUpload
                                                                link="/BulkCreate/"
                                                                template="/media/report_templates/bulk_cheque.xlsx"
                                                                title={t(
                                                                    "bulkTransaction"
                                                                )}
                                                                description={t(
                                                                    "templateInstruction4"
                                                                )}
                                                                filterValue="1"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/cheque_book"
                                                        element={
                                                            <BulkUpload
                                                                link="/ChequeBook/"
                                                                template="/media/report_templates/cheque_book.xlsx"
                                                                title={t(
                                                                    "bulkCheque"
                                                                )}
                                                                filterValue="2"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/benef_upload"
                                                        element={
                                                            <BulkUpload
                                                                link="/UpdateBeneficiary/"
                                                                // template="/media/report_templates/bulk_cheque.xlsx"
                                                                title={t(
                                                                    "benefCheque"
                                                                )}
                                                                description={t(
                                                                    "templateInstruction4"
                                                                )}
                                                                filterValue="3"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/upload_history"
                                                        element={
                                                            <UploadHistoryTable />
                                                        }
                                                    />

                                                    <Route
                                                        path="/drawee_report"
                                                        element={
                                                            <InquiryReport
                                                                title={t(
                                                                    "draweeData"
                                                                )}
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/beneficiary_report"
                                                        element={
                                                            <InquiryReport
                                                                title={t(
                                                                    "beneficiaryData"
                                                                )}
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/home"
                                                        element={<Home />}
                                                    />
                                                    <Route
                                                        path="/"
                                                        element={<Home />}
                                                    />
                                                    <Route
                                                        path="/usage_report"
                                                        element={
                                                            <UsageReport />
                                                        }
                                                    />

                                                    <Route
                                                        path="/usage_inquiry_report"
                                                        element={
                                                            <UsageReport
                                                                endpoint="/InquiryUsageReport/"
                                                                title={t(
                                                                    "sidemenu.usageInquiryReport"
                                                                )}
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/detailed_usage_report"
                                                        element={
                                                            <UsageReport
                                                                endpoint="/TransactionsReport/"
                                                                title={t(
                                                                    "sidemenu.detailedUsageReport"
                                                                )}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/BulkFilesView"
                                                        element={
                                                            <BulkFilesView />
                                                        }
                                                    />

                                                    {/* <Route
                          path="/detailed_entity_usage_report"
                          element={
                            <UsageReport
                              endpoint="/DetailedEntityUsageReport/"
                              title={t("sidemenu.detailedEntityUsageReport")}
                            />
                          }
                        /> */}

                                                    <Route
                                                        path="/dispute_report"
                                                        element={
                                                            <DisputeReport
                                                                endpoint="/DisputeReport/"
                                                                title={t(
                                                                    "disputeReport"
                                                                )}
                                                            />
                                                        }
                                                    />

                                                    {/* <Route
                          path="/consolidated_usage_report"
                          element={
                            <UsageReport
                              endpoint="/ConsolidatedUsageReport/"
                              title={t("sidemenu.consolidatedUsageReport")}
                            />
                          }
                        /> */}

                                                    <Route
                                                        path="/all_disputes"
                                                        element={
                                                            <AllDisputes />
                                                        }
                                                    />
                                                    <Route
                                                        path="/transaction_dispute"
                                                        element={
                                                            <ChequeData
                                                                dispute
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/personal_profile"
                                                        element={<Profile />}
                                                    />
                                                    <Route
                                                        path="/report_settings"
                                                        element={
                                                            <ReportsSettings />
                                                        }
                                                    />
                                                    <Route
                                                        path="/user_creation_entity"
                                                        element={
                                                            <UserCreationEntity />
                                                        }
                                                    />
                                                    <Route
                                                        path="/user_creation_iscore"
                                                        element={
                                                            <UserCreationIscore />
                                                        }
                                                    />

                                                    <Route
                                                        path="/email_configuration"
                                                        element={
                                                            <EmailConfig />
                                                        }
                                                    />
                                                    <Route
                                                        path="/invoice"
                                                        element={
                                                            <InvoiceMechanism />
                                                        }
                                                    />
                                                    <Route
                                                        path="/payment_configuration"
                                                        element={
                                                            <PaymentConfiguration />
                                                        }
                                                    />

                                                    <Route
                                                        path="/branch_configuration"
                                                        element={
                                                            <BranchesConfiguration />
                                                        }
                                                    />
                                                    <Route
                                                        path="/create_dispute"
                                                        element={
                                                            <CreateDispute />
                                                        }
                                                    />

                                                    <Route
                                                        path="/logs"
                                                        element={<Logs />}
                                                    />
                                                    <Route
                                                        path="/invoices"
                                                        element={
                                                            <Invoice
                                                                lang={language}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/users"
                                                        element={<AllUsers />}
                                                    />
                                                    <Route
                                                        path="/profile/:id"
                                                        element={<Profile />}
                                                    />
                                                    <Route
                                                        path="/viewBankUser/:id"
                                                        element={
                                                            <Profile
                                                                checker={true}
                                                                isbank
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/viewEntityUser/:id"
                                                        element={
                                                            <Profile
                                                                checker={true}
                                                                isentity
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/verify_data"
                                                        element={
                                                            <DataVerification />
                                                        }
                                                    />
                                                    <Route
                                                        path="/entity_types"
                                                        element={
                                                            <AllEntityTypes
                                                                isType
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/entities/:id"
                                                        element={
                                                            <AllEntityTypes
                                                                checkBank
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/branches/:type/:id"
                                                        element={
                                                            <AllBranches />
                                                        }
                                                    />
                                                    <Route
                                                        path="/drafts"
                                                        element={
                                                            <DataList
                                                                type="drafts"
                                                                title={t(
                                                                    "drafts"
                                                                )}
                                                                endpoint="/DraftCheque/"
                                                                description={t(
                                                                    "draftDescription"
                                                                )}
                                                                cheque
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/accepted_cheques"
                                                        element={
                                                            <DataList
                                                                type="accepted_cheques"
                                                                title={t(
                                                                    "acceptedCheques"
                                                                )}
                                                                endpoint="/FilterCheque/?state=accepted"
                                                                cheque
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/rejected_cheques"
                                                        element={
                                                            <DataList
                                                                type="rejected_cheques"
                                                                title={t(
                                                                    "rejectedCheques"
                                                                )}
                                                                endpoint="/FilterCheque/?state=rejected"
                                                                cheque
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/pending_cheques"
                                                        element={
                                                            <DataList
                                                                type="pending_cheques"
                                                                title={t(
                                                                    "pendingCheques"
                                                                )}
                                                                endpoint="/FilterCheque/?state=pending"
                                                                cheque
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/drawers"
                                                        element={
                                                            <DataList
                                                                modifyPermission={
                                                                    !permissions?.includes(
                                                                        "entity_maker"
                                                                    )
                                                                }
                                                                disableCheck={
                                                                    permissions?.includes(
                                                                        "transaction_monitor"
                                                                    ) ||
                                                                    permissions?.includes(
                                                                        "account_manager"
                                                                    )
                                                                }
                                                                type="pending_beneficiary"
                                                                title={t(
                                                                    "pendingBeneficiary"
                                                                )}
                                                                endpoint="/FilterCheque/?state=pending_beneficiary"
                                                                disableRowSelection={(
                                                                    row
                                                                ) =>
                                                                    row.beneficiary_state !==
                                                                    "3"
                                                                }
                                                                cheque
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/beneficiary"
                                                        element={
                                                            <DataList
                                                                modifyPermission={
                                                                    !permissions?.includes(
                                                                        "entity_maker"
                                                                    )
                                                                }
                                                                disableCheck={
                                                                    permissions?.includes(
                                                                        "transaction_monitor"
                                                                    ) ||
                                                                    permissions?.includes(
                                                                        "account_manager"
                                                                    )
                                                                }
                                                                type="pending_drawer"
                                                                title={t(
                                                                    "pendingDrawer"
                                                                )}
                                                                disableRowSelection={(
                                                                    row
                                                                ) =>
                                                                    row.state !==
                                                                    "3"
                                                                }
                                                                endpoint="/FilterCheque/?state=pending_drawer"
                                                                cheque
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/accepted_disputes"
                                                        element={
                                                            <DataList
                                                                type="accepted_disputes"
                                                                title={t(
                                                                    "acceptedDisputes"
                                                                )}
                                                                endpoint="/FilterDispute/?state=accepted"
                                                                dispute
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/rejected_disputes"
                                                        element={
                                                            <DataList
                                                                type="rejected_disputes"
                                                                title={t(
                                                                    "rejectedDisputes"
                                                                )}
                                                                endpoint="/FilterDispute/?state=rejected"
                                                                dispute
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/pending_disputes"
                                                        element={
                                                            <DataList
                                                                disableCheck
                                                                type="pending_disputes"
                                                                title={t(
                                                                    "pendingDisputes"
                                                                )}
                                                                endpoint="/FilterDispute/?state=pending"
                                                                dispute
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/under_review"
                                                        element={
                                                            <DataList
                                                                type="under_review"
                                                                title={t(
                                                                    "underReview"
                                                                )}
                                                                endpoint="/FilterDispute/?state=under_review"
                                                                dispute
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/created"
                                                        element={
                                                            <DataList
                                                                type="created"
                                                                title={t(
                                                                    "created"
                                                                )}
                                                                endpoint="/FilterDispute/"
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/entity_pending_users"
                                                        element={
                                                            <DataList
                                                                type="pending_users"
                                                                title={t(
                                                                    "pendingUsers"
                                                                )}
                                                                endpoint="/ListEntityUser/?user_state=1"
                                                                user
                                                                isEntity
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/entity_accepted_users"
                                                        element={
                                                            <DataList
                                                                type="accepted_users"
                                                                title={t(
                                                                    "acceptedUsers"
                                                                )}
                                                                endpoint="/ListEntityUser/?user_state=2"
                                                                user
                                                                isEntity
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/entity_rejected_users"
                                                        element={
                                                            <DataList
                                                                type="rejected_users"
                                                                title={t(
                                                                    "rejectedUsers"
                                                                )}
                                                                endpoint="/ListEntityUser/?user_state=3"
                                                                user
                                                                isEntity
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/bank_pending_users"
                                                        element={
                                                            <DataList
                                                                type="pending_users"
                                                                title={t(
                                                                    "pendingUsers"
                                                                )}
                                                                endpoint="/ListBankUser/?user_state=1"
                                                                user
                                                                isBank
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/bank_accepted_users"
                                                        element={
                                                            <DataList
                                                                type="accepted_users"
                                                                title={t(
                                                                    "acceptedUsers"
                                                                )}
                                                                endpoint="/ListBankUser/?user_state=2"
                                                                user
                                                                isBank
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/bank_rejected_users"
                                                        element={
                                                            <DataList
                                                                type="rejected_users"
                                                                title={t(
                                                                    "rejectedUsers"
                                                                )}
                                                                endpoint="/ListBankUser/?user_state=3"
                                                                user
                                                                isBank
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/draft_cheque/:id"
                                                        element={
                                                            <DraftedData
                                                                isChecker={
                                                                    false
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/edit_cheque/:id"
                                                        element={
                                                            <DraftedData bene />
                                                        }
                                                    />
                                                    <Route
                                                        path="/reject_cheque_details/:id"
                                                        element={
                                                            <DraftedData
                                                                rejected
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/check_cheque/:id"
                                                        element={
                                                            <DraftedData
                                                                isChecker
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path="/customize_report"
                                                        element={
                                                            <CustomizeReport />
                                                        }
                                                    />

                                                    <Route
                                                        path="/confirm_change_password/:code"
                                                        element={
                                                            <OldNewPassword
                                                                firstTime
                                                            />
                                                        }
                                                    />

                                                    <Route
                                                        path="/add_escalation/:id"
                                                        element={
                                                            <AddEscalation />
                                                        }
                                                    />
                                                    <Route
                                                        path="/add_escalation"
                                                        element={
                                                            <AddEscalation />
                                                        }
                                                    />

                                                    <Route
                                                        path="/edit_escalation/:id"
                                                        element={
                                                            <EditEscalation />
                                                        }
                                                    />
                                                    <Route
                                                        path="/rejection_config"
                                                        element={
                                                            <RejectionReasonConfig />
                                                        }
                                                    />
                                                    <Route
                                                        path="/rejection_logs"
                                                        element={
                                                            <RejectionLogs />
                                                        }
                                                    />
                                                    <Route
                                                        path="/view_dispute_details/:id"
                                                        element={
                                                            <RejectionReasonsForm />
                                                        }
                                                    />

                                                    <Route
                                                        path="/ParamEditorHomeMaker"
                                                        element={
                                                            <ParamEditorHomeMaker />
                                                        }
                                                    />

                                                    <Route
                                                        path="/ParamEditorHomeChecker"
                                                        element={
                                                            <ParamEditorHomeChecker />
                                                        }
                                                    />

                                                    <Route
                                                        path="/ReportConfigurationsPage"
                                                        element={
                                                            <ReportConfigurationsPage />
                                                        }
                                                    />

                                                    <Route
                                                        path="/ReportParametersPage/:id"
                                                        element={
                                                            <ReportParametersPage />
                                                        }
                                                    />

                                                    <Route
                                                        path="/ActionsStateChecker/:state"
                                                        element={
                                                            <ActionsStateChecker />
                                                        }
                                                    />

                                                    <Route
                                                        path="/ActionsStateMaker/:state"
                                                        element={
                                                            <ActionsStateMaker />
                                                        }
                                                    />
                                                    <Route
                                                        path="/HistoricalReportSession/:id"
                                                        element={
                                                            <HistoricalReportSession />
                                                        }
                                                    />
                                                </Routes>
                                            </div>
                                        </Content>
                                    </>
                                </Layout>
                            </>
                        ) : (
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <Login
                                            lang={language}
                                            setDirection={setDirection}
                                            setLanguage={setLanguage}
                                        />
                                    }
                                />
                                <Route
                                    path="/forget_password"
                                    element={<ForgetPassword />}
                                />
                                <Route
                                    path="/set_new_password"
                                    element={<SetNewPassword />}
                                />
                                <Route
                                    path="/change_password"
                                    element={<ChangePassword />}
                                />

                                <Route
                                    path="/confirm_change_password/:code"
                                    element={<OldNewPassword firstTime />}
                                />
                                <Route path="*" element={<Notfound />} />
                            </Routes>
                        )}
                        {/* </div> */}
                    </Layout>
                    {isAuth ? <FooterComp /> : null}
                </Router>
            </ConfigProvider>
        </LanguageContext.Provider>
    );
}

export default App;
