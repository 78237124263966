import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import DataTabel from "../layout/DataTable";
import { Space, Button, Spin, Pagination } from "antd";
import { Link, useParams } from "react-router-dom";
import EditEntityModal from "../layout/modals/EditEntityModal";
import CreateEntityTypeModal from "../layout/modals/CreateEntityTypeModal";
import { Container, Row, Col } from "react-bootstrap";
import CheckAuth from "../HOC/CheckAuth";

function AllEntityTypes({ isType }) {
  const [types, setTypes] = useState([]);
  const [show, setShow] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [chosenRow, setRow] = useState("");
  const [id, setID] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const { t } = useTranslation();
  let params = useParams();

  const getTypes = async () => {
    setLoading(true);

    try {
      let result = await axiosInstance.get(
        isType
          ? `/EntityType/?page=1`
          : !isType && params.id == "bank"
          ? "/Bank/?page=1"
          : `/Entity/?type=${params.id}&page=1`
      );

      console.log("getTypes", result);

      setLoading(false);
      setCount(result.data.count);
      setTypes(result.data.data);
    } catch (err) {
      console.log(err.respnce);
    }
  };

  useEffect(() => {
    getTypes();
  }, [isType, params]);

  const columns = [
    {
      title: t("form.name"),
      dataIndex: "name",
    },
    {
      title: t("form.nameAr"),
      dataIndex: "name_ar",
    },
    {
      title: t("actions"),
      key: "action",
      render: (row) => (
        <Space size="middle">
          <p className="my-0">
            <Link
              to={`/${
                isType
                  ? `entities/${row.id}`
                  : `branches/${params.id == "bank" ? "bank" : "entity"}/${
                      row.id
                    }`
              }`}
            >
              {isType && row.name !== "Bank"
                ? t("ViewEntities")
                : isType && row.name == "Bank"
                ? t("ViewBanks")
                : t("ViewBranches")}
            </Link>
          </p>
          {row.name == "Bank" ? null : (
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShow(true);
                setID(row.id);
                setRow(row);
              }}
              className="my-0"
            >
              {t("form.edit")}
            </p>
          )}
        </Space>
      ),
    },
  ];

  const hide = () => {
    setShow(false);
  };
  const hideCreate = () => {
    setShowCreate(false);
  };
  console.log(isType);

  const handleChange = async (page) => {
    setCurrent(page);
    setLoading(true);

    let result = await axiosInstance
      .get(
        isType
          ? `/EntityType/?page=${page}`
          : !isType && params.id == "bank"
          ? `/Bank/?page=${page}`
          : `/Entity/?type=${params.id}&page=${page}`
      )
      .catch((err) => console.log(err));
    if (result) {
      setLoading(false);

      // console.log(result.data.data)
      setTypes(result.data.data);
      setCount(result.data.count);
    }
  };
  return (
    <CheckAuth Permissions={["param_editor"]} userType={["iscore"]}>
      <Container>
        <Row className="my-3">
          <Col sm="6">
            <p className="title no-margin">
              {isType
                ? t("entityTypes")
                : params.id == "bank"
                ? t("allBanks")
                : t("allEntities")}
            </p>
          </Col>
          <Col className="flex-end" sm="6">
            <Button onClick={() => setShowCreate(true)} className="formBtn">
              {params.id == "bank" ? t("createBank") : t("createEntity")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {" "}
            {loading ? (
              <Spin tip={t("loading")} />
            ) : (
              <DataTabel data={types} columns={columns} columnsAr={columns} />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {types.length > 0 ? (
              <Pagination
                onChange={handleChange}
                total={count}
                defaultCurrent={current}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
        <EditEntityModal
          typeID={params.id}
          isName={isType ? false : true}
          show={show}
          hide={hide}
          id={id}
          getAll={getTypes}
          row={chosenRow}
        />
        <CreateEntityTypeModal
          show={showCreate}
          hide={hideCreate}
          getAll={getTypes}
          isName={isType ? false : true}
        />
      </Container>
    </CheckAuth>
  );
}

export default ErrorHandler(AllEntityTypes, axiosInstance);
// isBank={params.id == "bank" ? true : false}
