import React, { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Pie } from "@ant-design/plots";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  maintainAspectRatio: false,
  cutout: 110,
  elements: {
    point: {
      radius: 5,
      borderWidth: 3,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      align: "start",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
        boxWidth: 40,
      },
    },
  },
};
export default function DoughuntChart({ title, chartLabels, chartData }) {
  let lang = localStorage.getItem("i18nextLng");

  const data = {
    labels: chartLabels.map((label) =>
      lang === "en" ? label.title : label.title_ar
    ),
    datasets: [
      {
        label: "# of Votes",
        data: chartData,
        backgroundColor: [
          "#52C41A",
          "#FF4D4F",
          "#AE7504",
          "#FAAD14",
          "rgb(153, 153, 153)",
        ],
        borderColor: [
          "#52C41A",
          "#FF4D4F",
          "#AE7504",
          "#FAAD14",
          "rgb(153, 153, 153)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="dougnut-chart">
      <p className="subTitle">{title}</p>
      <div className="total-chart-data">
        {chartData.reduce((partialSum, a) => partialSum + a)}
      </div>
      <div className="canvas-container">
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
}

export function PieAnt({ data ,title ,lang}) {
  const config = {
    color: ['#52C41A', '#FF4D4F', '#AE7504',"#FAAD14","rgb(153, 153, 153)"],
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          background: "rgba(63, 147, 211, 1)",
          height: "40px",
          width: "40px",
          padding: "60px",
          borderRadius: "50%",
          color : 'white'
        },
        content: data?.map(state => state.value).reduce((partialSum, a) => partialSum + a),
      },
    },
  };



  console.log(data)
  return (
    <div className="dougnut-chart">
      <p className="subTitle">{title}</p>
      <Pie {...config} />
    </div>
  );
}
