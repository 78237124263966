import React, {useEffect, useState} from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import {useTranslation} from "react-i18next";
import DataTabel from "../../layout/DataTable";
import CheckAuth from "../../HOC/CheckAuth";
import {useSelector} from "react-redux";
import {Space, Switch, Button, Pagination, Input} from "antd";
import {Row, Col} from "react-bootstrap";
import RejectionSwitchModal from "./RejectionSwitchModal";
import {Link} from "react-router-dom";

function RejectionReasonConfig() {
    const {t} = useTranslation();
    const {Search} = Input;

    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [status, setStatus] = useState("");
    const [code, setCode] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [filter, setFilter] = useState(null);
    const [search, setSearch] = useState("");
    const [selectedRow, setSelectedRow] = useState("");

    const getReasons = async () => {
        let result = await axiosInstance.get(
            `/RejectionReason/?page=${current}&status=${
                filter !== null ? filter : ""
            }&search=${search}`
        );
        if (result) {
            console.log(result);
            setData(result.data.data);
            setCount(result.data.count);
        }
    };

    const onSearch = async (value) => {
        console.log(value);
        setSearch(value);
    };

    const changeFilter = (filter) => {
        setCurrent(1);
        setFilter(filter);
    };

    useEffect(() => {
        getReasons();
    }, [current, filter, search]);

    const columns = [
        {
            title: t("ReasonCode"),
            dataIndex: "id",
        },
        {
            title: t("ReasonDescription"),
            dataIndex: "name",
        },
        {
            title: t("calculations"),
            key: "action",
            render: (row) => (
                <Space size="middle">
                    <Switch
                        onChange={(e) => console.log(e, row)}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        onClick={(e) => {
                            setSelectedRow(row);
                            toggleActive(e);
                            setCode(row.id);
                        }}
                        checked={!row.status}
                        value={!row.status}
                    />
                </Space>
            ),
        },
    ];

    const toggleActive = async (e) => {
        setStatus(!e);
        setShowModal(true);
    };

    const changeStatus = async () => {
        console.log(status, code);

        let result = await axiosInstance
            .post(`/RejectionReasonTemp/`, {
                status: status,
                operation_type: "edit",
                real_id: code,
                name: selectedRow.name,
            })
            .catch((err) => console.log(err));
        if (result) {
            getReasons();
            setShowModal(false);
        }
    };

    const handleChange = async (page) => {
        setCurrent(page);
    };
    return (
        <CheckAuth Permissions={["param_editor"]} userType={["iscore"]}>
            <div>
                <p className="title mb-5">{t("rejectionConfig")}</p>
                <div style={{background: "#fff"}} className="p-3">
                    <Row>
                        <Col sm="12" md="4">
                            <p>{t("allReasons")}</p>
                        </Col>
                        <Col className="text-end" sm="12" md="8">
                            <Button className="configBtn" onClick={() => changeFilter(false)}>
                                {t("includeCalc")}
                            </Button>
                            <Button className="configBtn" onClick={() => changeFilter(true)}>
                                {t("excludeCalc")}
                            </Button>
                            <Button className="configBtn" onClick={() => changeFilter(null)}>
                                {t("all")}
                            </Button>
                            <Search
                                placeholder={t("searchHere")}
                                onSearch={onSearch}
                                style={{width: 200}}
                                className="mx-3"
                            />
                            <Link to="/rejection_logs">
                                <Button>{t("viewLogs")}</Button>{" "}
                            </Link>
                        </Col>
                    </Row>
                    <DataTabel data={data} columns={columns} columnsAr={columns}/>
                </div>
                {data.length > 0 ? (
                    <Pagination
                        onChange={handleChange}
                        total={count}
                        defaultCurrent={current}
                        current={current}
                    />
                ) : null}
                <RejectionSwitchModal
                    code={code}
                    status={status }
                    show={showModal}
                    hide={() => setShowModal(false)}
                    request={changeStatus}
                />
            </div>
        </CheckAuth>
    );
}

export default ErrorHandler(RejectionReasonConfig, axiosInstance);
