import { Select, Input, Table, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Drawer } from "antd";

import Modal from "react-bootstrap/Modal";
import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useParams } from "react-router-dom";

function ActionsStateChecker() {
    let params = useParams();

    const { t } = useTranslation();
    const { Search } = Input;

    const [reportType, SetReportType] = useState("");
    const [search, setSearch] = useState("");
    const [current, setCurrent] = useState(1);
    const [data, SetData] = useState([]);
    const [summry, SetSummry] = useState(null);

    const [tableType, setTableType] = useState(1);
    const [drawerView, SetDrawerView] = useState(false);
    const [showSwitchOn, setShowSwitchOn] = useState(false);
    const [showSwitchOff, setShowSwitchOff] = useState(false);

    const [itemInfo, setItemInfo] = useState("");

    const onSearch = async (value) => {
        console.log(value);
        setSearch(value);
    };

    const switchOnAndOff = (e, card) => {
        console.log("change", e);
        if (e === true) {
            setShowSwitchOn(true);
        } else {
            setShowSwitchOff(true);
        }
    };

    const HandleOpenDrawer = (info) => {
        SetSummry(info.changes);
        console.log("open");
        console.log(summry);
        SetDrawerView(!drawerView);
    };

    let GlobalColumns = [
        {
            dataIndex: "changed_by",
            title: t("MakerName"),
            render: (row) => <>{row.name}</>,
        },
        {
            dataIndex: "create_date",
            title: t("timeStamp"),
            render: (row) => <>{row.split("T")[0]}</>,
        },
        {
            dataIndex: "param",
            title: t("ParameterType"),
            render: (row) => <>{t(`changeables.${row}`)}</>,
        },
        {
            dataIndex: "operation_type",
            title: t("ActionType"),
            render: (row) => <>{t(`methods.${row}`)}</>,
        },

        {
            title: t("ActionDescription"),
            render: (row) => (
                <>
                    {`${row.changed_by?.name} ${t("wants")} ${t(
                        `methods.${row.operation_type}`
                    )} ${t(`changeables.${row.param}`)}`}
                </>
            ),
        },
        {
            title: t("action"),
            render: (row) => (
                <div className="d-flex  align-items-center">
                    {tableType === 3 ? null : (
                        <button
                            onClick={() => {
                                setItemInfo(row);
                                HandleOpenDrawer(row);
                            }}
                            className="px-0"
                            style={{
                                backgroundColor: "transparent",
                                border: "none",
                            }}
                        >
                            {t("ViewtoCheck")}
                        </button>
                    )}

                    {params.state === "pending" ? (
                        <>
                            <a
                                className="px-2"
                                style={{ color: "#52C41A" }}
                                onClick={() => {
                                    setItemInfo(row);
                                    switchOnAndOff(true);
                                }}
                            >
                                {t("accept")}
                            </a>
                            <a
                                className="px-2"
                                style={{ color: "#FF4D4F" }}
                                onClick={() => {
                                    setItemInfo(row);
                                    switchOnAndOff(false);
                                }}
                            >
                                {t("reject")}
                            </a>
                        </>
                    ) : null}
                </div>
            ),
        },
    ];

    let PerEntityColumns = [
        {
            key: "id",
            dataIndex: "id",
            title: t("form.entityName"),
        },
        {
            dataIndex: "id",
            title: t("form.entityType"),
        },
        {
            dataIndex: "id",
            title: t("NoOfPendingParameters"),
        },
        {
            dataIndex: "id",
            title: t("LastUpdate"),
        },
        {
            dataIndex: "id",
            title: t("ClickToOpen"),
            render: (row) => (
                <a onClick={() => setTableType(3)}>{t("ViewtoCheck")}</a>
            ),
        },
    ];

    const getdata = async () => {
        try {
            let result =
                await axiosInstance.get(`AllTemp/?state=${params.state}&params=${reportType}&page=${current}
      `);

            console.log("getdata", result);
            SetData(result.data);
        } catch (err) {
            console.log("getdata err", err);
        }
    };

    const changeTheDataState = async (state) => {
        console.log(itemInfo, state);

        try {
            let result = await axiosInstance.put(
                `${itemInfo.end_point}${itemInfo.id}`,
                {
                    operation_state: state,
                }
            );

            console.log("changeTheDataState", result);
            getdata();
            setShowSwitchOn(false);
            setShowSwitchOff(false);
            SetDrawerView(false);
        } catch (err) {
            console.log("changeTheDataState err", err);
        }
    };

    useEffect(() => {
        getdata();
    }, [reportType]);

    return (
        <main>
            <Drawer
                placement={"right"}
                closable={true}
                onClose={() => SetDrawerView(false)}
                visible={drawerView}
                height={"100vh"}
                // width={"50vw"}
                // title="Action Description"
                // className="d-flex justify-content-between "
            >
                <table className="mx-auto summrytable">
                    <tr className="text-center ">
                        <th>{t("changedField")}</th>
                        <th>{t("changedFrom")}</th>
                        <th>{t("ChangedTo")}</th>
                    </tr>

                    {summry !== null
                        ? summry.map((change, i) => (
                              <tr className="text-center">
                                  <td>
                                      {Object.keys(change)[0].replace(
                                          /_/g,
                                          " "
                                      )}
                                  </td>
                                  <td>
                                      {Object.values(change)[0].old.toString()}
                                  </td>
                                  <td>
                                      {Object.values(change)[0].new.toString()}
                                  </td>
                              </tr>
                          ))
                        : null}
                </table>
                <div className="d-flex align-items-center justify-content-around py-5">
                    {params.state === "pending" ? (
                        <>
                            <a
                                className="px-2"
                                style={{ color: "#52C41A" }}
                                onClick={() => {
                                    switchOnAndOff(true);
                                }}
                            >
                                {t("accept")}
                            </a>
                            <a
                                className="px-2"
                                style={{ color: "#FF4D4F" }}
                                onClick={() => {
                                    switchOnAndOff(false);
                                }}
                            >
                                {t("reject")}
                            </a>
                        </>
                    ) : null}
                </div>
            </Drawer>

            {/* <Link to="/ParamEditorHome">Back</Link> */}

            {/*<div className="d-flex align-items-center justify-content-center py-5">*/}
            {/*    <button*/}
            {/*        onClick={() => setTableType(1)}*/}
            {/*        className={*/}
            {/*            tableType === 1*/}
            {/*                ? "ActiveTableViewSwitchBtns"*/}
            {/*                : "TableViewSwitchBtns"*/}
            {/*        }*/}
            {/*    >*/}
            {/*        {t("Global")}*/}
            {/*    </button>*/}
            {/*    <button*/}
            {/*        onClick={() => setTableType(2)}*/}
            {/*        className={*/}
            {/*            tableType === 2 || tableType === 3*/}
            {/*                ? "ActiveTableViewSwitchBtns"*/}
            {/*                : "TableViewSwitchBtns"*/}
            {/*        }*/}
            {/*    >*/}
            {/*        {t("perEntity")}*/}
            {/*    </button>*/}
            {/*</div>*/}

            {tableType === 1 || tableType === 3 ? (
                <div className="formWrapper">
                    <div className="d-flex align-items-center justify-content-between py-4">
                        <p className="mb-0">
                            {" "}
                            {params.state === "rejected"
                                ? t("rejectedActions")
                                : params.state === "accepted"
                                ? t("acceptedActions")
                                : t("pendingActions")}
                        </p>
                        <div className="d-flex align-items-start">
                            <Select
                                style={{ minWidth: "150px" }}
                                placeholder={t("sheetType")}
                                onChange={(e) => SetReportType(e)}
                                value={reportType}
                            >
                                <Select.Option value={""}>
                                    {t("all")}
                                </Select.Option>
                                <Select.Option value={"bank"}>
                                    {t("form.banks")}
                                </Select.Option>
                                <Select.Option value={"entity"}>
                                    {t("entities")}
                                </Select.Option>
                                <Select.Option value={"globalvariables"}>
                                    {t("globalvariables")}
                                </Select.Option>{" "}
                                <Select.Option value={"bankbranch"}>
                                    {t("bankbranch")}
                                </Select.Option>
                                <Select.Option value={"entitybranch"}>
                                    {t("entitybranch")}
                                </Select.Option>{" "}
                                <Select.Option value={"entitytype"}>
                                    {t("entitytype")}
                                </Select.Option>
                                <Select.Option value={"rejectionreason"}>
                                    {t("rejectionreason")}
                                </Select.Option>{" "}
                                <Select.Option value={"paymentconfigs"}>
                                    {t("paymentconfigs")}
                                </Select.Option>
                                <Select.Option value={"configuration"}>
                                    {t("configuration")}
                                </Select.Option>{" "}
                                <Select.Option value={"emailtemplate"}>
                                    {t("emailtemplate")}
                                </Select.Option>
                                <Select.Option value={"conversionrate"}>
                                    {t("currency")}
                                </Select.Option>
                            </Select>
                            {/* <Search
                placeholder={t("searchHere")}
                onSearch={onSearch}
                style={{ width: 200 }}
                className="mx-3"
              /> */}
                        </div>
                    </div>

                    <Table
                        pagination={false}
                        columns={GlobalColumns}
                        dataSource={data}
                    />

                    <div>
                        <Pagination
                            current={current}
                            defaultCurrent={current}
                            onChange={(e) => setCurrent(e)}
                            total={10}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            ) : (
                <div className="formWrapper">
                    <div className="d-flex align-items-center justify-content-between py-4">
                        <p className="mb-0">{t("PendingParametersEntities")}</p>
                        <div className="d-flex align-items-start">
                            <Select
                                style={{ minWidth: "150px" }}
                                placeholder={t("sheetType")}
                                onChange={(e) => SetReportType(e)}
                                value={reportType}
                            >
                                <Select.Option value={""}>
                                    {t("all")}
                                </Select.Option>

                                <Select.Option value={1}>
                                    {t("form.banks")}
                                </Select.Option>
                                <Select.Option value={2}>
                                    {t("entities")}
                                </Select.Option>
                            </Select>
                            <Search
                                placeholder={t("searchHere")}
                                onSearch={onSearch}
                                style={{ width: 200 }}
                                className="mx-3"
                            />
                        </div>
                    </div>

                    <Table
                        pagination={true}
                        columns={PerEntityColumns}
                        dataSource={data}
                    />

                    <div>
                        <Pagination
                            current={current}
                            defaultCurrent={current}
                            onChange={(e) => setCurrent(e)}
                            total={10}
                            showSizeChanger={false}
                        />
                    </div>
                </div>
            )}

            <Modal
                show={showSwitchOff}
                onHide={() => setShowSwitchOff(false)}
                centered
            >
                <Modal.Body className="text-center">
                    <ExclamationCircleOutlined
                        style={{ fontSize: "46px", color: "#FF4D4F" }}
                    />
                    <div className="pt-2 pb-3">
                        <p className="mb-1"> {t("reject")}</p>
                        <p className="mb-1">
                            <strong>{itemInfo.operation_type}</strong>{" "}
                            {itemInfo.param}
                        </p>
                        <p className="mb-0">
                            {t("ThisActionWillApplyGloballyforAllEntites")}{" "}
                        </p>
                    </div>

                    <div className="d-flex justify-content-around align-items-center">
                        <button
                            className="cancleBtn"
                            onClick={() => setShowSwitchOff(false)}
                        >
                            {t("form.cancel")}
                        </button>
                        <button
                            className="SwitchOffBtn"
                            onClick={() => changeTheDataState("rejected")}
                        >
                            {t("reject")}{" "}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showSwitchOn}
                onHide={() => setShowSwitchOn(false)}
                centered
            >
                <Modal.Body className="text-center">
                    <CheckCircleOutlined
                        style={{ fontSize: "46px", color: "#52C41A" }}
                    />
                    <div className="pt-2 pb-3">
                        <p className="mb-1"> {t("accept")}</p>
                        <p className="mb-1">
                            <strong>{itemInfo.operation_type}</strong>{" "}
                            {itemInfo.param}
                        </p>
                        <p className="mb-0">
                            {t("ThisActionWillApplyGloballyforAllEntites")}{" "}
                        </p>
                    </div>

                    <div className="d-flex justify-content-around align-items-center">
                        <button
                            className="cancleBtn"
                            onClick={() => setShowSwitchOn(false)}
                        >
                            {t("form.cancel")}
                        </button>
                        <button
                            className="SwitchOnBtn"
                            onClick={() => changeTheDataState("accepted")}
                        >
                            {t("accept")}{" "}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </main>
    );
}

export default ErrorHandler(ActionsStateChecker, axiosInstance);
