import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Select, Pagination, Table } from "antd";
import { DatePicker } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import CheckAuth from "../HOC/CheckAuth";
const { RangePicker } = DatePicker;
function Logs() {
  const { t } = useTranslation();
  const { Option } = Select;
  const [startDate, setStartData] = useState("");
  const [endDate, setEndData] = useState("");
  const [data, setData] = useState([]);
  const [selectedFilter, setFilter] = useState([]);
  const [endPoint, setEndPoint] = useState("");
  const [logs, setLogs] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const columns = [
    {
      title: t("form.userName"),
      dataIndex: "history_user",
      render: (row) => <span className="tableCol1">{row?.name}</span>,
    },
    {
      title: t("change"),
      dataIndex: "history_type",
      render: (row) => (
        <>
          {row == "+" ? (
            <p style={{ color: "#1890FF" }}>{t("form.Add")}</p>
          ) : row == "-" ? (
            <p style={{ color: "#FF4D4F" }}>{t("delete")}</p>
          ) : row == "~" ? (
            <p style={{ color: "#AE7504" }}>{t("form.edit")}</p>
          ) : null}
        </>
      ),
    },
    {
      title: t("timeStamp"),
      dataIndex: "history_date",

      render: (row) => <>{row.split("T")[0]}</>,
    },
  ];

  const getFilters = async () => {
    let result = await axiosInstance.get(`/LogParams/`);
    console.log(result);
    if (result) {
      setData(result.data);
    }
  };
  const handleSelect = (e, v) => {
    console.log(e, v);
    setFilter(e);
    setEndPoint(v.name);
  };
  const getLogs = async () => {
    let result = await axiosInstance.get(
      `/${endPoint}/?start_date=${startDate}&end_date=${endDate}&page=${current}`
    );
    console.log(result);
    if (result) {
      setLogs(result.data.data);
      setCount(result.data.count);
    }
  };

  const UpdateFilter = async () => {
    setCurrent(1);
    let result = await axiosInstance.get(
      `/${endPoint}/?start_date=${startDate}&end_date=${endDate}&page=${current}`
    );
    console.log(result);

    if (result) {
      setLogs(result.data.data);
      setCount(result.data.count);
    }
  };

  useEffect(() => {
    if (endPoint !== "") {
      getLogs();
    }
  }, [current]);

  const handleDate = (_date, dateString) => {
    console.log(dateString);
    setStartData(dateString[0]);
    setEndData(dateString[1]);
  };
  const handleChange = async (page) => {
    setCurrent(page);
  };
  // const testData = { theName: { first: "moataz", last: "ahmed" } };

  return (
    <div>
      <CheckAuth Permissions={["iscore_account_manager"]} userType={["iscore"]}>
        <Container>
          <Row>
            <Col sm="12">
              {" "}
              <p className="title">{t("logs")}</p>
            </Col>
          </Row>
          <Row className="mb-3 mt-5">
            <Col sm="3">
              {" "}
              <Select
                value={selectedFilter}
                onChange={handleSelect}
                onClick={getFilters}
                placeholder={t("selectOption")}
                style={{ width: "100%" }}
              >
                {data?.map((info) => {
                  return (
                    <Option value={info.id} name={info.endpoint}>
                      {info.title}/{info.title_ar}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col sm="3">
              {" "}
              <RangePicker
                placeholder={[t("startDate"), t("endDate")]}
                onChange={handleDate}
              />{" "}
            </Col>
            <Col className="flex flex-end" sm="6">
              <button
                disabled={endPoint === ""}
                onClick={UpdateFilter}
                className="formBtn"
              >
                {t("filter")}
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Table
                dataSource={logs}
                columns={columns}
                pagination={false}
                expandedRowKeys={expandedRowKeys}
                rowKey={"history_id"}
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (row) => (
                    <table className="mx-auto">
                      <tr className="text-center">
                        <th>{t("changedField")}</th>
                        <th>{t("changedFrom")}</th>
                        <th>{t("ChangedTo")}</th>
                      </tr>
                      {row.changes !== null
                        ? row.changes.map((change, i) => (
                            <tr className="text-center">
                              <td>
                                {Object.keys(change)[0].replace(/_/g, " ")}
                              </td>
                              <td>{Object.values(change)[0].old.toString()}</td>
                              <td>{Object.values(change)[0].new.toString()}</td>
                            </tr>
                          ))
                        : null}
                    </table>
                  ),
                  onExpand: (expanded, record) => {
                    console.log("onExpand: ", record, expanded);
                    setExpandedRowKeys([record.history_id]);

                    console.log(expandedRowKeys);
                  },
                }}
                expandIconColumnIndex={2}
                expandIcon={({ expanded, onExpand, record }) =>
                  expanded ? (
                    <button
                      className="showMoreBtn"
                      onClick={(e) => {
                        setExpandedRowKeys([]);
                      }}
                    >
                      {t("hideSummary")}
                    </button>
                  ) : (
                    <>
                      {record.changes !== null ? (
                        //  &&
                        // record.changes.length === 0 ? (
                        //   false
                        // ) : true
                        <button
                          className="showMoreBtn"
                          onClick={(e) => {
                            onExpand(record, e);
                          }}
                        >
                          {t("showSummary")}
                        </button>
                      ) : null}{" "}
                    </>
                  )
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              {logs.length > 0 ? (
                <Pagination
                  onChange={handleChange}
                  total={count}
                  defaultCurrent={current}
                  current={current}
                  showSizeChanger={false}
                />
              ) : null}
            </Col>
          </Row>
        </Container>
        <div className="mt-5 mb-3"> </div>
      </CheckAuth>
    </div>
  );
}
export default ErrorHandler(Logs, axiosInstance);
