import React, { useState } from "react";
import { Button, Row, Col, Form, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import SuccessMsg from "../layout/SuccessMsg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckAuth from "../HOC/CheckAuth";

function UserCreationIscore() {
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const onFinish = async (values) => {
    console.log(values);
    let result;
    result = await axiosInstance
      .post(`${"/SignUpIscore/"}`, values)
      .catch((err) => {
        console.log(err.response);
        console.log(err);
        if (err.response && err.response.status !== 500) {
          for (const error in err.response.data) {
            console.log(err.response.data[error][0]);
            setTimeout(() => {
              toast.error(
                `${
                  error +
                  " : " +
                  err.response.data[error][0].charAt(0).toUpperCase() +
                  err.response.data[error][0].slice(1)
                }`
              );
            }, 500);
          }
        }
      });

    console.log(result);
    if (result) {
      setShowSuccess(true);
      form.resetFields();
    }
  };

  const handleSelect = (set, e) => {
    set(e);
    console.log(e);
  };

  const getRoles = async () => {
    let result;

    result = await axiosInstance.get(`/IscorePermission/?page_size=500`);

    console.log(result);
    setRoles(result.data.data);
  };

  return (
    <>
      <CheckAuth
        Permissions={[
          "iscore_account_manager",
          "user_create_checker",
          "user_create_maker",
        ]}
        userType={["iscore"]}
      >
        <div className="my-5">
          <p className="title mb-4"> {t("userCreation")}</p>
          <div className="formWrapper">
            <Row>
              <Col span={12}>
                <p className="subTitle mb-4">{t("createNewUser")}</p>
              </Col>
            </Row>

            <Form onFinish={onFinish} form={form} layout="vertical">
              <Form.Item
                label={t("form.name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.userName")}
                name="username"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                  {
                    pattern: `^[a-zA-Z]{3,}$`,
                    message: `${t("form.wrongFormat")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.email")}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredEmail"),
                  },
                  {
                    type: "email",
                    message: `${t("form.wrongFormat")}`,
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item
                label={t("form.mobileNumber")}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredPhone"),
                  },
                  {
                    min: 11,
                    message: t("lengthPhone"),
                  },
                  {
                    max: 11,
                    message: t("lengthPhone"),
                  },
                ]}
                type="number"
              >
                <Input
                  onKeyDown={(e) =>
                    ["e", ".", "E", "+", "-"].includes(e.key) &&
                    e.preventDefault()
                  }
                  type="number"
                />
              </Form.Item>

              <Form.Item
                name="permissions"
                label={t("form.userRole")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredUserRole"),
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  onChange={(e) => handleSelect(setSelectedRole, e)}
                  value={selectedRole}
                  onClick={getRoles}
                >
                  {roles.map((role) => {
                    return (
                      <Select.Option key={role.id} value={role.id}>
                        {role.name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {/* <img src={arrow} alt="arrow" /> */}
              </Form.Item>

              <Row className="text-end">
                {" "}
                <Button
                  color="primary"
                  htmlType="submit"
                  style={{ marginBottom: "1rem" }}
                  className="formBtn mt-3"
                >
                  {t("form.save")}
                </Button>
              </Row>
            </Form>
          </div>
          <SuccessMsg
            show={showSuccess}
            hide={() => setShowSuccess(false)}
            text={t("form.successfulRegister")}
            btnTxt={t("form.sendInvetation")}
            subTxt={t("")}
            // password={password}
          />
        </div>
        <ToastContainer position="bottom-right" />
      </CheckAuth>
    </>
  );
}

export default ErrorHandler(UserCreationIscore, axiosInstance);
