import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Button, Form, Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

const { RangePicker } = DatePicker;

const DisputeReport = ({ title, endpoint }) => {
  const { t } = useTranslation();
  const [banks, setBanks] = useState([]);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [date, setDate] = useState("");
  const [selectedType, setSelectedType] = useState("bank");
  const [reportGenerated, setReport] = useState("");

  const disputes = [
    { id: 1, name: "Name Dispute", name_ar: "تعارض الاسم" },
    { id: 2, name: "Invalid Dispute", name_ar: "تعارض غير صالح" },
    { id: 3, name: "Subject Dispute", name_ar: "تعارض الموضوع" },
  ];
  const [selectedDispute, setSelectedDispute] = useState([]);

  const onChange = (e, arr, setStatFun) => {
    if (e.includes("SelectAll")) {
      arr.map((val) => setStatFun((selectedItem) => [...selectedItem, val.id]));
    } else if (e.includes("UnselectAll")) {
      setStatFun([]);
    } else {
      setStatFun(e);
    }
  };
  const handleDate = (date, dateString) => {
    console.log(date);
    setDate(dateString);
  };

  const handleSelect = (set, e) => {
    set(e);
    console.log(e);
  };

  const getBankOrEntity = async () => {
    let result = await axiosInstance.get(
      `/${selectedType == "bank" ? "Bank" : "Entity"}/?page_size=800`
    );
    console.log(result);
    setBanks(result.data.data);
  };
  useEffect(() => {
    getBankOrEntity();
  }, [selectedType]);
  const onFinish = async (values) => {
    let result = await axiosInstance
      .get(
        `${endpoint}?start_date=${date[0] ?? ""}&end_date=${
          date[1] ?? ""
        }&dispute_type=${selectedDispute.join(",")}&${
          selectedType == "bank" ? "bank" : "entity"
        }=${selectedBanks.join(",")}`
      )
      .catch((err) => console.log(err));

    if (result) {
      console.log(result);
      setReport(result.data);

      if (result.data !== "") {
        document.getElementById("downloadBtn").click();
      }
    }
  };
  return (
    <div>
      <p className="report-title">{title}</p>
      <div className="formWrapper">
        <p className="report-filters">{t("reportFilters")}</p>
        <p className="filters-subtext">{t("filterReportText")}</p>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item label="date">
            <RangePicker
              allowClear
              placeholder={[t("startDate"), t("endDate")]}
              onChange={handleDate}
            />
          </Form.Item>
          <Form.Item
            label={t("form.entityType")}
            rules={[
              {
                required: true,
                message: t("form.requiredtype"),
              },
            ]}
          >
            <Select
              onChange={(e) => handleSelect(setSelectedType, e)}
              value={selectedType}
            >
              <Select.Option key={"bank"} value={"bank"}>
                Bank
              </Select.Option>
              <Select.Option key={"entity"} value={"entity"}>
                Entity
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={t("form.banks")}>
            <Select
              mode="multiple"
              onChange={(e) => onChange(e, banks, setSelectedBanks)}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              value={selectedBanks}
            >
              {selectedBanks.length === 0 ? (
                <Select.Option value="SelectAll">
                  {t("SelectAll")}
                </Select.Option>
              ) : (
                <Select.Option value="UnselectAll">
                  {t("UnselectAll")}
                </Select.Option>
              )}

              {banks.map((bank) => {
                return (
                  <Select.Option value={bank.id} key={bank.id}>
                    {localStorage.getItem("i18nextLng") === "ar"
                      ? bank.name_ar
                      : bank.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label={t("form.disputeType")}>
            <Select
              mode="multiple"
              onChange={(e) => onChange(e, disputes, setSelectedDispute)}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              value={selectedDispute}
            >
              {selectedDispute.length === 0 ? (
                <Select.Option value="SelectAll">
                  {t("SelectAll")}
                </Select.Option>
              ) : (
                <Select.Option value="UnselectAll">
                  {t("UnselectAll")}
                </Select.Option>
              )}

              {disputes.map((type) => {
                return (
                  <Select.Option value={type.id} key={type.id}>
                    {localStorage.getItem("i18nextLng") === "ar"
                      ? type.name_ar
                      : type.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row>
            <Col className="d-flex text-end" span={24}>
              <Button className="formBtn" htmlType="submit">
                {" "}
                + {t("generateReport")}
              </Button>
            </Col>
          </Row>
        </Form>

        <CSVLink
          data={reportGenerated}
          filename={"report.csv"}
          id="downloadBtn"
          style={{ display: "none" }}
        >
          Download me
        </CSVLink>
      </div>
    </div>
  );
};

export default ErrorHandler(DisputeReport, axiosInstance);
