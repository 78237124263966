import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import DataTabel from "../layout/DataTable";
import { Space, Button, Pagination, Spin } from "antd";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import EditBranchModal from "../layout/modals/EditBranchModal";
import CreateBranchModal from "../layout/modals/CreateBranchModal";

function AllBranches() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [types, setTypes] = useState([]);
  const [id, setID] = useState("");
  const [chosenRow, setRow] = useState("");
  const [showCreate, setShowCreate] = useState(false);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);

  let params = useParams();
  const getBranches = async () => {
    try {
      setLoading(true);
      let result = await axiosInstance.get(
        `/${
          params.type == "bank" ? "BankBranch/?bank=" : "EntityBranch/?entity="
        }${params.id}&page=1`
      );
      console.log(result);
      setTypes(result.data.data);
      setCount(result.data.count);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBranches();
  }, []);

  const columns = [
    {
      title: t("form.name"),
      dataIndex: "name",
      render: (row) => <span className="tableCol1">{row}</span>,
    },
    {
      title: t("form.nameAr"),
      dataIndex: "name_ar",
      render: (row) => <span className="tableCol1">{row}</span>,
    },
    {
      title: t("form.branchCode"),
      dataIndex: "branch_code",
      render: (row) => <span className="tableCol1">{row}</span>,
    },
    {
      title: t("actions"),
      key: "action",
      render: (row) => (
        <Space
          onClick={() => {
            setShow(true);
            setID(row.id);
            setRow(row);
          }}
          size="middle"
        >
          <p style={{ cursor: "pointer" }} className="my-0">
            {t("form.edit")}{" "}
          </p>
        </Space>
      ),
    },
  ];

  const hide = () => {
    setShow(false);
  };
  const hideCreate = () => {
    setShowCreate(false);
  };
  const handleChange = async (page) => {
    setCurrent(page);
    setLoading(true);
    let result = await axiosInstance.get(
      `/${
        params.type == "bank" ? "BankBranch/?bank=" : "EntityBranch/?entity="
      }${params.id}&page=${page}`
    );
    console.log(result);
    setTypes(result.data.data);
    setCount(result.data.count);
  };
  return (
    <Container>
      <Row className="my-3">
        <Col sm="6">
          <p className="title no-margin">{t("allBranches")}</p>
        </Col>
        <Col className="flex-end" sm="6">
          <Button onClick={() => setShowCreate(true)} className="formBtn">
            {t("createBranch")}
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Spin tip={t("loading")} />
      ) : (
        <DataTabel data={types} columns={columns} columnsAr={columns} />
      )}
      <Row>
        <Col sm="12">
          {types.length > 0 ? (
            <Pagination
              onChange={handleChange}
              total={count}
              defaultCurrent={current}
              current={current}
            />
          ) : null}
        </Col>
      </Row>
      <EditBranchModal
        show={show}
        hide={hide}
        entityID={params.id}
        id={id}
        getAll={getBranches}
        row={chosenRow}
        type={params.type}
      />
      <CreateBranchModal
        show={showCreate}
        hide={hideCreate}
        type={params.type}
        getAll={getBranches}
      />
    </Container>
  );
}

export default ErrorHandler(AllBranches, axiosInstance);
