import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../style-sheets/loginStyle.css";
import { Form, Input, Button, Spin } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import logo from "../../../images/logo1.svg";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { LockOutlined, ReloadOutlined, RobotOutlined } from "@ant-design/icons";
import SuccessMsg from "../../layout/SuccessMsg";
import { LogOut } from "../../../global-state/actions/authAction";

import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../../api/axios";
import { useDispatch, useSelector } from "react-redux";
export default function OldNewPassword() {
  const { t } = useTranslation();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [key, setKey] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let navigate = useNavigate();
  let location = useLocation();
  let { code } = useParams();
  const onFinish = async (data) => {
    data.secret_code = code ?? "aa";
    data.captcha_key = key;
    data.uid64 = searchParams.get("uid64");
    console.log(data);
    let result = await axios
      .post(BASE_URL + "/ResetPassword/", data)
      .catch((err) => {
        generateCaptcha();
        console.log(err.response);
        if (err.response) {
          for (const error in err.response.data) {
            for (const [key, value] of Object.entries(
              err.response.data[error]
            )) {
              setTimeout(() => {
                toast.error(
                  `${
                    error === "non_field_errors"
                      ? "Wrong captcha"
                      : error + " : " + value
                  }`
                );
              }, 1000);
            }
          }
        }
      });

    if (result) {
      setShow(true);
    }

    console.log(data);
  };

  const hide = () => {
    setShow(false);
    navigate("/");
  };

  const generateCaptcha = async () => {
    setLoading(true);
    let result = await axios
      .post(`${BASE_URL}/captcha/generate`)
      .catch((err) => console.log(err));

    if (result) {
      setLoading(false);
      console.log(result);
      setKey(result.data.captcha_key);
      setImage(result.data.captcha_image);
    }
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  useEffect(() => {
    try {
      if (isAuth) {
        dispatch(LogOut());
        navigate(location.pathname);
      }
      generateCaptcha();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div className="loginContainer container-fluid">
      <Row>
        <Col className="first" lg={4} sm={6}>
          <div className="wrapper">
            <div className="welcome-text">
              <p className="welcome">{t("welcome")}</p>
              <p className="welcome">{t("welcomeText")}</p>
            </div>
            <img className="logo" src={logo} />
          </div>
        </Col>
        <Col className="second" lg={8} sm={6}>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <p className="title">{t("form.enterNewPass")}</p>
            <p className="subText">{t("form.msg5")}</p>

            <Form.Item
              name="new_password"
              label={t("form.newpassword")}
              rules={[
                { required: true, message: `${t("passwordError")}` },
                {
                  pattern: `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`,
                  message: `${t("passwordPolicy")}`,
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                name="password"
                placeholder={t("form.newpassword")}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              label={t("form.confirmPass")}
              dependencies={["new_password"]}
              rules={[
                {
                  required: true,
                  message: `${t("passwordError")}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("form.confirmPassError"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                name="password"
                placeholder={t("form.confirmPass")}
              />
            </Form.Item>
            <div className="captcha-icon mb-2">
              {loading ? <Spin /> : <img src={BASE_URL + image} />}
              <ReloadOutlined onClick={() => generateCaptcha()} />
            </div>
            <Form.Item
              name="captcha_response"
              rules={[{ required: true, message: `${t("captchaError")}` }]}
              layout="vertical"
            >
              <Input
                prefix={<RobotOutlined className="site-form-item-icon" />}
                className="loginInput"
                type="text"
                placeholder="Captcha"
              />
            </Form.Item>
            <Form.Item>
              <Button className="loginBtn" htmlType="submit">
                {t("form.confirm")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <SuccessMsg
        show={show}
        hide={hide}
        btnTxt={t("ok")}
        text={t("passwordReset")}
      />
      <ToastContainer position="bottom-right" />
    </div>
  );
}
