import React, { useCallback, useState } from "react";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import CheckAuth from "../../HOC/CheckAuth";
import InquiryReport from "../../reports/InquiryReport";
import ReferenceNumberStep from "./referenceNumberStep";
import SubjectDisputeForm from "./SubjectDisputeForm";
import RejectionReasonsForm from "./RejectionReasonsForm";
import ConfirmRequest from "../../layout/modals/confirmRequest";

function CreateDispute() {
  const { t } = useTranslation();
  const [show, setShowSuccess] = useState(false);
  const [isName, setIsName] = useState(false);
  const [step, setStep] = useState("1");
  const [isInvalid, setIsInvalid] = useState(false);
  const [data, setData] = useState("");
  const [chequeData, setChequeData] = useState("");

  const navigate = useNavigate();
  const onFinish = async (values) => {
    setData(values);
    if (values.type === "3") {
      setStep("5");
    } else if (values.type === "2") {
      setIsInvalid(true);
      setStep("5");
    } else if (values.type === "1") {
      let result = await axiosInstance
        .post(`/Dispute/`, values)
        .catch((err) => {
          console.log(err.response);
        });
      if (result) {
        setShowSuccess(true);
      }
    } else {
      //
    }
  };
  const handleType = (e) => {
    if (e == 1) {
      setIsName(true);
    } else {
      setIsName(false);
    }
  };

  return (
    <>
      <CheckAuth
        Permissions={["dispute_starter"]}
        userType={["entity", "bank"]}
      >
        {step === "1" ? (
          <InquiryReport dispute setStep={setStep} />
        ) : step === "2" ? (
          <ReferenceNumberStep setStep={setStep} />
        ) : step === "3" ? (
          <div className="my-5">
            <p className="title mb-1">{t("disputeRequest")}</p>
            <p className="subHeader">{t("disputeSubText")}</p>

            <div className="formWrapper">
              <Row>
                <Col span={12}>
                  <p className="subTitle mb-2">{t("createDisputeRequest")}</p>
                </Col>
              </Row>

              <Form className="invoice" onFinish={onFinish} layout="vertical">
                <Form.Item
                  className="d-flex"
                  name="type"
                  label={t("form.disputeType")}
                  rules={[
                    {
                      required: true,
                      message: t("form.requiredField"),
                    },
                  ]}
                >
                  <Select onChange={handleType}>
                    <Select.Option value={"1"}>
                      {t("name_Dispute")}{" "}
                    </Select.Option>
                    <Select.Option value={"2"}>
                      {t("invalidCheque")}
                    </Select.Option>
                    <Select.Option value={"3"}>
                      {t("disputeType3")}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t("form.draweeID")}
                  name="drawee"
                  rules={[
                    {
                      required: true,
                      message: t("form.requiredField"),
                    },
                  ]}
                  extra={t("disputeTip")}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  className="d-flex"
                  name="phone"
                  label={t("form.draweeMobile")}
                  rules={[
                    {
                      required: true,
                      message: t("form.requiredField"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                {isName ? (
                  <>
                    {" "}
                    <Form.Item
                      name="drawee_name"
                      label={t("form.draweeName")}
                      rules={[
                        {
                          required: true,
                          message: t("form.requiredField"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="new_name"
                      label={t("newname")}
                      rules={[
                        {
                          required: true,
                          message: t("form.requiredField"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                ) : null}
                <Row className="text-end">
                  {" "}
                  <Button
                    color="primary"
                    htmlType="submit"
                    style={{ marginBottom: "1rem" }}
                    className="formBtn mt-3"
                  >
                    {t("proceed")}
                  </Button>
                </Row>
              </Form>
            </div>

            <ConfirmRequest
              text={t("submitnamedispute")}
              subTxt={t("feedbackmsg1")}
              show={show}
              hide={() => setShowSuccess(false)}
              btnTxt={t("form.done")}
              color="#52C41A"
              request={() => navigate("/all_disputes")}
            />
          </div>
        ) : step === "5" ? (
          <SubjectDisputeForm
            data={data}
            isInvalid={isInvalid}
            setStep={setStep}
            setChequeData={setChequeData}
          />
        ) : step === "6" ? (
          <RejectionReasonsForm chequeData={chequeData} />
        ) : null}
      </CheckAuth>
    </>
  );
}

export default ErrorHandler(CreateDispute, axiosInstance);
