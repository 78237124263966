import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
function ParamEditorHome() {
  const [CardsData, setCardsData] = useState("");

  const cards = [
    {
      EnTitle: "Under Review",
      ArTitle: "تحت المراجعه",
      color: "#FAAD14",
      state: "pending",
    },
    {
      EnTitle: "Approved",
      ArTitle: "مقبول",
      color: "#52C41A",
      state: "accepted",
    },
    {
      EnTitle: "Rejected",
      ArTitle: "مرفوض",
      color: "#FF4D4F",
      state: "rejected",
    },
  ];

  const getCards = async () => {
    try {
      let result = await axiosInstance.get(`/Summary/`);
      console.log(result);
      setCardsData(result.data);
    } catch (err) {
      console.log("getCards err", err);
    }
  };

  useEffect(() => {
    getCards();
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center  ">
      {cards.map((card) => (
        <div className="dashboardCard w-25 mx-5">
          <h1 style={{ color: card.color }} className="subTitle">
            {localStorage.getItem("i18nextLng") === "en"
              ? card.EnTitle
              : card.ArTitle}
          </h1>

          <div className="d-flex align-items-center justify-content-between">
            <div className="title">{CardsData[card.state]}</div>
            <Link to={`/ActionsStateMaker/${card.state}`}>
              <div className="cardArrow">
                <FontAwesomeIcon
                  icon={
                    localStorage.getItem("i18nextLng") === "en"
                      ? faAngleRight
                      : faAngleLeft
                  }
                />
              </div>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ErrorHandler(ParamEditorHome, axiosInstance);
