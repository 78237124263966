import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../style-sheets/loginStyle.css";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import logo from "../../../images/logo1.svg";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { UserOutlined, MailOutlined } from "@ant-design/icons";

export default function ChangePassword() {
  const { t } = useTranslation();

  let navigate = useNavigate();

  const onFinish = async (data) => {
    console.log(data);
    navigate("/confirm_change_password");
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  return (
    <div className="loginContainer container-fluid">
      <Row>
        <Col className="first" lg={4} sm={6}>
          <div className="wrapper">
            <div className="welcome-text">
              <p className="welcome">{t("welcome")}</p>
              <p className="welcome">{t("welcomeText")}</p>
            </div>
            <img className="logo" src={logo} />
          </div>
        </Col>
        <Col className="second" lg={8} sm={6}>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <p className="title">{t("form.msg1")}</p>
            <p className="subText">{t("form.msg4")}</p>
            <Form.Item
              name="name"
              rules={[{ required: true, message: `${t("emailError")}` }]}
              layout="vertical"
              label={t("form.name")}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                className="loginInput"
                placeholder={t("form.name")}
                type="text"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: `${t("emailError")}` }]}
              layout="vertical"
              label={t("form.email")}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                className="loginInput"
                placeholder="youremail@iscore.com.eg"
                type="email"
              />
            </Form.Item>

            <Form.Item>
              <Button className="loginBtn" htmlType="submit">
                {t("form.next")}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
