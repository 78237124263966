import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import DataTabel from "../../layout/DataTable";
import {Space, Button, Pagination, Spin, Input} from "antd";
import {useParams} from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
import {RightOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {DatePicker} from "antd";
import CheckAuth from "../../HOC/CheckAuth";

const {RangePicker} = DatePicker;

function RejectionLogs() {
    const {t} = useTranslation();
    const [types, setTypes] = useState([]);
    const [id, setID] = useState("");
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const {RangePicker} = DatePicker;
    const [startDate, setStartData] = useState("");
    const [endDate, setEndData] = useState("");
    const [userFilter, setUserFilter] = useState("");

    const getLogs = async () => {
        try {
            let result = await axiosInstance.get(
                `HistoricalRejectionReason/?page=${current}&start_date=${startDate}&end_date=${endDate}&user=${userFilter}`
            );

            console.log("getLogs", result);
            setLogs(result.data.data);
            setCount(result.data.count);
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) {
                setLogs([]);
                setCount(0);
            }
        }
    };

    useEffect(() => {
        getLogs();
    }, [current, startDate, endDate, userFilter]);

    const columns = [
        {
            title: t("Timestamp"),
            dataIndex: "history_date",
            render: (row) => <span className="tableCol1">{row}</span>,
        },
        {
            title: t("form.userName"),
            dataIndex: "changed_by",
            render: (row) => <span className="tableCol1">{row?.name}</span>,
        },
        {
            title: t("reasonCode"),
            dataIndex: "id",
            render: (row) => <span className="tableCol1">{row}</span>,
        },
        {
            title: t("activity"),
            dataIndex: "history_type",
            render: (row) => (
                <span className="tableCol1">
          {row === "+" ? "Add" : row === "-" ? "Delete" : "Edit"}
        </span>
            ),
        },
    ];
    const handleChange = async (page) => {
        setCurrent(page);
    };
    const handleDate = (date, dateString) => {
        setStartData(dateString[0]);
        setEndData(dateString[1]);
        setCurrent(1);
    };
    const handleSearch = (e) => {
        setUserFilter(e.target.value);
        setCurrent(1);
    };
    return (
        <CheckAuth Permissions={["param_editor"]} userType={["iscore"]}>
            <Container>
                <Row className="my-3">
                    <Col sm="6">
                        <p className="title no-margin">{t("logs")}</p>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col sm="12">
                        <p className="no-margin rejectionLogsText">
                            <Link to="/rejection_config">{t("rejectionConfig")}</Link>{" "}
                            <RightOutlined/>
                            {t("logs")}
                        </p>
                    </Col>
                </Row>
                <Row className="my-4">
                    <Col sm="3">
                        <Input.Search
                            allowClear
                            // style={{ width: "50%" }}
                            placeholder="input user name"
                            value={userFilter}
                            onChange={handleSearch}
                        />
                    </Col>
                    <Col sm="3">
                        {" "}
                        <RangePicker
                            placeholder={[t("startDate"), t("endDate")]}
                            onChange={handleDate}
                        />{" "}
                    </Col>
                </Row>
                {loading ? (
                    <Spin tip={t("loading")}/>
                ) : (
                    <DataTabel data={logs} columns={columns} columnsAr={columns}/>
                )}
                <Row>
                    <Col sm="12">
                        {logs.length > 0 ? (
                            <Pagination
                                onChange={handleChange}
                                total={count}
                                defaultCurrent={current}
                                current={current}
                                showSizeChanger={false}
                            />
                        ) : null}
                    </Col>
                </Row>
            </Container>
        </CheckAuth>
    );
}

export default ErrorHandler(RejectionLogs, axiosInstance);
