import "./CurrencyPages.css";
import { useTranslation } from "react-i18next";
import { Space, Table, Input, Pagination } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const { Search } = Input;

function AllCurrencies() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);

  const getData = async () => {
    try {
      setLoading(true);
      let result = await axiosInstance.get(`/ConversionRate/?page=${current}`);
      console.log("Currency data", result.data.data);
      setdata(result.data.data);
      setCount(result.data.count);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePages = (page) => {
    setCurrent(page);
  };

  const handleDelete = async (row) => {
    console.log(row);
    try {
      setLoading(true);
      let result = await axiosInstance.post(`/ConversionRateTemp/`, {
        currency: row.currency.id,
        rate: row.rate,
        operation_type: "remove",
        real_id: row.id,
      });
      console.log(result);
      toast.success(t("success"), {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });

      getData();
      setLoading(false);
    } catch {
      toast.error(t("failed"), {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [current]);

  const onSearch = (value) => console.log(value);

  const columns = [
    {
      title: t("no"),
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },

    {
      title: t("CurrencyCode"),
      dataIndex: "currency",
      render: (row) => <>{row.name}</>,
    },

    {
      title: t("CurrencyNameAr"),
      dataIndex: "currency",
      render: (row) => <>{row.name_expanded_ar}</>,
    },
    {
      title: t("CurrencyNameEn"),
      dataIndex: "currency",
      render: (row) => <>{row.name_expanded}</>,
    },
    {
      title: t("AmountInEGP"),
      dataIndex: "rate",
    },

    {
      title: t("actions"),
      key: "operation",
      fixed: "right",
      render: (e) => (
        <Space>
          <Link
            className="actions"
            to={`/edit_currency/${e.id}-${e.currency_id}`}
          >
            {t("form.edit")}
          </Link>
          <button
            className="actions"
            onClick={() => {
              console.log(e.id);
              handleDelete(e);
            }}
          >
            {t("delete")}
          </button>
        </Space>
      ),
    },
  ];

  return (
    <section>
      <ToastContainer position="bottom-right" />

      <h1 className="pageTitle"> {t("sidemenu.CurrencyConfiguration")}</h1>

      <div className="sectionHolder">
        <div className="sectionHeader">
          <p className="pageSubTitle"> {t("viewAllCurrencies")}</p>

          <div className="tableOptions">
            <Search
              placeholder={t("inputSearch")}
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
            <Link to="/currencies_logs" className="logsLink">
              {t("viewLog")}
            </Link>
            <Link to="/add_currency" className="addNewCurrencyLink">
              <FontAwesomeIcon className="px-1" icon={faPlus} />
              {t("addNewCurrency")}
            </Link>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
        />
        <Pagination
          defaultCurrent={1}
          total={count}
          onChange={(page) => handlePages(page)}
          showSizeChanger={false}
        />
      </div>
    </section>
  );
}

export default ErrorHandler(AllCurrencies, axiosInstance);
