import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/loginStyle.css";
import { Form, Input, Button, Spin } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo1.svg";
import { userLogin } from "../../global-state/actions/authAction";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import { BASE_URL } from "../../api/axios";
import Captcha from "../Captcha";

const Login = ({ setDirection, setLanguage, lang }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [password, setPassword] = useState("");
  const [key, setKey] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const Lang = localStorage.getItem("i18nextLng");
  let navigate = useNavigate();

  const onFinish = async (data) => {
    setLoadingRequest(true);
    console.log(data);
    data.captcha_key = key;
    const userData = await dispatch(userLogin(data));

    if (userData.status === 401) {
      generateCaptcha();
      form.setFieldsValue({ captcha_response: "" });
      setTimeout(() => {
        setLoadingRequest(false);
      }, 2000);
      setTimeout(() => {
        Lang === "en"
          ? toast.error(userData.data.detail.en)
          : toast.error(userData.data.detail.ar);
      }, 500);
    } else if (userData.status === 400) {
      form.setFieldsValue({ captcha_response: "" });
      setTimeout(() => {
        setLoadingRequest(false);
      }, 2000);
      generateCaptcha();
      setTimeout(() => {
        toast.error(t("wrongCaptcha"));
      }, 500);
    } else {
      setTimeout(() => {
        setLoadingRequest(false);
      }, 2000);
      console.log(userData);
      if (userData.permissions.includes("param_editor")) {
        navigate("/ParamEditorHomeMaker");
      } else if (userData.permissions.includes("param_editor_checker")) {
        navigate("/ParamEditorHomeChecker");
      } else if (userData.permissions.includes("drawee_inquiry")) {
        navigate("/drawee_report");
      } else if (userData.permissions.includes("beneficiary_inquiry")) {
        navigate("/beneficiary_report");
      } else {
        navigate("/home");
      }
    }
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const generateCaptcha = async () => {
    setLoading(true);
    let result = await axios
      .post(`${BASE_URL}/captcha/generate`)
      .catch(() => setLoading(false));

    if (result) {
      setLoading(false);
      console.log(result);
      setKey(result.data.captcha_key);
      setImage(result.data.captcha_image);
    }
  };

  const changeLanguage = () => {
    console.log(lang);
    if (lang === "en") {
      setDirection("rtl");
      setLanguage("ar");

      console.log("english");
    } else {
      setDirection("ltr");
      setLanguage("en");
      console.log("arabic");
    }
  };

  return (
    <div className="loginContainer container-fluid">
      <Row>
        <Col className="first" lg={4} sm={6}>
          <div className="wrapper">
            <div className="welcome-text">
              <p className="welcome">{t("welcome")}</p>
              <p className="welcome">{t("welcomeText")}</p>
            </div>
            <img className="logo" src={logo} />
          </div>
        </Col>
        <Col className="second" lg={8} sm={6}>
          <div className="language">
            <a onClick={changeLanguage} className="">
              {lang === "en" ? "العربية" : "English"}
            </a>
          </div>

          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
            form={form}
          >
            <p className="title">{t("hello")}</p>
            <p className="title mb-5">{t("helloText")}</p>
            <Form.Item
              name="username"
              rules={[{ required: true, message: `${t("form.requiredName")}` }]}
              layout="vertical"
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                className="loginInput"
                placeholder={t("form.userName")}
                type="text"
                data-testid="User Name"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: `${t("passwordError")}` }]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                value={password}
                name="password"
                onChange={handlePassword}
                placeholder={t("password")}
                data-testid="Password"
                className=""
                autoComplete="off"
              />
            </Form.Item>
            <Captcha
              setLoading={setLoading}
              setKey={setKey}
              setImage={setImage}
              loading={loading}
              image={image}
              generate={generateCaptcha}
            />

            <Form.Item>
              <Button
                data-testid="Submit"
                className="loginBtn"
                htmlType="submit"
                disabled={loadingRequest}
              >
                {loadingRequest ? <Spin /> : t("login")}
              </Button>
            </Form.Item>

            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <Link className="passwordLinks" to={"/forget_password"}>
                  {t("form.forgetPass")}
                </Link>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Login;
