import React, { useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import axiosInstance from "../../../../api/axios";
import SuccessMsg from "../../SuccessMsg";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";

function DisputeDetails({ data, show, hide, setSolved }) {
  console.log(data);
  const { t } = useTranslation();
  const [showSolveConflict, setShowSolveConflict] = useState(false);
  const solveConflict = async () => {
    hide();
    let result = axiosInstance
      .post(`/Conflict/${data.id}`, { state: 1 })
      .catch((err) => console.log(err));

    if (result) {
      setShowSolveConflict(true);
    }
  };

  const solveWithExcel = async () => {
    let result = await axiosInstance
      .put(
        `/${
          data.foreign
            ? `ForeignPerson/${data.foreign}`
            : `EgyptianPerson/${data.egyptian}`
        }`,
        {
          name: data.new_data,
          person_id: data.foreign ? data.foreign : data.egyptian,
        }
      )
      .catch((err) => console.log(err.response));

    if (result) {
      console.log(result);
      setShowSolveConflict(true);
      hide();
    }
  };
  return (
    <>
      {" "}
      <Modal size="lg" show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>Conflict details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="12">
              <p className="conflict-text">
                You have a conflict in data which is:
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3" sm="12">
              <span>{data.old_data}</span>
              <span className="data-place">(Name IN database)</span>
            </Col>
            <Col className="mb-3" sm="12">
              <span>{data.new_data}</span>
              <span className="data-place">(Name IN Excel file)</span>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <Button
                onClick={solveWithExcel}
                style={{ borderRadius: "7px" }}
                className="start-dispute-btn"
              >
                Proceed with Excel Name
              </Button>
            </Col>
            <Col sm="6">
              <Button
                className="solve-conflict-btn"
                onClick={solveConflict}
                style={{ borderRadius: "7px" }}
              >
                Proceed with Database Name
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* <StartDispute data={data} show={showStartDispute} hide={() => setShowStartDispute(false)} /> */}
      <SuccessMsg
        show={showSolveConflict}
        hide={() => {
          setSolved(true);
          setShowSolveConflict(false);
        }}
        text={t("solvedConflict")}
        subTxt="Conflict"
        btnTxt="Ok"
      />
    </>
  );
}

export default ErrorHandler(DisputeDetails, axiosInstance);
