import React, { Component } from "react";
import Modal from "./Modal/Modal";
import axios from "axios";
import axiosInstance, { BASE_URL } from "../api/axios";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Cookies from "js-cookie";
import { LogOut } from "../global-state/actions/authAction";

const axiosApiInstance = axios.create();

const ErrorHandler = (WrappedComponent, axios) => {
  return class extends Component {
    state = {
      error: null,
      show: false,
    };

    UNSAFE_componentWillMount() {
      this.reqInterceptor = axios.interceptors.request.use(async (config) => {
        config.headers.Authorization = "Bearer " + Cookies.get("accessToken");
        this.setState({ error: null });

        return config;
      });
      this.resInterceptor = axios.interceptors.response.use(
        (response) => {
          return response;
        },
        async (error) => {
          const originalRequest = error.config;
          if (error.response && error.response.status === 401) {
            console.log(originalRequest._retry);
            originalRequest._retry = true;

            console.log(error.response);
            if (error.response.config?.url.includes("/auth/jwt/refresh")) {
              this.props.LogOut();
              window.location.pathname = `/`;
            } else {
              let x = await axios
                .post(BASE_URL + `/auth/jwt/refresh`, {
                  refresh: localStorage.getItem("refreshToken"),
                })
                .catch((err) => {
                  console.log("here");
                  if (err.response && err.response.status === 401) {
                    console.log("here");
                    this.props.LogOut();
                    window.location.pathname = `/`;
                  }
                });

              if (x) {
                Cookies.set("accessToken", x.data.access);
                console.log(x);
              }
            }

            return axiosApiInstance(originalRequest);
          } else if (error.response) {
            console.log("hna fl else if");
            if (error.response.status === 400 && error.response.status) {
              this.setState({ error: "", show: false });
            } else if (error.response.status === 403) {
              console.log(error.response.data.detail);

              this.setState(
                { error: { message: error.response.data.detail } },
                () => {
                  this.setState({ show: true });
                }
              );
            }
          } else {
            console.log(error);
            this.setState({ error: error, show: true });
            console.log(this.state);
          }
          return Promise.reject(error);
        }
      );
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    errorConfirmed = () => {
      this.setState({ error: null, show: false });
    };
    render() {
      return (
        <div>
          <Modal show={this.state.show} clicked={this.errorConfirmed}>
            {this.state.show ? this.state.error.message : null}
          </Modal>
          <WrappedComponent {...this.props}></WrappedComponent>
        </div>
      );
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ LogOut }, dispatch);
};

const composedComponent = compose(
  connect(null, mapDispatchToProps),
  ErrorHandler
);
export default composedComponent;
