import React, { useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Input, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { useNavigate } from "react-router-dom";
function TransactionDispute({
  show,
  hide,
  fields,
  id,
  banks,
  benfBranch,
  drawerBranch,
  currency
}) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  useEffect(() => {
    console.log(fields);

    for (let field of fields) {
      console.log(field);
      if (
        field.field_name == "drawer_bank" ||
        field.field_name == "beneficiary_bank"
      ) {
        field.oldName = banks.filter((bank) => bank.id === field.old_data)[0];
        field.newName = banks.filter((bank) => bank.id === field.new_data)[0];
        console.log(field.name);
        console.log(field);
      }
      if (field.field_name == "beneficiary_branch") {
        field.oldName = benfBranch.filter(
          (branch) => branch.id === field.old_data
        )[0];
        field.newName = benfBranch.filter(
          (branch) => branch.id === field.new_data
        )[0];
        console.log(field.name);
        console.log(field);
      }
      if (field.field_name == "drawer_branch") {
        field.oldName = drawerBranch.filter(
          (branch) => branch.id === field.old_data
        )[0];
        field.newName = drawerBranch.filter(
          (branch) => branch.id === field.new_data
        )[0];
        console.log(field.name);
        console.log(field);
      }

      if (field.field_name == "currency") {
        field.oldName = currency.filter(
          (curr) => curr.id === field.old_data
        )[0];
        field.newName = currency.filter(
          (curr) => curr.id === field.new_data
        )[0];
        console.log(field.name);
        console.log(field);
      }
    }
  }, [fields]);

  const onFinish = async (values) => {
    values.type = "2";
    values.cheque = id;
    values.fields = fields;
    console.log(values);
    let result = await axiosInstance.post("/Dispute/", values);
    console.log(result);
    if (result) {
      hide();
      navigate("/all_disputes");
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      show={show}
      onHide={hide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction Dispute</Modal.Title>
      </Modal.Header>
      <Form onFinish={onFinish} layout="vertical">
        <Modal.Body>
          {fields.length > 0
            ? fields.map((field) => {
                return (
                  <>
                    {" "}
                    <h5 className="subTitle">
                      {field.field_name
                        .split("_")
                        .map(
                          (word) =>
                            word.substr(0, 1).toUpperCase() + word.substr(1)
                        )
                        .join(" ")}
                    </h5>
                    <Row className="my-4">
                      <Col sm="6">
                        {" "}
                        <Form.Item label="From">
                          <Input
                            value={
                              field.field_name === "drawer_bank" ||
                              field.field_name === "beneficiary_bank" ||
                              field.field_name == "drawer_branch" ||
                              field.field_name == "beneficiary_branch" ||
                              field.field_name == "currency"
                                ? field?.oldName?.name
                                : field.old_data
                            }
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col sm="6">
                        <Form.Item label="To">
                          <Input
                            value={
                              field.field_name === "drawer_bank" ||
                              field.field_name === "beneficiary_bank" ||
                              field.field_name == "drawer_branch" ||
                              field.field_name == "beneficiary_branch" ||
                              field.field_name == "currency"
                                ? field?.newName?.name
                                : field.new_data
                            }
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <hr />
                  </>
                );
              })
            : null}
          <Row>
            {" "}
            <Col sm="6">
              <Form.Item label="Email" name="email">
                <Input placeholder={t("form.email")} />
              </Form.Item>
            </Col>
            <Col sm="6">
              <Form.Item
                label={t("form.mobileNumber")}
                name="phone"
                rules={[
                  {
                    required: true,
                    pattern: `^01[0125][0-9]{8}$`,
                    message: `${t("form.wrongFormat")}`
                  }
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Form.Item name="notes" label="Notes">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={hide} className="cancelBtn">
            Cancel
          </Button>
          <Button htmlType="submit" className="formBtn">
            Confirm&Create Dispute
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ErrorHandler(TransactionDispute, axiosInstance);
