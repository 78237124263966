import React, { useEffect, useState } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import EscalationForm from "./EscalationForm";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function EditEscalation() {
  let params = useParams();
  const permission = useSelector((state) => state.auth.user.permissions);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      let result = await axiosInstance.get(`/EscalationMatrix/${params.id}`);

      console.log("det", result.data);
      setData(result.data);
      setLoading(false);
    } catch (err) {
      console.log("getData", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const EditUser = async (values, dispute, transactions) => {
    setBtnLoading(true);

    let reqData = {
      email: values.email,
      name: values.name,
      dispute_days: values.dispute_days,
      transactions_days: values.transactions_days,
      etype:
        (transactions === true) & (dispute === true)
          ? "3"
          : (transactions === true) & (dispute === false)
          ? "2"
          : (transactions === false) & (dispute === true)
          ? "1"
          : (transactions === false) & (dispute === false)
          ? "4"
          : "",
    };

    console.log("reqData", reqData);
    console.log("values", values);

    let result = await axiosInstance
      .put(`/EscalationMatrix/${params.id}`, reqData)
      .catch((err) => {
        // console.log(err)
        console.log(err.response);
        if (err.response && err.response.status !== 500) {
          for (const error in err.response.data) {
            console.log(err.response.data[error][0]);
            setTimeout(() => {
              toast.error(`${error + " : " + err.response.data[error][0]}`);
            }, 500);
          }
        }
        setLoading(false);
      });

    if (result) {
      console.log("edit EscalationMatrix", result.data);
      console.log("edit", values);

      setBtnLoading(false);

      toast.success(t("successfulRequest"));
      setTimeout(() => {
        permission.includes("account_manager")
          ? navigate("/banker_escalation_users")
          : permission.includes("iscore_account_manager")
          ? navigate("/escalation_users")
          : navigate("/home");
      }, 1500);
    }
  };

  return (
    <section>
      <h1 className="pageTitle"> {t("EditEsclationMatrix")}</h1>

      <div className="sectionHolder p-5">
        <h2 className="currancyFormTitle">{t("userEsclationSettings")}</h2>
        <p className="currancyFormsubTitle">{t("escalationSubtitle")} </p>
        {loading ? (
          <Spin />
        ) : (
          <EscalationForm data={data} formId="escalationForm" Func={EditUser} />
        )}
      </div>
      <div className="formBtnsHolders">
        <button className="formBtn" onClick={() => navigate(-1)}>
          <FontAwesomeIcon className="px-1" icon={faTrash} /> {t("discard")}
        </button>
        <button className="formBtn" form="escalationForm" htmlType="submit">
          <FontAwesomeIcon className="pe-2 ps-2" icon={faCheck} />
          {btnLoading ? <Spin /> : t("saveSettings")}
        </button>
      </div>
      <ToastContainer position="bottom-right" />
    </section>
  );
}

export default ErrorHandler(EditEscalation, axiosInstance);
