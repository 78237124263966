import React, { useEffect, useState } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { Form, Row, Col, Button, Select, DatePicker, Table } from "antd";
import arrow from "../../images/arrow.svg";
import SuccessMsg from "../layout/SuccessMsg";
import { useSelector } from "react-redux";
import { CSVDownload } from "react-csv";
import UnSuccessMsg from "../layout/UnSuccessMsg";

const lang = localStorage.getItem("i18nextLng");
const { RangePicker } = DatePicker;

function InvoiceMechanism() {
    const [form] = Form.useForm();
    const { t, i18n } = useTranslation();
    const userType = useSelector((state) => state.auth.user.user_type);
    const [banks, setBanks] = useState([]);
    const [payments, setPayments] = useState([]);
    const [date, setDate] = useState("");
    const [invoiceData, setInvoiceData] = useState();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFail, setShowFail] = useState(false);
    const [invoice, setInvoice] = useState("");
    const [selectedBanks, setSelectedBanks] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const columns = [
        {
            title: "Bank Code",
            dataIndex: "bank",
            // render: (row) => <span className="tableCol1">{row?}</span>,
        },
        {
            title: "Branch Code",
            dataIndex: "branch_code",
        },
        {
            title: "Branch Name",
            dataIndex: "branch_name",
        },
        {
            title: "Product ID",
            dataIndex: "product",
        },
        {
            title: "Configurations",
            dataIndex: "configuration",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
        },
    ];
    const columnsAr = [
        {
            title: "كود البنك",
            dataIndex: "bank",
        },
        {
            title: "كود الفرع",
            dataIndex: "branch_code",
        },
        {
            title: "اسم الفرع",
            dataIndex: "branch_name",
        },
        {
            title: "رقم المنتج",
            dataIndex: "product",
        },
        {
            title: "الإعدادات",
            dataIndex: "configuration",
        },
        {
            title: "الكمية",
            dataIndex: "quantity",
        },
    ];
    const getBanks = async () => {
        try {
            let result = await axiosInstance.get(`/Bank/?page_size=50`);
            console.log(result);
            setBanks(result.data.data);
        } catch (err) {
            console.log(err);
        }
    };

    const getPayments = async () => {
        try {
            let result = await axiosInstance.get(`/PaymentConfigs/`);
            console.log(result);
            setPayments(result.data.data);
        } catch (err) {
            console.log(err);
        }
    };

    const handleOnChange = (date, dateString, setFunction) => {
        console.log(date);
        setFunction(dateString);
    };

    const onChange = (e, setFunction) => {
        setFunction(e);
    };

    const onFinish = async (values) => {
        values.banks = selectedBanks;
        values.services = selectedTypes;
        values.date = date;
        console.log(values);
        console.log(
            "banks",
            values.banks.join(","),
            "start :",
            values.date[0],
            "end :",
            values.date[1],
            "services",
            values.services.join(",")
        );

        let result = await axiosInstance
            .get(
                `/WebUsageInvoice/?start_date=${
                    values.date[0] === undefined ? "" : values.date[0]
                }&end_date=${
                    values.date[1] === undefined ? "" : values.date[1]
                }&bank=${values.banks.join(",")}&payment=${values.services.join(
                    ","
                )}`
            )
            .catch((err) => console.log(err));
        if (result) {
            console.log(result);
            if (result.data === "") {
                setShowFail(true);
            } else {
                setShowSuccess(true);
            }
            setInvoice(result.data);
        }
    };

    const viewInvoice = async () => {
        let result = await axiosInstance
            .get(
                `/WebUsage/?start_date=${
                    date[0] === undefined ? "" : date[0]
                }&end_date=${
                    date[1] === undefined ? "" : date[1]
                }&bank=${selectedBanks.join(",")}&payment=${selectedTypes.join(
                    ","
                )}`
            )
            .catch((err) => console.log(err));

        if (result) {
            console.log(result);
            setInvoiceData(result.data);
        }
    };

    const downloadInvoiceTextFile = async () => {
        let result = await axiosInstance
            .get(
                `/WebUsageInvoice/?start_date=${
                    date[0] === undefined ? "" : date[0]
                }&end_date=${
                    date[1] === undefined ? "" : date[1]
                }&bank=${selectedBanks.join(",")}&payment=${selectedTypes.join(
                    ","
                )}`
            )
            .catch((err) => console.log(err));

        if (result.data === "") {
            setShowFail(true);
        } else {
            setShowSuccess(true);

            console.log("start");

            console.log(result);

            const file = window.URL.createObjectURL(new Blob([result.data]));
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = file;
            // the filename you want
            a.download = "Invoice.txt";
            // <a href=file download="Invoice.txt" style=display/>
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(file);
            console.log("end");
        }
    };
    useEffect(() => {
        getBanks();
        getPayments();
    }, []);

    return (
        <>
            <div className="my-5">
                <p className="title mb-4">
                    {userType == "entity" || userType == "bank"
                        ? t("entityInvoicingMechanism")
                        : t("iscoreInvoicingMechanism")}
                </p>
                <div className="formWrapper">
                    <Row>
                        <Col span={12}>
                            <p className="subTitle mb-2">
                                {t("invoicingMechanism")}
                            </p>
                            <p className="subHeader">{t("form.invoiceText")}</p>
                        </Col>
                    </Row>

                    <Form
                        className="invoice"
                        onFinish={onFinish}
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item name="banks" label={t("form.banks")}>
                            <Select
                                mode="multiple"
                                onChange={(e) => onChange(e, setSelectedBanks)}
                            >
                                {banks.map((bank) => {
                                    return (
                                        <Select.Option
                                            value={bank.id}
                                            key={bank.id}
                                        >
                                            {bank.name} - {bank.name_ar}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                            <img src={arrow} alt="arrow" />
                        </Form.Item>
                        <Form.Item
                            label={t("form.invoiceDateRange")}
                            name="date"
                            rules={[
                                {
                                    required: false,
                                    message: t("form.requiredDate"),
                                },
                            ]}
                        >
                            <RangePicker
                                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                onChange={(e, v) =>
                                    handleOnChange(e, v, setDate)
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            className="d-flex"
                            name="services"
                            label={t("form.servicesTypes")}
                        >
                            <Select
                                mode="multiple"
                                value={selectedTypes}
                                style={{minWidth: "500px"}}
                                onChange={(e) => onChange(e, setSelectedTypes)}
                            >
                                {payments.map((payment) => {
                                    return (
                                        <Select.Option
                                            value={payment.id}
                                            key={payment.id}
                                        >
                                            {payment.title} - {payment.title_ar}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                            <img src={arrow} alt="arrow" />
                        </Form.Item>

                        <Row className="text-end">
                            {" "}
                            <Button
                                color="primary"
                                htmlType="submit"
                                style={{ marginBottom: "1rem" }}
                                className="formBtn mt-3"
                            >
                                {t("form.InvoiceMechanismBtn1")}
                            </Button>
                            <Button
                                color="primary"
                                // htmlType="submit"
                                style={{ marginBottom: "1rem" }}
                                className="formBtn mt-3 mx-2"
                                onClick={() => downloadInvoiceTextFile()}
                            >
                                {t("form.InvoiceMechanismBtn2")}
                            </Button>
                        </Row>
                    </Form>
                    <Row className="flex-end">
                        <Button
                            style={{ marginBottom: "1rem" }}
                            className="formBtn mt-3"
                            onClick={viewInvoice}
                        >
                            {t("form.viewInvoice")}
                        </Button>
                    </Row>
                    {invoiceData ? (
                        <Table
                            columns={lang === "en" ? columns : columnsAr}
                            dataSource={invoiceData}
                        />
                    ) : null}
                </div>
                {invoice !== "" ? (
                    <CSVDownload data={invoice} target="_blank" />
                ) : null}

                <SuccessMsg
                    show={showSuccess}
                    hide={() => setShowSuccess(false)}
                    text={t("form.InvoiceMechanismSuccess")}
                    btnTxt={t("form.done")}
                />
            </div>

            <UnSuccessMsg
                show={showFail}
                hide={() => setShowFail(false)}
                text={t("form.InvoiceMechanismFail")}
                btnTxt={t("form.done")}
            />
        </>
    );
}

export default ErrorHandler(InvoiceMechanism, axiosInstance);
