import {Select, Input, Table, Pagination} from "antd";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Drawer} from "antd";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import {useParams} from "react-router-dom";

function ActionsStateMaker() {
    let params = useParams();

    const {t} = useTranslation();

    const [reportType, SetReportType] = useState("");
    const [current, setCurrent] = useState(1);

    const [drawerView, SetDrawerView] = useState(false);

    const [data, SetData] = useState([]);
    const [summry, SetSummry] = useState(null);

    const HandleOpenDrawer = (info) => {
        SetSummry(info.changes);
        console.log("open");
        console.log(summry);
        SetDrawerView(!drawerView);
    };

    let GlobalColumns = [
        {
            dataIndex: "create_date",
            title: t("timeStamp"),
            render: (row) => <>{row.split("T")[0]}</>,
        },
        {
            dataIndex: "param",
            title: t("ParameterType"),
            render: (row) => <>{t(`changeables.${row}`)}</>
        },
        {
            dataIndex: "operation_type",
            title: t("ActionType"),
            render: (row) => <>{t(`methods.${row}`)}</>,
        },

        {
            title: t("ActionDescription"),
            render: (row) => <>
                {`${
                    row.changed_by?.name
                } ${
                    t("wants")
                } ${
                    t(`methods.${row.operation_type}`)
                } ${
                    t(`changeables.${row.param}`)
                }`}
            </>
        },
        {
            title: t("action"),
            render: (row) => (
                // row.operation_type !== "edit" ? null : (
                <button
                    onClick={() => HandleOpenDrawer(row)}
                    style={{backgroundColor: "transparent", border: "none"}}
                >
                    {t("ViewtoCheck")}
                </button>
            ),
            // )
        },
    ];

    const getdata = async () => {
        try {
            let result = await axiosInstance.get(
                `AllTemp/?state=${params.state}&params=${reportType}&page=${current}`
            );

            console.log("getdata", result);
            SetData(result.data);
        } catch (err) {
            console.log("getdata err", err);
        }
    };

    useEffect(() => {
        getdata();
    }, [reportType]);

    return (
        <main>
            <Drawer
                placement={"right"}
                closable={true}
                onClose={() => SetDrawerView(false)}
                visible={drawerView}
                height={"100vh"}
                // width={"50vw"}
                // title="Action Description"
                // className="d-flex justify-content-between "
            >
                <table className="mx-auto summrytable">
                    <tr className="text-center ">
                        <th>{t("changedField")}</th>
                        <th>{t("changedFrom")}</th>
                        <th>{t("ChangedTo")}</th>
                    </tr>

                    {summry !== null
                        ? summry.map((change, i) => (
                            <tr className="text-center">
                                <td>{Object.keys(change)[0].replace(/_/g, " ")}</td>
                                <td>{Object.values(change)[0].old.toString()}</td>
                                <td>{Object.values(change)[0].new.toString()}</td>
                            </tr>
                        ))
                        : null}
                </table>
            </Drawer>

            {/* <Link to="/ParamEditorHome">Back</Link> */}

            <div className="formWrapper">
                <div className="d-flex align-items-center justify-content-between py-4">
                    <p className="mb-0">
                        {params.state === "rejected"
                            ? t("rejectedActions")
                            : params.state === "accepted"
                                ? t("acceptedActions")
                                : t("pendingActions")}
                    </p>
                    <div className="d-flex align-items-start">
                        <Select
                            style={{minWidth: "150px"}}
                            placeholder={t("sheetType")}
                            onChange={(e) => SetReportType(e)}
                            value={reportType}
                        >
                            <Select.Option value={""}>{t("all")}</Select.Option>
                            <Select.Option value={"bank"}>{t("form.banks")}</Select.Option>
                            <Select.Option value={"entity"}>{t("entities")}</Select.Option>
                            <Select.Option value={"bankbranch"}>
                                {t("bankbranch")}
                            </Select.Option>
                            <Select.Option value={"entitybranch"}>
                                {t("entitybranch")}
                            </Select.Option>{" "}
                            <Select.Option value={"entitytype"}>
                                {t("entitytype")}
                            </Select.Option>
                            <Select.Option value={"rejectionreason"}>
                                {t("rejectionreason")}
                            </Select.Option>{" "}
                            <Select.Option value={"paymentconfigs"}>
                                {t("paymentconfigs")}
                            </Select.Option>
                            <Select.Option value={"configuration"}>
                                {t("configuration")}
                            </Select.Option>{" "}
                            <Select.Option value={"emailtemplate"}>
                                {t("emailtemplate")}
                            </Select.Option>
                            <Select.Option value={"conversionrate"}>
                                {t("currency")}
                            </Select.Option>
                        </Select>
                    </div>
                </div>

                <Table pagination={false} columns={GlobalColumns} dataSource={data}/>

                <div>
                    <Pagination
                        current={current}
                        defaultCurrent={current}
                        onChange={(e) => setCurrent(e)}
                        total={10}
                        showSizeChanger={false}
                    />
                </div>
            </div>
        </main>
    );
}

export default ErrorHandler(ActionsStateMaker, axiosInstance);
