import React, { useState, useEffect } from "react";
import { Upload, message, Button, Row, Col, Spin, Empty, Progress } from "antd";
import Icon, {
  InboxOutlined,
  DownloadOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import axiosInstance, { BASE_URL } from "../../../api/axios";
import UploadedHistory from "./UploadedHistory";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import ConflictRow from "./ConflictRow";
import axios from "axios";
// const axiosInstance = axios.create({
//   headers: {
//     "content-type": "text/xlsx"
//   },
//   baseURL: BASE_URL
// });
const Data = ({ color, number, text, loading }) => {
  const antIcon = <Icon style={{ fill: color }} component={LoadingOutlined} />;

  return (
    <div className="mt-5">
      {loading ? (
        <div className="icon-wrapper">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <>
          {" "}
          <FontAwesomeIcon
            style={{ color: color, fontSize: "12px", marginInlineEnd: "5px" }}
            icon={faCircle}
          />
          <span className="data">{text}</span>
          <strong>{number}</strong>{" "}
        </>
      )}
    </div>
  );
};

function BulkUpload({ link, template, title, description, filterValue }) {
  const { t } = useTranslation();

  const [loading] = useState(false);
  const [data, setData] = useState("");
  const [showDispute, setShowDispute] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loadresponse, setLoadRes] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {}, []);

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: false,
    action: `${BASE_URL + link}`,
    beforeUpload(file, fileList) {
      console.log(file, fileList);
      const size = file.size;
      // if (size >= 5242880) {
      //   message.error(t("fileSize"));
      // }
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      if (!isExcel) {
        message.error(t(`checkFile`));
      }
    },
    customRequest({ action, file, filename, onProgress, onSuccess }) {
      if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        //  && file.size <= 5242880
      ) {
        setLoadRes(true);
        setDisabled(true);

        const formData = new FormData();

        formData.append(filename, file);
        axiosInstance
          .post(action, formData, {
            onUploadProgress: (event) => {
              const percent = Math.floor((event.loaded / event.total) * 100);
              setProgress(percent);
              if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
              }
              onProgress({ percent: (event.loaded / event.total) * 100 });
            }
          })
          .then(({ data: response }) => {
            message.success(t("fileSucessUpload"));
            onSuccess(response, file);
            setData(response);
            setUploaded(!uploaded);
            setLoadRes(false);
            setDisabled(false);
          })
          .catch(() => {
            message.error(t("fileUploadFailed"));
            setLoadRes(false);
          });
      }

      return {
        abort() {
          console.log("upload progress is aborted.");
        }
      };
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    }
  };

  const getTemplate = async () => {
    // let res = await fetch(`${BASE_URL}/BulkCreate/`, {
    //   method: "GET",

    //   headers: {
    //     "content-type": "text/xlsx",

    //     Authorization:
    //       "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjYzODgxODE1LCJqdGkiOiIxNzM3MTNjMjk3MzA0ZWI0YmUwOGQyNTFkN2ZmOTAzZSIsInVzZXJfaWQiOjJ9.7cguM-jQ7Ffsd0CgMRNGRRSKHiBImuUkAmoYXHuqGXc"
    //   }
    // });
    // console.log(res);
    // let blob = await res.blob();

    // const blobUrl = window.URL.createObjectURL(new Blob([blob]));

    // let a = document.createElement("a");

    // a.href = blobUrl;

    // a.download = "file.xlsx";

    // a.click();

    let result = await axiosInstance
      .get(`${link} `, { responseType: "blob" })
      .catch((err) => console.log(err));
    // console.log(result);
    const file = window.URL.createObjectURL(new Blob([result.data]));
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = file;
    // the filename you want
    a.download = "template.xlsx";
    // <a href=file download="Invoice.txt" style=display/>
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(file);
  };

  return (
    <div>
      <p className="title-upload">{t("uploadFile")}</p>

      <div className="bulk-card">
        <p className="subTitle">{title}</p>
        <hr />
        <p style={{ marginBottom: "0px" }}>{t("templateInstruction")}</p>
        <p>
          {t("templateInstruction1")}{" "}
          <strong> {t("templateInstruction2")}</strong>{" "}
          {t("templateInstruction3")} <strong> {description} </strong>
        </p>
        <DownloadOutlined />
        <Button className="tempBtn" onClick={getTemplate}>
          {" "}
          {t("downloadTemplate")}
        </Button>
        <div className="uploadWrapper">
          <Dragger {...props} disabled={progress > 0 ? true : false}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("downloadText1")}</p>
            <p className="ant-upload-hint">{t("downloadText2")}</p>
          </Dragger>
          {progress > 0 ? <Progress percent={progress} /> : null}
        </div>
        {/* {link === "/BulkCreate/" ? ( */}
        <div>
          <UploadedHistory filterValue={filterValue} uploaded={uploaded} />
        </div>
        {/* ) : null} */}
      </div>

      {data == "" && !loadresponse ? (
        <div className="mt-5">
          <Empty description={t("noData")} />
        </div>
      ) : loadresponse ? (
        <div className="text-center mt-5">
          {" "}
          <Spin tip="Loading..." />
        </div>
      ) : (
        <>
          <Row>
            <Col span={4}>
              <Data
                text={t("records")}
                loading={loading}
                color="#242731D9"
                number={data.total}
              />
            </Col>
            {link === "/BulkCreate/" ? (
              <Col span={4}>
                <Data
                  text={t("exists")}
                  loading={loading}
                  color="#00000073"
                  number={data.exists?.count}
                />
              </Col>
            ) : null}

            <Col span={4}>
              <Data
                text={t("success")}
                loading={loading}
                color="#52C41A"
                number={data.success?.count}
              />
            </Col>
            <Col span={4}>
              <Data
                text={t("updates")}
                loading={loading}
                color="#3F93D3"
                number={data.update?.count}
              />
            </Col>
            <Col span={4}>
              <Data
                text={t("failed")}
                loading={loading}
                color="#F5222D"
                number={data.errors?.count}
              />
            </Col>
            {link === "/BulkCreate/" ? (
              <Col span={4}>
                <Data
                  text={t("conflicts")}
                  loading={loading}
                  color="#FFAB00"
                  number={data.conflicts?.count}
                />
              </Col>
            ) : null}
          </Row>

          {data.erros?.count !== 0 ? (
            <>
              {" "}
              <Row className="mt-4 tableheader">
                <Col span={6}>
                  <p>{t("rowNumber")}</p>
                </Col>
                <Col span={6}>
                  <p>{t("column")}</p>
                </Col>{" "}
                <Col span={6}>
                  <p>{t("errorInfo")}</p>
                </Col>{" "}
                <Col span={6}>
                  <p>{t("status")}</p>
                </Col>
              </Row>
              {Object.keys(data.errors?.data).map((issue) => {
                return (
                  <Row className="tableRow">
                    <Col className="d-flex align-items-center" span={6}>
                      <span>{issue}</span>
                    </Col>
                    <Col span={6}>
                      {data.errors?.data[issue].map((field) => {
                        return (
                          <>
                            {Object.keys(field).map((key) => (
                              <p className="error-icon">{key}</p>
                            ))}
                          </>
                        );
                      })}
                    </Col>
                    <Col span={6}>
                      {data.errors?.data[issue].map((field) => {
                        console.log(Object.values(field));
                        return (
                          <>
                            {Object.values(field).map((err) => (
                              <p className="error-icon">{err[0]}</p>
                            ))}
                          </>
                        );
                      })}
                    </Col>
                    <Col className="d-flex align-items-center" span={6}>
                      <Button danger>{t("failed")}</Button>
                    </Col>
                  </Row>
                );
              })}
              {link === "/BulkCreate/" &&
                Object.keys(data.conflicts?.data).map((issue, i) => {
                  return (
                    <ConflictRow
                      key={i}
                      data={data}
                      issue={issue}
                      showDispute={showDispute}
                      setShowDispute={setShowDispute}
                    />
                  );
                })}
            </>
          ) : null}
        </>
      )}
    </div>
  );
}

export default ErrorHandler(BulkUpload, axiosInstance);
