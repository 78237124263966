import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Menu } from "antd";
import {
    HomeFilled,
    InteractionFilled,
    ContainerFilled,
    DashboardFilled,
    EditFilled,
    AppstoreOutlined,
    FileTextOutlined,
    UsergroupAddOutlined,
    UserAddOutlined,
    VerifiedOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import "../../style-sheets/side-menu.css";
import ilogo from "../../images/Icon.svg";
import logo from "../../images/full-logo.svg";
import CheckAuth from "../HOC/CheckAuth";

const { Sider } = Layout;
const { SubMenu } = Menu;
export default function Sidemenu({ direction }) {
    const location = useLocation();
    const { t } = useTranslation();

    const [collapsed, setCollapsed] = useState(true);

    const onCollapse = (collapse) => {
        setCollapsed(collapse);
    };

    return (
        <Sider
            reverseArrow={direction === "ltr" ? false : true}
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
        >
            <div className="logo" />
            <Menu
                selectedKeys={location.pathname}
                theme="light"
                mode="inline"
                style={{ borderRadius: "20px", overflow: "hidden" }}
            >
                <div className="image-div">
                    {collapsed ? (
                        <img src={ilogo} />
                    ) : (
                        <img
                            className={collapsed ? "" : "logo-animation"}
                            src={logo}
                        />
                    )}
                    <hr />
                </div>
                <CheckAuth
                    Permissions={[
                        "account_manager",
                        "report_viewer",
                        "transaction_monitor",
                        "entity_maker",
                        "entity_checker",
                        "dispute_monitor",
                        "dispute_checker",
                        "dispute_starter",
                        "dispute_maker",
                        "user_create_maker",
                        "user_create_checker",
                        "iscore_account_manager",
                    ]}
                    userType={["iscore", "entity", "bank"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/home" ? "activeItem" : null
                        }
                        onClick={(e) => console.log(e)}
                        key="/home"
                        icon={<HomeFilled />}
                    >
                        <Link activeClassName={"active"} to={"/home"}>
                            {t("sidemenu.dashboard")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["entity_maker"]}
                    userType={["entity", "bank"]}
                >
                    <SubMenu
                        key="bulk"
                        title={t("sidemenu.bulkUpload")}
                        icon={<ContainerFilled />}
                    >
                        <Menu.Item
                            className={
                                location.pathname === "/bulk_upload"
                                    ? "activeItem"
                                    : null
                            }
                            key="/bulk_upload"
                        >
                            <Link activeClassName="active" to={"/bulk_upload"}>
                                {t("sidemenu.transaction")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            className={
                                location.pathname === "/benef_upload"
                                    ? "activeItem"
                                    : null
                            }
                            key="/benef_upload"
                        >
                            <Link activeClassName="active" to={"/benef_upload"}>
                                {t("benifUpload")}
                            </Link>
                        </Menu.Item>

                        <Menu.Item
                            className={
                                location.pathname === "/cheque_book"
                                    ? "activeItem"
                                    : null
                            }
                            key="/cheque_book"
                        >
                            <Link activeClassName="active" to={"/cheque_book"}>
                                {t("ChequeBook")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            className={
                                location.pathname === "/upload_history"
                                    ? "activeItem"
                                    : null
                            }
                            key="/upload_history"
                        >
                            <Link
                                activeClassName="active"
                                to={"/upload_history"}
                            >
                                {t("sidemenu.uploadHistory")}
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </CheckAuth>
            </Menu>
            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["entity_maker"]}
                    userType={["entity", "bank"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/transaction"
                                ? "activeItem"
                                : null
                        }
                        key="/transaction"
                        icon={<EditFilled />}
                    >
                        <Link activeClassName="active" to={"/transaction"}>
                            {t("sidemenu.createTransaction")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>
            {/* <Menu selectedKeys={location.pathname} theme="light" mode="inline">
        <CheckAuth Permissions={["user_create_maker"]} userType={["iscore"]}>
          <Menu.Item
            className={
              location.pathname === "/escalation_users" ? "activeItem" : null
            }
            key="/escalation_users"
            icon={<EditFilled />}
          >
            <Link activeClassName="active" to={"/escalation_users"}>
              {t("sidemenu.escUsers")}
            </Link>
          </Menu.Item>
        </CheckAuth>
      </Menu> */}
            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={[
                        "dispute_monitor",
                        "dispute_checker",
                        "dispute_starter",
                        "dispute_maker",
                    ]}
                    userType={["entity", "bank", "iscore"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/all_disputes"
                                ? "activeItem"
                                : null
                        }
                        key="/all_disputes"
                        icon={<InteractionFilled />}
                    >
                        <Link activeClassName="active" to={"/all_disputes"}>
                            {t("disputes")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["report_viewer"]}
                    userType={["iscore"]}
                >
                    <SubMenu
                        key="reports"
                        title={t("sidemenu.inquiryReport")}
                        icon={
                            <svg
                                className="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="14"
                                viewBox="0 0 11 14"
                                fill="none"
                            >
                                <path
                                    d="M10.8333 4.33337L6.83333 0.333374H1.5C1.14638 0.333374 0.807237 0.47385 0.557188 0.723898C0.30714 0.973947 0.166664 1.31309 0.166664 1.66671V12.3334C0.166664 12.687 0.30714 13.0261 0.557188 13.2762C0.807237 13.5262 1.14638 13.6667 1.5 13.6667H9.5C9.85362 13.6667 10.1928 13.5262 10.4428 13.2762C10.6929 13.0261 10.8333 12.687 10.8333 12.3334V4.33337ZM3.5 11.6667H2.16666V5.66671H3.5V11.6667ZM6.16666 11.6667H4.83333V7.66671H6.16666V11.6667ZM8.83333 11.6667H7.5V9.66671H8.83333V11.6667ZM6.83333 5.00004H6.16666V1.66671L9.5 5.00004H6.83333Z"
                                    fill="rgba(185, 185, 195, 1)"
                                />
                            </svg>
                        }
                    >
                        <Menu.Item
                            className={
                                location.pathname === "/drawee_report"
                                    ? "activeItem"
                                    : null
                            }
                            key="/drawee_report"
                        >
                            <Link
                                activeClassName="active"
                                to={"/drawee_report"}
                            >
                                {t("sidemenu.draweeReport")}
                            </Link>
                        </Menu.Item>

                        <Menu.Item
                            className={
                                location.pathname === "/beneficiary_report"
                                    ? "activeItem"
                                    : null
                            }
                            key="/beneficiary_report"
                        >
                            <Link
                                activeClassName="active"
                                to={"/beneficiary_report"}
                            >
                                {t("sidemenu.beneficiaryReport")}
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </CheckAuth>
            </Menu>
            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["beneficiary_inquiry", "drawee_inquiry"]}
                    userType={["entity", "bank"]}
                >
                    <SubMenu
                        key="reports"
                        title={t("sidemenu.inquiryReport")}
                        icon={
                            <svg
                                className="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="14"
                                viewBox="0 0 11 14"
                                fill="none"
                            >
                                <path
                                    d="M10.8333 4.33337L6.83333 0.333374H1.5C1.14638 0.333374 0.807237 0.47385 0.557188 0.723898C0.30714 0.973947 0.166664 1.31309 0.166664 1.66671V12.3334C0.166664 12.687 0.30714 13.0261 0.557188 13.2762C0.807237 13.5262 1.14638 13.6667 1.5 13.6667H9.5C9.85362 13.6667 10.1928 13.5262 10.4428 13.2762C10.6929 13.0261 10.8333 12.687 10.8333 12.3334V4.33337ZM3.5 11.6667H2.16666V5.66671H3.5V11.6667ZM6.16666 11.6667H4.83333V7.66671H6.16666V11.6667ZM8.83333 11.6667H7.5V9.66671H8.83333V11.6667ZM6.83333 5.00004H6.16666V1.66671L9.5 5.00004H6.83333Z"
                                    fill="rgba(185, 185, 195, 1)"
                                />
                            </svg>
                        }
                    >
                        <CheckAuth
                            Permissions={["drawee_inquiry"]}
                            userType={["entity", "bank"]}
                        >
                            <Menu.Item
                                className={
                                    location.pathname === "/drawee_report"
                                        ? "activeItem"
                                        : null
                                }
                                key="/drawee_report"
                            >
                                <Link
                                    activeClassName="active"
                                    to={"/drawee_report"}
                                >
                                    {t("sidemenu.draweeReport")}
                                </Link>
                            </Menu.Item>
                        </CheckAuth>
                        <CheckAuth
                            Permissions={["beneficiary_inquiry"]}
                            userType={["entity", "bank"]}
                        >
                            <Menu.Item
                                className={
                                    location.pathname === "/beneficiary_report"
                                        ? "activeItem"
                                        : null
                                }
                                key="/beneficiary_report"
                            >
                                <Link
                                    activeClassName="active"
                                    to={"/beneficiary_report"}
                                >
                                    {t("sidemenu.beneficiaryReport")}
                                </Link>
                            </Menu.Item>
                        </CheckAuth>
                    </SubMenu>
                </CheckAuth>
            </Menu>
            <CheckAuth
                Permissions={["report_viewer"]}
                userType={["iscore", "entity", "bank"]}
            >
                <Menu mode="inline" selectedKeys={location.pathname}>
                    <SubMenu
                        key="usage_reports"
                        title={t("sidemenu.reports")}
                        icon={<DashboardFilled />}
                    >
                        <Menu.Item key="/usage_inquiry_report">
                            <Link
                                activeClassName="active"
                                to={"/usage_inquiry_report"}
                            >
                                {t("sidemenu.usageInquiryReport")}
                            </Link>
                        </Menu.Item>
                        <CheckAuth
                            Permissions={["report_viewer"]}
                            userType={["iscore"]}
                        >
                            <Menu.Item key="/dispute_report">
                                <Link
                                    activeClassName="active"
                                    to={"/dispute_report"}
                                >
                                    {t("disputeReport")}
                                </Link>
                            </Menu.Item>
                        </CheckAuth>
                        <Menu.Item key="/detailed_usage_report">
                            <Link
                                activeClassName="active"
                                to={"/detailed_usage_report"}
                            >
                                {t("sidemenu.detailedUsageReport")}
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </CheckAuth>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["param_editor_checker"]}
                    userType={["iscore"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/ParamEditorHomeChecker"
                                ? "activeItem"
                                : null
                        }
                        onClick={(e) => console.log(e)}
                        key="/ParamEditorHomeChecker"
                        icon={<HomeFilled />}
                    >
                        <Link
                            activeClassName={"active"}
                            to={"/ParamEditorHomeChecker"}
                        >
                            {t("sidemenu.dashboard")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth Permissions={["param_editor"]} userType={["iscore"]}>
                    <Menu.Item
                        className={
                            location.pathname === "/ParamEditorHomeMaker"
                                ? "activeItem"
                                : null
                        }
                        onClick={(e) => console.log(e)}
                        key="/ParamEditorHomeMaker"
                        icon={<HomeFilled />}
                    >
                        <Link
                            activeClassName={"active"}
                            to={"/ParamEditorHomeMaker"}
                        >
                            {t("sidemenu.dashboard")}
                        </Link>
                    </Menu.Item>

                    <Menu.Item
                        className={
                            location.pathname === "/report_settings"
                                ? "activeItem"
                                : null
                        }
                        onClick={(e) => console.log(e)}
                        key="/report_settings"
                        icon={<SettingOutlined />}
                    >
                        <Link
                            activeClassName={"active"}
                            to={"/report_settings"}
                        >
                            {t("sidemenu.ReportConfigurations")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth Permissions={["param_editor"]} userType={["iscore"]}>
                    <SubMenu
                        key="settings"
                        title={t("sidemenu.configurations")}
                        icon={<DashboardFilled />}
                    >
                        <Menu.Item
                            className={
                                location.pathname === "/currency_configuration"
                                    ? "activeItem"
                                    : null
                            }
                            key="/currency_configuration"
                        >
                            <Link
                                activeClassName="active"
                                to={"/currency_configuration"}
                            >
                                {t("sidemenu.CurrencyConfiguration")}
                            </Link>
                        </Menu.Item>

                        {/* <Menu.Item
              className={
                location.pathname === "/report_settings" ? "activeItem" : null
              }
              key="/report_settings"
            >
              <Link activeClassName="active" to={"/report_settings"}>
                {t("sidemenu.customizeReport")}
              </Link>
            </Menu.Item> */}
                        <CheckAuth
                            Permissions={["param_editor"]}
                            userType={["iscore"]}
                        >
                            <Menu.Item
                                className={
                                    location.pathname === "/email_configuration"
                                        ? "activeItem"
                                        : null
                                }
                                key="/email_configuration"
                            >
                                <Link
                                    activeClassName="active"
                                    to={"/email_configuration"}
                                >
                                    {t("sidemenu.emailConfiguration")}
                                </Link>
                            </Menu.Item>

                            <Menu.Item
                                className={
                                    location.pathname ===
                                    "/payment_configuration"
                                        ? "activeItem"
                                        : null
                                }
                                key="/payment_configuration"
                            >
                                <Link
                                    activeClassName="active"
                                    to={"/payment_configuration"}
                                >
                                    {t("sidemenu.paymentConfiguration")}
                                </Link>
                            </Menu.Item>

                            <Menu.Item
                                className={
                                    location.pathname === "/entity_types"
                                        ? "activeItem"
                                        : null
                                }
                                key="/entity_types"
                            >
                                <Link
                                    activeClassName="active"
                                    to={"/entity_types"}
                                >
                                    {t("entityTypes")}
                                </Link>
                            </Menu.Item>
                            <Menu.Item
                                className={
                                    location.pathname === "/entities/bank"
                                        ? "activeItem"
                                        : null
                                }
                                key="/entities/bank"
                            >
                                <Link
                                    activeClassName="active"
                                    to={"/entities/bank"}
                                >
                                    {t("allBanks")}
                                </Link>
                            </Menu.Item>
                            <Menu.Item
                                className={
                                    location.pathname === "/rejection_config"
                                        ? "activeItem"
                                        : null
                                }
                                key="/rejection_config"
                            >
                                <Link
                                    activeClassName="active"
                                    to={"/rejection_config"}
                                >
                                    {t("rejectionConfig")}
                                </Link>
                            </Menu.Item>
                        </CheckAuth>
                    </SubMenu>
                </CheckAuth>
            </Menu>
            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["iscore_account_manager"]}
                    userType={["iscore"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/logs" ? "activeItem" : null
                        }
                        key="/logs"
                        icon={<AppstoreOutlined />}
                    >
                        <Link activeClassName="active" to={"/logs"}>
                            {t("sidemenu.logs")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["report_viewer"]}
                    userType={["iscore"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/invoice"
                                ? "activeItem"
                                : null
                        }
                        key="/invoice"
                        icon={<FileTextOutlined />}
                    >
                        <Link activeClassName="active" to={"/invoice"}>
                            {t("sidemenu.invoices")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>
            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={[
                        "iscore_account_manager",
                        "user_create_maker",
                        "user_create_checker",
                    ]}
                    userType={["iscore", "entity", "bank"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/users" ? "activeItem" : null
                        }
                        key="/users"
                        icon={<UsergroupAddOutlined />}
                    >
                        <Link activeClassName="active" to={"/users"}>
                            {t("sidemenu.users")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["user_create_maker"]}
                    userType={["iscore", "entity", "bank"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/user_creation_entity"
                                ? "activeItem"
                                : null
                        }
                        key="/user_creation_entity"
                        icon={<UserAddOutlined />}
                    >
                        <Link
                            activeClassName="active"
                            to={"/user_creation_entity"}
                        >
                            {t("addUser")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["iscore_account_manager"]}
                    userType={["iscore"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/user_creation_iscore"
                                ? "activeItem"
                                : null
                        }
                        key="/user_creation_iscore"
                        icon={<UserAddOutlined />}
                    >
                        <Link
                            activeClassName="active"
                            to={"/user_creation_iscore"}
                        >
                            {t("sidemenu.usersCreation")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["iscore_account_manager"]}
                    userType={["iscore"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/escalation_users"
                                ? "activeItem"
                                : null
                        }
                        key="/escalation_users"
                        icon={<VerifiedOutlined />}
                    >
                        <Link activeClassName="active" to={"/escalation_users"}>
                            {t("EntityUserEsclations")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={["account_manager"]}
                    userType={["entity", "bank"]}
                >
                    <Menu.Item
                        className={
                            location.pathname === "/banker_escalation_users"
                                ? "activeItem"
                                : null
                        }
                        key="/banker_escalation_users"
                        icon={<VerifiedOutlined />}
                    >
                        <Link
                            activeClassName="active"
                            to={"/banker_escalation_users"}
                        >
                            {t("EntityUserEsclations")}
                        </Link>
                    </Menu.Item>
                </CheckAuth>
            </Menu>

            <Menu selectedKeys={location.pathname} theme="light" mode="inline">
                <CheckAuth
                    Permissions={[
                        "entity_checker",
                        "transaction_monitor",
                        "account_manager",
                        // "entity_maker"
                    ]}
                    userType={["iscore", "bank"]}
                >
                    <SubMenu
                        key="DataVerification"
                        title={t("sidemenu.verifyData")}
                        icon={
                            <svg
                                className="icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="16"
                                viewBox="0 0 14 16"
                                fill="none"
                            >
                                <path
                                    d="M13.5434 3.09415C13.4835 3.02573 13.4101 2.97041 13.3279 2.93166C13.2456 2.8929 13.1562 2.87155 13.0653 2.86892C11.653 2.832 9.86591 1.32185 8.68437 0.72923C7.95452 0.364307 7.47268 0.123692 7.11083 0.060307C7.03736 0.0496009 6.9627 0.0498083 6.88929 0.0609224C6.52744 0.124307 6.0456 0.364922 5.31637 0.729846C4.13483 1.32185 2.34775 2.832 0.935445 2.86892C0.844472 2.87168 0.755037 2.8931 0.672681 2.93184C0.590325 2.97058 0.516804 3.02583 0.456675 3.09415C0.332043 3.23516 0.267739 3.41945 0.277598 3.60738C0.580983 9.77538 2.79329 13.5926 6.67514 15.8111C6.77606 15.8683 6.88806 15.8978 6.99944 15.8978C7.11083 15.8978 7.22283 15.8683 7.32437 15.8111C11.2062 13.5926 13.4179 9.77538 13.7219 3.60738C13.7323 3.41948 13.6682 3.23505 13.5434 3.09415ZM10.4567 5.52L7.18098 10.3538C7.06344 10.5274 6.87883 10.6425 6.69606 10.6425C6.51268 10.6425 6.30898 10.5422 6.18037 10.4135L3.87144 8.104C3.79606 8.02834 3.75374 7.92588 3.75374 7.81908C3.75374 7.71227 3.79606 7.60982 3.87144 7.53415L4.44191 6.96246C4.51773 6.88738 4.62012 6.84526 4.72683 6.84526C4.83354 6.84526 4.93593 6.88738 5.01175 6.96246L6.51329 8.464L9.12191 4.61354C9.18226 4.52543 9.27503 4.46477 9.37995 4.44483C9.48487 4.42488 9.59343 4.44726 9.68191 4.50708L10.3496 4.96C10.4378 5.02026 10.4986 5.11298 10.5187 5.21791C10.5387 5.32284 10.5164 5.43145 10.4567 5.52Z"
                                    fill="#b9b9c3"
                                />
                            </svg>
                        }
                    >
                        <Menu.Item
                            className={
                                location.pathname === "/BulkFilesView"
                                    ? "activeItem"
                                    : null
                            }
                            key="/BulkFilesView"
                        >
                            <Link
                                activeClassName="active"
                                to={"/BulkFilesView"}
                            >
                                {t("BulkFilesView")}
                            </Link>
                        </Menu.Item>

                        <Menu.Item
                            className={
                                location.pathname === "/verify_data"
                                    ? "activeItem"
                                    : null
                            }
                            key="/verify_data"
                        >
                            <Link activeClassName="active" to={"/verify_data"}>
                                {t("TransactionsView")}
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                </CheckAuth>
            </Menu>

            {/* </CheckAuth> */}
        </Sider>
    );
}
