import { LOGIN, LOGOUT, REGISTER } from "../actions/actionTypes";
const initialState = {
  isAuth: false,
  user: {},
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuth: true,
        user: action.payload,
      };
    case REGISTER:
      return {
        ...state,
      };
    case LOGOUT:
      return { ...state, isAuth: false, user: {} };

    default:
      return state;
  }
}
