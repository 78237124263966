import { Row, Col, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";
import CheckAuth from "./HOC/CheckAuth";
import { ReloadOutlined } from "@ant-design/icons";

export default function ChequesFilter({
  setBranch,
  setChequeType,
  branches,
  search,
  sendRequest,
  title,
  getData,
  chequeType,
  cheque,
  searchText,
  selectedBranch,
  current,
  personType,
  setSearchText,
  changeType,
  setCurrent
}) {
  const { t } = useTranslation();
  const onChange = (e, setFunction) => {
    setFunction(e);
    setCurrent(1);
  };

  return (
    <div className="cheques-filter">
      <Row gutter={16} className="flex flex-align-center">
        <Col span={8}>
          <p className="subTitle my-0">{title}</p>
        </Col>
        <Col span={7}>
          <Input
            value={searchText}
            onChange={search}
            onKeyDown={(e) => sendRequest(e)}
            className="inputWidth search-input"
            placeholder={t("searchPlaceholder")}
          />
          <button
            onClick={() => {
              getData(personType, 10, searchText, current, selectedBranch);
            }}
            className="noRadiusBtn"
          >
            {t("search")}
          </button>
        </Col>

        <CheckAuth
          Permissions={["account_manager"]}
          userType={["entity", "bank"]}
        >
          <Col span={3}>
            <Select
              placeholder={t("form.selectedBranch")}
              className="w-100"
              onChange={(e) => onChange(e, setBranch)}
              value={selectedBranch}
            >
              {branches.map((branch) => {
                return (
                  <Select.Option value={branch.id}>{branch.name}</Select.Option>
                );
              })}
            </Select>
          </Col>
        </CheckAuth>
        {cheque ? (
          <Col span={3}>
            <Select
              placeholder={t("form.chequeType")}
              className="w-100"
              onChange={(e) => onChange(e, setChequeType)}
              value={chequeType}
            >
              <Select.Option hidden value="">
                {t("form.chequeType")}
              </Select.Option>
              <Select.Option name="Normal Cheque" value="normal cheque">
                {t("normalCheque")}
              </Select.Option>
              <Select.Option name="Corporate Cheque" value="corporate cheque">
                {t("corporateCheque")}
              </Select.Option>
              <Select.Option
                name="Pre-approved Cheque"
                value="certified cheque"
              >
                {t("certifiedCheque")}
              </Select.Option>
            </Select>
          </Col>
        ) : null}

        <Col span={3}>
          <ReloadOutlined
            onClick={() => {
              getData("egyptian", 10, "", 1);
              setBranch("");
              setChequeType("");
              setSearchText("");
              changeType("egyptian");
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
