import React, { useEffect } from "react";
import "../../style-sheets/dashboard.css";
import { useTranslation } from "react-i18next";
import { Table,Space } from "antd";
import { Link } from "react-router-dom";

export default function RecentTransactions({ data }) {
  const { t } = useTranslation();

  let lang = localStorage.getItem("i18nextLng");

  const columns = [
    {
      title: t("form.date"),
      dataIndex: "create_date",
      render: (row) =>
        row?.split("T")[0] + " / " + row?.split("T")[1].split(".")[0]
    },
    {
      title: t("form.transID"),
      dataIndex: "id"
    },
    {
      title: t("status"),
      dataIndex: "state",
      render: (row) =>
        row === "3" ? (
          <span className="pending">{t("pending")}</span>
        ) : row === "2" ? (
          <span className="rejected">{t("reject")}</span>
        ) : (
          <span className="accepted">{t("accept")}</span>
        )
    },
    {
      key: "actions",
      title: t("action"),
      fixed: lang === "en" ? "right" : "left",
      render: (row) =>
          <Space size="middle">
            <Link to={`/${row.state==="2"?"reject_cheque_details":"check_cheque"}/${row.id}`} style={{ color: "#1A5785" }}>
              {t("view")}
            </Link>
          </Space>
    }
  ];
  return (
    <div className="recent p-3">
      <h5 className="">{t("recentTrans")}</h5>
      <Table pagination={false} dataSource={data} columns={columns} />
    </div>
  );
}
