import React, { useState } from "react";
import axiosInstance, { BASE_URL } from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Upload, Button, message } from "antd";
function CustomizeReport() {
  const { t } = useTranslation();

  const [uploaded, setUploaded] = useState(false);
  const [data, setData] = useState("");

  const props = {
    filename: "Template",
    multiple: false,
    action: `${BASE_URL}`,
    beforeUpload(file, fileList) {
      console.log(file, fileList);
      const name = file.name;
      if (!name.includes("docx")) {
        message.error("File has to be a docx file!");
      }
    },
    customRequest({ action, file, filename, onSuccess }) {
      const formData = new FormData();
      formData.append(filename, file);
      axiosInstance
        .post(action, formData)
        .then(({ data: response }) => {
          message.success(`file uploaded successfully`);
          onSuccess(response, file);
          console.log(response);
          setData(response);
          setUploaded(!uploaded);
        })
        .catch(() => {
          message.error(`file failed to upload`);
        });

      return {
        abort() {
          console.log("upload progress is aborted.");
        }
      };
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    }
  };
  return (
    <div>
      <p className="title mb-4">{t("customizeReportTitle")}</p>

      <div className="formWrapper3">
        <p>
          Download a template, Customize your data, then upload it here for
          processing.
        </p>
        <DownloadOutlined />
        <a target="_blank" href={`${BASE_URL}`}>
          {" "}
          {t("downloadTemplate")}
        </a>
      </div>
      <div className="mt-4 formWrapper3">
        <p className="subTitle">{t("customizeReportSubTitle")}</p>
        <p className="subHeader">{t("customizeReportText")}</p>
        <p>
          <span style={{ color: "red" }}>*</span> {t("upload")} :{" "}
        </p>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>{t("clickUpload")}</Button>
        </Upload>
      </div>
    </div>
  );
}

export default ErrorHandler(CustomizeReport, axiosInstance);
