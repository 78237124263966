import React, { useEffect, useState, useCallback } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Input, Spin, Table, Space, Pagination, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import CheckAuth from "../../HOC/CheckAuth";
import RejectionModal from "../../layout/modals/RejectionModal";
import { useNavigate } from "react-router-dom";

function AllDisputes() {
    let lang = localStorage.getItem("i18nextLng");
    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [showReject, setShowRejectModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [id, setID] = useState("");
    const navigate = useNavigate();
    let columns = [
        {
            title: "Date Created",
            dataIndex: "create_date",
            render: (row) => row?.split("T")[0],
        },
        {
            title: "Dispute ID",
            dataIndex: "id",
        },
        {
            title: "Dispute Type",
            dataIndex: "type",
            render: (row) =>
                row === "1"
                    ? "Name Dispute"
                    : row == "2"
                    ? "Invalid Cheque"
                    : row == "3"
                    ? "Subject (Rejection reason)"
                    : null,
        },
        {
            title: "Cheque No.",
            dataIndex: "cheque",
            render: (row) => row?.cheque_number,
        },
        {
            title: "Actions",
            render: (_text, record) => (
                <Space size="middle">
                    <CheckAuth
                        Permissions={[
                            "dispute_checker",
                            "dispute_monitor",
                            "dispute_maker",
                        ]}
                        userType={["entity", "bank", "iscore"]}
                    >
                        <a
                            style={{ color: "#1A5785" }}
                            onClick={() => {
                                // handleDisput(3, record.id)
                                navigate(`/view_dispute_details/${record.id}`);
                            }}
                        >
                            {t("view")}
                        </a>
                    </CheckAuth>

                    {/* <CheckAuth
            Permissions={["dispute_checker", "dispute_maker"]}
            userType={["entity", "bank"]}
          >
            <a
              style={{ color: "#52C41A" }}
              onClick={() => handleDisput(1, record.id)}
            >
              Accept
            </a>{" "}
            <a
              style={{ color: "#FF4D4F" }}
              onClick={() => handleDisput(2, record.id)}
            >
              Reject
            </a>
          </CheckAuth> */}
                </Space>
            ),
        },
    ];

    let columnsAr = [
        {
            title: "تاريخ الانشاء",
            dataIndex: "create_date",
            render: (row) => row?.split("T")[0],
        },
        {
            title: "رقم النزاع",
            dataIndex: "id",
        },
        {
            title: "نوع النزاع",
            dataIndex: "type",
            render: (row) =>
                row === "1"
                    ? "Name Dispute"
                    : row == "2"
                    ? "Invalid Cheque"
                    : row == "3"
                    ? "Subject (Rejection reason)"
                    : null,
        },
        {
            title: "رقم الشيك",
            dataIndex: "cheque",
            render: (row) => row?.cheque_number,
        },
        // {
        //   title: "ملاحظات",
        //   dataIndex: "notes"
        // },
        {
            title: "إجراءات",
            render: (_text, record) => (
                <Space size="middle">
                    <CheckAuth
                        Permissions={[
                            "dispute_checker",
                            "dispute_monitor",
                            "dispute_maker",
                        ]}
                        userType={["entity", "bank", "iscore"]}
                    >
                        <a
                            onClick={() => {
                                // handleDisput(3, record.id)
                                navigate(`/view_dispute_details/${record.id}`);
                            }}
                            style={{ color: "#1A5785" }}
                        >
                            {t("view")}
                        </a>
                    </CheckAuth>
                    {/* <CheckAuth
            Permissions={["dispute_checker,dispute_maker"]}
            userType={["entity", "bank"]}
          >
            <a
              style={{ color: "#52C41A" }}
              onClick={() => handleDisput(1, record.id)}
            >
              قبول
            </a>{" "}
            <a
              style={{ color: "#FF4D4F" }}
              onClick={() => handleDisput(2, record.id)}
            >
              رفض
            </a>
          </CheckAuth> */}
                </Space>
            ),
        },
    ];

    const getDisputes = async (size, search, page) => {
        if (search.length === 0) setLoading(true);
        setCurrent(page);
        console.log(size, search, page);
        let result = await axiosInstance
            .get(
                `/FilterDispute/?page_size=${size}&search=${search}&page=${page}`
            )
            .catch((err) => console.log(err));
        if (result) {
            setLoading(false);

            // console.log(result.data.data)
            setData(result.data.data);
            setCount(result.data.count);
        }
    };

    const search = (e) => {
        setSearchText(e.target.value);
        setCurrent(1);
    };

    const sendRequest = useCallback(
        async (e) => {
            if (e.key == "Enter") {
                getDisputes(pageSize, searchText, current);
            }
        },
        [searchText]
    );

    useEffect(() => {
        try {
            console.log("hereee", pageSize, current);
            getDisputes(pageSize, searchText, current);
        } catch (error) {
            console.log(error);
        }
    }, [current, pageSize, searchText]);

    const handleDisput = async (type, id) => {
        if (type === 2) {
            setShowRejectModal(true);
            setID(id);
        } else {
            let result = await axiosInstance.put(`/Dispute/${id}`, {
                state_1: type === 1 ? "1" : type === 2 ? "2" : "3",
            });
            if (result) {
                console.log(result);
                getDisputes(pageSize, searchText, current);
            }
        }
        console.log(type, id);
    };
    const getAll = () => {
        getDisputes(pageSize, searchText, current);
    };
    return (
        <>
            <CheckAuth
                Permissions={[
                    "dispute_monitor",
                    "dispute_checker",
                    "dispute_maker",
                    "dispute_starter",
                ]}
                userType={["entity", "bank", "iscore"]}
            >
                <Container>
                    <Row>
                        <Col sm="6">
                            <p className="title no-margin">
                                {t("disputeReport")}
                            </p>
                        </Col>
                        <CheckAuth
                            Permissions={["dispute_starter"]}
                            userType={["entity", "bank"]}
                        >
                            <Col className="text-end" sm="6">
                                <Button className="formBtn">
                                    <Link to="/create_dispute">
                                        {" "}
                                        {t("createDispute")}
                                    </Link>
                                </Button>
                            </Col>
                        </CheckAuth>
                    </Row>
                    <div className="p-4 mt-4" style={{ background: "#fff" }}>
                        <Row className="mb-3">
                            <Col sm={6}>
                                {/* <p>Dispute Pool Table</p> */}
                                {t("disputePoolTable")}
                            </Col>
                            <Col className="text-end" sm={6}>
                                {" "}
                                <Input.Search
                                    onChange={search}
                                    onKeyPress={sendRequest}
                                    className="inputWidth"
                                    placeholder={t("searchPlaceholder")}
                                />
                                {/* <Button className="mx-3">View Log</Button> */}
                            </Col>
                        </Row>
                        <Row>
                            {loading ? (
                                <Spin tip="...loading" />
                            ) : (
                                <>
                                    <Table
                                        pagination={false}
                                        dataSource={data}
                                        columns={
                                            lang === "ar" ? columnsAr : columns
                                        }
                                        rowKey={(row) => row.id}
                                        expandable={{
                                            expandedRowKeys: expandedRowKeys,
                                            onExpand: (expanded, record) => {
                                                const keys = [];
                                                if (expanded) {
                                                    keys.push(record.id);
                                                }
                                                setExpandedRowKeys(keys);
                                            },
                                            expandedRowRender: (record) => {
                                                return (
                                                    <span className="mx-3">
                                                        {t("notes")} :{" "}
                                                        {record.notes}
                                                    </span>
                                                );
                                            },
                                        }}
                                    />
                                    <Pagination
                                        onChange={(e) => setCurrent(e)}
                                        total={count}
                                        defaultCurrent={current}
                                        current={current}
                                    />
                                </>
                            )}
                        </Row>
                    </div>
                </Container>
                <RejectionModal
                    show={showReject}
                    getAll={getAll}
                    id={id}
                    hide={() => setShowRejectModal(false)}
                />
            </CheckAuth>
        </>
    );
}

export default ErrorHandler(AllDisputes, axiosInstance);
