import React, { useEffect } from "react";
import { Form, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { ReloadOutlined, RobotOutlined } from "@ant-design/icons";
import { BASE_URL } from "../api/axios";

export default function Captcha({

  loading,
  image,
  generate
}) {
  const { t } = useTranslation();



  useEffect(() => {
    try {
      generate();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <div className="captcha-icon mb-2">
        {loading ? <Spin /> : <img src={BASE_URL + image} />}
        <ReloadOutlined onClick={() => generate()} />
      </div>
      <Form.Item
        name="captcha_response"
        rules={[{ required: true, message: `${t("captchaError")}` }]}
        layout="vertical"
      >
        <Input
          prefix={<RobotOutlined className="site-form-item-icon" />}
          className="loginInput"
          type="text"
          placeholder="Captcha"
        />
      </Form.Item>
    </>
  );
}
