import React, { useState } from "react";
import { Layout } from "antd";
import footerImg from "../../images/footer.svg";
import { useTranslation } from "react-i18next";
import ReleaseModal from "./modals/ReleaseModal";
const { Footer } = Layout;

export default function FooterComp() {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const [show, setShow] = useState(false);
  return (
    <>
      <Footer onClick={() => setShow(true)} dir={lang === "en" ? "ltr" : "rtl"}>
        <img src={footerImg} alt="Footer image" />
        <p className="footer-text">{t("footerText")}</p>
      </Footer>
      <ReleaseModal
        show={show}
        hide={() => setShow(false)}
        text={t("release")}
        subTxt="Front-end release 4.3.1"
      />
    </>
  );
}
