import React from "react";
import { Input, Form, Modal, Button } from "antd";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../api/axios";

export default function RejectionModal({
  show,
  hide,
  id,
  getAll,
  cheque,
  userChangeState,
  endPoint
}) {
  const { t } = useTranslation();
  const onFinish = async (values) => {
    let result = await axiosInstance.put(
      `/${cheque ? "Cheque" : userChangeState ? endPoint : "Dispute"}/${id}`,
      cheque
        ? {
            state: "2"
          }
        : userChangeState
        ? {
            user_state: "3",
            reject_reason: values.reason
          }
        : {
            state_1: 2,
            notes: values.reason
          }
    );
    if (result) {
      hide();
      getAll();
    }
  };
  return (
    <Modal
      title={`${t("rejectionReason")}`}
      footer={false}
      visible={show}
      onCancel={hide}
      destroyOnClose
    >
      <Container className="p-4 entityModal">
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item name="reason">
            <Input.TextArea placeholder="type here....." />
          </Form.Item>

          <div className="d-flex justify-content-end">
            <Button className="formBtn" htmlType="submit">
              {t("submit")}
            </Button>
          </div>
        </Form>
      </Container>
    </Modal>
  );
}
