import { Form, Input, Select } from "antd";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function CurrencyForm({
  data,
  formId,
  func,
  currencies,
  edit,
  getSingleData,
  loading,
  rate,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log("form", data);
  }, [data]);

  const onFinish = (formData) => {
    console.log("Success:", formData);
    func(formData);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Col sm={12} md={6} lg={3} className="py-3">
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        id={formId}
        initialValues={{
          currencyCode: data ? data.name : "empty",
          currencyNameEn: data ? data.name_expanded : "empty",
          currencyNameAr: data ? data.name_expanded_ar : "empty",

          currencyExchangeRate: rate ? rate : "",
        }}

        // initialValues ={add ?   { username:'default value' } : null}
      >
        <Form.Item label={t("CurrencyNameAr")}>
          <Select
            disabled={edit}
            placeholder={t("CurrencyNameAr")}
            name="currencyNameAr"
            className="w-100"
            onChange={(e) => getSingleData(e)}
            value={data && data.id}
          >
            {currencies &&
              currencies.map((currency) => {
                return (
                  <Select.Option value={currency.id}>
                    {currency.name_expanded_ar}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        {/* <Form.Item
          required
          rules={[
            {
              required: true,
              message: t("form.requiredCurrencyNameAr"),
            },
            {
              pattern: `^[\u0621-\u064A\\s]+$`,
              message: t("form.arabicCharactersOnly"),
            },
          ]}
        >
          <Input />
        </Form.Item> */}

        <Form.Item label={t("CurrencyNameEn")} name="currencyNameEn">
          <Input disabled />
        </Form.Item>

        <Form.Item label={t("CurrencyCode")} name="currencyCode">
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t("currencyExchangeRate")}
          name="currencyExchangeRate"
          required
          rules={[
            {
              required: true,
              message: t("form.requiredCurrencyExchangeRate"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Col>
  );
}
