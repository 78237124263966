import React, { useState, useCallback } from "react";
import { Button, Row, Col, Form, Select, Input,Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import SuccessMsg from "../layout/SuccessMsg";
import CheckAuth from "../HOC/CheckAuth";
import CreateEntityTypeModal from "../layout/modals/CreateEntityTypeModal";
import { ToastContainer, toast } from "react-toastify";

function BranchesConfiguration() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState("");
  const [nextEntities, setNextEntities] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [show, setShow] = useState(false);

  const onFinish = async (values) => {
    values.entity = selectedEntity;
    console.log(values);
    let result = await axiosInstance.post(`/EntityBranch/`, values).catch((err) => {
      // console.log(err)
      console.log(err.response);
      if (err.response && err.response.status !== 500) {
        for (const error in err.response.data) {
          console.log(err.response.data[error][0]);
          setTimeout(() => {
            toast.error(
              `${
                error 
                +
                " : " +
                err.response.data[error][0]
              }`
            );
          }, 500);
        }
      }
    });
    console.log(result);
    if(result){

      setShowSuccess(true);
      form.resetFields()
    }
  };

  const getEntities = async () => {
    let result = await axiosInstance.get(`/Entity/`);
    console.log(result);
    setEntities(result.data.data);
  };

  const handleChange = (e, v) => {
    console.log(e);
    console.log(v);
    setSelectedEntity(e);
  };

  const onScroll = useCallback(
    async (event) => {
      var target = event.target;
      console.log(target.scrollTop);
      console.log(target.offsetHeight);
      console.log(target.scrollHeight);

      let result;
      if (
        loadMore &&
        Math.round(target.scrollTop) + target.offsetHeight ===
          target.scrollHeight
      ) {
        result = await axiosInstance
          .get(`${nextEntities}`)
          .catch((err) => console.log(err));

        if (result) {
          setEntities([...entities, ...result.data.data]);
          setNextEntities(result.data.next);
          if (result.data.next === null) {
            setLoadMore(false);
          }
        }
      }
    },
    [entities, loadMore, nextEntities]
  );
  const addNewEntity = () => {
    setShow(true);
  };
  const hide = () => {
    setShow(false);
  };
  return (
    <>
      <CheckAuth Permissions={["account_manager","param_editor"]} userType={["iscore"]}>
        <div className="my-5">
          <p className="title mb-4">{t("branchesConfiguration")}</p>
          <div className="formWrapper">
            <Row>
              <Col span={12}>
                <p className="subTitle mb-4">{t("createBranchesConfig")}</p>
              </Col>
            </Row>
            <Form onFinish={onFinish} form={form} layout="vertical">
              <Form.Item
                label={t("form.entityName")}
                name="entity"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredEntity"),
                  },
                ]}
              >
                {/* <CustomSelect
                getData={getEntities}
                data={entities}
                setData={setEntities}
                placeholder={t("form.entityName")}
                selected={selectedEntity}
                setSelected={setSelectedEntity}
                next={nextEntities}
                setNext={setNextEntities}
              /> */}
                <Select
                  onChange={handleChange}
                  value={selectedEntity}
                  onClick={getEntities}
                  onPopupScroll={onScroll}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Space align="center" style={{ padding: "0 8px 4px" }}>
                        <Typography.Link onClick={addNewEntity}>
                          + Add new entity
                        </Typography.Link>
                      </Space>
                    </>
                  )}
                >
                  {entities.map((entity) => {
                    return (
                      <Select.Option key={entity.id} value={entity.id}>
                        {entity.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("form.branchNameEnglish")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                  {
                    pattern: `^[a-zA-Z ]+$`,
                    message: `${t("form.wrongFormat")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("form.branchNameArabic")}
                name="name_ar"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                  {
                    pattern: `^[\u0621-\u064A ]+$`,
                    message: `${t("form.wrongFormat")}`,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("form.branchCode")}
                name="branch_code"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredField"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
              name="creation_stamp"
              label={t("form.creationStamp")}
              rules={[
                {
                  required: true,
                  message: t("form.requiredField"),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY/MM/DD"
                value={creationDate}
                onChange={(e, v) => handleDate(e, v, setCreationDate)}
              />
            </Form.Item>
            <Form.Item
              name="modification_stamp"
              label={t("form.modificationStamp")}
              rules={[
                {
                  required: true,
                  message: t("form.requiredField"),
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="YYYY/MM/DD"
                value={modificationDate}
                onChange={(e, v) => handleDate(e, v, setModificationDate)}
              />
            </Form.Item> */}

              <Row className="text-end">
                {" "}
                <Button
                  color="primary"
                  htmlType="submit"
                  style={{ marginBottom: "1rem" }}
                  className="formBtn mt-3"
                >
                  {t("form.createBranch")}
                </Button>
              </Row>
            </Form>
          </div>
          <SuccessMsg
            show={showSuccess}
            hide={() => setShowSuccess(false)}
            text={t("form.branchCreated")}
            btnTxt={t("form.done")}
            subTxt={t("")}
          />
        </div>
        <CreateEntityTypeModal isName={true} show={show} hide={hide}/>
      </CheckAuth>
      <ToastContainer position="bottom-right" />

    </>
  );
}

export default ErrorHandler(BranchesConfiguration, axiosInstance);
