import React, {useEffect, useState} from "react";
import {Button, Row, Col, Form, Select, Input} from "antd";
import {useTranslation} from "react-i18next";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import SuccessMsg from "../layout/SuccessMsg";
import {useParams, useNavigate} from "react-router-dom";
import CreateEntityTypeModal from "../layout/modals/CreateEntityTypeModal";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckAuth from "../HOC/CheckAuth";
import {useSelector} from "react-redux";

function UserCreationEntity() {
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const {t} = useTranslation();
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedType, setSelectedType] = useState("");
    const [selectedEntity, setSelectedEntity] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [types, setTypes] = useState([]);
    const [entities, setEntities] = useState([]);
    const [branches, setBranches] = useState([]);
    const [show, setShow] = useState(false);

    const onFinish = async (values) => {
        console.log({values})
        let result;
        try {
            if (
                (selectedType !== "bank" && user.user_type !== "bank") ||
                user.user_type === "Entity" ||
                selectedType === "Entity"
            ) {
                result = await axiosInstance.post("/EntityUser/", values);
            } else if (selectedType == "bank" || user.user_type === "bank") {
                result = await axiosInstance.post("/BankUser/", values);
            }
        } catch (err) {
            if (err.response && err.response.status !== 500) {
                for (const error in err.response.data) {
                    setTimeout(() => {
                        toast.error(
                            `${
                                error +
                                " : " +
                                err.response.data[error][0].charAt(0).toUpperCase() +
                                err.response.data[error][0].slice(1)
                            }`
                        );
                    }, 500);
                }
            }
        }

        if (result) {
            setShowSuccess(true);

            setTimeout(
                () =>
                    selectedRole.includes("jAuV") && selectedType === "bank"
                        ? navigate(`/add_escalation/${result.data.user.id}`) : navigate("/users")
                ,

                // if (
                //   result.data.permissions.filter(
                //     (permission) => permission.key === "account_manager"
                //   ).length === 1
                // ) {

                //   navigate(`/add_escalation/${result.data.user.id}`);
                // }
                3000
            );

            form.resetFields();
        }
    };

    const handleSelect = (set, e) => {
        set(e);
        console.log(e);
    };

    const getRoles = async () => {
        let result;
        if (
            (selectedType !== "bank" && user.user_type !== "bank") ||
            user.user_type === "Entity"
        ) {
            result = await axiosInstance.get(`/EntityPermission/?page_size=500`);
        } else if (selectedType == "bank" || user.user_type === "bank") {
            result = await axiosInstance.get(`/BankPermission/?page_size=500`);
        } else {
        }
        if (
            user.user_type === "iscore" &&
            user.permissions.includes("user_create_maker")
        ) {
            let filter = result.data.data.filter(
                (data) =>
                    data.name === "Account Manager" ||
                    data.name === "User Creator Checker" ||
                    data.name === "User Creator Maker"
            );
            console.log(filter);
            setRoles(filter);
        } else {
            setRoles(result.data.data);
        }
    };
    const getTypes = async () => {
        let result = await axiosInstance.get(`/EntityType/?page_size=500`);
        console.log(result);
        setTypes(result.data.data);
    };
    const getEntityName = async () => {
        let result;
        if (selectedType == "bank") {
            result = await axiosInstance.get(`/Bank/?page_size=500`);
        } else {
            result = await axiosInstance.get(
                `/Entity/?type=${selectedType}&page_size=800`
            );
        }
        console.log(result);
        setEntities(result.data.data);
    };
    const getBranches = async () => {
        let result;
        if (selectedType == "bank") {
            result = await axiosInstance.get(
                `/BankBranch/?bank=${selectedEntity}&page_size=500`
            );
        } else {
            if (user.user_type == "entity") {
                result = await axiosInstance.get(
                    `/EntityBranch/?entity=${user.user_entity.id}&page_size=500`
                );
            } else if (selectedType === "" && user.user_type === "bank") {
                result = await axiosInstance.get(
                    `/BankBranch/?bank=${user.user_entity.id}&page_size=500`
                );
            } else {
                result = await axiosInstance.get(
                    `/EntityBranch/?entity=${selectedEntity}&page_size=500`
                );
            }
        }

        console.log(result);
        setBranches(result.data.data);
    };

    const hide = () => {
        setShow(false);
    };
    return (
        <>
            <CheckAuth
                Permissions={["account_manager", "user_create_maker"]}
                userType={["iscore", "entity", "bank"]}
            >
                <div className="my-5">
                    <p className="title mb-4"> {t("createEntityUsers")}</p>
                    <div className="formWrapper">
                        <Row>
                            <Col span={12}>
                                <p className="subTitle mb-4">{t("createNewUser")}</p>
                            </Col>
                        </Row>
                        <Form onFinish={onFinish} form={form} layout="vertical">
                            <CheckAuth
                                Permissions={["account_manager", "user_create_maker"]}
                                userType={["iscore"]}
                            >
                                <Form.Item
                                    name="type"
                                    label={t("form.entityType")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("form.requiredtype"),
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={(e) => handleSelect(setSelectedType, e)}
                                        value={selectedType}
                                        onClick={getTypes}
                                    >
                                        <Select.Option key={"bank"} value={"bank"}>
                                            Bank
                                        </Select.Option>
                                        {types.map((type) => {
                                            return (
                                                <>
                                                    <Select.Option key={type.id} value={type.id}>
                                                        {type.name} / {type.name_ar}
                                                    </Select.Option>
                                                </>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="entity"
                                    label={t("form.entityName")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("form.requiredEntityName"),
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={(e) => handleSelect(setSelectedEntity, e)}
                                        value={selectedEntity}
                                        onClick={getEntityName}
                                    >
                                        {entities.map((role) => {
                                            return (
                                                <Select.Option key={role.id} value={role.id}>
                                                    {role.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </CheckAuth>

                            <Form.Item
                                name="branch"
                                label={t("form.branch")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("form.requiredBranch"),
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(e) => handleSelect(setSelectedBranch, e)}
                                    value={selectedBranch}
                                    onClick={getBranches}
                                >
                                    {branches.map((branch) => {
                                        return (
                                            <Select.Option key={branch.id} value={branch.id}>
                                                {branch.name}/ {branch.name_ar}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={t("form.name")}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: t("form.requiredName"),
                                    },
                                    {
                                        min: 3,
                                        message: t("form.minLength"),
                                    },
                                    {
                                        max: 255,
                                        message: t("form.maxLength"),
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t("form.userName")}
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: t("form.requiredName"),
                                    },
                                    {
                                        min: 3,
                                        message: t("form.minLength"),
                                    },
                                    {
                                        max: 255,
                                        message: t("form.maxLength"),
                                    },
                                    {
                                        pattern: `^[a-zA-Z]{3,}$`,
                                        message: `${t("form.wrongFormat")}`,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label={t("form.email")}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: t("form.requiredEmail"),
                                    },
                                    {
                                        type: "email",
                                        message: `${t("form.wrongFormat")}`,
                                    },
                                ]}
                            >
                                <Input type="email"/>
                            </Form.Item>
                            <Form.Item
                                label={t("form.mobileNumber")}
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: t("form.requiredPhone"),
                                    },
                                    {
                                        min: 11,
                                        message: t("lengthPhone"),
                                    },
                                    {
                                        max: 11,
                                        message: t("lengthPhone"),
                                    },
                                ]}
                                type="number"
                            >
                                <Input
                                    type="number"
                                    onKeyDown={(e) =>
                                        ["e", ".", "E", "+", "-"].includes(e.key) &&
                                        e.preventDefault()
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                name="permissions"
                                label={t("form.userRole")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("form.requiredUserRole"),
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    onChange={(e) => handleSelect(setSelectedRole, e)}
                                    value={selectedRole}
                                    onClick={getRoles}
                                >
                                    {roles.map((role) => {
                                        return (
                                            <Select.Option key={role.id} value={role.id}>
                                                {role.name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Row className="text-end">
                                {" "}
                                <Button
                                    color="primary"
                                    htmlType="submit"
                                    style={{marginBottom: "1rem"}}
                                    className="formBtn mt-3"
                                >
                                    {t("form.save")}
                                </Button>
                            </Row>
                        </Form>
                    </div>
                    <SuccessMsg
                        show={showSuccess}
                        hide={() => setShowSuccess(false)}
                        text={t("form.successfulRegister")}
                        btnTxt={t("form.sendInvetation")}
                        subTxt={t("")}
                    />
                    <CreateEntityTypeModal show={show} hide={hide}/>
                </div>
                <ToastContainer position="bottom-right"/>
            </CheckAuth>
        </>
    );
}

export default ErrorHandler(UserCreationEntity, axiosInstance);
