import { LOGOUT, LOGIN, REGISTER } from "./actionTypes";
import axios from "axios";
import { BASE_URL } from "../../api/axios";
import Cookies from "js-cookie";
export const userLogin = (userdata) => async (dispatch) => {
  return await new Promise((resolve) => {
    axios
      .post(BASE_URL + "/auth/jwt/create", userdata)
      .then((res) => {
        resolve(res.data);
        if (res.data) {
          const accessToken = res.data.access;
          const refreshToken = res.data.refresh;
          delete res.data.access;
          localStorage.setItem("refreshToken", refreshToken);
          Cookies.set("accessToken", accessToken);
          dispatch({ type: LOGIN, payload: res.data });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          console.log(err.response.data.detail);
          resolve(err.response);
        }
      });
  });
};

export const userRegister = (userdata) => async (dispatch) => {
  const regiserdata = await new Promise((resolve) => {
    axios
      .post(BASE_URL + "/student_signup", userdata)
      .then((res) => {
        resolve(res.data);
        if (res.data) {
          dispatch({ type: REGISTER, payload: res.data });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
          resolve(err.response.data);
        }
      });
  });
  return regiserdata;
};

export const LogOut = () => async (dispatch) => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  Cookies.remove("accessToken");
  dispatch({ type: LOGOUT });
};
