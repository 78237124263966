import React, { useEffect, useState } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { useTranslation } from "react-i18next";
import {
    Form,
    Row,
    Button,
    Input,
    Select,
    DatePicker,
    Spin,
    message,
} from "antd";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import SuccessMsg from "../layout/SuccessMsg";
import { useSelector } from "react-redux";

function Profile({ checker, isbank, isentity }) {
    const userType = useSelector((state) => state.auth.user.user_type);
    const permission = useSelector((state) => state.auth.user.permissions);
    const { t } = useTranslation();
    let params = useParams();
    let location = useLocation();

    const [date, setDate] = useState("");
    const [details, setDetails] = useState("");
    const [show, setShow] = useState(false);
    const [showForgetModal, setShowForget] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [banks, setBanks] = useState([]);
    const [branches, setBranches] = useState([]);
    const [initaialBank, setInitailBank] = useState("");

    const [form] = Form.useForm();

    const handleOnChange = (_date, dateString, setFunction) => {
        console.log(_date);
        setFunction(dateString);
    };

    const onFinish = async (values) => {
        let link = isentity
            ? "EntityUserEdit"
            : isbank
            ? "BankUserEdit"
            : "User";

        delete values.date;
        // delete values.privileges;
        console.log({ ...values, user_state: "1" });

        let result = await axiosInstance
            .put(`/${link}/${params.id}`, { ...values, user_state: "1" })
            .catch((err) => console.log(err));
        if (result) {
            setShow(true);
        }
    };

    useEffect(() => {
        try {
            getBanks();
            getDetails();
            getBranches();
        } catch (err) {
            console.log(err);
        }
    }, []);

    // useEffect(() => {
    //   getRoles();

    // }, [])

    const getDetails = async () => {
        try {
            let result = await axiosInstance.get(
                location?.pathname.trim() === `/personal_profile`
                    ? "/User/"
                    : userType === "iscore" &&
                      location?.pathname.trim() !== `/personal_profile` &&
                      !checker
                    ? `/ListIscoreUser/${params.id}`
                    : (userType === "entity" &&
                          location?.pathname.trim() !== `/personal_profile`) ||
                      isentity
                    ? `/ListEntityUser/${params.id}`
                    : (userType === "bank" &&
                          location?.pathname.trim() !== `/personal_profile`) ||
                      isbank
                    ? `/ListBankUser/${params.id}`
                    : null
            );

            console.log("getDetails", result.data);
            if (
                permission.includes("user_create_maker") &&
                result.data?.user_state == "3"
            ) {
                getRoles();
                setIsEditable(true);
            }
            setDetails(result.data);
            form.setFieldsValue({
                email:
                    location.pathname === "/personal_profile"
                        ? result.data?.email
                        : result.data.user?.email,
                permissions:
                    location.pathname === "/personal_profile"
                        ? result.data?.user_permissions
                        : result.data?.permissions,
                phone:
                    location.pathname === "/personal_profile"
                        ? result.data?.phone
                        : result.data.user?.phone,
                entity: isentity
                    ? result.data?.entity?.id
                    : result.data?.branch?.bank?.id,
                branch: result.data?.branch?.id,
            });
            setInitailBank(
                isentity
                    ? result.data?.entity?.id
                    : result.data?.branch?.bank?.id
            );
        } catch (err) {
            console.log(err);
        }

        // `/ListIscoreUser/${params.id}`
    };

    const getRoles = async () => {
        let result = await axiosInstance.get(
            `/${
                isentity ? "EntityPermission" : "BankPermission"
            }/?page_size=500`
        );
        if (result) {
            setAllRoles(result.data.data);
        }
    };

    const getBanks = async () => {
        let result = await axiosInstance.get(
            `/${isentity ? "Entity" : "Bank"}/?page_size=500`
        );
        console.log(result);
        setBanks(result.data.data);
    };
    const getBranches = async () => {
        let result = await axiosInstance.get(
            `/${
                isentity ? "EntityBranch/?entity" : "BankBranch/?bank"
            }=${initaialBank}&page_size=500`
        );
        setBranches(result.data.data);
    };
    const resetPassword = async () => {
        console.log(details);
        let result = await axiosInstance
            .post(`/ForgetPassword/`, {
                email:
                    location.pathname === "/personal_profile"
                        ? details?.email
                        : details.user?.email,
            })
            .catch((err) => {
                console.log(err.response);
                message.error(err?.response?.data);
            });
        if (result) {
            setShowForget(true);
        }
    };
    return (
        <>
            <div className="my-5">
                <p className="title mb-4">{t("form.profile")}</p>
                {details !== "" ? (
                    <>
                        <div className="formWrapper">
                            <Form
                                disabled
                                className="invoice"
                                onFinish={onFinish}
                                layout="vertical"
                                form={form}
                            >
                                <Form.Item
                                    initialValue={
                                        location.pathname ===
                                        "/personal_profile"
                                            ? details?.name
                                            : details?.user?.name
                                    }
                                    name="name"
                                    label={t("form.name")}
                                >
                                    <Input disabled={!isEditable} type="text" />
                                </Form.Item>
                                <Form.Item
                                    // initialValue={
                                    //   location.pathname === "/personal_profile"
                                    //     ? details?.email
                                    //     : details?.user?.email
                                    // }
                                    name="email"
                                    label={t("form.email")}
                                >
                                    <Input
                                        disabled={!isEditable}
                                        type="email"
                                    />
                                </Form.Item>
                                {userType !== "iscore" && (
                                    <>
                                        <Form.Item
                                            name="entity"
                                            label={t("form.bankName")}
                                        >
                                            <Select disabled={!isEditable}>
                                                {banks.map((role) => {
                                                    return (
                                                        <Select.Option
                                                            value={role.id}
                                                        >
                                                            {role.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="branch"
                                            label={t("form.branch")}
                                        >
                                            <Select disabled={!isEditable}>
                                                {branches.map((role) => {
                                                    return (
                                                        <Select.Option
                                                            value={role.id}
                                                        >
                                                            {role.name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </>
                                )}
                                <Form.Item
                                    name="phone"
                                    label={t("form.mobileNumber")}
                                >
                                    <Input
                                        type="number"
                                        disabled={!isEditable}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="permissions"
                                    label={t("form.privilege")}
                                >
                                    <Select
                                        disabled={!isEditable}
                                        mode="multiple"
                                    >
                                        {allRoles.map((role) => {
                                            return (
                                                <Select.Option value={role.id}>
                                                    {role.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label={t("form.date")}
                                    name="date"
                                    rules={[
                                        {
                                            required: false,
                                            message: t("form.requiredDate"),
                                        },
                                    ]}
                                    initialValue={moment(
                                        details?.create_date,
                                        "YYYY/MM/DD"
                                    )}
                                >
                                    <DatePicker
                                        onChange={(e, v) =>
                                            handleOnChange(e, v, setDate)
                                        }
                                        disabled
                                    />
                                </Form.Item>
                                {isEditable ? (
                                    <Button
                                        htmlType="submit"
                                        className="formBtn"
                                    >
                                        {t("form.save")}
                                    </Button>
                                ) : null}
                            </Form>
                            {/* <CheckAuth
                Permissions={["user_create_maker"]}
                userType={["iscore", "entity","bank"]}
              > */}
                            {!checker ? (
                                <Row className="text-end">
                                    <Button
                                        onClick={resetPassword}
                                        className="formBtn"
                                    >
                                        {t("form.forgetPass")}
                                    </Button>
                                </Row>
                            ) : null}

                            {/* </CheckAuth> */}
                        </div>
                    </>
                ) : (
                    <Spin />
                )}
                <SuccessMsg
                    show={show}
                    hide={() => setShow(false)}
                    text={t("successfulRequest")}
                    btnTxt={t("form.done")}
                />
                <SuccessMsg
                    show={showForgetModal}
                    hide={() => setShowForget(false)}
                    text={t("successfulRequest")}
                    btnTxt={t("form.done")}
                />
            </div>
        </>
    );
}

export default ErrorHandler(Profile, axiosInstance);
