import React, { useState } from "react";
import {
  Steps,
  Radio,
  Button,
  Row,
  Form,
  Input,
  message,
  DatePicker,
  Spin,
} from "antd";
import { SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import axiosInstance, { BASE_URL } from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { Viewer, Worker, ProgressBar } from "@react-pdf-viewer/core";
import Cookies from "js-cookie";
import fileDownload from "js-file-download";
import CheckAuth from "../HOC/CheckAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InfoCard from "../layout/modals/InfoCard";
const { RangePicker } = DatePicker;

function InquiryReport({ title, dispute, setStep }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { Step } = Steps;
  const [type, setType] = useState(1);
  const [date, setDate] = useState("");
  const [presentDate, setPresentDate] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingHistorical, setloadingHistorical] = useState(false);
  let navigate = useNavigate();
  const onChange = (e) => {
    setType(e.target.value);
  };

  const onFinish = async (values) => {
    setLoading(true);
    setLink("");
    delete values.date;
    delete values.presented_date;
    values.end_date = date;
    values.present_start = presentDate[0];
    values.present_end = presentDate[1];
    values.is_drawer = title === t("beneficiaryData") ? false : true;
    console.log(values);
    let result = await axiosInstance.post(`/Report/`, values).catch((err) => {
      console.log(err.response);
      setLoading(false);
      if (err.response.status === 500) {
        message.error("Server Error");
      } else {
        message.error(err.response.data);
      }
    });
    if (result) {
      setLoading(false);
      console.log(result);
      handleDownload(`${result.data.file}`, "Report.pdf");
      if (dispute) {
        setStep("3");
      }
      // window.open(`${BASE_URL}${result.data.file}`, "_blank");
    }
  };

  const handleDownload = async (url, filename) => {
    setLoading2(true);
    let result = await axiosInstance.get(url, {
      responseType: "blob",
    });

    if (result) {
      setLoading2(false);
      console.log(result);
      fileDownload(result.data, filename);
    }
  };

  const handleOnChange = (datee, dateString, setFunction) => {
    console.log(datee);
    setFunction(dateString);
  };

  const onFinishFailed = (values) => {
    console.log(values);
  };

  const buildHistory = async () => {
    setloadingHistorical(true);
    let result = await axiosInstance.get(`/BuildHistoricalCheque/`);
    if (result) {
      setloadingHistorical(false);
      console.log(result);
      setTimeout(() => {
        toast.success(`Done`);
      }, 500);
    }
  };
  return (
    <CheckAuth
      Permissions={[
        "report_viewer",
        "dispute_starter",
        "beneficiary_inquiry",
        "drawee_inquiry",
      ]}
      userType={["iscore", "entity", "bank"]}
    >
      <div className="inquiry">
        {dispute ? (
          <div>
            <p className="title pb-2">{t("createNewDispute")}</p>
            <p className="subHeader">{t("disputeSubText")}</p>
            <InfoCard />
          </div>
        ) : null}
        <p className="title">{t("chequeInquiryReport")}</p>
        <Steps size="small" current={0}>
          <Step icon={<FontAwesomeIcon icon={faCircle} />} title={title} />
          <Step
            icon={<FontAwesomeIcon icon={faCircle} />}
            title={t("viewReport")}
          />
        </Steps>
        <p className="subTitle">{t("chooseType")}</p>
        <Radio.Group onChange={onChange} value={type}>
          <Radio value={1}>{t("egyptianPerson")}</Radio>
          <Radio value={2}>{t("foreignPerson")}</Radio>
          <Radio value={3}>{t("entityType")}</Radio>
        </Radio.Group>
        <p className="title mt-5 mb-3">{title}</p>

        <div className="formWrapper">
          <p className="subTitle">
            {type === 3 ? t("form.entityData") : t("form.personalData")}
          </p>
          <p className="subText">
            {type === 1
              ? t("specifyasNationalID")
              : type === 2
              ? t("specifyasPassport")
              : null}
          </p>
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            {type === 3 ? (
              <>
                {" "}
                <Form.Item
                  label={t("form.taxID")}
                  name="tax_id"
                  rules={[
                    {
                      required: true,
                      message: t("form.requiredTaxId"),
                    },
                    {
                      pattern: `^[0-9]{3}-[0-9]{3}-[0-9]{3}`,
                      message: `${t("form.wrongFormat")}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={t("form.taxID")}
                    suffix={<QuestionCircleOutlined />}
                  />
                </Form.Item>
              </>
            ) : type === 2 ? (
              <>
                <Form.Item
                  label={t("form.passportNumber")}
                  name="passport_id"
                  rules={[
                    {
                      required: true,
                      message: t("form.requiredPassport"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("form.passportNumber")}
                    suffix={<SearchOutlined />}
                    onWheel={(e) => e.target.blur()}
                    type="number"
                  />
                </Form.Item>
              </>
            ) : (
              <>
                {" "}
                <Form.Item
                  label={t("form.nationalID")}
                  name="national_id"
                  rules={[
                    {
                      required: true,
                      message: t("form.requiredID"),
                    },
                    {
                      // pattern: `^[23](?:[0-9]{2}(?:(?:(?:0[13578]|1[02])(?:0[1-9]|[12][0-9]|3[01]))|(?:(?:0[469]|11)(?:0[1-9]|[12][0-9]|30))|(?:02(?:0[1-9]|1[0-9]|2[0-8])))|(?:(?:04|08|[2468][048]|[13579][26]|(?<=3)00)0229))(?:0[1-4]|[12][1-9]|3[1-5]|88)[0-9]{4}[1-9]$`,
                      message: `${t("form.wrongID")}`,
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder={t("form.nationalID")}
                    suffix={<SearchOutlined />}
                  />
                </Form.Item>
              </>
            )}
            {dispute ? null : (
              <>
                {" "}
                <Form.Item
                  label={t("form.presentedDate")}
                  name="presented_date"
                  rules={[
                    {
                      required: false,
                      message: t("form.requiredDate"),
                    },
                    // {
                    //   message: `${t("form.wrongFormat")}`
                    // }
                  ]}
                >
                  <RangePicker
                    onChange={(e, v) => handleOnChange(e, v, setPresentDate)}
                    placeholder={[t("startDate"), t("endDate")]}
                  />
                </Form.Item>
                <Form.Item
                  label={t("form.testingDate")}
                  name="date"
                  rules={[
                    {
                      required: false,
                      message: t("form.requiredDate"),
                    },
                    // {
                    //   message: `${t("form.wrongFormat")}`
                    // }
                  ]}
                >
                  <DatePicker
                    placeholder={t("form.selectDate")}
                    onChange={(e, v) => handleOnChange(e, v, setDate)}
                  />
                </Form.Item>
              </>
            )}

            <Row className="text-end">
              {" "}
              {dispute ? (
                <Button className="cancelBtn" onClick={() => setStep("2")}>
                  {t("form.skip")}
                </Button>
              ) : null}
              <Button
                color="primary"
                htmlType="submit"
                style={{ marginBottom: "1rem" }}
                className="formBtn"
              >
                {loading ? <Spin /> : t("downloadReport")}
              </Button>
              {dispute ? null : (
                <Button
                  color="primary"
                  style={{ marginBottom: "1rem" }}
                  className="formBtn mx-1"
                  onClick={buildHistory}
                >
                  {loadingHistorical ? <Spin /> : t(" BuildHistorical")}
                </Button>
              )}
            </Row>
          </Form>
          <Row className="text-end">
            {link !== "" ? (
              <>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                  <Viewer
                    // scrollMode="Horizontal"
                    fileUrl={`${BASE_URL}${link}`}
                    renderLoader={(percentages) => (
                      <div style={{ width: "240px" }}>
                        <ProgressBar progress={Math.round(percentages)} />
                      </div>
                    )}
                    httpHeaders={{
                      Authorization: "Bearer " + Cookies.get("accessToken"),
                    }}
                    withCredentials={true}
                  />
                </Worker>
              </>
            ) : null}
          </Row>
          <Row className="text-end"></Row>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </CheckAuth>
  );
}

export default ErrorHandler(InquiryReport, axiosInstance);
