import React, { useCallback, useEffect, useState } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import ReactQuill, { Quill } from "react-quill";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { Form, Row, Col, Button, Input, Spin, Select } from "antd";
import SuccessMsg from "../layout/SuccessMsg";
import CheckAuth from "../HOC/CheckAuth";
import DOMPurify from "dompurify";
import { message } from "antd";

function EmailConfig() {
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const getText = async () => {
    let result = await axiosInstance.get("/EmailTemplate/");

    if (result) {
      console.log(result.data.data);
      setData(result.data?.data);
    }
  };

  const onChange = (value, setFunction) => {
    setFunction(value);
  };

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  const onFinish = async (values) => {
    const clean = DOMPurify.sanitize(value);

    values.html = clean;
    values.key = selectedType.key;
    values.title = title;
    values.operation_type = "edit";
    values.real_id = selectedType.id;
    console.log(values);

    try {
      let result = await axiosInstance.post(`/EmailTemplateTemp/`, values);
      console.log(result);
      setShowSuccess(true);
    } catch (err) {
      message.error(err.response.data);
      console.log("err", err.response.data, err.response.status);
    }

    // let result = await axiosInstance
    //   .post(`/EmailTemplateTemp/`, values)
    //   .catch((err) => {
    //     message.error(err.response.data);
    //     console.log("err", err.response.data, err.response.status);
    //   });
  };

  const handleSelect = useCallback(async (e) => {
    try {
      setLoading(true);

      let result = await axiosInstance.get(`/EmailTemplate/${e}`);
      setLoading(false);
      setSelectedType(result.data);
      setTitle(result.data.title);
      setValue(DOMPurify.sanitize(result.data.html));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    //direction
    Quill.register(Quill.import("attributors/style/direction"), true);
    //Alignment
    Quill.register(Quill.import("attributors/style/align"), true);
    try {
      getText();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <>
      <CheckAuth
        Permissions={["account_manager", "param_editor"]}
        userType={["iscore"]}
      >
        <div className="my-5">
          <p className="title mb-4">{t("emailConfig")}</p>
          <div className="formWrapper">
            <Row>
              <Col span={12}>
                <p className="subTitle mb-4">{t("editEmail")}</p>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <label>{t("emailType")}</label>
                <Select
                  onChange={handleSelect}
                  className="w-100 mt-1 mb-5"
                  placeholder={t("selectType")}
                >
                  {data?.map((type) => {
                    return (
                      <Select.Option value={type.id} key={type.id}>
                        {type.key.split("_").join(" ")}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            {selectedType !== "" && value ? (
              <Form onFinish={onFinish} layout="vertical">
                <label className="mb-2">{t("emailTitle")}</label>
                <Input value={title} onChange={handleChange} type="text" />
                <label className="mt-4 mb-2">{t("emailMessage")}</label>
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={(e) => onChange(e, setValue)}
                  modules={{
                    toolbar: {
                      container: [
                        ["bold", "italic", "underline", "strike"], // toggled buttons
                        ["blockquote", "code-block"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }], // superscript/subscript
                        [{ direction: "rtl" }], // text direction
                        ["image"], // image upload
                        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                        [{ font: [] }],
                        [{ align: [] }],

                        ["clean"],
                      ],
                    },
                  }}
                />
                <Row className="text-end">
                  {" "}
                  <Button
                    color="primary"
                    htmlType="submit"
                    style={{ marginBottom: "1rem" }}
                    className="formBtn mt-3"
                    disabled={title === "" || value === "<p><br></p>"}
                  >
                    {t("form.save")}
                  </Button>
                </Row>
              </Form>
            ) : loading ? (
              <div className="flex-center">
                <Spin />
              </div>
            ) : null}
          </div>
          <SuccessMsg
            show={showSuccess}
            hide={() => setShowSuccess(false)}
            text={t("form.emailEdit")}
            btnTxt={t("form.done")}
          />
        </div>
      </CheckAuth>
    </>
  );
}

export default ErrorHandler(EmailConfig, axiosInstance);
