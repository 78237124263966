import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "../../../style-sheets/forms.css";
import { Form, Input, Select, DatePicker, Row, Col, Radio } from "antd";
import { Collapse } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import moment from "moment";

function BeneficiaryData({
  title,
  issueDate,
  setIssueDate,
  handleDate,
  birthDate,
  setBirthDate,
  type,
  setType,
  changeType,
  offices,
  governorates,
  form,
  draft,
  isChecker
}) {
  const { t } = useTranslation();
  const [toggle] = useState(true);
  const [searchText, setSearchText] = useState("");
  const sendRequest = useCallback(
    async (e) => {
      if (e.key == "Enter") {
        e.preventDefault();
        getUserData();
      }
    },
    [searchText]
  );
  const search = (e) => {
    setSearchText(e.target.value);
  };
  const getUserData = async () => {
    let result = await axiosInstance.get(
      `${
        type === 1
          ? `/SearchEgyptianPerson/${searchText}`
          : type == 2
          ? `/SearchForeignPerson/${searchText}`
          : `/SearchLegalEntity/${searchText}`
      }`
    );
    if (result) {
      console.log(result);
      form.setFieldsValue({
        address_bene: result.data.address,
        name_bene: result.data.name,
        gender_bene: result.data.gender,
        // birth_date_bene:moment(result.data.birth_date,"YYYY-MM-DD"),
        nationality_bene: result.data.nationality
        // issue_date_bene:moment(result.data.issue_date,"YYYY-MM-DD")
      });
    }
  };
  useEffect(() => {
    if (draft !== "") {
      if (draft?.beneficiary_egyptian !== null) {
        setBirthDate(draft?.beneficiary_egyptian?.birth_date);

        form.setFieldsValue({
          address_bene: draft?.beneficiary_egyptian?.address,
          name_bene: draft?.beneficiary_egyptian?.name,
          gender_bene: draft?.beneficiary_egyptian?.gender,
          birth_date_bene: moment(
            draft?.beneficiary_egyptian?.birth_date,
            "YYYY-MM-DD"
          ),
          nationality_bene: draft?.beneficiary_egyptian?.nationality,
          national_id_bene: draft?.beneficiary_egyptian?.national_id,
          email_bene: draft?.beneficiary_egyptian?.email,
          mobile_bene: draft?.beneficiary_egyptian?.mobile
          // issue_date:moment(result.data.issue_date,"YYYY-MM-DD")
        });
      } else if (draft?.beneficiary_entity !== null) {
        form.setFieldsValue({
          name: draft?.beneficiary_entity?.name
        });
      } else {
        form.setFieldsValue({
          name: draft?.beneficiary_foreign?.name
          // gender: draft.gender,
          // // birth_date:moment(draft.birth_date,"YYYY-MM-DD"),
          // nationality: draft.nationality
          // issue_date:moment(result.data.issue_date,"YYYY-MM-DD")
        });
      }
    }
  }, []);
  return (
    <div>
      <p className="subTitle">{t("chooseType")}</p>
      <Radio.Group
        disabled={isChecker}
        onChange={(e) => changeType(e, setType)}
        value={type}
      >
        <Radio value={1}>{t("egyptianPerson")}</Radio>
        <Radio value={2}>{t("foreignPerson")}</Radio>
        <Radio value={3}>{t("entityType")}</Radio>
      </Radio.Group>
      <p className="title">{title}</p>
      <p className="typeChoosen">
        {type === 1
          ? `${t("egyptianPerson")}`
          : type === 2
          ? `${t("foreignPerson")}`
          : `${t("entityType")}`}
      </p>
      <div className="formWrapper">
        <p className="subTitle">{t("beneficiaryData")}</p>
        <p className="subHeader">
          {type === 1
            ? t("specifyasNationalID")
            : type === 2
            ? t("specifyasPassport")
            : null}
        </p>

        {type === 3 ? (
          <Form.Item
            label={t("form.commercialReg")}
            name="commercial_no_bene"
            rules={[
              {
                required: false,
                message: t("form.requiredCommercial")
              }
            ]}
          >
            <Input
              disabled={!toggle || isChecker}
              onChange={search}
              onKeyPress={sendRequest}
              // placeholder="input placeholder"
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        ) : type === 2 ? (
          <Form.Item
            label={t("form.passportNumber")}
            name="passport_id_bene"
            rules={[
              {
                required: false,
                message: t("form.requiredPassport")
              }
            ]}
          >
            <Input
              disabled={!toggle || isChecker}
              onChange={search}
              onKeyPress={sendRequest}
              // placeholder="input placeholder"
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={t("form.nationalID")}
            name="national_id_bene"
            rules={[
              {
                required: false,
                message: t("form.requiredID")
              },
              {
                pattern: `^[23](?:[0-9]{2}(?:(?:(?:0[13578]|1[02])(?:0[1-9]|[12][0-9]|3[01]))|(?:(?:0[469]|11)(?:0[1-9]|[12][0-9]|30))|(?:02(?:0[1-9]|1[0-9]|2[0-8])))|(?:(?:04|08|[2468][048]|[13579][26]|(?<=3)00)0229))(?:0[1-4]|[12][1-9]|3[1-5]|88)[0-9]{4}[1-9]$`,
                message: `${t("form.wrongID")}`
              }
            ]}
          >
            <Input
              disabled={!toggle || isChecker}
              onChange={search}
              onKeyPress={sendRequest}
              // placeholder="input placeholder"
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        )}

        <Collapse isOpen={toggle}>
          {type === 2 ? (
            <Row gutter={16}>
              <>
                {" "}
                <Col span={12}>
                  <Form.Item
                    name="nationality_bene"
                    label={t("form.nationality")}
                  >
                    <Input disabled={isChecker} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="issue_date_bene"
                    label={t("form.issuanceDate")}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      value={issueDate}
                      onChange={(e, v) => handleDate(e, v, setIssueDate)}
                      disabled={isChecker}
                    />
                  </Form.Item>
                </Col>{" "}
              </>
            </Row>
          ) : null}
          {type === 3 ? (
            <Form.Item
              label={t("form.taxID")}
              name="tax_id_bene"
              rules={[
                {
                  required: false,
                  message: t("form.requiredTaxId")
                },
                {
                  message: `${t("form.requiredField")}`
                },
                {
                  pattern: `^[0-9]{3}-[0-9]{3}-[0-9]{3}`,
                  message: `${t("form.wrongFormat")}`
                }
              ]}
            >
              <Input
                disabled={isChecker}
                // placeholder="input placeholder"
                // suffix={<Tooltip overlayClassName="conflict-card" title={()=> <> <ReportTooltip data />
                // </>}> <QuestionCircleOutlined /> </Tooltip>}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label={t("form.name")}
              name="name_bene"
              rules={[
                {
                  required: false,
                  message: t("form.requiredName")
                },
                {
                  pattern: `^[\u0621-\u064A ]+$`,
                  message: `${t("form.wrongFormat")}`
                }
              ]}
            >
              <Input
                disabled={isChecker}
                // placeholder="input placeholder"
                // suffix={
                //   <Tooltip
                //     overlayClassName="conflict-card"
                //     title={() => (
                //       <>
                //         {" "}
                //         <ReportTooltip data />
                //       </>
                //     )}
                //   >
                //     {" "}
                //     <QuestionCircleOutlined />{" "}
                //   </Tooltip>
                // }
              />
            </Form.Item>
          )}

          <Row gutter={16}>
            {type === 3 ? (
              <>
                {" "}
                <Col span={12}>
                  <Form.Item
                    name="entity_name_bene"
                    label={t("form.entityName")}
                    rules={[
                      {
                        required: false,
                        message: t("form.requiredEntity")
                      }
                    ]}
                  >
                    <Input disabled={isChecker} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="governorate_bene"
                    label={t("form.governorate")}
                    rules={[
                      {
                        required: false,
                        message: t("form.requiredGovernorate")
                      }
                    ]}
                  >
                    <Select
                      // placeholder="Select a option and change input text above"
                      allowClear
                      disabled={isChecker}
                    >
                      {governorates.map((governorate) => {
                        return (
                          <Select.Option
                            value={governorate.id}
                            key={governorate.id}
                          >
                            {governorate.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>{" "}
              </>
            ) : (
              <>
                {" "}
                <Col span={12}>
                  <Form.Item
                    name="gender_bene"
                    label={t("form.gender")}
                    rules={[
                      {
                        required: false,
                        message: t("form.requiredGender")
                      }
                    ]}
                  >
                    <Select
                      // placeholder="Select a option and change input text above"
                      allowClear
                      disabled={isChecker}
                    >
                      <Select.Option value="1">{t("form.male")}</Select.Option>
                      <Select.Option value="2">
                        {t("form.female")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="birth_date_bene"
                    label={t("form.birthDate")}
                    rules={[
                      {
                        required: false,
                        message: t("form.requiredBirthDate")
                      }
                    ]}
                  >
                    <DatePicker
                      placeholder={t("form.selectDate")}
                      style={{ width: "100%" }}
                      value={birthDate}
                      onChange={(e, v) => handleDate(e, v, setBirthDate)}
                      disabled={isChecker}
                    />
                  </Form.Item>
                </Col>{" "}
              </>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t("form.address")}
                name="address_bene"
                rules={[
                  {
                    required: false,
                    message: t("form.requiredAddress")
                  }
                ]}
              >
                <Input
                  disabled={isChecker}
                  // placeholder="input placeholder"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {type === 3 ? (
                <Form.Item
                  label={t("form.office")}
                  name="office_bene"
                  rules={[
                    {
                      required: false,
                      message: t("form.requiredOffice")
                    }
                  ]}
                >
                  <Select disabled={isChecker}>
                    {offices.map((office) => {
                      return (
                        <Select.Option
                          key={office.id}
                          value={office.id}
                          id={office.id}
                        >
                          {office.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t("form.email")}
                name="email_bene"
                rules={[
                  {
                    required: false,
                    message: t("form.requiredEmail")
                  }
                ]}
              >
                <Input disabled={isChecker} type="email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("form.mobileNumber")}
                name="mobile_bene"
                rules={[
                  {
                    required: false,
                    message: t("form.requiredPhone")
                  }
                ]}
              >
                <Input
                  disabled={isChecker}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", ".", "E", "+", "-"].includes(e.key) &&
                    e.preventDefault()
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Collapse>
      </div>
    </div>
  );
}

export default ErrorHandler(BeneficiaryData, axiosInstance);
