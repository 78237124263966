import React from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function UnSuccessMsg({ text, subTxt, btnTxt, show, hide }) {
  return (
    <Modal show={show} onHide={hide}>
      <Container className="p-4">
        <Row>
          <Col sm="12">
            <div className="d-flex align-items-center">
              <CloseCircleOutlined
                style={{ color: "red", marginRight: "10px" }}
              />{" "}
              <span className="title1">{text}</span>
            </div>
            <p className="ps-4">{subTxt}</p>
          </Col>
        </Row>
        <Row>
          <Col className="text-end" sm="12">
            <Button
              onClick={hide}
              style={{ borderRadius: "7px" }}
              className="resetBtn2"
            >
              {btnTxt}
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
