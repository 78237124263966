import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../style-sheets/forms.css";
import { Steps, Spin, Button, Form } from "antd";
import PersonalData from "./personalData";
import ChequeData from "./chequeData";
import BeneficiaryData from "./beneficiaryData";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import img from "../../../images/archive.svg";
import "react-toastify/dist/ReactToastify.css";
import CheckAuth from "../../HOC/CheckAuth";
import { useParams, useNavigate } from "react-router-dom";
import ConfirmRequest from "../../layout/modals/confirmRequest";
import SuccessMsg from "../../layout/SuccessMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
const { Step } = Steps;

function DraftedData({ bene, isChecker, rejected }) {
  const { t } = useTranslation();
  let params = useParams();
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [dueDate, setDueDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [presentedDate, setPresentedDate] = useState("");
  const [rejectionDate, setRejectionDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthDateBene, setBirthDateBene] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [governorates, setGovernorates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [offices, setOffices] = useState([]);
  const [details, setDetails] = useState("");
  const [isRejected, setIsRejected] = useState(details?.is_rejected);
  const [drawerType, setDrawerType] = useState(1);
  const [beneficiaryType, setBeneficiaryType] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [message, setMessage] = useState("");
  const handleDate = (date, dateString, setFunction) => {
    console.log(dateString);
    setFunction(dateString);
  };
  const handleIsRejected = (e) => {
    console.log(e);
    setIsRejected(e);
  };
  const onFinish = async (values, type) => {
    const obj = {
      drawer_bank: values.drawer_bank,
      drawer_branch: values.drawer_branch,
      debit_account: values.debit_account,
      cheque_number: values.cheque_number,
      currency: values.currency,
      cheque_amount: values.cheque_amount,
      due_date: dueDate,
      presented_date: presentedDate,
      cheque_type: values.cheque_type,
      is_rejected: isRejected,
      credit_account: values.credit_account,
      beneficiary_bank: values.beneficiary_bank,
      beneficiary_branch: values.beneficiary_branch,
      rejection_times: values.rejection_times,
      payment_date: paymentDate,
      paid_amount: values.paid_amount
    };

    const draweeEgyptian = {
      national_id: values.national_id,
      name: values.name,
      mobile: values.phone,
      email: values.email,
      address: values.address,
      birth_date: birthDate,
      gender: values.gender
    };

    const beneficiaryEgyptian = {
      national_id: values.national_id_bene,
      name: values.name_bene,
      mobile: values.phone_bene,
      email: values.email_bene,
      address: values.address_bene,
      birth_date: birthDateBene,
      gender: values.gender_bene
    };

    const draweeForeign = {
      passport_id: values.passport_id,
      name: values.name,
      birth_date: birthDate,
      mobile: values.phone,
      email: values.email,
      address: values.address,
      gender: values.gender,
      nationality: values.nationality,
      issue_date: issueDate
    };

    const beneficiaryForeign = {
      passport_id: values.passport_id_bene,
      name: values.name_bene,
      birth_date: birthDateBene,
      mobile: values.phone_bene,
      email: values.email_bene,
      address: values.address_bene,
      gender: values.gender_bene,
      nationality: values.nationality_bene,
      issue_date: issueDate
    };

    const draweeEntity = {
      tax_id: values.tax_id,
      name: values.entity_name,
      commercial_no: values.commercial_no,
      governorate: values.governorate,
      office: values.office,
      mobile: values.phone,
      email: values.email,
      address: values.address
    };

    const beneficiaryEntity = {
      tax_id: values.tax_id_bene,
      name: values.entity_name_bene,
      commercial_no: values.commercial_no_bene,
      governorate: values.governorate_bene,
      office: values.office_bene,
      mobile: values.phone_bene,
      email: values.email_bene,
      address: values.address_bene
    };

    let data = {
      ...obj
    };
    if (drawerType == 1) {
      data.drawee_egyptian = draweeEgyptian;
    } else if (drawerType == 2) {
      data.drawee_foreign = draweeForeign;
    } else {
      data.drawee_entity = draweeEntity;
    }
    if (beneficiaryType == 1 && birthDateBene !== "") {
      data.beneficiary_egyptian = beneficiaryEgyptian;
    } else if (beneficiaryType == 2 && birthDateBene !== "") {
      data.beneficiary_foreign = beneficiaryForeign;
    } else if (beneficiaryType === 3) {
      data.beneficiary_entity = beneficiaryEntity;
    } else {
      //
    }
    console.log(isRejected);
    if (details.is_rejected) {
      console.log(rejectionDate);
      data.rejection_reason = values.rejection_reason;
      data.reject_date = rejectionDate;
    }
    let result;

    if (type === "create") {
      result = await axiosInstance
        .put(
          `/${bene || rejected ? "Cheque" : "DraftCheque"}/${params.id}`,
          data
        )
        .catch((err) => {
          console.log(err.response);
        });
      if (result) {
        setMessage(t("editedSuccessfully"));
        setShow(true);
      }
    } else if (type === "acceptCheque" && isChecker) {
      result = await axiosInstance
        .put(`/${"Cheque"}/${params.id}`, { state: "1" })
        .catch((err) => {
          console.log(err.response);
        });
      if (result) {
        setMessage(t("acceptedSuccessfully"));
        setShow(true);
      }
    } else if (type === "rejectCheque" && isChecker) {
      result = await axiosInstance
        .put(`/${"Cheque"}/${params.id}`, { state: "2" })
        .catch((err) => {
          console.log(err.response);
        });
      if (result) {
        setMessage(t("rejectedSuccessfully"));
        setShow(true);
      }
    } else {
      result = await axiosInstance
        .post("/Cheque/", data)
        .catch((err) => console.log(err));
    }
  };

  const deleteDraft = async () => {
    let result = await axiosInstance.delete(`/DraftCheque/${params.id}`);

    if (result) {
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    }
  };
  const getDetails = async () => {
    setLoading(true);
    let result = await axiosInstance.get(
      `/${bene || isChecker || rejected ? "Cheque" : "DraftCheque"}/${
        params.id
      }`
    );
    if (result) {
      console.log(result);
      setDetails(result.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("is checkerrrrrrr", isChecker);
    getDetails();
  }, []);
  const onChangeType = (e, typeFunction) => {
    typeFunction(parseInt(e.target.value));
  };
  return (
    <div>
      <p className="title">{t("chequeDraft")}</p>
      <Steps size="small" current={0}>
        <Step
          icon={<FontAwesomeIcon icon={faCircle} />}
          title={t("draweeData")}
        />
        <Step
          icon={<FontAwesomeIcon icon={faCircle} />}
          title={t("chequeData")}
        />
        <Step
          icon={<FontAwesomeIcon icon={faCircle} />}
          title={t("beneficiaryData")}
        />
      </Steps>
      <Form layout="vertical" form={form}>
        {details && !loading ? (
          <>
            <PersonalData
              setType={setDrawerType}
              type={drawerType}
              draft={details}
              form={form}
              changeType={onChangeType}
              params={params.id}
              handleDate={handleDate}
              birthDate={birthDate}
              issueDate={issueDate}
              setIssueDate={setIssueDate}
              setBirthDate={setBirthDate}
              offices={offices}
              governorates={governorates}
              isChecker={isChecker}
              bene={bene}
            />
            <ChequeData
              draft={details}
              form={form}
              handleDate={handleDate}
              setDueDate={setDueDate}
              setPaymentDate={setPaymentDate}
              setPresentedDate={setPresentedDate}
              setRejectionDate={setRejectionDate}
              paymentDate={paymentDate}
              rejectionDate={rejectionDate}
              dueDate={dueDate}
              presentedDate={presentedDate}
              handleIsRejected={handleIsRejected}
              isRejected={isRejected}
              setIsRejected={setIsRejected}
              isChecker={isChecker}
              bene={bene}
            />
            <BeneficiaryData
              setType={setBeneficiaryType}
              type={beneficiaryType}
              draft={details}
              form={form}
              changeType={onChangeType}
              params={params.id}
              handleDate={handleDate}
              birthDate={birthDateBene}
              setBirthDate={setBirthDateBene}
              issueDate={issueDate}
              setIssueDate={setIssueDate}
              offices={offices}
              governorates={governorates}
              isChecker={isChecker}
            />

            {isChecker && details?.state === "3" ? (
              <CheckAuth
                Permissions={["entity_checker"]}
                userType={["entity", "bank"]}
              >
                <div className="btnDiv mt-3">
                  <Button
                    color="primary"
                    htmlType="submit"
                    className="submitBtn"
                    onClick={() => setShowConfirm(true)}
                  >
                    <FontAwesomeIcon className="pe-2 ps-2" icon={faCheck} />

                    {t("accept")}
                  </Button>
                  <Button
                    color="primary"
                    htmlType="submit"
                    className="deleteBtn"
                    onClick={() => setShowReject(true)}
                  >
                    <FontAwesomeIcon className="pe-2 ps-2" icon={faCheck} />

                    {t("reject")}
                  </Button>
                </div>
              </CheckAuth>
            ) : null}
            {!isChecker ? (
              <>
                {" "}
                <div className="btnDiv mt-3">
                  {isRejected ? (
                    <>
                      <Button
                        color="primary"
                        htmlType="submit"
                        className="submitBtn"
                        onClick={() =>
                          onFinish(form.getFieldsValue(), "create")
                        }
                      >
                        <FontAwesomeIcon className="pe-2 ps-2" icon={faCheck} />

                        {t("formSubmission")}
                      </Button>
                      <Button
                        htmlType="button"
                        onClick={() => {
                          navigate("/home");
                        }}
                        className="deleteBtn"
                      >
                        <FontAwesomeIcon className="pe-2 ps-2" icon={faTrash} />

                        {t("discardTransaction")}
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Button
                        color="primary"
                        htmlType="submit"
                        className="submitBtn"
                        onClick={() =>
                          onFinish(form.getFieldsValue(), "create")
                        }
                      >
                        <FontAwesomeIcon className="pe-2 ps-2" icon={faCheck} />

                        {t("formSubmission")}
                      </Button>
                      <Button
                        htmlType="submit"
                        className="draftBtn"
                        onClick={() => onFinish(form.getFieldsValue(), "edit")}
                      >
                        <img
                          src={img}
                          className="img-fluid pe-2 ps-2"
                          alt="Draft icon"
                        />
                        {t("formDraft")}
                      </Button>
                      <Button
                        htmlType="button"
                        onClick={deleteDraft}
                        className="deleteBtn"
                      >
                        <FontAwesomeIcon className="pe-2 ps-2" icon={faTrash} />
                        {t("deleteTransaction")}
                      </Button>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </>
        ) : loading ? (
          <Spin tip={t("loading")} />
        ) : null}
        <ConfirmRequest
          show={showConfirm}
          text={t("acceptChequeTxt")}
          btnTxt={t("confirm")}
          hide={() => setShowConfirm(false)}
          request={() => onFinish(form.getFieldsValue(), "acceptCheque")}
        />
        <ConfirmRequest
          show={showReject}
          text={t("rejectChequeTxt")}
          btnTxt={t("confirm")}
          request={() => onFinish(form.getFieldsValue(), "rejectCheque")}
          hide={() => setShowReject(false)}
        />
        <SuccessMsg
          show={show}
          hide={() => {
            setShow(false);
            setShowConfirm(false);
            setShowReject(false);
          }}
          text={message}
          btnTxt={t("ok")}
        />
      </Form>
    </div>
  );
}

export default ErrorHandler(DraftedData, axiosInstance);
