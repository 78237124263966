import React, { useState, useEffect } from "react";
import { Form, Button, Input, Modal, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import { Row, Col, Container } from "react-bootstrap";
import axiosInstance from "../../../api/axios";
import { commandRegistryModule } from "@antv/xflow-core";

export default function EditEntityModal({
  show,
  hide,
  isName,
  id,
  getAll,
  typeID,
  row,
}) {
  const { t } = useTranslation();
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  const onFinish = async (values) => {
    values.operation_type = "edit";
    values.real_id = id;
    console.log(values);

    try {
      let result = await axiosInstance.post(
        `${
          isName && typeID !== "bank"
            ? `/EntityTemp/`
            : isName && typeID == "bank"
            ? `/BankTemp/`
            : `/EntityTypeTemp/`
        }`,
        values
      );
      hide();
      getAll();
    } catch (err) {
      console.log(err.response.data);
      message.error(err.response.data);
    }
  };
  const handleSelect = (set, e) => {
    set(e);
    console.log(e);
  };
  const getTypes = async () => {
    let result = await axiosInstance.get(`/EntityType/?page_size=500`);
    console.log(result);
    setTypes(result.data.data);
  };
  useEffect(() => {
    getTypes();
  }, []);

  return (
    <Modal
      title={`${t("form.enterName")}`}
      footer={false}
      visible={show}
      onCancel={hide}
      destroyOnClose
    >
      <Container className="p-4 entityModal">
        <Form onFinish={onFinish} layout="vertical">
          <Row>
            <Col sm="12">
              <Form.Item
                label={t("form.nameAr")}
                name="name_ar"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                  {
                    pattern:
                      "[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]",
                    message: "wrong format",
                  },
                ]}
                initialValue={row.name_ar}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Form.Item
                label={t("form.nameEn")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredName"),
                  },
                  {
                    min: 3,
                    message: t("form.minLength"),
                  },
                  {
                    max: 255,
                    message: t("form.maxLength"),
                  },
                ]}
                initialValue={row.name}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {isName ? (
            <>
              <Row>
                <Col sm="12">
                  <Form.Item
                    name="type"
                    label={t("form.entityType")}
                    rules={[
                      {
                        required: true,
                        message: t("form.requiredtype"),
                      },
                    ]}
                    initialValue={typeID}
                  >
                    <Select
                      onChange={(e) => handleSelect(setSelectedType, e)}
                      value={selectedType}
                      disabled
                    >
                      {types.map((type) => {
                        return (
                          <>
                            <Select.Option key={type.id} value={type.id}>
                              {type.name} / {type.name_ar}
                            </Select.Option>
                          </>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>{" "}
              <Row>
                <Col sm="12">
                  <Form.Item
                    initialValue={row.provider_id}
                    label={t("form.providerID")}
                    name="provider_id"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}

          <Row>
            <Col className="text-end" sm="12">
              {" "}
              <>
                <Button
                  onClick={hide}
                  style={{ borderRadius: "7px" }}
                  className="cancelBtn mx-3"
                >
                  {t("form.cancel")}
                </Button>
                <Button
                  style={{ borderRadius: "7px" }}
                  className="formBtn"
                  htmlType="submit"
                >
                  {t("form.save")}
                </Button>
              </>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  );
}
