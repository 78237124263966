import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/loginStyle.css";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import logo from "../../images/logo1.svg";
import "react-toastify/dist/ReactToastify.css";
import { UserOutlined } from "@ant-design/icons";
import ResetPasswordCard from "../layout/ResetPasswordCard";
import axios from "axios";
import { BASE_URL } from "../../api/axios";
import Captcha from "../Captcha";
import { ToastContainer, toast } from "react-toastify";

const ForgetPassword = () => {
  const { t } = useTranslation();

  const [email] = useState("");
  const [isNext, setIsNext] = useState(false);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  const onFinish = async (data) => {
    let result = await axios
      .post(BASE_URL + "/ForgetPassword/", data)
      .catch((err) => {
        generateCaptcha();
        console.log(err.response);
        setTimeout(() => {
          toast.error(`${err.response.data}`);
        }, 500);
      });
    if (result) {
      setIsNext(true);
    }
  };

  const generateCaptcha = async () => {
    setLoading(true);
    let result = await axios
      .post(`${BASE_URL}/captcha/generate`)
      .catch((err) => console.log(err));

    if (result) {
      setLoading(false);
      console.log(result);
      setImage(result.data.captcha_image);
    }
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  return (
    <div className="loginContainer container-fluid">
      <Row>
        <Col className="first" lg={4} sm={6}>
          <div className="wrapper">
            <div className="welcome-text">
              <p className="welcome">{t("welcome")}</p>
              <p className="welcome">{t("welcomeText")}</p>
            </div>
            <img className="logo" src={logo} />
          </div>
        </Col>
        <Col className="second" lg={8} sm={6}>
          {isNext ? (
            <ResetPasswordCard />
          ) : (
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <p className="title">{t("form.forgetPass")}!</p>
              <p className="subText">{t("form.msg1")}</p>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: `${t("emailError")}` },
                  {
                    pattern: `^[a-zA-Z0-9_.-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$`,
                    message: `${t("validEmail")}`,
                  },
                ]}
                layout="vertical"
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  className="loginInput"
                  placeholder="youremail@iscore.com.eg"
                  type="email"
                  name="email"
                  value={email}
                />
              </Form.Item>
              <Captcha
                setLoading={setLoading}
                setImage={setImage}
                loading={loading}
                image={image}
                generate={generateCaptcha}
              />
              <Form.Item>
                <Button className="loginBtn" htmlType="submit">
                  {t("form.next")}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ForgetPassword;
