import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../style-sheets/forms.css";
import { Form, Input, Select, DatePicker, Row, Col, Radio } from "antd";
import { Collapse } from "reactstrap";
import moment from "moment";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { rejectionReasons } from "../../layout/RejectionReasons";
import TransactionDispute from "../../layout/modals/disputes/TransactionDispute";
import { useLocation } from "react-router-dom";
function ChequeData({
  handleDate,
  setPaymentDate,
  setDueDate,
  setPresentedDate,
  setRejectionDate,
  paymentDate,
  rejectionDate,
  dueDate,
  presentedDate,
  handleIsRejected,
  form,
  draft,
  isChecker,
  bene,
  isRejected,
}) {
  const location = useLocation();
  const { t } = useTranslation();
  const { Option } = Select;

  const [toggle] = useState(true);

  const [rejectedToggle, setRejectedToggle] = useState(false);
  const [searchText] = useState("");
  const [selectedChequeType, setSelectedCheque] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(draft?.drawer_bank?.id);
  const [banksCount, setBanksCount] = useState("");
  const [nextBanksUrl, setNextBanksUrl] = useState("");
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branchesCount, setBranchesCount] = useState("");
  const [nextBranchUrl, setNextBranchUrl] = useState("");
  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedBankBenf, setSelectedBankBenf] = useState(
    draft?.beneficiary_bank?.id
  );
  const [selectedBranchBenf, setSelectedBranchBenf] = useState("");
  const [branchesCountBenf, setBranchesCountBenf] = useState("");
  const [nextBranchUrlBenf, setNextBranchUrlBenf] = useState("");
  const [branchesBenf, setBranchesBenf] = useState([]);
  const [fields] = useState("");
  const [show, setShow] = useState(false);
  const [filterBank, setFilterBank] = useState("");

  const onToggle = (state, setFunction) => {
    setFunction(!state);
  };

  const getCurrency = async () => {
    let result = await axiosInstance.get("/Currency/?page_size=50");
    setCurrency(result.data.data);
    console.log(result);
  };

  const getBanks = async () => {
    let result = await axiosInstance.get(`/Bank/?page_size=50`);
    if (result) {
      console.log(result);
      setBanks(result.data.data);
      setBanksCount(result.data.count);
      setNextBanksUrl(result.data.next);
    }
  };
  const handleBank = (e, v, setFunction) => {
    console.log(e, v);
    setFunction(v.value);
  };

  const getBranches = async () => {
    let result = await axiosInstance.get(
      `/BankBranch/?bank=${selectedBank}&page_size=50`
    );
    if (result) {
      console.log(result);
      setBranches(result.data.data);
      setBranchesCount(result.data.count);
      setNextBranchUrl(result.data.next);
    }
  };
  const getBranchesBenf = async () => {
    let result = await axiosInstance.get(
      `/BankBranch/?bank=${selectedBankBenf}&page_size=50`
    );
    if (result) {
      console.log(result);
      setBranchesBenf(result.data.data);
      setBranchesCountBenf(result.data.count);
      setNextBranchUrlBenf(result.data.next);
    }
  };
  const handleBranch = (e, v) => {
    console.log(e, v);
    setSelectedBranch(v.id);
  };
  const handleBranchBenf = (e, v) => {
    console.log(e, v);
    setSelectedBranchBenf(v.id);
  };
  useEffect(() => {
    try {
      console.log(location);
      if (location.pathname !== "/transaction") {
        getCurrency();
        getBanks();
        getBranches();
        getBranchesBenf();
      }
    } catch (err) {
      console.log(err);
    }
  }, []);
  const hide = () => {
    setShow(false);
  };

  useEffect(() => {
    console.log(draft);
    if (draft !== "") {
      setSelectedBank(draft?.drawer_bank?.id);
      setRejectedToggle(draft?.is_rejected);
      handleIsRejected(draft?.is_rejected);
      setDueDate(draft?.due_date);
      setRejectionDate(draft?.reject_date ?? "");
      setPaymentDate(draft?.payment_date);
      setPresentedDate(draft?.presented_date);
      console.log(draft?.reject_date);
      form.setFieldsValue({
        drawer_bank: draft?.drawer_bank?.id,
        drawer_branch: draft?.drawer_branch?.id,
        debit_account: draft?.debit_account,
        cheque_type: draft?.cheque_type,
        cheque_number: draft?.cheque_number,
        currency: draft?.currency?.id,
        cheque_amount: draft?.cheque_amount,
        due_date: moment(draft?.due_date, "YYYY-MM-DD"),
        reject_date: moment(
          draft?.reject_date === null ? "2022-05-06" : draft?.reject_date,
          "YYYY-MM-DD"
        ),
        rejection_times: draft?.rejection_times,
        paid_amount: draft?.paid_amount,
        presented_date: moment(draft?.presented_date, "YYYY-MM-DD"),
        payment_date: moment(draft?.payment_date, "YYYY-MM-DD"),
        credit_account: draft?.credit_account,
        beneficiary_bank: draft?.beneficiary_bank?.id,
        beneficiary_branch: draft?.beneficiary_branch?.id,
        rejection_reason: draft?.rejection_reason,
      });
    }
  }, []);

  console.log(draft?.is_rejected);

  return (
    <div className="my-5">
      <p className="title mb-4">{t("chequeData")}</p>
      <div className="formWrapper">
        <p className="subTitle">{t("chequeDetails")}</p>
        <p className="subHeader">{t("specidyasCheque")}</p>

        <Collapse isOpen={toggle}>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.bankName")}
                required
                rules={[
                  {
                    required: true,
                    message: t("form.requiredBankName"),
                  },
                ]}
                name="drawer_bank"
              >
                <Select
                  placeholder={t("form.selectBank")}
                  value={selectedBank}
                  onChange={(e, v) => handleBank(e, v, setSelectedBank)}
                  disabled={isChecker || bene}
                  onClick={getBanks}
                >
                  {banks.map((bank) => {
                    return (
                      <Option value={bank.id} id={bank.name}>
                        {bank.name}-{bank.name_ar}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.branch")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredBranch"),
                  },
                ]}
                name="drawer_branch"
              >
                <Select
                  onClick={getBranches}
                  value={selectedBranch}
                  placeholder={t("form.selectBranch")}
                  onChange={handleBranch}
                  disabled={isChecker || bene}

                >
                  {branches.map((branch) => {
                    return (
                      <Option value={branch.id} id={branch.name}>
                        {branch.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.debitAccount")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredDebitAcc"),
                  },
                ]}
                name="debit_account"
              >
                <Input
                  disabled={isChecker || bene}
                  placeholder={t("form.debitAccount")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.chequeNumber")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredChequeNumber"),
                  },
                ]}
                name="cheque_number"
              >
                <Input
                  disabled={isChecker || bene}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("form.chequeNumber")}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.chequeType")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredField"),
                  },
                ]}
                name="cheque_type"
              >
                <Select
                  disabled={isChecker || bene}
                  value={selectedChequeType}
                  onChange={(e, v) => handleBank(e, v, setSelectedCheque)}
                >
                  <Select.Option name="Normal Cheque" value="normal cheque">
                    Normal Cheque
                  </Select.Option>
                  <Select.Option
                    name="Corporate Cheque"
                    value="corporate cheque"
                  >
                    Corporate Cheque
                  </Select.Option>
                  <Select.Option
                    name="Pre-approved Cheque"
                    value="certified cheque"
                  >
                    Certified Cheque
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.currency")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredCurrency"),
                  },
                ]}
                name="currency"
              >
                <Select
                  disabled={isChecker || bene}
                  onClick={getCurrency}
                  placeholder={t("form.selectCurrency")}
                  // onChange={handleCurrency}
                >
                  {currency.map((curr) => {
                    return (
                      <Option value={curr.id} id={curr.id}>
                        {curr.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.chequeAmount")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredChequeAmount"),
                  },
                ]}
                name="cheque_amount"
              >
                <Input
                  disabled={isChecker || bene}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("form.chequeAmount")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.dueDate")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredDueDate"),
                  },
                ]}
                name="due_date"
              >
                <DatePicker
                  disabled={isChecker || bene}
                  placeholder={t("form.selectDate")}
                  value={dueDate}
                  onChange={(e, v) => handleDate(e, v, setDueDate)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t("form.rejected")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    console.log(e.target);
                    handleIsRejected(e.target.value);
                    console.log(e.target.value);
                    onToggle(
                      e.target.value == true ? false : true,
                      setRejectedToggle
                    );
                  }}
                  defaultValue={draft?.is_rejected}
                  // value={isRejected}
                  disabled={isChecker || bene}
                >
                  <Radio value={true}>{t("form.yes")}</Radio>
                  <Radio value={false}>{t("form.no")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Collapse
            isOpen={!isChecker || bene ? rejectedToggle : draft?.is_rejected}
          >
            <Row gutter={16}>
              <Col span={12}>
                {" "}
                <Form.Item
                  rules={[{ required: isRejected ? true : false }]}
                  label={t("form.rejectedDate")}
                  name="reject_date"
                >
                  <DatePicker
                    value={rejectionDate}
                    onChange={(e, v) => handleDate(e, v, setRejectionDate)}
                    disabled={isChecker || bene}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {" "}
                <Form.Item
                  name="rejection_reason"
                  label={t("form.rejectionReasons")}
                  rules={[
                    {
                      required: false,
                      message: t("form.requiredDate"),
                    },
                  ]}
                >
                  <Select disabled={isChecker || bene}>
                    {rejectionReasons.map((reason) => {
                      return (
                        <Select.Option value={`${reason}`}>
                          {reason}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Collapse>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.rejectionTimes")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredRejTimes"),
                  },
                ]}
                name="rejection_times"
                initialValue={0}
              >
                <Input
                  disabled={isChecker || bene}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("form.rejectionTimes")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.paidAmount")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredPaidAmount"),
                  },
                ]}
                name="paid_amount"
              >
                <Input
                  disabled={isChecker || bene}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder={t("form.paidAmount")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.paymentDate")}
                name="payment_date"
                rules={[
                  {
                    required: isRejected ? true : false,
                  },
                ]}
              >
                <DatePicker
                  disabled={isChecker || bene}
                  placeholder={t("form.paymentDate")}
                  value={paymentDate}
                  onChange={(e, v) => handleDate(e, v, setPaymentDate)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.presentedDate")}
                name="presented_date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  disabled={isChecker || bene}
                  placeholder={t("form.presentedDate")}
                  value={presentedDate}
                  onChange={(e, v) => handleDate(e, v, setPresentedDate)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.bankName")}
                required
                rules={[
                  {
                    required: true,
                    message: t("form.requiredBankName"),
                  },
                ]}
                name="beneficiary_bank"
              >
                <Select
                  disabled={isChecker || bene}
                  value={selectedBankBenf}
                  placeholder={t("form.selectBank")}
                  onChange={(e, v) => handleBank(e, v, setSelectedBankBenf)}
                >
                  {banks.map((bank) => {
                    return (
                      <Option value={bank.id} id={bank.name}>
                        {bank.name}-{bank.name_ar}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.branch")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredBranch"),
                  },
                ]}
                name="beneficiary_branch"
              >
                <Select
                  disabled={isChecker || bene}
                  onClick={getBranchesBenf}
                  value={selectedBranchBenf}
                  placeholder={t("form.selectBranch")}
                  onChange={handleBranchBenf}
                >
                  {branchesBenf.map((branch) => {
                    return (
                      <Option value={branch.id} id={branch.id}>
                        {branch.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {" "}
              <Form.Item
                label={t("form.creditAccount")}
                rules={[
                  {
                    required: true,
                    message: t("form.requiredCreditAcc"),
                  },
                ]}
                name="credit_account"
              >
                <Input
                  disabled={isChecker || bene}
                  placeholder={t("form.creditAccount")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Collapse>

        <TransactionDispute
          id={searchText}
          show={show}
          fields={fields}
          hide={hide}
          banks={banks}
          benfBranch={branchesBenf}
          drawerBranch={branches}
          currency={currency}
        />
      </div>
    </div>
  );
}

export default ErrorHandler(ChequeData, axiosInstance);
