import React from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
export default function RejectionSwitchModal({
  show,
  hide,
  code,
  text,
  btnTxt,
  request,
  color,
  status
}) {
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();

  return (
    <Modal dir={lang === "en" ? "ltr" : "rtl"} show={ show} onHide={hide}>
      <Container className="p-4">
        <Row>
          <Col className="text-center" sm="12">
            <p>
              {status ? (
                <CheckCircleOutlined
                  style={{
                    color: "#52C41A",
                    padding: "5px",
                    fontSize: "40px"
                  }}
                />
              ) : (
                <ExclamationCircleOutlined
                  style={{
                    color: "#FF4D4F",
                    padding: "5px",
                    fontSize: "40px"
                  }}
                />
              )}
            </p>
            <p style={{ marginBottom: "0rem" }} className="title1">
              {" "}
              {status ? "Swich On" : "Switch Off"}
            </p>
            <p className="ps-4 title1">
              {t("ReasonCode")}: <strong>{code}</strong>
            </p>
            <p className="switchTxt">
              {t("SwitchText.1")}{" "}
              <strong>{status ? t("included") :  t("excluded")}</strong>  {t("SwitchText.2")}
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" sm="6">
            <Button
              onClick={hide}
              className="swichBtn mx-2"
              style={{
                backgroundColor: "#fff",
                color: "#000",
                border: "1px solid #D0D5DD"
              }}
            >
              {t("form.cancel")}
            </Button>
          </Col>{" "}
          <Col className="text-center" sm="6">
            <Button
              onClick={request}
              style={{
                backgroundColor: status ? "#52C41A" : "#FF4D4F",
                borderColor: status ? "#52C41A" : "#FF4D4F"
              }}
              className="swichBtn"
            >
              {status ? t("SwitchOn") : t("SwitchOff")}
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}
