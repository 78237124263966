import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
export default function ResetPasswordCard() {
  const { t } = useTranslation();
  let navigate=useNavigate()
  const redirect = () => {
    navigate("/");
  };
  return (
    <div className="resetCard py-5">
      <Container>
        <Row>
          <Col sm="12">
            {" "}
            <p className="text1 mb-5">{t("form.msg2")}</p>
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            {" "}
            <p className="text1">{t("form.msg3")}</p>{" "}
          </Col>
        </Row>
        <Row>
          <Col className="text-center" sm="12">
            <Button onClick={redirect} className="formBtn">
              {t("backToLogin")}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
