import { Pagination, Table, Space, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";

function BulkFilesView() {
  const { t } = useTranslation();
  let lang = localStorage.getItem("i18nextLng");

  const [sheetType, SetSheetType] = useState("");

  const [current, setCurrent] = useState(1);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [summry, setSummry] = useState([]);
  const [total, setTotal] = useState("");

  const [data, setData] = useState([]);
  const [file, setFile] = useState("");

  let columns = [
    {
      key: "id",
      dataIndex: "id",
      title: t("fileId"),
    },
    {
      key: "UploadedDate",
      title: t("UploadedDate"),
      render: (row) => <>{row.create_date.split("T")[0]}</>,
    },

    {
      key: "UploadedBy",
      title: t("UploadedBy"),
      render: (row) => <>{row.changed_by.name}</>,
    },

    {
      key: "actions",
      title: t("action"),
      fixed: lang === "en" ? "right" : "left",
      render: (row) => (
        <Space size="middle">
          <button onClick={(e) => downloadFile(row.id)} className="downloadBtn">
            {t("download")}
          </button>
        </Space>
      ),
    },
  ];

  const getFiles = async () => {
    let result = await axiosInstance.get(
      `UploadSession/?sheet_type=${sheetType}&page=${current}`
    );

    if (result) {
      setTotal(result.data.count);
      setData(result.data.data);
    }
  };

  const downloadFile = async (sessionId) => {
    let result = await axiosInstance.get(`/ExportUploadSession/${sessionId}`);
    if (result) {
      console.log("file", result.data);
      setFile(result.data);
      document.getElementById("downloadBtn").click();
    }
  };
  const getSummary = async (id) => {
    setExpandedRowKeys([id]);
    console.log(id);
    let result = await axiosInstance.get(`/UploadSessionSummary/${id}`);
    if (result) {
      setSummry(result.data);
      console.log("getSummary", result.data);
    }
  };

  useEffect(() => {
    getFiles();
  }, [sheetType, current]);

  return (
    <div>
      <div className="formWrapper">
        <div className="d-flex align-items-center justify-content-between py-4">
          <p className="mb-0"> {t("BulkFilesView")}</p>
          <div className="d-flex align-items-start">
            <Select
              style={{ minWidth: "150px" }}
              placeholder={t("sheetType")}
              onChange={(e) => SetSheetType(e)}
              value={sheetType}
            >
              <Select.Option value={""}>{t("all")}</Select.Option>

              <Select.Option value={1}>{t("Drawer")}</Select.Option>
              <Select.Option value={2}>{t("benifUpload")}</Select.Option>
            </Select>
          </div>
        </div>

        <Table
          pagination={false}
          expandedRowKeys={expandedRowKeys}
          rowKey={"id"}
          expandable={{
            expandRowByClick: true,
            expandedRowRender: () => (
              <table style={{ width: "50%" }} className="mx-auto">
                <tr className="text-center">
                  <th>{t("Currency")}</th>
                  <th style={{ color: "#52C31B" }}>{t("Collected")}</th>
                  <th style={{ color: "#FD4D4F" }}>{t("Rejected")}</th>
                  <th style={{ color: "#3C7095" }}>{t("TotalAmount")}</th>
                </tr>
                {summry.map((tablerow) => (
                  <tr className="text-center">
                    <td>{tablerow.currency}</td>
                    <td>{tablerow.collected}</td>
                    <td>{tablerow.rejected}</td>
                    <td>{tablerow.total}</td>
                  </tr>
                ))}
              </table>
            ),
            onExpand: (expanded, record) => {
              console.log("onExpand: ", record, expanded);

              getSummary(record.id);
            },
          }}
          expandIconColumnIndex={3}
          columns={columns}
          dataSource={data}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <button
                className="showMoreBtn"
                onClick={() => {
                  setExpandedRowKeys([]);
                }}
              >
                {t("hideSummary")}
              </button>
            ) : (
              <button
                className="showMoreBtn"
                onClick={(e) => {
                  onExpand(record, e);
                }}
              >
                {t("showSummary")}
              </button>
            )
          }
        />

        <div>
          <Pagination
            current={current}
            defaultCurrent={current}
            onChange={(e) => setCurrent(e)}
            total={total}
            showSizeChanger={false}
          />
        </div>
      </div>

      <CSVLink
        data={file}
        filename={"report.csv"}
        id="downloadBtn"
        style={{ display: "none" }}
      >
        Download me
      </CSVLink>
    </div>
  );
}
export default ErrorHandler(BulkFilesView, axiosInstance);
