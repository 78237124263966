import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "../../../style-sheets/forms.css";
import { Form, Input, Select, DatePicker, Row, Col, Radio } from "antd";
import { Collapse } from "reactstrap";
import { SearchOutlined } from "@ant-design/icons";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import moment from "moment";
import { useLocation } from "react-router-dom";
function PersonalData({
  title,
  issueDate,
  setIssueDate,
  handleDate,
  birthDate,
  setBirthDate,
  changeType,
  type,
  setType,
  offices,
  governorates,
  form,
  draft,
  isChecker,
  bene,
}) {
  const nationalID = Form.useWatch("national_id", form);
  const location = useLocation();
  const { t } = useTranslation();
  const [toggle] = useState(true);
  const [searchText, setSearchText] = useState("");
  const sendRequest = useCallback(
    async (e) => {
      if (e.key == "Enter") {
        e.preventDefault();
        getUserData();
      }
    },
    [searchText]
  );

  const search = (e) => {
    setSearchText(e.target.value);
  };
  const getUserData = async () => {
    let result = await axiosInstance.get(
      `${
        type === 1
          ? `/SearchEgyptianPerson/${searchText}`
          : type == 2
          ? `/SearchForeignPerson/${searchText}`
          : `/SearchLegalEntity/${searchText}`
      }`
    );
    if (result) {
      console.log(result);
      form.setFieldsValue({
        address: result.data.address,
        name: result.data.name,
        gender: result.data.gender,
        // birth_date:moment(result.data.birth_date,"YYYY-MM-DD"),
        nationality: result.data.nationality,
        // issue_date:moment(result.data.issue_date,"YYYY-MM-DD")
      });
    }
  };
  // useEffect(() => {
  //   console.log("is checker", isChecker);

  if (draft !== "") {
    if (draft.drawee_egyptian !== null) {
      console.log("draft", draft);

      setBirthDate(draft?.drawee_egyptian?.birth_date, "YYYY-MM-DD");
      setType(1);
      form.setFieldsValue({
        address: draft?.drawee_egyptian?.address,
        name: draft?.drawee_egyptian?.name,
        gender: draft?.drawee_egyptian?.gender,
        birth_date: moment(draft?.drawee_egyptian?.birth_date, "YYYY-MM-DD"),
        nationality: draft?.drawee_egyptian?.nationality,
        national_id: draft?.drawee_egyptian?.national_id,
        email: draft?.drawee_egyptian?.email,
        mobile: draft?.drawee_egyptian?.mobile,
        // issue_date:moment(result.data.issue_date,"YYYY-MM-DD")
      });
    } else if (draft.drawee_entity !== null) {
      setType(3);
      form.setFieldsValue({
        entity_name: draft?.drawee_entity?.name,
        tax_id: draft?.drawee_entity?.tax_id,
        commercial_no: draft?.drawee_entity?.commercial_no,
        mobile: draft?.drawee_entity?.mobile,
        address: draft?.drawee_entity?.address,
      });
    } else {
      setType(2);
      form.setFieldsValue({
        passport_id: draft?.drawee_foreign?.passport_id,
        name: draft?.drawee_foreign?.name,
        nationality: draft?.drawee_foreign?.nationality,
        email: draft?.drawee_foreign?.email,
        mobile: draft?.drawee_foreign?.mobile,
        address: draft?.drawee_foreign?.address,
      });
      setIssueDate(draft?.drawee_foreign?.issue_date, "YYYY-MM-DD");
      setBirthDate(draft?.drawee_foreign?.birth_date, "YYYY-MM-DD");
    }
  }
  // }, [draft]);

  useEffect(() => {
    if (
      nationalID !== undefined &&
      nationalID.length === 14 &&
      location.pathname === "/transaction"
    ) {
      form.setFieldsValue({
        birth_date: moment(
          `${nationalID[0] === "2" ? 19 : 20}${nationalID[1]}${nationalID[2]}-${
            nationalID[3]
          }${nationalID[4]}-${nationalID[5]}${nationalID[6]}`
        ),
        gender: nationalID[12] % 2 === 0 ? "2" : "1",
      });
    }
  }, [nationalID]);
  return (
    <div>
      <p className="subTitle">{t("chooseType")}</p>
      <Radio.Group
        disabled={isChecker || bene}
        onChange={(e) => changeType(e, setType)}
        value={type}
      >
        <Radio value={1}>{t("egyptianPerson")}</Radio>
        <Radio value={2}>{t("foreignPerson")}</Radio>
        <Radio value={3}>{t("entityType")}</Radio>
      </Radio.Group>
      <p className="title">{title}</p>
      <p className="typeChoosen">
        {type === 1
          ? `${t("egyptianPerson")}`
          : type === 2
          ? `${t("foreignPerson")}`
          : `${t("entityType")}`}
      </p>
      <div className="formWrapper">
        <p className="subTitle">
          {type === 3 ? t("form.entityData") : t("form.personalData")}
        </p>
        <p className="subHeader">
          {type === 1
            ? t("specifyasNationalID")
            : type === 2
            ? t("specifyasPassport")
            : null}
        </p>

        {type === 3 ? (
          <Form.Item
            label={t("form.commercialReg")}
            name="commercial_no"
            rules={[
              {
                required: title === t("draweeData"),
                message: t("form.requiredCommercial"),
              },
            ]}
          >
            <Input
              disabled={!toggle || isChecker || bene}
              // placeholder="input placeholder"
              onChange={search}
              onKeyPress={sendRequest}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        ) : type === 2 ? (
          <Form.Item
            label={t("form.passportNumber")}
            name="passport_id"
            rules={[
              {
                required: title === t("draweeData"),
                message: t("form.requiredPassport"),
              },
            ]}
          >
            <Input
              disabled={!toggle || isChecker || bene}
              onChange={search}
              onKeyPress={sendRequest}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={t("form.nationalID")}
            name="national_id"
            rules={[
              {
                required: title === t("draweeData"),
                message: t("form.requiredID"),
              },
              {
                pattern: `^[23](?:[0-9]{2}(?:(?:(?:0[13578]|1[02])(?:0[1-9]|[12][0-9]|3[01]))|(?:(?:0[469]|11)(?:0[1-9]|[12][0-9]|30))|(?:02(?:0[1-9]|1[0-9]|2[0-8])))|(?:(?:04|08|[2468][048]|[13579][26]|(?<=3)00)0229))(?:0[1-4]|[12][1-9]|3[1-5]|88)[0-9]{4}[1-9]$`,
                message: `${t("form.wrongID")}`,
              },
            ]}
          >
            <Input
              disabled={!toggle || isChecker || bene}
              onChange={search}
              onKeyPress={sendRequest}
              suffix={<SearchOutlined />}
            />
          </Form.Item>
        )}

        <Collapse isOpen={toggle}>
          {type === 2 ? (
            <Row gutter={16}>
              <>
                {" "}
                <Col span={12}>
                  <Form.Item name="nationality" label={t("form.nationality")}>
                    <Input disabled={isChecker || bene} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="issue_date" label={t("form.issuanceDate")}>
                    <DatePicker
                      style={{ width: "100%" }}
                      value={issueDate}
                      onChange={(e, v) => handleDate(e, v, setIssueDate)}
                      disabled={isChecker || bene}
                    />
                  </Form.Item>
                </Col>{" "}
              </>
            </Row>
          ) : null}
          {type === 3 ? (
            <Form.Item
              label={t("form.taxID")}
              name="tax_id"
              rules={[
                {
                  required: true,
                  message: t("form.requiredTaxId"),
                },
                {
                  pattern: `^[0-9]{3}-[0-9]{3}-[0-9]{3}`,
                  message: `${t("form.wrongFormat")}`,
                },
              ]}
            >
              <Input
                // placeholder="input placeholder"
                // suffix={<Tooltip overlayClassName="conflict-card" title={()=> <> <ReportTooltip data />
                // </>}> <QuestionCircleOutlined /> </Tooltip>}
                disabled={isChecker || bene}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label={t("form.name")}
              name="name"
              rules={[
                {
                  required: type === 1,
                  message: t("form.requiredName"),
                },
                {
                  pattern: `^[\u0621-\u064A ]+$`,
                  message: `${t("form.wrongFormat")}`,
                },
              ]}
            >
              <Input disabled={isChecker || bene} />
            </Form.Item>
          )}

          <Row gutter={16}>
            {type === 3 ? (
              <>
                {" "}
                <Col span={12}>
                  <Form.Item
                    name="entity_name"
                    label={t("form.entityName")}
                    rules={[
                      {
                        required: false,
                        message: t("form.requiredEntity"),
                      },
                    ]}
                  >
                    <Input disabled={isChecker || bene} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="governorate"
                    label={t("form.governorate")}
                    rules={[
                      {
                        required: false,
                        message: t("form.requiredGovernorate"),
                      },
                    ]}
                  >
                    <Select
                      // placeholder="Select a option and change input text above"
                      allowClear
                      disabled={isChecker || bene}
                    >
                      {governorates.map((governorate) => {
                        return (
                          <Select.Option
                            value={governorate.id}
                            key={governorate.id}
                          >
                            {governorate.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>{" "}
              </>
            ) : (
              <>
                {" "}
                <Col span={12}>
                  <Form.Item
                    name="gender"
                    label={t("form.gender")}
                    rules={[
                      {
                        required: false,
                        message: t("form.requiredGender"),
                      },
                    ]}
                  >
                    <Select
                      // placeholder="Select a option and change input text above"
                      allowClear
                      disabled={isChecker || bene}
                    >
                      <Select.Option value="1">{t("form.male")}</Select.Option>
                      <Select.Option value="2">
                        {t("form.female")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="birth_date"
                    label={t("form.birthDate")}
                    rules={[
                      {
                        required: true,
                        message: t("form.requiredBirthDate"),
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder={t("form.selectDate")}
                      style={{ width: "100%" }}
                      value={birthDate}
                      onChange={(e, v) => handleDate(e, v, setBirthDate)}
                      disabled={isChecker || bene}
                    />
                  </Form.Item>
                </Col>{" "}
              </>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t("form.address")}
                name="address"
                rules={[
                  {
                    required: false,
                    message: t("form.requiredAddress"),
                  },
                ]}
              >
                <Input
                  // placeholder="input placeholder"
                  disabled={isChecker || bene}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              {type === 3 ? (
                <Form.Item
                  label={t("form.office")}
                  name="office"
                  rules={[
                    {
                      required: false,
                      message: t("form.requiredOffice"),
                    },
                  ]}
                >
                  <Select disabled={isChecker || bene}>
                    {offices.map((office) => {
                      return (
                        <Select.Option
                          key={office.id}
                          value={office.id}
                          id={office.id}
                        >
                          {office.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={t("form.email")}
                name="email"
                rules={[
                  {
                    required: false,
                    message: t("form.requiredEmail"),
                  },
                ]}
              >
                <Input disabled={isChecker || bene} type="email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("form.mobileNumber")}
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: t("form.requiredPhone"),
                  },
                ]}
              >
                <Input
                  disabled={isChecker || bene}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    ["e", ".", "E", "+", "-"].includes(e.key) &&
                    e.preventDefault()
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Collapse>
        {/* <Row className="text-end">
            {" "}
            {title === `${t("beneficiaryData")}` ? (
              <Button
                style={{ marginBottom: "1rem" }}
                className="skipBtn"
                onClick={onToggle}
              >
                {t("form.skip")}
              </Button>
            ) : null}
            <Button
              color="primary"
              htmlType="submit"
              style={{ marginBottom: "1rem" }}
              className="formBtn"
            >
              {t("form.save")}
            </Button>
          </Row> */}
        {/* </Form> */}
      </div>
    </div>
  );
}

export default ErrorHandler(PersonalData, axiosInstance);
