import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../style-sheets/dashboard.css";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
export default function DashboardCard({ title, color, number, route, user }) {
  return (
    <Container className="dashboardCard">
      <Row>
        <Col sm="12">
          <h5 style={{ color: user ? "#1A5785" : color }} className="subTitle">
            {user ? (
              <Avatar
                style={{ backgroundColor: color }}
                icon={<UserOutlined />}
              />
            ) : null}{" "}
            {title}
          </h5>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <h5 className="title">{number}</h5>
        </Col>
        <Col className="flex-end" sm="6">
          <Link to={route} className="cardArrow">
            <FontAwesomeIcon
              icon={
                localStorage.getItem("i18nextLng") === "en"
                  ? faAngleRight
                  : faAngleLeft
              }
            />
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
