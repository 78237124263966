import {useTranslation} from "react-i18next";
import EscalationForm from "./EscalationForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faCheck} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import {Spin} from "antd";
import {useState} from "react";
import {useSelector} from "react-redux";
import {ToastContainer, toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

function AddEscalation() {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();
    let params = useParams();

    const permission = useSelector((state) => state.auth.user.permissions);

    const addUser = async (values, dispute, transactions) => {
        setLoading(true);

        let reqData = {
            email: values.email,
            name: values.name,
            dispute_days: values.dispute_days === "" ? null : values.dispute_days,
            transactions_days:
                values.transactions_days === "" ? null : values.transactions_days,
            user: params.id ? params.id : null,
            etype:
                (transactions === true) & (dispute === true)
                    ? "3"
                    : (transactions === true) & (dispute === false)
                        ? "2"
                        : (transactions === false) & (dispute === true)
                            ? "1"
                            : (transactions === false) & (dispute === false)
                                ? "4"
                                : "",
        };

        let result = await axiosInstance
            .post(`/EscalationMatrix/`, reqData)
            .catch((err) => {
                // console.log(err)
                console.log(err.response);
                if (err.response && err.response.status !== 500) {
                    for (const error in err.response.data) {
                        console.log(err.response.data[error][0]);
                        setTimeout(() => {
                            toast.error(`${error + " : " + err.response.data[error][0]}`);
                        }, 500);
                    }
                }
                setLoading(false);
            });

        if (result) {
            console.log("EscalationMatrix", result.data.data);
            setTimeout(() => {
                toast.success(t("successfulRequest"));
            }, 500);
            setLoading(false);

            setTimeout(() => {
                permission.includes("account_manager")
                    ? navigate("/banker_escalation_users")
                    : permission.includes("iscore_account_manager")
                        ? navigate("/escalation_users")
                        : navigate("/home");
            }, 1500);
        }
    };
    useEffect(() => {
        console.log("params.id:", params.id);
    }, []);

    return (
        <section>
            <h1 className="pageTitle"> {t("CreateEsclationMatrix")}</h1>

            <div className="sectionHolder p-5">
                <h2 className="currancyFormTitle">{t("userEsclationSettings")}</h2>
                <p className="currancyFormsubTitle">{t("escalationSubtitle")} </p>

                <EscalationForm formId="escalationForm" Func={addUser}/>
            </div>
            <div className="formBtnsHolders">
                <button
                    className="formBtn"
                    onClick={() => {
                        permission.includes("user_create_maker")
                            ? navigate("/home")
                            : navigate(-1);
                    }}
                >
                    <FontAwesomeIcon className="pe-2 ps-2" icon={faTrash}/>{" "}
                    {permission.includes("user_create_maker")
                        ? t("form.skip")
                        : t("form.cancel")}
                </button>
                <button className="formBtn" form="escalationForm" htmlType="submit">
                    <FontAwesomeIcon className="pe-2 ps-2" icon={faCheck}/>
                    {loading ? <Spin/> : t("saveSettings")}
                </button>
            </div>
            <ToastContainer position="bottom-right"/>
        </section>
    );
}

export default ErrorHandler(AddEscalation, axiosInstance);
