import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Button, Spin } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import SuccessMsg from "../../layout/SuccessMsg";
import { useTranslation } from "react-i18next";

function PaymentForm({ info }) {
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const cancel = () => {
    setDisabled(true);
  };

  const save = async (values) => {
    setLoading(true);

    values.operation_type = "edit";
    values.real_id = info.id;
    values.title_ar = info.title_ar;
    values.amount = info.amount;
    values.title = info.title;

    console.log(values);
    let result = await axiosInstance
      .post(`/PaymentConfigsTemp/`, values)
      .catch((err) => console.log(err));

    if (result) {
      setLoading(false);
      setShowSuccess(true);
      setDisabled(true);
    }
  };

  return (
    <div className="formWrapper2 my-4">
      <Container fluid>
        <Row>
          <Col sm="12" md="8">
            <p className="payment-title mb-4">
              {localStorage.getItem("i18nextLng") === "en"
                ? info.title
                : info.title_ar}
              <span className="formSpan">{t("dateParameters")}</span>
            </p>
          </Col>
          <Col className="text-end d-flex align-items-start" sm="12" md="4">
            <span onClick={() => setDisabled(false)} className="paymentEditBtn">
              {t("form.edit")}
            </span>
            <EditOutlined />
          </Col>
        </Row>

        <Form onFinish={save}>
          <Row className="mt-3">
            <Col sm="12" md="6">
              <div class="formContainer">
                <div class="material-textfield">
                  <Form.Item initialValue={info.code} name="code">
                    <Input disabled={disabled} className="formInput" />
                  </Form.Item>

                  <label
                    className={
                      disabled
                        ? "formLabel formLabelDisabled"
                        : "formLabel formLabelActive"
                    }
                  >
                    {t("code")}
                  </label>
                </div>
              </div>
            </Col>
            <Col sm="12" md="6">
              <div class="formContainer">
                <div class="material-textfield">
                  <Form.Item
                    initialValue={info?.issuance_period}
                    name="issuance_period"
                  >
                    <Input disabled={disabled} className="formInput" />
                  </Form.Item>

                  <label
                    className={
                      disabled
                        ? "formLabel formLabelDisabled"
                        : "formLabel formLabelActive"
                    }
                  >
                    {t("issuancePeriod")}
                  </label>
                </div>
              </div>
            </Col>
            {/* <Col sm="12" md="4">
              <div class="formContainer">
                <div class="material-textfield">
                  <Form.Item initialValue={info?.amount} name="amount">
                    <Input disabled={disabled} className="formInput" />
                  </Form.Item>

                  <label className={disabled ? "formLabel formLabelDisabled" : "formLabel formLabelActive"}>{t("amount")}</label>
                </div>
              </div>
            </Col> */}
          </Row>
          <Row>
            <Col className="text-end d-flex" sm="12">
              <Button
                onClick={cancel}
                disabled={disabled}
                className={"cancelBtn"}
              >
                {t("form.cancel")}
              </Button>
              <Button
                htmlType="submit"
                disabled={disabled}
                className={"formBtn"}
              >
                {loading ? <Spin /> : t("form.save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <SuccessMsg
        show={showSuccess}
        hide={() => setShowSuccess(false)}
        text={t("successfulEdit")}
        btnTxt={t("done")}
      />
    </div>
  );
}

export default ErrorHandler(PaymentForm, axiosInstance);
