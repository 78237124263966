import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import DashboardCard from "../layout/DashboardCard";
import SuccessMsg from "../layout/SuccessMsg";
import { Row, Col } from "react-bootstrap";
import RecentTransactions from "./RecentTransactions";
import { Spin, Button, Select } from "antd";
import CheckAuth from "../HOC/CheckAuth.js";
import DoughuntChart from "../charts";
import report1 from "../../images/report1.svg";
import report2 from "../../images/report2.svg";
import report3 from "../../images/report3.svg";
import report4 from "../../images/report4.svg";
import report5 from "../../images/report5.svg";
import ReportCard from "../ReportCard";

const chequesCards = {
    drafts: {
        color: "#999999",
        title: "Drafts",
        title_ar: "مسودات",
        route: "/drafts",
    },
    accepted: {
        color: "#52C41A",
        title: "Accepted",
        title_ar: "مقبولة",
        route: "/accepted_cheques",
    },
    rejected: {
        color: "#FF4D4F",
        title: "Rejected",
        title_ar: "مرفوضة",
        route: "/rejected_cheques",
    },
    pending: {
        color: "#AE7504",
        title: "Pending",
        title_ar: "في الإنتظار",
        route: "/pending_cheques",
    },
    pending_beneficiary: {
        color: "#634710B2",
        title: "Drawer",
        title_ar: "في إنتظار مسحوب منه",
        route: "/drawers",
    },

    pending_drawer: {
        color: "#634710B2",
        title: "Beneficiary",
        title_ar: "في إنتظار مستفيد",
        route: "/beneficiary",
    },
};

const disputesCards = {
    accepted: {
        color: "#52C41A",
        title: "Accepted",
        title_ar: "مقبولة",
        route: "/accepted_disputes",
    },
    rejected: {
        color: "#FF4D4F",
        title: "Rejected",
        title_ar: "مرفوضة",
        route: "/rejected_disputes",
    },
    pending: {
        color: "#AE7504",
        title: "Pending",
        title_ar: "في الإنتظار",
        route: "/pending_disputes",
    },
    under_review: {
        color: "#FAAD14",
        title: "Under Review",
        title_ar: "تحت المراجعة",
        route: "/under_review",
    },
    created: {
        color: "#FAAD14",
        title: "Created",
        title_ar: "أنشأتها",
        route: "/created",
    },
};

const usersCards = {
    accepted: {
        color: "#52C41A",
        title: "Accepted",
        title_ar: "مقبولة",
        entityRoute: "/entity_accepted_users",
        bankRoute: "/bank_accepted_users",
    },
    rejected: {
        color: "#FF4D4F",
        title: "Rejected",
        title_ar: "مرفوضة",
        entityRoute: "/entity_rejected_users",
        bankRoute: "/bank_rejected_users",
    },
    pending: {
        color: "#AE7504",
        title: "Pending",
        title_ar: "في الإنتظار",
        entityRoute: "/entity_pending_users",
        bankRoute: "/bank_pending_users",
    },
};

function Home() {
    let lang = localStorage.getItem("i18nextLng");
    const { t } = useTranslation();

    const reportsCards = [
        {
            icon: report1,
            title: t("sidemenu.detailedUsageReport"),
            route: "/detailed_usage_report",
            permission: ["report_viewer"],
            userType: ["entity", "bank", "iscore"],
        },
        {
            icon: report2,
            title: t("sidemenu.usageInquiryReport"),
            route: "/usage_inquiry_report",
            permission: ["report_viewer"],
            userType: ["entity", "bank", "iscore"],
        },
        {
            icon: report5,
            title: t("invoicingMechanism"),
            route: "/invoice",
            permission: ["report_viewer"],
            userType: "iscore",
        },
        {
            icon: report4,
            title: t("disputeReport"),
            route: "/dispute_report",
            permission: ["report_viewer"],
            userType: "iscore",
        },
    ];

    const [show, setShow] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [chequesChartData, setChequesChartData] = useState([]);
    const [disputesChartData, setDisputesChartData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const hide = () => {
        setShow(false);
    };
    const deleteData = () => {
        let result = axiosInstance
            .delete("/Cheque/")
            .catch((err) => console.log(err));

        if (result) {
            setShow(true);
        }
    };

    const getSummary = async (link) => {
        let chequesData = [];
        let disputesData = [];
        setLoading(true);

        try {
            let result = await axiosInstance
                .get(link)
                .catch((err) => console.log(err));
            setData(result.data);

            setLoading(false);
            if (result.data?.cheques) {
                for (let key in result.data?.cheques) {
                    chequesData.push({
                        type: t(key),
                        value: result.data?.cheques[key],
                    });
                }

                setChequesChartData(chequesData);
            }

            if (result.data?.Disputes) {
                for (let key in result.data?.Disputes) {
                    disputesData.push({
                        type: t(key),
                        value: result.data?.Disputes[key],
                    });
                }
                setDisputesChartData(disputesData);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getTransactions = async () => {
        let result = await axiosInstance.get("/Cheque/");

        if (result) {
            setTransactions(result.data.data);
        }
    };

    useEffect(() => {
        try {
            getSummary(`/Summary/`);
            getTransactions();
        } catch (e) {
            console.log(e);
        }
    }, []);

    // const CheckPermissions = useCheckPermissions(["entity_maker", "tester"]);
    const handleSelectedMonth = (e) => {
        setSelectedMonth(e);
        getSummary(`/Summary/?filter=${e}`);
    };
    return (
        <CheckAuth
            Permissions={[
                "account_manager",
                "report_viewer",
                "transaction_monitor",
                "entity_maker",
                "entity_checker",
                "dispute_monitor",
                "dispute_checker",
                "dispute_starter",
                "dispute_maker",
                "param_editor",
                "user_create_maker",
                "user_create_checker",
                "iscore_account_manager",
            ]}
            userType={["iscore", "entity", "bank"]}
        >
            <div>
                <CheckAuth
                    Permissions={[
                        "transaction_monitor",
                        "entity_maker",
                        "entity_checker",
                        "dispute_monitor",
                        "dispute_checker",
                        "dispute_starter",
                        "dispute_maker",
                        "iscore_account_manager",
                        "account_manager",
                    ]}
                    userType={["iscore", "bank"]}
                >
                    <Row className="my-3">
                        <Col sm="6">
                            <Select
                                value={selectedMonth}
                                onChange={handleSelectedMonth}
                                className="dashboardFilter"
                                placeholder={t("FilterBy")}
                            >
                                <Select.Option value={1}>
                                    {t("LastMonth")}
                                </Select.Option>
                                <Select.Option value={3}>
                                    {t("Last3Month")}
                                </Select.Option>
                                <Select.Option value={6}>
                                    {t("Last6Month")}
                                </Select.Option>
                                <Select.Option value={12}>
                                    {t("Last12Month")}
                                </Select.Option>
                            </Select>
                            <Button className="applyFilterBtn">
                                {t("Apply")}
                            </Button>
                        </Col>
                    </Row>
                </CheckAuth>

                <Row>
                    {reportsCards.map((report) => {
                        return (
                            <CheckAuth
                                key={report.title}
                                Permissions={report.permission}
                                userType={report.userType}
                            >
                                <Col className="mb-4" sm="12" md="6" lg="5">
                                    <ReportCard
                                        icon={report.icon}
                                        title={report.title}
                                        route={report.route}
                                    />
                                </Col>
                            </CheckAuth>
                        );
                    })}
                </Row>

                <CheckAuth
                    Permissions={[
                        "entity_checker",
                        "transaction_monitor",
                        "account_manager",
                        "entity_maker",
                    ]}
                    userType={["iscore", "bank"]}
                >
                    {data && data.cheques ? (
                        <Row className="mb-4">
                            <p className="title mb-4">{t("cheques")}</p>

                            {Object.entries(data.cheques).map((card, index) => {
                                return (
                                    <Col
                                        className="mb-4"
                                        key={index}
                                        sm="12"
                                        md="3"
                                    >
                                        <DashboardCard
                                            title={
                                                lang === "en"
                                                    ? chequesCards[card[0]]
                                                          ?.title
                                                    : chequesCards[card[0]]
                                                          .title_ar
                                            }
                                            color={chequesCards[card[0]]?.color}
                                            number={card[1]}
                                            route={chequesCards[card[0]]?.route}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    ) : loading ? (
                        <Spin />
                    ) : null}
                </CheckAuth>

                <Row className="mb-4">
                    {data && data.Disputes ? (
                        <p className="title mb-4">{t("disputes")}</p>
                    ) : null}
                    {data && data.Disputes ? (
                        Object.entries(data.Disputes).map((card, index) => {
                            return (
                                <Col
                                    className="mb-4"
                                    key={index}
                                    sm="12"
                                    md="3"
                                >
                                    <DashboardCard
                                        title={
                                            lang === "en"
                                                ? disputesCards[card[0]]?.title
                                                : disputesCards[card[0]]
                                                      .title_ar
                                        }
                                        color={disputesCards[card[0]]?.color}
                                        number={card[1]}
                                        route={disputesCards[card[0]]?.route}
                                    />
                                </Col>
                            );
                        })
                    ) : loading ? (
                        <Spin />
                    ) : null}
                </Row>

                <Row className="mb-4">
                    {data && data.EntityUsers ? (
                        <p className="title mb-4">
                            {t("sidemenu.EntityUsers")}
                        </p>
                    ) : null}
                    {data && data.EntityUsers ? (
                        Object.entries(data.EntityUsers).map((card, index) => {
                            return (
                                <Col
                                    className="mb-4"
                                    key={index}
                                    sm="12"
                                    md="3"
                                >
                                    <DashboardCard
                                        title={
                                            lang === "en"
                                                ? usersCards[card[0]]?.title
                                                : usersCards[card[0]].title_ar
                                        }
                                        color={usersCards[card[0]]?.color}
                                        number={card[1]}
                                        route={usersCards[card[0]]?.entityRoute}
                                        user
                                    />
                                </Col>
                            );
                        })
                    ) : loading ? (
                        <Spin />
                    ) : null}
                </Row>

                <Row className="mb-4">
                    {data && data.BankUsers ? (
                        <p className="title mb-4">{t("sidemenu.BankUsers")}</p>
                    ) : null}
                    {data && data.BankUsers ? (
                        Object.entries(data.BankUsers).map((card, index) => {
                            return (
                                <Col
                                    className="mb-4"
                                    key={index}
                                    sm="12"
                                    md="3"
                                >
                                    <DashboardCard
                                        title={
                                            lang === "en"
                                                ? usersCards[card[0]]?.title
                                                : usersCards[card[0]].title_ar
                                        }
                                        color={usersCards[card[0]]?.color}
                                        number={card[1]}
                                        route={usersCards[card[0]]?.bankRoute}
                                        user
                                    />
                                </Col>
                            );
                        })
                    ) : loading ? (
                        <Spin />
                    ) : null}
                </Row>

                {/* ) : null} */}
                <CheckAuth
                    Permissions={[
                        "transaction_monitor",
                        "account_manager",
                        "entity_maker",
                        "entity_checker",
                        "iscore_account_manager",
                    ]}
                    userType={["iscore", "entity", "bank"]}
                >
                    <Row>
                        <Col sm="12">
                            <RecentTransactions data={transactions} />
                        </Col>
                    </Row>
                </CheckAuth>
                <Row className="mt-4">
                    {data && data.cheques ? (
                        <Col sm="3">
                            <DoughuntChart
                                title={t("cheques")}
                                chartLabels={Object.keys(data?.cheques).map(
                                    (label) => chequesCards[label]
                                )}
                                chartData={Object.values(data?.cheques)}
                            />
                            {/* <PieAnt data={chequesChartData} title={t("cheques")} lang={lang} /> */}
                        </Col>
                    ) : null}
                    {data && data.Disputes ? (
                        <Col sm="3">
                            <DoughuntChart
                                title={t("disputes")}
                                chartLabels={Object.keys(data?.Disputes).map(
                                    (label) => disputesCards[label]
                                )}
                                chartData={Object.values(data?.Disputes)}
                            />
                        </Col>
                    ) : null}
                </Row>
                {/* <CheckAuth
          Permissions={["iscore_account_manager"]}
          userType={["iscore"]}
        >
          <Button className="mt-4" type="primary" danger onClick={deleteData}>
            {t("resetDatabase")}
          </Button>
        </CheckAuth> */}
                <SuccessMsg
                    text="Data Deleted Successfully"
                    show={show}
                    hide={hide}
                    subTxt="Data Reset"
                    btnTxt="Ok"
                />
            </div>
        </CheckAuth>
    );
}

export default ErrorHandler(Home, axiosInstance);
