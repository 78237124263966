import React, { useEffect, useState, useCallback } from "react";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Input, Select, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import CheckAuth from "../../HOC/CheckAuth";
import ConfirmRequest from "../../layout/modals/confirmRequest";
import moment from "moment";

function SubjectDisputeForm({ isInvalid, data }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [banks, setBanks] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [existReason, setExistReason] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    delete values.amount;
    delete values.cheque_date;
    delete values.currency_name;
    delete values.bank;
    delete values.bank_branch;
    console.log(values);
    let result = await axiosInstance.post(`/Dispute/`, { ...data, ...values });
    if (result) {
      console.log(result);
      setShow(true);
    }
  };
  const getBanks = async () => {
    getMissingData();
    let result = await axiosInstance.get(`/Bank/?page_size=300`);
    setBanks(result.data.data);
  };
  const getCurrency = async () => {
    let result = await axiosInstance.get("/Currency/?page_size=50");
    setCurrency(result.data.data);
  };
  const getBranches = async () => {
    let result = await axiosInstance.get(`/BankBranch/?bank=${selectedBank}`);
    setBranches(result.data.data);
  };
  const handleBank = (e) => {
    setSelectedBank(e);
  };
  useEffect(() => {
    getCurrency();
  }, []);
  const sendRequest = useCallback(
    async (e) => {
      if (e.key == "Enter") {
        e.preventDefault();
        getMissingData();
      }
    },
    [searchText]
  );
  const search = (e) => {
    setSearchText(e.target.value);
  };
  const getMissingData = async () => {
    let result = await axiosInstance.get(
      `${`/ChequeRejections/${searchText}${
        isInvalid ? "/invalid" : "/subject"
      }`}`
    );
    if (result) {
      console.log(result);
      setExistReason(result.data?.rejections);
      form.setFieldsValue({
        amount: result.data?.amount,
        cheque_date: moment(result.data?.due_date, "YYYY-MM-DD"),
        currency_name: result.data?.currency?.id
      });
    }
  };
  return (
    <div className="my-5">
      <p className="title mb-1">
        {isInvalid ? t("invaliddisputeRequest") : t("subjectdisputerequest")}
      </p>
      <p className="subHeader">{t("disputeSubText")}</p>
      <div className="formWrapper">
        <Row>
          <Col span={12}>
            <p className="subTitle mb-2">{t("transactiondata")}</p>
            {/* {chequeamount} */}
          </Col>
        </Row>
        <Form
          className="invoice"
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          <Form.Item
            label={t("form.chequeNumber")}
            name="cheque"
            rules={[
              {
                required: true,
                message: t("form.chequeNumber")
              }
            ]}
          >
            <Input
              placeholder="Enter Cheque Number here ex: 152487966456"
              type="number"
              onChange={search}
              onKeyPress={sendRequest}
            />
          </Form.Item>{" "}
          <Form.Item name="bank" label={t("form.banks")}>
            <Select
              onChange={handleBank}
              onClick={getBanks}
              style={{ width: "100%" }}
            >
              {banks.map((bank) => {
                return (
                  <Select.Option value={bank.id}>{bank.name}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="bank_branch" label={t("form.selectedBranch")}>
            <Select
              placeholder={t("form.selectedBranch")}
              className="w-100"
              onClick={getBranches}
            >
              {branches.map((branch) => {
                return (
                  <Select.Option value={branch.id}>{branch.name}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={t("form.dueDate")} name="cheque_date">
            <DatePicker disabled />
          </Form.Item>
          <Form.Item placeholder="Amount" label={t("amount")} name="amount">
            <Input disabled />
          </Form.Item>{" "}
          <Form.Item label={t("form.currency")} name="currency_name">
            <Select disabled placeholder={t("form.selectCurrency")}>
              {currency.map((curr) => {
                return (
                  <Select.Option value={curr.id}>{curr.name}</Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {!isInvalid ? (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("form.requiredDate")
                }
              ]}
              label={t("oldreason")}
              name="dispute_reason"
            >
              <Select>
                {existReason.map((reason) => {
                  return (
                    <Select.Option value={`${reason.id}`}>
                      {reason.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : null}
          <Form.Item className="d-flex" name="notes" label={t("form.addNote")}>
            <Input.TextArea placeholder="Add your note over here..." />
          </Form.Item>
          <Row className="text-end">
            {" "}
            <Button
              color="primary"
              htmlType="submit"
              style={{ marginBottom: "1rem" }}
              className="formBtn mt-3"
            >
              {t("submitrequest")}
            </Button>
          </Row>
        </Form>
      </div>
      <ConfirmRequest
        text={
          !isInvalid ? t("subjectdisputerequest") : t("invaliddisputeRequest")
        }
        subTxt={
          !isInvalid
            ? t("feedbackmsg2")
            : t("feedbackmsg3")
        }
        show={show}
        hide={() => setShow(false)}
        btnTxt={t("form.done")}
        color="#52C41A"
        request={() => navigate("/all_disputes")}
      />
    </div>
  );
}

export default ErrorHandler(SubjectDisputeForm, axiosInstance);
