import React from 'react'
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ReleaseModal({show,hide,subTxt,text}) {
  const lang = localStorage.getItem("i18nextLng")
  const { t } = useTranslation();

  return (
        <Modal dir={lang === "en" ? "ltr" : "rtl"} show={show} onHide={hide}>
          <Container className="p-4">
            <Row>
              <Col sm="12">
                <p className="title1">
                  {" "}
                  <i style={{color : "red"}} className="fa fa-exclamation-circle pe-1"></i> {text}
                </p>
                <p>{subTxt}</p>
              </Col>
            </Row>
            <Row>
              <Col className="text-end" sm="12">
              <Button
                  onClick={hide}
                  style={{ borderRadius: "7px" }}
                  className="resetBtn2 mx-2"
                >
                  {t("close")}
                </Button>
                
              </Col>
            </Row>
          </Container>
        </Modal>
  )
}
