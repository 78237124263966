import { Select, Input, Table, Pagination } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ReportConfigurationsPage() {
  const { t } = useTranslation();
  const { Search } = Input;

  const [reportType, SetReportType] = useState("");
  const [search, setSearch] = useState("");
  const [current, setCurrent] = useState(1);

  const onSearch = async (value) => {
    console.log(value);
    setSearch(value);
  };

  let columns = [
    {
      dataIndex: "id",
      title: t("entityName"),
    },
    {
      dataIndex: "id",
      title: t("form.entityType"),
    },
    {
      dataIndex: "id",
      title: t("TypeOfCurrentReport"),
    },
    {
      dataIndex: "id",
      title: t("LastUpdate"),
    },
    {
      dataIndex: "id",
      title: t("ClickToOpen"),
      render: (row) => (
        <Link to="/ReportParametersPage/1">{t("OpentoConfigure")}</Link>
      ),
    },
  ];

  return (
    <main>
      {/* <Link to="/ParamEditorHome">Back</Link> */}

      <div className="formWrapper">
        <div className="d-flex align-items-center justify-content-between py-4">
          <p className="mb-0"> {t("allEntities")}</p>
          <div className="d-flex align-items-start">
            <Select
              style={{ minWidth: "150px" }}
              placeholder={t("sheetType")}
              onChange={(e) => SetReportType(e)}
              value={reportType}
            >
              <Select.Option value={""}>{t("all")}</Select.Option>

              <Select.Option value={1}>{t("form.banks")}</Select.Option>
              <Select.Option value={2}>{t("entities")}</Select.Option>
            </Select>
            <Search
              placeholder={t("searchHere")}
              onSearch={onSearch}
              style={{ width: 200 }}
              className="mx-3"
            />
          </div>
        </div>

        <Table
          pagination={false}
          columns={columns}
          dataSource={[{ id: 1 }, { id: 2 }, { id: 3 }]}
        />
        <div>
          <Pagination
            current={current}
            defaultCurrent={current}
            onChange={(e) => setCurrent(e)}
            total={10}
            showSizeChanger={false}
          />
        </div>
      </div>
    </main>
  );
}
