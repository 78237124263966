import { useTranslation } from "react-i18next";
import { Space, Table, Input, Pagination, Select } from "antd";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CheckAuth from "../../HOC/CheckAuth";
const { Search } = Input;

function EscalationUsers() {
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [entity, setEntity] = useState("");

  const getData = async () => {
    setLoading(true);
    // Currency
    let result = await axiosInstance.get(
      `/EscalationMatrix/?search=${searchKeyword}&entity=${entity}&page=${current}`
    );
    console.log("all Escalation Matrix", result.data.data);
    setdata(result.data.data);
    setCount(result.data.count);
    setLoading(false);
  };

  const handlePages = (page) => {
    setCurrent(page);
  };
  useEffect(() => {
    getData();
  }, [current, searchKeyword, entity]);
  const onSearch = (value) => setSearchKeyword(value);
  const handleEntity = (value) => setEntity(value);

  const columns = [
    {
      title: t("no"),
      dataIndex: "id",
      key: "id",
      fixed: lang === "en" ? "left" : "right"
    },

    {
      title: t("form.entityName"),
      render: (row) => (
        <span>
          {row?.entity?.bank ? row.entity.bank.name : row.entity.entity.name}
        </span>
      )
    },

    {
      title: t("branchName"),
      render: (row) => <span>{row?.entity?.name}</span>
    },
    {
      title: t("form.userName"),
      render: (row) => <span>{row?.name}</span>
    },
    {
      title: t("form.email"),
      render: (row) => <span>{row?.email}</span>
    },

    {
      title: t("form.mobileNumber"),
      render: (row) => <span>{row?.user?.phone}</span>
    },

    {
      title: t("actions"),
      key: "operation",
      fixed: lang === "en" ? "right" : "left",
      render: (row) => (
        <Space>
          <Link
            to={`/edit_escalation/${row.id}`}
            className="actions"
            // onClick={() => {
            //   console.log(e.id);
            // }}
          >
            {t("form.edit")}
          </Link>
        </Space>
      )
    }
  ];

  return (
    <section>
      <h1 className="pageTitle"> {t("EntityUserEsclations")}</h1>

      <div className="sectionHolder">
        <div className="sectionHeader">
          <p className="pageSubTitle"> {t("viewEntityAccountManagers")}</p>

          <div className="tableOptions">
            <Select
              placeholder={"Entity Type "}
              onChange={(e) => handleEntity(e)}
            >
              <Select.Option value={""}>all</Select.Option>

              <Select.Option value={"bank"}>bank</Select.Option>
              <Select.Option value={"entity"}>entity </Select.Option>
            </Select>
            <Search
              placeholder={t("inputSearch")}
              onSearch={onSearch}
              style={{
                width: 200
              }}
            />
            <CheckAuth
              Permissions={["account_manager"]}
              userType={["entity", "bank"]}
            >
              <Link to="/add_escalation" className="addNewCurrencyLink">
                <FontAwesomeIcon className="px-1" icon={faPlus} />
                {t("AddEsclationUser")}
              </Link>
            </CheckAuth>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
        />
        <Pagination
          defaultCurrent={1}
          total={count}
          onChange={(page) => handlePages(page)}
          showSizeChanger={false}
        />
      </div>
    </section>
  );
}

export default ErrorHandler(EscalationUsers, axiosInstance);
