import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const locale = i18n;
locale
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        lng: localStorage.getItem("i18nextLng") === "en" ? "en" : "ar",
        fallbackLng: "ar",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    sidemenu: {
                        home: "Home",
                        bulkUpload: "Bulk Upload",
                        inquiryReport: "Inquiry Report",
                        transaction: "Transaction",
                        createTransaction: "Create Transaction",
                        logout: "Logout",
                        reports: "Reports",
                        draweeReport: "Drawer Report",
                        beneficiaryReport: "Beneficiary Report",
                        usageReport: "Usage Report",
                        settings: "Settings",
                        configurations: "Configurations",
                        customizeReport: "Customize Report",
                        dashboard: "Dashboard",
                        emailConfiguration: "Email Configuration",
                        paymentConfiguration: "Payment Configuration",
                        branchConfiguration: "Branch Configuration",
                        logs: "Logs",
                        invoices: "Invoices",
                        users: "Users",
                        usersCreation: "Users Creation ",
                        AccountManager: " Account Manager",
                        verifyData: "Data Verification",
                        uploadHistory: "Upload History",
                        CurrencyConfiguration: "Currency Configuration",
                        entityUsageReport: "Entity Usage Report",
                        detailedEntityUsageReport:
                            "Detailed Entity Usage Report",
                        consolidatedUsageReport: "Consolidated Usage Report",
                        detailedUsageReport: "Detailed Usage Report",
                        escUsers: "Escalation Users",
                        accountManagers: "Account Managers",
                        BankUsers: "Bank Users",
                        EntityUsers: "Entity Users",
                        usageInquiryReport: "Usage Inquiry Report",
                        ReportConfigurations: "Report Configurations",
                    },
                    oldName: "Current Name",
                    nationality: "Nationality",
                    globalEditError:
                        "This Global Variables already have changes to be confirmed. first wait for the checker to confirm it",
                    NoOfPendingParameters: "No. of Pending Parameters",
                    PendingParametersEntities:
                        "View All Entities have a pending parameters",
                    changedField: "Changed Field",
                    hideSummary: "hide Summary",
                    showSummary: "show Summary",
                    sheetType: "Sheet Type",
                    Collected: "Collected",
                    Rejected: "Rejected",
                    TotalAmount: "Total Amount",
                    Currency: "Currency",
                    beneficiaryState: "Beneficiary State",
                    DataVerification: "Data Verification  ",
                    nameof: "Name of (Entity/Bank)",
                    BulkFilesView: "Bulk Files View",
                    AddEsclationUser: "Add Esclation User",
                    NumberOfDays: "Number Of Days",
                    EntityUserEsclations: "Entity User Escalations",
                    ViewAllUsersEsclation: "View All Users Escalation",
                    fileId: "File id",
                    UploadedDate: "Uploaded Date",
                    UploadedBy: "Uploaded By",
                    Transactions: "Transactions",
                    cheques: "Cheques",
                    disputes: "Disputes",
                    hello: "Hello,",
                    helloText: "Enter Your Credentials here...",
                    login: "Login",
                    welcome: "Welcome to",
                    welcomeText: "Cheques System",
                    chequeDataEntry: "Cheque Data Entry",
                    draweeData: "Drawer Data",
                    chequeData: "Cheque data",
                    beneficiaryData: "Beneficiary Data",
                    chooseType: "Choose Type of Person/Entity",
                    egyptianPerson: "Egyptian Natural Person",
                    foreignPerson: "Foreign Natural Person",
                    entityType:
                        "Egyptian/foreign legal entity operating in Egypt",
                    email: "Email",
                    password: "Password",
                    emailError: "Please input your Email!",
                    validEmail: "Please enter valid email",
                    passwordError: "Please input your password!",
                    captchaError: "Captcha is required",
                    uploadFile: "Upload a file",
                    bulkTransaction: "Bulk Update Transactions",
                    bulkCheque: "Bulk Upload Cheque Book",
                    entities: "entities",
                    templateInstruction:
                        "Download a template, add your data, then upload it here for processing.",
                    templateInstruction1: "You will be able to",
                    templateInstruction2: "View any formatting errors",
                    templateInstruction3: "here",
                    templateInstruction4:
                        "and check a summary for created transactions.",
                    downloadTemplate: "Download a Template",
                    downloadText1: "Click or drag file to this area to upload",
                    downloadText2:
                        "Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files",
                    records: "Records",
                    success: "Success",
                    failed: "Failed",
                    conflicts: "Conflicts",
                    exists: "Exists",
                    updates: "Updates",
                    download: "Download",
                    transactionSubmission: "Transaction Submission",
                    formSubmission: "Submit the full form of transaction",
                    formDraft: "Draft the full form of transaction",
                    deleteTransaction: "Delete  the full form of transaction",
                    chequeDetails: "Checque Details",
                    specifyasNationalID:
                        "Specify exactly as in his national ID",
                    specifyasPassport: "Specify exactly as in his passport",
                    specidyasCheque: "Specify exactly as in his Cheque",
                    viewAllCurrencies: "View All Currencies",
                    inputSearch: "search",
                    viewLog: "View Log",
                    addNewCurrency: "Add New Currency",
                    CreateEsclationMatrix: "Create Esclation Matrix",
                    EditEsclationMatrix: "Edit Esclation Matrix  ",

                    editCurrency: "Edit Currency",
                    discard: "Discard",
                    saveThisCurrency: "Save this Currency",

                    configurationLogsTable: "Configuration logs table",
                    userID: "User ID",
                    changedBy: "Changed by",
                    typeOfChange: "Type of change",
                    changedFrom: "Changed From",
                    ChangedTo: "Changed to",
                    timeStamp: "Timestamp",
                    oldData: "Old Data",
                    newData: "New Data",

                    no: "no",
                    CurrencyCode: "Currency Code",
                    CurrencyNameAr: "  Currency Name (Ar) ",
                    CurrencyNameEn: "Currency Name (En)",
                    AmountInEGP: "Amount In EGP  ",
                    actions: "actions",
                    currencyExchangeRate: "Currency Exchange Rate",
                    currencySettings: "Currency Settings ",
                    currencySettingsSubtitle:
                        "This template file will apply on every report that will be exported. ",
                    Details: "Details",
                    BuildHistorical: "Build Historical",
                    FilterBy: "Filter by",
                    Apply: "Apply",
                    LastMonth: "Last Month",
                    Last3Month: "Last 3 Months",

                    Last6Month: "Last 6 Months",

                    Last12Month: "Last 12 Month",
                    SelectReportDate: "Select Report Date",
                    TransactionsView: "Transactions View",
                    TypeOfCurrentReport: "Type Of Current Report",
                    LastUpdate: "Last Update",
                    OpentoConfigure: "Open to Configure",
                    stander: "stander",
                    Customize: "Customize",
                    ReportParameters: "Report Parameters",
                    ClickToOpen: "Click To Open",

                    SwitchOff: "Switch Off",
                    SwitchOn: "Switch On",
                    SwitchText: {
                        1: "Are you sure you want to change this rejection reason to be",
                        2: "in calculation which affect rejection ratio globally",
                    },
                    included: "included",
                    excluded: "excluded",
                    Parameter: "Parameter",
                    AreYouSureToOn:
                        " Are you sure you change this Report Parameter to be Switchd on from",
                    AreYouSureToOff:
                        " Are you sure you change this Report Parameter to be Switchd off from",

                    bankbranch: " Bank Branch ",
                    globalvariables: "Global Variables",
                    entitybranch: " Entity Branch",
                    entitytype: "Entity Type",
                    rejectionreason: "Rejection Reason ",
                    paymentconfigs: "Payment Configs",
                    configuration: "configuration",
                    emailtemplate: "Email Template",
                    currency: "currency",

                    ParameterType: "Parameter Type",
                    ActionType: "Action Type",
                    ActionDescription: "Action Description",
                    ViewtoCheck: "View to Check",
                    pendingActions: " Pending Actions",
                    acceptedActions: "Approved Actions ",
                    ThisActionWillApplyGloballyforAllEntites:
                        "This Action Will Apply Globally for All Entites",
                    rejectedActions: "Rejected Actions ",
                    MakerName: "Maker Name",
                    Global: "Global",
                    perEntity: "Per Entity",
                    methods: {
                        add: "Add",
                        edit: "Edit",
                        delete: "Delete",
                        remove: "Remove",
                    },
                    changeables: {
                        Bank: "Bank",
                        BankBranch: "Bank Branch",
                        Entity: "Entity",
                        EntityBranch: "Entity Branch",
                        EntityType: "Entity Type",
                        EmailTemplate: "Email Template",
                        RejectionReason: "Rejection Reason",
                        ConversionRate: "Conversion Rate",
                        PaymentConfigs: "Payment Configs",
                        Configuration: "Configuration",
                    },
                    form: {
                        rejectionReasons: "Rejection Reason",
                        wrongFormat: "Wrong Format",
                        name: "Name",
                        password: "Password",
                        userRole: "User Role",
                        gender: "Gender",
                        male: "Male",
                        female: "Female",
                        commercialReg: "Commercial Reg No.",
                        passportNumber: "Passport Number",
                        nationalID: "National ID",
                        wrongID: "Wrong ID Number",
                        nationality: "Nationality",
                        issuanceDate: "Issuance Date",
                        birthDate: "Date of Birth",
                        address: "Address",
                        birthPlace: "Place of Birth",
                        email: "E-mail",
                        mobileNumber: "Mobile Number",
                        bankName: "Bank Name",
                        selectBank: "Select Bank",
                        branch: "Branch",
                        selectBranch: "Select Branch",
                        branchCode: "Branch Code",
                        branchNameEnglish: "Branch Name in English",
                        branchNameArabic: "Branch Name in Arabic",
                        debitAccount: "Debit Account",
                        chequeNumber: "Cheque Number",
                        chequeType: "Cheque Type",
                        currency: "Currency",
                        selectCurrency: "Select Currency",
                        chequeAmount: "Cheque Amount",
                        dueDate: "Due Date",
                        testingDate: "Testing Date",

                        rejected: "Rejected",
                        no: "No",
                        yes: "Yes",
                        creditAccount: "Credit Account",
                        taxID: "Tax ID",
                        governorate: "Governorate",
                        office: "Office",
                        rejectedReason: "Rejection Reason",
                        paidAmount: "Paid Amount",
                        paymentDate: "Payment Date",
                        save: "Save",
                        cancel: "Cancel",
                        skip: "Skip",
                        selectDate: "Select Date",
                        date: "Date",
                        presentedDate: "Presented Date",
                        forgetPass: "Forgot Password",
                        changePass: "Change Password",
                        msg1: "  Please enter your email To send reset it",
                        next: "Next",
                        msg2: "Reset you password link",
                        msg3: "Please check your email inbox to access the link for setting your password",
                        resendLink: "Resend Link",
                        checkBox: "Check your mailbox",
                        msg4: " Enter your name and email to change password",
                        enterNewPass: "Enter New Password ",
                        msg5: "Enter your Current password , new password and repeat it to confirm",
                        newpassword: "New Password",
                        confirmPass: "Confirm New Password",
                        confirmPassError:
                            "The two passwords that you entered do not match!",
                        confirm: "Confirm",
                        requiredID: "'National ID' is required",
                        requiredName: " Name is required",
                        requiredGender: "Gender is required",
                        requiredAddress: "Address is required",
                        requiredBirthDate: "Date of birth is required",
                        requiredBirthPlace: "Place of birth is required",
                        requiredEmail: "Email is required",
                        requiredPhone: "Phone number is required",
                        requiredBankName: "Bank name is required",
                        requiredBranch: "Branch is required",
                        requiredDebitAcc: "Debit Account is required",
                        requiredChequeNumber: "Cheque number is required",
                        requiredCurrency: "Currency is required",
                        requiredChequeAmount: "Cheque amount is required",
                        requiredDueDate: "Due date is required",
                        requiredPaidAmount: "Paid amount is required",
                        requiredCreditAcc: "Credit account is required",
                        rejectedDate: " Rejected Date",
                        requiredPassport: "Passport number is required",
                        requiredPassword: "Password is required",
                        requiredCommercial: "Commercial ID is required",
                        requiredTaxId: "Tax ID is required",
                        requiredEntity: "Entity name is required",
                        requiredEntityType: "Entity type is required",
                        requiredGovernorate: "Governrate is required",
                        requiredOffice: "Office is required",
                        requiredUserRole: "User roles are required",
                        requiredField: "This field is required",
                        entityData: "Entity Data",
                        personalData: "Personal Data",
                        requiredDate: "Date is required",
                        transID: "Transaction ID",
                        userName: "User Name",
                        maxLength: "This field can't exceed 255 char",
                        minLength: "This field can't be less than 3 char",
                        successfulRegister:
                            "User has been registred succesfully!",
                        sendInvetation: "Send invitation",
                        entityName: "Entity Name",
                        selectedBranch: "Branch",
                        requiredtype: "Entity type is required",
                        requiredEntityName: "Entity name is required",
                        enterName: "Enter Name",
                        add: "Add Name",
                        Add: "Add",
                        nameAr: "Name in Arabic",
                        nameEn: " Name in English",
                        accManager: "Account manager",
                        entityType: "Entity Type",
                        creationStamp: "Creation Stamp",
                        modificationStamp: "Modification Stamp",
                        createBranch: "Create Branch",
                        done: "Done",
                        branchCreated: "Branch has been created successfully",
                        emailEdit: "Email has been edited succesfully",
                        edit: "Edit",
                        banks: "Banks",
                        InvoiceMechanismSuccess:
                            "Invoice downloaded successfully",
                        InvoiceMechanismFail: "No Invoice was found",
                        InvoiceMechanismBtn1: "Download as Excel",
                        InvoiceMechanismBtn2: "Download as Text File",
                        viewInvoice: "View Invoice",
                        invoiceDateRange: "Invoice Date Range",
                        servicesTypes: "Types of Services",
                        invoiceText:
                            "Edit and choose parameters then view the invoice file.",
                        profile: "Profile",
                        privilege: "User Privileges",
                        draweeID: "Drawer ID",
                        draweeMobile: "Drawer Mobile no.",
                        draweeBank: "Drawer Bank",
                        draweeType: "Drawer Type",
                        disputeType: "Dispute Type",
                        addNote: "Add Note",
                        rejectionTimes: "Rejection Times",
                        requiredRejTimes: "Rejection times is required",
                        providerID: "Provide ID",
                        draftedBy: "Drafted By",
                        notes: "Notes",
                        disputeID: "Dispute ID",
                        changedBy: "Changed By",
                        requiredCurrencyCode: "Please enter the currency Code",
                        requiredCurrencyNameEn:
                            "Please enter the currency english name",
                        requiredCurrencyNameAr:
                            "Please enter the currency arabic name",
                        requiredCurrencyExchangeRate:
                            "Please enter the currency exchange rate",
                        englishCharactersOnly:
                            "Please enter only english characters",
                        arabicCharactersOnly:
                            "Please enter only arabic characters",
                        draweeName: "Drawer Name",
                        dispute_date: "Dispute created date",
                        disputeReason: "Dispute reason",
                        rejectionHistory: "Rejection reasons history",
                        disputeReject:
                            "Are you sure you want to reject this dispute?",
                        rejectNote: "You have to leave a rejection note",
                        noteHolders: "Add your note over here...",
                        comment: "Comment",
                    },
                    Drawer: "Drawer",
                    wants: "wants to",
                    pendingDrawer: "Beneficiary",
                    noDrawee: "No Drawee",
                    chequeInquiryReport: "Cheque Inquiry Report",
                    viewReport: "View Report",
                    delete: "Delete",
                    resetDatabase: "Reset Database",
                    submit: "Submit",
                    search: "Search",
                    searchPlaceholder: "Search here",
                    disputePoolTable: "Dispute Pool Table",
                    createDispute: "Create Dispute",
                    transactionDispute: "Transaction Dispute",
                    name_Dispute: "Name Dispute",
                    invalidCheque: "Invalid Cheque",
                    disputeType3: "Subject (Rejection reason)",
                    createNewDispute: "Create New Dispute",
                    startDisputeTitle: "Dispute has started succesfully",
                    startDisputeSubtitle: "Name dispute",
                    ok: "Ok",
                    status: "Status",
                    DataBreakdown: "Data Breakdown",
                    UserDetails: "User Details",
                    SearchDetails: "Search Details",
                    ChequeSummary: "Cheque Summary",
                    HistoricalInfo: "Historical Info",
                    Charts: "Charts",
                    RejectedDetails: "Rejected Details",
                    ChequeBook: "Cheque Book",
                    AdditionalInfo: "Additional Info",
                    parameters: "Parameters",
                    action: "Action",
                    successfulRequest: "Successful Request",
                    reportsSettings: "Reports Settings",
                    userCreation: "User Creation",
                    createNewUser: "Create New User",
                    passwordReset: "Password has been reset successfully",
                    branchesConfiguration: "Branches Configurations",
                    createBranchesConfig: "Create Branches Configurations",
                    selectType: "Select Type",
                    selectOption: "Select Option",
                    emailType: "Email Type",
                    emailTitle: "Title",
                    emailMessage: "Email Message",
                    emailConfig: "Email Configuration",
                    editEmail: "Edit Email Template",
                    logs: "Activity Logs",
                    invoice: "Invoicing Mechanism",
                    recentTrans: "Recent Transactions",
                    successfulEdit: "Successful Edit",
                    amount: "Amount",
                    code: "Code",
                    issuancePeriod: "Issuance Period",
                    dateParameters: "(without date parameters)",
                    paymentConfiguration: "Payment Configuration",
                    invoicingMechanism: "Invoicing Mechanism",
                    iscoreInvoicingMechanism: "Iscore Invoicing Mechanism",
                    downloadReport: "Download Report",
                    invoiceText:
                        "Edit and choose parameters that will be automatically add to your email massage.",
                    users: "Users",
                    submitRequest: "Submit Requst",
                    disputeRequest: "Dispute Request",
                    disputeSubText:
                        "Dispute Recorder can fill this data and submit a request.",
                    createDisputeRequest: "Create New Dispute request",
                    newName: "New Name",
                    deleteUser: "Are you sure you want to delete this user?",
                    deleteUserSuccess: "User has been deleted successfully",
                    userDeletion: "User Deletion",
                    createEntity: "Create Entity",
                    entityTypes: "All Entity Types",
                    allEntities: "All Entities",
                    allBranches: "All Branches",
                    createBranch: "Create Branch",
                    editBranch: "Edit Branch",
                    createBank: "Create Bank",
                    filter: "Filter",
                    allBanks: "All Banks Configurations",
                    bankName: "Bank Name",
                    change: "Change",
                    uploadHistory: "Bulk Upload History",
                    id: "ID",
                    dateCreated: "Date Created",
                    dateModified: "Date Modified",
                    footerText: "All rights reserved © 2022",
                    createdBy: "Created By",
                    CreatedBy: "Created By",
                    dataVerification: "All Data Verification",
                    customizeReportTitle: "Customize Inquiry Reports Style",
                    customizeReportSubTitle:
                        "Upload your inquiry Report Template File",
                    customizeReportText:
                        "This template file will apply on every report that will be exported.",
                    upload: "Upload",
                    clickUpload: "Click to Upload",
                    createEntityUsers: "Create Entity Users",
                    noData: "No Data",
                    entityInvoicingMechanism: "Entity Invoicing Mechanism",
                    passwordPolicy:
                        "Password length must be between 8 to 16 characters , also it must contain at least one symbol , lowercase  and uppercase characters",
                    backToLogin: "Back to login page",
                    drafts: "Drafts",
                    acceptedCheques: "Accepted Cheques",
                    rejectedCheques: "Rejected Cheques",
                    pendingCheques: "Pending Cheques",
                    pendingBeneficiary: "Drawers",
                    acceptedDisputes: "Accepted Disputes",
                    rejectedDisputes: "Rejected Disputes",
                    pendingDisputes: "Pending Disputes",
                    underReview: "Under Review",
                    acceptedUsers: "Accepted Users",
                    rejectedUsers: "Rejected Users",
                    pendingUsers: "Pending Users",
                    viewDraft: "View Draft",
                    draftDescription: "View all transactions drafts",
                    review: "Review",
                    accept: "Accept",
                    reject: "Reject",
                    verify: "Verify",
                    view: "View",
                    pending: "Pending",
                    normalCheque: "Normal Cheque",
                    corporateCheque: "Corporate Cheque",
                    certifiedCheque: "Certified Cheque",
                    filterByRole: "Filter by role",
                    clear: "Clear",
                    rowNumber: "Row Number",
                    column: "Column",
                    errorInfo: "Error Info",
                    moreDetails: "More Details",
                    transactionCreated:
                        "Transaction has been created successfully with ID ",
                    draftCreated:
                        "Draft has been created successfully with ID ",
                    branchName: "Branch Name",
                    userMaker: "User Maker",
                    userChecker: "User Checker",
                    drawerInquiry: "Drawer Inquiry",
                    beneficiaryInquiry: "Beneficiary Inquiry",
                    entityName: "Entity Name",
                    generateReport: "Generate Report",
                    reportFilters: "Report Filters",
                    filterReportText:
                        "Adjust your report and export it easily.",
                    startDate: "Start Date",
                    filterByuserType: "Filter by user type",
                    ChequeDraft: "Cheque Draft",
                    created: "Created",
                    endDate: "End Date",
                    acceptChequeTxt:
                        "Are you sure you want to accept this transaction?",
                    rejectionReason: "Rejection Reason",
                    rejectChequeTxt:
                        "Are you sure you want to reject this transaction",
                    permissions: "Permissions",
                    entityAccountManagers: "Entity Account Managers",
                    viewEntityAccountManagers:
                        "View All  Entity Account Managers",
                    addAccountManger: "Add Account Manager",
                    saveSettings: "Save Settings",
                    transactionEscalation: "Transaction Escalation",
                    disputeEscalation: "Dispute Escalation",
                    createEsclationMatrix: "Create Escalation Matrix",
                    userEsclationSettings: "User Escalation Settings",
                    escalationSubtitle:
                        "Fill the user data assigned to esclation with time frame and type of mail.",
                    ViewEntities: "View Entities",
                    ViewBanks: "View Banks",
                    ViewBranches: "View Branches",
                    close: "Close",
                    succesMsg: "Transaction has been accepted successfully",
                    rejectionMsg: "Transaction has been rejected",
                    chequeDraft: "Cheque Draft",
                    accepted: "Accepted",
                    rejected: "Rejected",
                    pending_beneficiary: "Drawers",
                    under_review: "Under Review",
                    fileUploadFailed: "file failed to upload",
                    fileSucessUpload: "file uploaded successfully",
                    checkFile: "This is not an excel file",
                    editedSuccessfully:
                        "Successful edit on transaction number ",
                    acceptedSuccessfully:
                        "Transaction has been accepted successfully",
                    rejectedSuccessfully:
                        "Transaction has been rejected successfully",
                    discardTransaction: "Discard Transaction",
                    solvedConflict: "Conflict Solved Succeffully",
                    release: "Release Number",
                    fileSize: "File must smaller than 5MB!",
                    verifyMsg:
                        "Are you sure that you want to confirm this user?",
                    file: "File",
                    loading: "Loading...",
                    addUser: "Add User",
                    selectAll: "Select All",
                    proceed: "Proceed",
                    nameDispute: "Name dispute request",
                    currname: "Current Name",
                    newname: "Updated Name",
                    submitnamedispute: "Name dispute request submitted",
                    submitrequest: "Submit a request",
                    subjectDispute: "Subject Dispute request",
                    transactiondata: "Transaction Data",
                    oldreason: "Existing Rejection Reasons",
                    choosenew: "Choose Rejection Reason",
                    invaliddisputeRequest: "Invalid Cheque Dispute request",
                    subjectdisputerequest: "Subject Dispute request",
                    wrongCaptcha: "Wrong Captcha",
                    lengthPhone: "This field must be 11 numbers",
                    ReasonCode: "Reason Code",
                    ReasonDescription: "Rejection Reason Description",
                    calculations: "Include Calculations",
                    rejectionConfig: "Rejection Reasons Configurations",
                    allReasons: "All Rejection Reasons",
                    reasonCode: "Reason Code",
                    activity: "Activity",
                    viewDisputeDetails: "View Dispute Details",
                    updateRjec: "Update Rejection Reason",
                    disputeCardTitle: "Information about Create new dispute",
                    disputeInfoStep2:
                        "If you already have a valid report referance id you can skip, then enter the ref. id.",
                    disputeInfoStep1:
                        "You must have export a cheque inquiry report first.",
                    enterRefNum: "Enter Cheque Inquiry reference ID",
                    validRefNum: "It must be a valid reference ID",
                    chequeInquiryData: "Cheque Inquiry Data",
                    backInquiry: "Back to create new inquiry",
                    disputeTip:
                        "(National ID in case of Egyptian person, Passport number in case of foreign person, Tax ID in case of Legal Entity)",
                    feedbackmsg1:
                        "Your Name dispute request submitted successfully, now you can wait for feedback!",
                    feedbackmsg2:
                        "Your Subject dispute request submitted successfully, now you can wait for feedback!",
                    feedbackmsg3:
                        "Your invalid cheque dispute request submitted successfully, now you can wait for feedback!",
                    feedbackmsg4: "Your Subject dispute accepted successfully",
                    acceptUserTxt:
                        "Are you sure that you want to confirm this user?",
                    benifUpload: "Beneficiary",
                    benefCheque: "Bulk Update Beneficiary Data",
                    includeCalc: "Include Calculations",
                    excludeCalc: "Exclude Calculations",
                    all: "All",
                    searchHere: "Search Here",
                    viewLogs: "View Logs",
                    subDisAccepted: "Subject dispute Accepted",
                    disputeOnly: "dispute only",
                    transactionOnly: "transactions only ",
                    transactionDis: "transactions dispute ",
                    off: "Off",
                    escType: "Escalation Type ",
                    disputeReport: "Dispute Report",
                    SelectAll: "Select All",
                    AcceptSelected: "Accept Selected",
                    RejectSelected: "Reject Selected",
                },
            },

            ar: {
                translation: {
                    sidemenu: {
                        home: "الرئيسية",
                        bulkUpload: "تحميل مجمع ",
                        inquiryReport: "تقارير الإستعلام",
                        transaction: "التحويلات",
                        createTransaction: "إنشاء تحويل",
                        logout: "خروج",
                        reports: "التقارير",
                        draweeReport: "تقرير الساحب",
                        beneficiaryReport: "تقرير المستفيد",
                        usageReport: "تقرير الإستخدام",
                        settings: "الإعدادات",
                        configurations: "الإعدادات",
                        customizeReport: "تعديل التقرير",
                        emailConfiguration: "الإعدادات البريد الاكتروني",
                        paymentConfiguration: "الإعدادات الدفع",
                        branchConfiguration: "الإعدادات الفروع",
                        logs: "السجل",
                        invoices: "الفواتير",
                        users: "المستخدمين",
                        usersCreation: "انشاء مستخدم",
                        AccountManager: " انشاء مدير حساب",
                        verifyData: "التحقق من البيانات",
                        uploadHistory: "الملفات المرفوعة",
                        dashboard: "لوحة القيادة",
                        CurrencyConfiguration: "اعدادات العملة",
                        entityUsageReport: "تقرير الاستخدام للمؤسسة",
                        detailedEntityUsageReport:
                            "تقرير الاستخدام المفصل للمؤسسة",
                        consolidatedUsageReport: "تقرير الاستخدام الموحد",
                        detailedUsageReport: "تقرير الاستخدام  المفصل",
                        escUsers: "مستخدمو الماتريكس",
                        accountManagers: "مديروا الحسابات",
                        BankUsers: "مستخدمين البنوك",
                        EntityUsers: "مستخدمين المؤسسات",
                        usageInquiryReport: "تقرير استفسار الاستخدام",
                        ReportConfigurations: " اعدادات التقرير",
                    },
                    oldName: "الاسم الحالي",
                    nationality: "الجنسية",
                    globalEditError:
                        "هناك عملية تغير تحت المراجعة لهذا المتغير العام بالفعل، من فضلك انتظر حتى يتم مرتاجعه.",
                    NoOfPendingParameters: "عدد المتغيرات المعلقة",
                    PendingParametersEntities:
                        "جميع الاكنة التي بها متغيرات معلقة",
                    changedField: "البيانات المتغيرة",
                    wants: "يريد",
                    Drawer: "المسحوب منه",
                    noDrawee: "بدون مسحوب منه ",
                    beneficiaryState: "حالة المستفيد",

                    pendingDrawer: "في إنتظار بيانات مستفيد",
                    UploadedBy: " تم الرفع بواسطة",
                    hideSummary: "اخفاء الملخص",
                    showSummary: "اظهار الملخص",
                    nameof: "اسم الهيئه او البنك",
                    fileId: "رقم الملف ",
                    UploadedDate: "تريخ الرفع",
                    DataVerification: "التحقق من البيانات",
                    Currency: "العمله",
                    Collected: "جمعت",
                    Rejected: "مرفوض",
                    TotalAmount: "المجموع",
                    AddEsclationUser: "إضافة مستخدم Esclation",
                    NumberOfDays: "عدد الايام",
                    Transactions: "المعاملات",
                    EntityUserEsclations: "تصعيدات جميع مستخدمي الهيئه",
                    ViewAllUsersEsclation: "مشاهدة جميع التصعيدات",
                    cheques: "الشيكات",
                    disputes: "الشكاوى",
                    hello: "مرحباً,",
                    helloText: "ادخل بياناتك هنا...",
                    login: "دخول",
                    welcome: "اهلا بك في",
                    welcomeText: "نظام الشيكات",
                    chequeDataEntry: "بيانات الشيك",
                    draweeData: "بيانات الساحب",
                    chequeData: "بيانات الشيك",
                    beneficiaryData: "بيانات المستفيد",
                    chooseType: "اختر نوع الشخص/الكيان",
                    egyptianPerson: "شخص مصرى",
                    foreignPerson: "شخص أجنبى",
                    entityType: "كيان مصرى/أجنبى في مصر",
                    email: "البريد الإلكترونى",
                    password: "كلمة المرور",
                    emailError: "من فضلك ادخل بريدك الإلكترونى!",
                    validEmail: "من فضلك ادخل بريد إلكتروني صحيح",
                    passwordError: "من فضلك ادخل كلمة المرور!",
                    captchaError: "من فضلك ادخل كلمة التحقق",
                    uploadFile: "رفع ملف",
                    bulkTransaction: "تعديل التحويلات المجمعة",
                    bulkCheque: "رفع دفاتر الشيكات المجمعة",
                    entities: "الهيئات",

                    templateInstruction:
                        "حمل النموذج , اضف بياناتك ,ثم قم برفعها هنا للمعالجة.",
                    templateInstruction1: "سيكون بإمكانك",
                    templateInstruction2: "رؤية اي اخطاء",
                    templateInstruction3: "هنا , و",
                    templateInstruction4: "رؤية ملخص للمعاملات المنشأة.",
                    downloadTemplate: "تنزيل النموذج",
                    downloadText1: "قم بالظغط او سحب ملف هنا لرفعه.",
                    downloadText2:
                        "يدعم رفع فردي او مجمع. ممنوع رفع بيانات خاصة بالشركة",
                    noData: "لا يوجد بيانات",
                    records: "سجلات",
                    success: "نجاح",
                    failed: "فشل",
                    conflicts: "تعارض",
                    updates: "تعديلات",
                    exists: "موجودة",
                    download: "تحميل",
                    transactionSubmission: "تثبيت العملية",
                    formSubmission: "تثبيت عملية التحويل بأكملها",
                    formDraft: "وضع التحويل بأكمله في مسودة",
                    deleteTransaction: "مسح عملية التحويل بأكملها",
                    chequeDetails: "تفاصيل الشيك",
                    specifyasPassport: "يتم ملأها كما هي في جواز السفر",
                    specidyasCheque: "يتم ملأها كما هي في الشيك",
                    specifyasNationalID: "يتم ملأه كما فى جواز السفر",
                    viewAllCurrencies: "  عرض جميع العملات  ",
                    inputSearch: "بحث",
                    viewLog: " عرض السجل",
                    addNewCurrency: "إضافة عملة جديدة",
                    CreateEsclationMatrix: "اضافة تصعيد جديد",
                    EditEsclationMatrix: "تعديل تصعيد ",

                    ParameterType: "نوع المتغير",
                    ActionType: "نوع الحدث",
                    ActionDescription: "وصف الحدث ",
                    ViewtoCheck: "اضغط للعرض التفاصيل",
                    pendingActions: "الاحداث المعلقه",
                    acceptedActions: "الاحداث المقبولة",
                    ThisActionWillApplyGloballyforAllEntites:
                        "هذاالفعل سيتم تطبيقه علي جميع الهيئات",

                    rejectedActions: "الاحداث المرفوضه",
                    MakerName: "اسم الفاعل ",
                    Global: "عام",
                    perEntity: "للكيان الواحد ",

                    bankbranch: "فرع البنك ",
                    globalvariables: "المتغيرات العامة",
                    entitybranch: "فرع الهيئه",
                    entitytype: "نوع الهيئه",
                    rejectionreason: "سبب الرفض",
                    paymentconfigs: "اعدادات الدفع",
                    configuration: "العدادات",
                    emailtemplate: "شكل الاميل",
                    currency: "العمله",
                    editCurrency: "تعديل عملية",

                    configurationLogsTable: "جدول سجلات الاعدادات",
                    userID: " رقم المستخدم ",
                    changedBy: "تغيير من قبل ",
                    typeOfChange: "نوع التغيير",
                    changedFrom: "تغير من",

                    ChangedTo: "تغير إلى",
                    timeStamp: "الوقت",

                    discard: "تجاهل",
                    saveThisCurrency: "حفظ العملة ",
                    oldData: "البيانات القديمة",
                    newData: "البيانات الجديدة",
                    no: "الرقم",
                    CurrencyCode: "رمز العملة",
                    CurrencyNameAr: "اسم العمله باللغة العربية ",
                    CurrencyNameEn: "اسم العمله باللغة الانجليزية ",
                    AmountInEGP: "المبلغ بالجنيه المصري",
                    actions: "العمليات",
                    currencyExchangeRate: "سعر صرف العملات ",
                    currencySettings: "اعدادات العملة",
                    currencySettingsSubtitle:
                        "سيتم تطبيق ملف القالب هذا على كل تقرير سيتم تصديره. ",
                    SelectReportDate: "اختار فترة التقرير",
                    BulkFilesView: "عرض الملفات المجمعة",

                    TransactionsView: "عرض المعاملات ",
                    TypeOfCurrentReport: "نوع التقرير",
                    LastUpdate: "اخر تعديل",
                    OpentoConfigure: "فتح العدادات",
                    stander: "عادي",
                    Customize: "معدل",
                    ReportParameters: "متغيرات التقرير",
                    ClickToOpen: "اضغط للفتح",

                    SwitchOff: "غلق",
                    SwitchOn: "فتح",
                    SwitchText: {
                        1: "هل انت متأكد انك تريد تغير سبب الرفض هذا",
                        2: "من العمليات الحسابية التي تؤثر على نسبة الرفض في النظام بأكمله ",
                    },
                    included: "ليُضمن",
                    excluded: "ليُستبعد",
                    Parameter: "المتغير",
                    AreYouSureToOn:
                        "هل أنت متأكد من تغيير معامِل التقرير هذا ليتم تشغيله منه",
                    AreYouSureToOff:
                        "هل أنت متأكد من تغيير معامِل التقرير هذا ليتم غلقه منه",

                    methods: {
                        add: "اضافة",
                        edit: "تعديل",
                        delete: "حذف",
                        remove: "حذف",
                    },
                    changeables: {
                        Bank: "بنك",
                        BankBranch: "فرع الينك",
                        Entity: "كيان",
                        EntityBranch: "فرع الكيان",
                        EntityType: "نوع الكيان",
                        EmailTemplate: "نموذج البريد الالكتروني",
                        RejectionReason: "سبب الرفض",
                        ConversionRate: "معدل التحويل",
                        PaymentConfigs: "إعدادات الدفع",
                        Configuration: "الإعدادات",
                    },

                    form: {
                        wrongFormat: "صيغة خاطئة",
                        name: "الإسم",
                        password: "كلمة المرور",
                        userRole: "أدوار المستخدم",
                        gender: "النوع",
                        commercialReg: "رقم السجل التجارى",
                        passportNumber: "رقم جواز السفر",
                        nationalID: "الرقم القومى",
                        wrongID: "الرقم القومى خاطئ",
                        nationality: "الجنسية",
                        male: "ذكر",
                        female: "أنثى",
                        issuanceDate: "تاريخ الإصدار",
                        birthDate: "تاريخ الميلاد",
                        address: "العنوان",
                        birthPlace: "محل الميلاد",
                        email: "البريد الإلكتروني",
                        mobileNumber: "رقم المحمول",
                        bankName: "اسم البنك",
                        selectBank: "اختر البنك",
                        branch: "الفرع",
                        selectBranch: "اختر الفرع",
                        branchCode: "كود الفرع",
                        branchNameEnglish: "اسم الفرع باللغة الإنجليزية",
                        branchNameArabic: "اسم الفرع باللغة العربية",
                        debitAccount: "حساب المحسوب عليه",
                        chequeNumber: "رقم الشيك",
                        chequeType: "نوع الشيك",
                        currency: "العملة",
                        selectCurrency: "اختر العملة",
                        chequeAmount: "قيمة الشيك",
                        dueDate: "تاريخ الإستحقاق",
                        testingDate: "تاريخ الإختبار",
                        rejected: "مرفوض",
                        no: "لا",
                        yes: "نعم",
                        creditAccount: "حساب المستفيد",
                        taxID: "الرقم الضريبي",
                        governorate: "المحافظة",
                        office: "عنوان المؤسسة",
                        paidAmount: "القيمة المدفوعة",
                        paymentDate: "تاريخ الدفع",
                        save: "حفظ",
                        cancel: "إلغاء",
                        skip: "تخطي",
                        selectDate: "اختر التاريخ",
                        date: "التاريخ",
                        presentedDate: "تاريخ التقديم",
                        forgetPass: "نسيت كلمة المرور",
                        changePass: "تغير كلمة المرور",
                        msg1: "من فضلك أدخل البريد الالكترونى ",
                        next: "التالى",
                        msg2: "رابط استعادة كلمة المرور",
                        msg3: "تحقق من صندوق البريد الخاص بك للحصول على رابط إعادة تعيين كلمة المرور الخاصة بك.",
                        resendLink: "إعادة إرسال الرابط",
                        checkBox: "تحقق من صندوق الوارد",
                        msg4: "أدخل الاسم والبريد الالكترونى ل تغير كلمة السر",
                        enterNewPass: "ادخل كلمة مرور جديدة",
                        msg5: "أدخل كلمة المرور الحالية وكلمة المرور الجديدة وكررها للتأكيد",
                        newpassword: "كلمة مرور جديدة",
                        confirmPass: "تأكيد كلمة المرور الجديدة",
                        confirmPassError: "كلمتي المرور غير متطابقتين!",
                        confirm: "تأكيد",
                        requiredID: "الرقم القومى مطلوب",
                        requiredName: "الاسم مطلوب",
                        requiredGender: "النوع مطلوب",
                        requiredAddress: "العنوان مطلوب",
                        requiredBirthDate: "تاريخ الميلاد مطلوب",
                        requiredBirthPlace: "محل الميلاد مطلوب",
                        requiredEmail: "البريد الالكترونى مطلوب",
                        requiredPhone: "رقم الهاتف مطلوب",
                        requiredBankName: "اسم البنك مطلوب",
                        requiredBranch: "اسم الفرع مطلوب",
                        requiredDebitAcc: "حساب المسحوب عليه مطلوب",
                        requiredChequeNumber: "رقم الشيك مطلوب",
                        requiredCurrency: "رقم العمله مطلوب",
                        requiredChequeAmount: "قيمة الشيك مطلوبة",
                        requiredDueDate: "الموعد النهئى مطلوب",
                        requiredPaidAmount: "قيمة الدفع مطلوبة",
                        requiredCreditAcc: "حساب المستفيد مطلوب",
                        rejectedDate: "تاريخ الرفض",
                        rejectedReason: "سبب الرفض",
                        requiredPassport: "رقم جواز السفر مطلوب",
                        requiredPassword: "كلمة المرور مطلوبة",
                        requiredCommercial: "الرقم التجاري مطلوب",
                        requiredTaxId: "الرقم الضريبى مطلوب",
                        requiredEntity: "اسم المؤسسه مطلوب",
                        requiredGovernorate: "المحافظه مطلوبه",
                        requiredOffice: "عنوان المؤسسه مطلوب",
                        requiredUserRole: "أدوار المستخدم مطلوبة",
                        requiredField: "هذا الحقل مطلوب",
                        entityData: "بيانات الشخص",
                        personalData: "البيانات الشخصية",
                        requiredDate: "التاريخ مطلوب",
                        rejectionReasons: "أسباب الرفض",
                        transID: "رقم العملية",
                        userName: "إسم المستخدم",
                        maxLength: "هذا الحقل لا يمكن ان يتعدى 255 حرف",
                        minLength: "هذا الحقل يجب الا يقل عن 3 حروف",
                        successfulRegister: "تم إنشاء مستخدم جديد بنجاح",
                        sendInvetation: "أرسال الدعوة",
                        entityType: "نوع الكيان",
                        entityName: "اسم الكيان",
                        selectedBranch: "الفرع",
                        requiredtype: "نوع الكيان مطلوب",
                        requiredEntityName: "اسم الكيان مطلوب",
                        enterName: "ادخل الاسم",
                        add: "إضافة",
                        Add: "اضافة",
                        nameAr: "الاسم بالعربية",
                        nameEn: "الاسم بالانجليزية",
                        accManager: "مدير الحساب",
                        creationStamp: "تاريخ الإنشاء",
                        modificationStamp: "تاريخ التعديل",
                        createBranch: "إنشاء فرع",
                        done: "تم",
                        branchCreated: "تم إنشاء الفرع بنجاح",
                        emailEdit: "تم تعديل النموذج بنجاح",
                        edit: "تعديل",
                        banks: "البنوك",
                        InvoiceMechanismSuccess: "تم تنزيل الفاتورة بنجاح",
                        InvoiceMechanismFail: "لا يوجد فاتورة متاحة",
                        InvoiceMechanismBtn1: "تحميل الفاتورة اكسيل",
                        InvoiceMechanismBtn2: "تحميل ك ملف كتابى",
                        viewInvoice: "عرض الفاتورة",
                        invoiceDateRange: "تاريخ الفاتورة",
                        servicesTypes: "نوع الخدمات",
                        invoiceText:
                            "عدل و اختر الالإختيارات ثم قم برؤية ملف الفاتورة",
                        profile: "الحساب",
                        privilege: "الصلاحيات المستخدم",
                        draweeID: "رقم الساحب",
                        draweeMobile: "رقم تليفون الساحب",
                        draweeBank: "بنك الساحب",
                        draweeType: "نوع الساحب",
                        disputeType: "نوع الشكوي",
                        addNote: "إضافة ملاحظة",
                        rejectionTimes: "مرات الرفض",
                        requiredRejTimes: "عدد مرات الرفض مطلوبه",
                        providerID: "كود ال provider",
                        draftedBy: "صيغ بواسطة",
                        notes: "ملاحظات",
                        disputeID: "رقم الشكوي",
                        changedBy: "اخر تغيير",
                        requiredCurrencyCode: "برجاء ادخال كود العملة",
                        requiredCurrencyNameEn:
                            "برجاء ادخال اسم العملة باللغه الانجليزية",
                        requiredCurrencyNameAr:
                            "برجاء ادخال اسم العملة باللغه العربيه",
                        requiredCurrencyExchangeRate:
                            "برجاء ادخال سعر صرف العملة ",
                        englishCharactersOnly: "برجاء ادخال حروف انجليزي فقط ",
                        arabicCharactersOnly: "برجاء ادخال حروف عربي فقط",
                        draweeName: "اسم الساحب",
                        dispute_date: "تاريخ انشاء النزاع",
                        disputeReason: "سبب النزاع",
                        rejectionHistory: "سجل اسباب الرفض",
                        disputeReject: "هل انت متاكد من رغبتك في رفض النزاع؟",
                        rejectNote: "يجب عليك ترك ملاحظة قبل الرفض",
                        noteHolders: "اضف ملاحظتك هنا...",
                        comment: "تعليق",
                    },

                    change: "التغير",
                    Details: "تفاصيل",
                    BuildHistorical: "اصدار تقرير قديم ",
                    chequeInquiryReport: "تقارير إستعلام الشيكات",
                    viewReport: "عرض التقرير",
                    delete: "مسح",
                    resetDatabase: "مسح قاعدة البيانات",
                    submit: "تثبيت",
                    disputeReport: "تقرير الجدالات",
                    search: "بحث",
                    searchPlaceholder: "ابحث هنا",
                    disputePoolTable: "عرض جميع الشكاوي",
                    createDispute: "انشاء شكوي",
                    transactionDispute: "شكوي عملية",
                    name_Dispute: "شكوي اسم",
                    invalidCheque: "شيك غير صحيح",
                    disputeType3: "محتوى (سبب الرفض)",
                    createNewDispute: "إنشاء شكوي جديد",
                    startDisputeTitle: "تم بدأ الشكوي بنجاح",
                    startDisputeSubtitle: "عملية شكوي اسم",
                    ok: "موافق",
                    DataBreakdown: "تحليل البيانات",
                    UserDetails: "تفاصيل المستخدم",
                    SearchDetails: "تفاصيل البحث",
                    ChequeSummary: "تفاصيل الشيك",
                    HistoricalInfo: "المعلومات السابقة",
                    Charts: "الرسوم البيانية",
                    RejectedDetails: "تفاصيل الرفض",
                    ChequeBook: "دفتر الشيكات",
                    AdditionalInfo: "معلومات إضافية",
                    parameters: "المتغيرات",
                    action: "الإجراء",
                    successfulRequest: "تمت العملية بنجاح",
                    reportsSettings: "إعدادات التقارير",
                    userCreation: "إنشاء مستخدم",
                    createNewUser: "إنشاء مستخدم جديد",
                    passwordReset: "تم تغيير كلمة المرور بنجاح",
                    branchesConfiguration: "إعدادات الفروع",
                    createBranchesConfig: "إنشاء إعدادات الفروع",
                    selectType: "اختر النوع",
                    selectOption: "حدد اختيارك",
                    emailType: "نوع الإيميل",
                    emailTitle: "العنوان",
                    emailMessage: "نص الإيميل",
                    emailConfig: "تعديل الإيميل",
                    editEmail: "تعديل نموذج الإيميل",
                    logs: "سجل النشاطات",
                    invoice: "نظام الفواتير",
                    recentTrans: "المعاملات الاخيرة",
                    successfulEdit: "تم التعديل بنجاح",
                    amount: "القيمة",
                    code: "الكود",
                    issuancePeriod: "فترة الإصدار",
                    dateParameters: "(بدون متغيرات التواريخ)",
                    paymentConfiguration: "إعدادات الدفع",
                    invoicingMechanism: "نظام الفواتير",
                    iscoreInvoicingMechanism: "نظام فواتير ",
                    downloadReport: "تحميل التقرير",
                    invoiceText:
                        "قم بتعديل واختيار المتغيرات وسوف تضاف تلقائيا الى رسائل بريدك الالكترونى",
                    users: "المستخدمون",
                    submitRequest: "تثبيت الطلب",
                    disputeRequest: "طلب شكوي",
                    disputeSubText:
                        "مسجل الشكوي يمكنه ملئ هذه البيانات و تثبيت الطلب.",
                    createDisputeRequest: "إنشاء طلب شكوي جديد",
                    newName: "الاسم الجديد",
                    deleteUser: "هل انت متأكد انك تريد مسح هذا المستخدم؟",
                    deleteUserSuccess: "تم مسح المستخدم بنجاح",
                    userDeletion: "مسح مستخدم",
                    createEntity: "انشاء كيان",
                    entityTypes: "جميع انواع الكيانات",
                    allEntities: "جميع الكيانات",
                    createBranch: "انشاء فرع",
                    allBranches: "جميع الفروع",
                    editBranch: "تعديل الفرع",
                    createBank: "انشاء بنك",
                    filter: "تصفية",
                    allBanks: "جميع البنوك",
                    bankName: "اسم البنك",
                    entityInvoicingMechanism: "نظام الفواتير",
                    passwordPolicy:
                        "كلمه المرور يجيب ان تحتوي علي الاقل من 8 ل 16 احرف , رمز واحد على الاقل و احرف كبيره وصغيره",
                    backToLogin: "رجوع للصفحه الرئيسيه",
                    entityName: "اسم المؤسسة",
                    generateReport: "إنتاج تقرير",
                    reportFilters: "فلتر التقارير",
                    filterReportText: "عدل تقريرك و استخرجه بسهولة",
                    startDate: "تاريخ البداية",
                    endDate: "تاريخ النهاية",
                    ChequeDraft: "مسوده الشيك",
                    acceptedCheques: "شيكات مقبولة",
                    rejectedCheques: "شيكات مرفوضة",
                    pendingCheques: "شيكات قيد الإنتظار",
                    pendingBeneficiary: "في إنتظار بيانات مسحوب منه",
                    acceptedDisputes: "شكاوى مقبولة",
                    rejectedDisputes: "شكاوى مرفوضة",
                    pendingDisputes: "شكاوى قيد الإنتظار",
                    underReview: "تحت المراجعة",
                    acceptedUsers: "مستخدمين مقبولين",
                    rejectedUsers: "مستخدمين مرفوضين",
                    pendingUsers: "مستخدمين قيد الإنتظار",
                    viewDraft: "عرض المسودة",
                    draftDescription: "عرض جميع مسودات المعاملات",
                    review: "مراجعة",
                    accept: "قبول",
                    reject: "رفض",
                    verify: "تأكيد",
                    view: "عرض",
                    pending: "قيد الإنتظار",
                    normalCheque: "شيك عادي",
                    corporateCheque: "شيك شركة",
                    preapprovedCheque: "شيك مسبوق الدفع",
                    uploadHistory: "الملفات المرفوعة سابقاً",
                    id: "الرقم التسلسلى",
                    dateCreated: "تاريخ الإنشاء",
                    dateModified: "تاريخ التعديل",
                    footerText: "All rights reserved © 2022",
                    createdBy: "المنشئ",
                    CreatedBy: "المنشئ",
                    dataVerification: "العمليات قيد التأكيد",
                    customizeReportTitle: "تعديل شكل تقارير الإستعلام",
                    customizeReportSubTitle: "قم برفع نموذج تقرير الإستعلام",
                    customizeReportText:
                        "هذا النموذج سيتم تطبيقه علي كل تقرير يتم استخراجه",
                    upload: "رفع",
                    clickUpload: "اضغط للرفع",
                    createEntityUsers: "إنشاء مستخدمين ",
                    filterByRole: "تصفية بالصلاحية",
                    clear: "إعادة ضبط",
                    rowNumber: "رقم الصف",
                    column: "عمود",
                    errorInfo: "معلومات الخطأ",
                    status: "الحالة",
                    moreDetails: "تفاصيل إضافية",
                    transactionCreated: "تم إنشاء عملية بنجاح برقم ",
                    draftCreated: "تم إنشاء مسودة بنجاح برقم ",
                    branchName: "اسم الفرع",
                    userMaker: "مستخدم صانع",
                    userChecker: "مستخدم فاحص",
                    drawerInquiry: "المستعلم عن المسحوب عليه",
                    beneficiaryInquiry: "المستعلم عن المستفيد",
                    acceptChequeTxt: "هل انت متأكد انك تريد قبول هذا الشيك؟",
                    rejectionReason: "سبب الرفض",
                    rejectChequeTxt: "هل انت متأكد انك تريد رفض هذا الشيك؟",
                    created: "أنشأتها",
                    permissions: "صلاحيات المستخدم",
                    entityAccountManagers: "مديري حسابات المؤسسات",
                    viewEntityAccountManagers: "عرض جميع مديري الحسابات",
                    addAccountManger: "إضافة مدير حساب",
                    transactionEscalation: "تصعيد عملية",
                    disputeEscalation: "تصعيد شكوى",
                    createEsclationMatrix: "إنشاء مصفوفة تصعيد",
                    userEsclationSettings: "ضبط عملية التصعيد للمستخدم",
                    ViewEntities: "عرض الكيانات",
                    ViewBanks: "عرض البنوك",
                    ViewBranches: "عرض الفروع",
                    close: "اغلاق",
                    succesMsg: "تم قبول العمليه بنجاح",
                    rejectionMsg: "تم رفض العمليه بنجاح",
                    chequeDraft: "مسوده الشيك",
                    certifiedCheque: "شيك معتمد",
                    saveSettings: "حفظ الاختيارات",
                    Apply: "طبق",
                    FilterBy: "تصفية",
                    LastMonth: "اخر شهر",
                    Last3Month: "اخر 3 شهور",

                    Last6Month: "اخر 6 شهور",

                    Last12Month: "اخر 12 شهر",
                    escalationSubtitle:
                        "املأ البيانات الخاصة بالمستخدم المعين للتصعيد بالوقت و البريد الإلكتروني.",
                    filterByuserType: "تصفيه بنوع المستخدم",
                    accepted: "مقبول",
                    rejected: "مرفوض",
                    pending_beneficiary: "المسحوب منهم",
                    drafts: "مسودات",
                    under_review: "تحت المراجة",
                    fileUploadFailed: "فشل تحميل الملف",
                    fileSucessUpload: "تم تحميل الملف بنجاح",
                    checkFile: "هذا ليس ملف اكسيل",
                    editedSuccessfully: "تم التعديل بنجاح علي العملية رقم ",
                    acceptedSuccessfully: "تم قبول العملية بنجاح",
                    rejectedSuccessfully: "تم رفض العملية بنجاح",
                    discardTransaction: "تجاهل",
                    solvedConflict: "تم حل التعارض بنجاح",
                    release: "رقم الاصدار",
                    fileSize: "يجب ان يقل حجم الملف عن 5 ميجابايت",
                    verifyMsg: "هل انت متأكد انك تريد تأكيد هذا المستخدم؟",
                    file: "الملف",
                    loading: "جاري التحميل...",
                    addUser: "إضافة مستخدم",
                    selectAll: "اختر الكل",
                    proceed: "استكمال",
                    nameDispute: "",
                    submitnamedispute: "تسجيل تعارض الاسم",
                    submitrequest: "تسجيل الطلب",
                    subjectDispute: "طلب تعارض الموضوع",
                    transactiondata: "بيانات العملية",
                    oldreason: "اسباب موجودة مسبقا",
                    choosenew: "اختر سبب الرفض",
                    invaliddisputeRequest: "طلب نزاع شيك غير صالح",
                    subjectdisputerequest: "طلب نزاع موضوع",
                    wrongCaptcha: "كلمة التحقق خاطئة",
                    lengthPhone: "هذا الحقل لا يجب ان يكون 11 رقم",
                    ReasonCode: "كود السبب",
                    ReasonDescription: "وصف السبب",
                    calculations: "شمول الحساب",
                    rejectionConfig: "اعدادات اسباب الرفض",
                    allReasons: "كل الاسباب",
                    reasonCode: "كود السبب",
                    activity: "النشاط",
                    viewDisputeDetails: "عرض تفاصيل النزاع",
                    updateRjec: "سبب النزاع الحديث",
                    disputeCardTitle: "معلومات عند انشاء طلب نزاع جديد",
                    disputeInfoStep1: "يجب اصدار تقرير استعلام عن الشيك اولا",
                    disputeInfoStep2:
                        "اذا كان لديك معرف احالة تقرير صالح يمكنك تخطى تلك الخطوة ثم ادخل الرقم التعريفى",
                    enterRefNum: "أدخل المعرّف المرجعي للاستعلام عن الشيك",
                    validRefNum: "يجب ان يكون رقم تعريفي صالح",
                    chequeInquiryData: "تحقق من بيانات الاستفسار",
                    backInquiry: "عودة الي انشاء استعلام جديد",
                    disputeTip:
                        "(الرقم القومي في حالة الشخص المصري ، رقم جواز السفر في حالة الشخص الأجنبي ، المعرف الضريبي في حالة الكيان القانوني)",
                    feedbackmsg1:
                        "تم إرسال طلب اعتراض الاسم الخاص بك بنجاح ، يمكنك الآن انتظار التعليقات!",
                    feedbackmsg2:
                        "تم إرسال طلب الاعتراض على الموضوع بنجاح ، يمكنك الآن انتظار التعليقات!",
                    feedbackmsg3:
                        "تم إرسال طلب الاعتراض على الشيكات غير الصالحة بنجاح ، يمكنك الآن انتظار التعليقات!",
                    feedbackmsg4: "تم قبول طلب الاعنراض على الموضوع بنجاح",
                    acceptUserTxt: "هل تريد تأكيد هذا المستخدم؟",
                    benifUpload: "المستفيد",
                    benefCheque: "تحديث مجمّع لبيانات المستفيد",
                    sheetType: "نوع الملف",
                    includeCalc: "تشمل العمليات الحسابية",
                    excludeCalc: "استبعاد العمليات الحسابية",
                    all: "الجميع",
                    searchHere: "ابحث هنا",
                    viewLogs: "عرض السجلات",
                    subDisAccepted: "قبول نزاع الموضوع",
                    disputeOnly: "النزاع فقط",
                    transactionOnly: "المعاملات فقط",
                    transactionDis: "نزاع المعاملات",
                    off: "الغاء",
                    escType: "نوع التصعيد",
                    SelectAll: "اختيار الكل",
                    AcceptSelected: "قبول المحدد",
                    RejectSelected: "رفض المحدد",
                },
            },
        },
    });

export default locale;
