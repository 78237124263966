import React, { useState, useCallback } from "react";
import { Select } from "antd";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";

function CustomSelect({
  data,
  setData,
  setSelected,
  selected,
  next,
  setNext,
  label,
  placeholder,
  getData,
  multiple,
  disabled,
}) {
  const lang = localStorage.getItem("i18nextLng");
  const [loadMore, setLoadMore] = useState(true);
  const { t } = useTranslation();

  const search = async (value) => {
    // setSearchTerm(value)
    let result = await axiosInstance.get(`/FilterSeller/?search=${value}`);
    if (result) {
      setData(result.data.data);
      setNext(result.data.next);
    }
    console.log(value);
  };

  const onScroll = useCallback(async (event) => {
    var target = event.target;

    let result;
    if (
      loadMore &&
      Math.round(target.scrollTop) + target.offsetHeight === target.scrollHeight
    ) {
      result = await axiosInstance
        .get(`${next}`)
        .catch((err) => console.log(err));

      if (result) {
        setData([...data, ...result.data.data]);
        setNext(result.data.next);
        if (result.data.next === null) {
          setLoadMore(false);
        }
      }
    }
  });

  const handleChange = (e, v) => {
    console.log(e);
    console.log(v);
    setSelected(e);
  };

  const onChange = (e, arr, setStatFun) => {
    if (e.includes("SelectAll")) {
      arr.map((val) => setStatFun((selectedItem) => [...selectedItem, val.id]));
    } else if (e.includes("UnselectAll")) {
      setStatFun([]);
    } else {
      setStatFun(e);
    }
  };

  return (
    <div dir={lang === "en" ? "ltr" : "rtl"} className="">
      {label ? <label>{label}</label> : null}

      <Select
        disabled={disabled}
        onClick={getData}
        placeholder={placeholder}
        mode={"multiple"}
        onPopupScroll={onScroll}
        onChange={(e) => onChange(e, data, setSelected)}
        value={selected}
        showSearch
        onSearch={search}
        className="mb-3 w-100"
      >
        {selected.length === 0 ? (
          <Select.Option value="SelectAll">{t("SelectAll")}</Select.Option>
        ) : (
          <Select.Option value="UnselectAll">{t("UnselectAll")}</Select.Option>
        )}
        {data.map((element) => {
          return (
            <Select.Option key={element.id} value={element.id}>
              {`${element.name ?? element.user.name} ${element.name_ar ?? ""}`}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

export default ErrorHandler(CustomSelect, axiosInstance);
