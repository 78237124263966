import {Tabs, Pagination, Table, Space, Select} from "antd";
import React, {useState, useCallback, useEffect} from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {useTranslation} from "react-i18next";
import CheckAuth from "../HOC/CheckAuth";
import {Link} from "react-router-dom";
import ChequesFilter from "../ChequesFilter";
import {useSelector} from "react-redux";

const {TabPane} = Tabs;

function CollapseData({record}) {
    const {t} = useTranslation();

    return (
        <>
      <span className="mx-3">
        {t("form.branch")} : {record.drawer_branch.name}
      </span>
            <span className="mx-3">
        {t("form.presentedDate")} : {record.presented_date}
      </span>
            <span className="mx-3">
        {t("form.chequeType")} : {record.cheque_type}
      </span>
            {record.state === "2" ? (
                <span className="mx-3">
          {t("form.rejectionReasons")} : {record.rejection_reason}
        </span>
            ) : null}
        </>
    );
}

function DataVerification() {
    const {t} = useTranslation();
    let lang = localStorage.getItem("i18nextLng");
    const user = useSelector((state) => state.auth.user);
    const userType = user.user_type;
    const permissions = user.permissions;
    const [data, setData] = useState([]);
    const [type, setType] = useState("egyptian");
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState("");
    const [searchText, setSearchText] = useState("");
    const [selectedBranch, setBranch] = useState("");
    const [branches, setBranches] = useState([]);
    const [chequeqType, setChequeType] = useState("");
    const [current, setCurrent] = useState(1);
    const [selectedRow, setSelectedRow] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chequeqType2, setChequeType2] = useState("accepted");

    console.log({user})
    let columns = [
        {
            key: "id",
            title: t("form.transID"),
            dataIndex: "id",
        },
        {
            key: "name",
            title: t("form.name"),
            dataIndex:
                type === "egyptian"
                    ? "drawee_egyptian"
                    : type === "foreign"
                        ? "drawee_foreign"
                        : "drawee_entity",
            render: (row) => row?.name,
        },
        {
            key: "type",
            title: t("form.draweeType"),
            render: (row) =>
                row?.drawee_egyptian
                    ? t("egyptianPerson")
                    : row?.drawee_entity
                        ? t("entityType")
                        : t("foreignPerson"),
        },
        {
            key: "id",
            title: t("id"),
            dataIndex:
                type === "egyptian"
                    ? "drawee_egyptian"
                    : type === "foreign"
                        ? "drawee_foreign"
                        : "drawee_entity",
            render: (row) =>
                type === "egyptian"
                    ? row?.national_id
                    : type === "foreign"
                        ? row?.passport_id
                        : row?.tax_id,
        },

        {
            key: "cheque_number",
            title: t("form.chequeNumber"),
            dataIndex: "cheque_number",
        },
        {
            key: "cheque_type",
            title: t("form.chequeType"),
            dataIndex: "cheque_type",
            render: (row) =>
                row === "normal cheque"
                    ? t("normalCheque")
                    : row === "corporate cheque"
                        ? t("corporateCheque")
                        : t("certifiedCheque"),
        },
        {
            key: "cheque_amount",
            title: t("form.chequeAmount"),
            dataIndex: "cheque_amount",
        },
        {
            key: "paid_amount",
            title: t("form.paidAmount"),
            dataIndex: "paid_amount",
        },
        {
            key: "currency",
            title: t("form.currency"),
            dataIndex: "currency",
            render: (row) => row?.name,
        },
        {
            key: "status",
            title: t("status"),
            dataIndex: "state",
            render: (row) =>
                row === "3" ? (
                    <span className="pending">{t("pending")}</span>
                ) : row === "2" ? (
                    <span className="rejected">{t("reject")}</span>
                ) : (
                    <span className="accepted">{t("accept")}</span>
                ),
        },

        {
            key: "status",
            title: t("beneficiaryState"),
            dataIndex: "beneficiary_state",
            render: (row) =>
                row === "3" ? (
                    <span className="pending">{t("pending")}</span>
                ) : row === "2" ? (
                    <span className="rejected">{t("reject")}</span>
                ) : (
                    <span className="accepted">{t("accept")}</span>
                ),
        },
        {
            key: "created_by",
            title: t("createdBy"),
            dataIndex: "created_by",
            render: (row) => row?.name,
        },
        {
            key: "dueDate",
            title: t("form.dueDate"),
            dataIndex: "due_date",
        },
        {
            key: "paymentDate",
            title: t("form.paymentDate"),
            dataIndex: "payment_date",
        },
        {
            key: "presentedDate",
            title: t("form.presentedDate"),
            dataIndex: "presented_date",
        },
        {
            key: "created_date",
            title: t("dateCreated"),
            dataIndex: "create_date",
            render: (row) => row.split("T")[0],
        },
        {
            key: "actions",
            title: t("action"),
            fixed: lang === "en" ? "right" : "left",
            render: (row) => (
                <Space size="middle">
                    <Link to={`/check_cheque/${row.id}`} style={{color: "#1A5785"}}>
                        {t("view")}
                    </Link>
                    {chequeqType2 === "accepted" || chequeqType2 === "rejected" ? null : (
                        <CheckAuth
                            Permissions={["entity_checker"]}
                            userType={["entity", "bank"]}
                        >
                            <a
                                style={{color: "#52C41A"}}
                                onClick={() => selectAction(row.id, "1")}
                            >
                                {t("accept")}
                            </a>{" "}
                            <a
                                style={{color: "#FF4D4F"}}
                                onClick={() => selectAction(row.id, "2")}
                            >
                                {t("reject")}
                            </a>
                        </CheckAuth>
                    )}
                </Space>
            ),
        },
    ];

    const changeType = (e) => {
        setType(e);
    };

    const sendRequest = useCallback(
        async (e) => {
            if (e.key == "Enter") {
                getData(type, pageSize, searchText, current);
            }
        },
        [searchText]
    );

    const getData = async (e, size, searchText, currentFun) => {
        try {
            setCurrent(current);
            let result = await axiosInstance.get(
                `/Cheque/?drawee_type=${e}&page_size=${size}&search=${searchText}&page=${currentFun}&branch=${selectedBranch}&state=${chequeqType2}`
            );
            console.log("getData", result.data.data);
            setData(result.data.data);
            setTotal(result.data.count);
        } catch (err) {
            console.log(err);
        }
    };

    const getBranches = async () => {
        let result = await axiosInstance.get(
            `/${userType == "bank" ? "BankBranch/" : "EntityBranch/"}`
        );
        console.log(result);
        setBranches(result.data.data);
    };

    const search = (e) => {
        setSearchText(e.target.value);
    };

    const onShowSizeChange = (current, pageSize) => {
        setPageSize(pageSize);
    };

    const selectAction = async (id, verdict) => {
        let result = await axiosInstance
            .put(`/Cheque/${id}`, {state: verdict})
            .catch((err) => console.log(err));

        if (result) {
            getData(type, pageSize, searchText, current);
        }
    };

    useEffect(() => {
        console.log(type);

        getData(type, pageSize, searchText, current);
    }, [type, pageSize, current, selectedBranch, chequeqType2]);

    const rowSelection = {
        selectedRow,

        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );

            setSelectedRow(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled:
                chequeqType2 === "accepted" || chequeqType2 === "rejected"
                    ? true
                    : record.state !== "3"
                        ? true
                        : false,
        }),
    };

    useEffect(() => {
        getBranches();
    }, []);

    const RejectAllSelected = async () => {
        setLoading(true);
        console.log(selectedRow);

        let newObject =
            chequeqType2 === "pending_cheques"
                ? selectedRow.map((item) => ({
                    id: item,
                    state: 2,
                    beneficiary_state: 2,
                }))
                : chequeqType2 === "pending_drawer"
                    ? selectedRow.map((item) => ({id: item, state: 2}))
                    : selectedRow.map((item) => ({id: item, beneficiary_state: 2}));
        console.log(newObject);

        let result = await axiosInstance
            .put(`/ChequeState/`, newObject)
            .catch((err) => console.log(err));

        if (result) {
            setSelectedRow([]);
            getData(type, pageSize, searchText, current);
            setLoading(false);
        }
    };

    const AcceptAllSelected = async () => {
        setLoading(true);

        console.log(selectedRow);

        let newObject =
            chequeqType2 === "pending_cheques"
                ? selectedRow.map((item) => ({
                    id: item,
                    state: 1,
                    beneficiary_state: 1,
                }))
                : chequeqType2 === "pending_drawer"
                    ? selectedRow.map((item) => ({id: item, state: 1}))
                    : selectedRow.map((item) => ({id: item, beneficiary_state: 1}));

        console.log(newObject);

        let result = await axiosInstance
            .put(`/ChequeState/`, newObject)
            .catch((err) => console.log(err));

        if (result) {
            setSelectedRow([]);

            getData(type, pageSize, searchText, current);
            setLoading(false);
        }
    };

    return (
        <div>
            <ChequesFilter
                current={current}
                personType={type}
                setType={setType}
                setBranch={setBranch}
                setChequeType={setChequeType}
                branches={branches}
                search={search}
                sendRequest={sendRequest}
                title={t("dataVerification")}
                searchText={searchText}
                selectedBranch={selectedBranch}
                getData={getData}
                setSearchText={setSearchText}
                changeType="egyptian"
                setCurrent={setCurrent}
            />
            <div className="d-flex align-items-center justify-content-between">
                <Tabs onChange={changeType} defaultActiveKey={1}>
                    <TabPane tab={t("egyptianPerson")} key={"egyptian"}></TabPane>
                    <TabPane tab={t("foreignPerson")} key={"foreign"}></TabPane>
                    <TabPane tab={t("entityType")} key={"entity"}></TabPane>
                    <TabPane tab={t("noDrawee")} key="no_drawee"></TabPane>
                </Tabs>

                <div>
                    <Select
                        placeholder="select Cheques type"
                        onChange={(e) => setChequeType2(e)}
                        value={chequeqType2}
                        style={{minWidth: "200px"}}
                    >
                        <Select.Option value={"accepted"}>
                            {t("acceptedCheques")}
                        </Select.Option>

                        <Select.Option value={"rejected"}>
                            {t("rejectedCheques")}
                        </Select.Option>

                        <Select.Option value={"pending"}>
                            {t("pendingCheques")}
                        </Select.Option>

                        <Select.Option value={"pending_drawer"}>
                            {t("pendingDrawer")}
                        </Select.Option>

                        <Select.Option value={"pending_beneficiary"}>
                            {t("pendingBeneficiary")}
                        </Select.Option>
                    </Select>
                </div>

                {permissions.includes("transaction_monitor") ? null : <div>
                    <button
                        onClick={() => RejectAllSelected()}
                        className={`RejectSelectedBtn ${
                            selectedRow.length === 0 ? "dis" : ""
                        }`}
                        disabled={selectedRow.length === 0}
                    >
                        {t("RejectSelected")}{" "}
                        {selectedRow.length !== 0 && selectedRow.length}
                    </button>
                    <button
                        onClick={() => AcceptAllSelected()}
                        className={`AcceptSelectedBtn ${
                            selectedRow.length === 0 ? "dis" : ""
                        }`}
                        disabled={selectedRow.length === 0}
                    >
                        {t("AcceptSelected")}{" "}
                        {selectedRow.length !== 0 && selectedRow.length}
                    </button>
                </div>}
            </div>

            {loading ? (
                "loading"
            ) : (
                <>
                    <Table
                        pagination={false}
                        dataSource={data}
                        columns={columns}
                        rowKey={(row) => row.id}
                        rowSelection={{
                            ...rowSelection,
                        }}
                    />
                    <Pagination
                        current={current}
                        defaultCurrent={current}
                        onChange={(e) => {
                            setCurrent(e);
                        }}
                        onShowSizeChange={onShowSizeChange}
                        total={total}
                    />
                </>
            )}
        </div>
    );
}

export default ErrorHandler(DataVerification, axiosInstance);
