import React, { useEffect, useState } from "react";
import { Form, Input, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
export default function EscalationForm({ formId, Func, data }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [transactions, setTransactions] = useState(false);
  const [dispute, setDispute] = useState(false);

  useEffect(() => {
    if (data) {
      // if (data.etype === "2" || data.etype === "3") {
      //   console.log("data.etype = 2 or 3");
      //   setTransactions(true);
      // } else {
      //   setTransactions(false);
      //   console.log("data.etype != 2 or 3");
      // }

      // if (data.etype === "1" || data.etype === "3") {
      //   setDispute(true);
      //   console.log("data.etype = 1 or 3");
      // } else {
      //   setDispute(false);
      //   console.log("data.etype != 1 or 3");
      // }

      if (data.etype === "1") {
        setDispute(true);

        setTransactions(false);
      } else if (data.etype === "2") {
        setTransactions(true);
        setDispute(false);
      } else if (data.etype === "3") {
        setDispute(true);
        setTransactions(true);
      } else if (data.etype === "4") {
        setDispute(false);
        setTransactions(false);
      } else {
        console.log();
      }
    }
  }, [data]);

  useEffect(() => {
    console.log("dispute", dispute);
    console.log("transactions", transactions);
  }, [dispute, transactions]);

  // useEffect(() => {
  //   console.log(
  //     data ? data.etype : "nodata",
  //     "transactions",
  //     transactions,
  //     "dispute",
  //     dispute
  //   );
  // }, [transactions, dispute]);

  const onFinish = (values) => {
    console.log("Success:", values);
    Func(values, dispute, transactions);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    console.log("data", data);
  }, []);

  return (
    <Col sm={12} md={6} lg={4} className="py-3">
      <Form
        initialValues={{
          name: data ? data.name : "",
          email: data ? data.email : "",
          transactions_days: data ? data.transactions_days : "",
          dispute_days: data ? data.dispute_days : "",
          transactionCheck: transactions,
          disputeCheck: dispute,
        }}
        id={formId}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label={t("form.name")}
          name="name"
          required
          rules={[
            {
              required: true,
              message: t("form.requiredField"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("form.email")}
          name="email"
          required
          rules={[
            {
              required: true,
              message: t("form.requiredField"),
            },
            {
              type: "email",
              message: `${t("form.wrongFormat")}`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="d-flex align-items-start justify-content-start">
          <Form.Item
            label={t("transactionEscalation")}
            name="transactions_days"
            required={transactions ? true : false}
            rules={[
              {
                required: transactions ? true : false,
                message: t("form.requiredField"),
              },
            ]}
            // extra={t("NumberOfDays")}
          >
            <Input
              // addonBefore={t("NumberOfDays")}
              type="number"
              onKeyDown={(e) =>
                ["e", ".", "E", "-", "+"].includes(e.key) && e.preventDefault()
              }
            />
          </Form.Item>

          <Form.Item name="transactionCheck" className="px-3">
            <Switch
              onChange={(e, i, y) => setTransactions(e)}
              checked={transactions}
            />
          </Form.Item>
        </div>

        <div className="d-flex align-items-start justify-content-start">
          <Form.Item
            label={t("disputeEscalation")}
            name="dispute_days"
            required={dispute ? true : false}
            rules={[
              {
                required: dispute ? true : false,
                message: t("form.requiredField"),
              },
            ]}
            // extra={t("NumberOfDays")}
          >
            <Input
              type="number"
              onKeyDown={(e) =>
                ["e", ".", "E", "-", "+"].includes(e.key) && e.preventDefault()
              }
            />
          </Form.Item>

          <Form.Item name="disputeCheck" className="px-3">
            <Switch onChange={(e, i, y) => setDispute(e)} checked={dispute} />
          </Form.Item>
        </div>
      </Form>
    </Col>
  );
}
