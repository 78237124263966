import {
    Tabs,
    Table,
    Space,
    Pagination,
    ConfigProvider,
    Empty,
    Switch,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axiosInstance from "../api/axios";
import ErrorHandler from "../error-handler/ErrorHandler";
import RejectionModal from "./layout/modals/RejectionModal";
import ChequesFilter from "../components/ChequesFilter";
import ConfirmRequest from "./layout/modals/confirmRequest";
import SuccessMsg from "./layout/SuccessMsg";

const { Column } = Table;
const { TabPane } = Tabs;

function DataList({
    type,
    title,
    endpoint,
    description,
    cheque,
    dispute,
    user,
    isEntity,
    disableCheck,
    modifyPermission,
    disableRowSelection,
    isBank,
}) {
    console.log({ modifyPermission });
    const permission = useSelector((state) => state.auth.user.permissions);
    const { t } = useTranslation();
    let lang = localStorage.getItem("i18nextLng");
    const userType = useSelector((state) => state.auth.user.user_type);
    const [data, setData] = useState([]);
    const [showReject, setShowRejectModal] = useState(false);
    const [showChequeReject, setShowChequeReject] = useState(false);
    const [id, setID] = useState("");
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [total, setTotal] = useState();
    const [current, setCurrent] = useState(1);
    const [selectedBranch, setBranch] = useState("");
    const [branches, setBranches] = useState([]);
    const [chequeType, setChequeType] = useState("");
    const [searchText, setSearchText] = useState("");
    const [personType, setType] = useState("egyptian");
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showUserRejectionReason, setShowUserRejectionReason] =
        useState(false);
    const [showConfirmUser, setShowConfirmUser] = useState(false);
    const [showRejectionUser, setShowRejectionUser] = useState(false);
    const [stateLoading, setStateLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const getData = async (
        current,
        personType,
        search,
        chequetype,
        selectedBranch
    ) => {
        try {
            setCurrent(current);
            let result = await axiosInstance.get(
                endpoint.includes("?")
                    ? endpoint +
                          "&page=" +
                          current +
                          "&search=" +
                          search +
                          "&cheque_type=" +
                          chequetype +
                          "&drawee_type=" +
                          personType +
                          "&branch=" +
                          selectedBranch
                    : endpoint +
                          "?page=" +
                          current +
                          "&search=" +
                          search +
                          "&cheque_type=" +
                          chequetype +
                          "&drawee_type=" +
                          personType +
                          "&branch=" +
                          selectedBranch
            );

            setData(result.data.data);
            setTotal(result.data.count);
        } catch (err) {
            console.log(err);
        }
    };

    const changeType = (e) => {
        setCurrent(1);
        setType(e);
    };

    const handleDisput = async (type, id) => {
        if (type === 2) {
            setShowRejectModal(true);
            setID(id);
        } else {
            let result = await axiosInstance.put(`/Dispute/${id}`, {
                state_1: type === 1 ? "1" : type === 2 ? "2" : "3",
            });
            if (result) {
                console.log(result);
                getData(
                    current,
                    personType,
                    searchText,
                    chequeType,
                    selectedBranch
                );
            }
        }
    };

    const selectAction = async (state, id) => {
        let result;
        if (state === "2") {
            setShowRejectModal(true);
            setID(id);
        } else {
            result = await axiosInstance
                .put(`/Cheque/${id}`, { state: state })
                .catch((err) => console.log(err));

            if (result) {
                setShowConfirm(false);

                setShowSuccess(true);

                getData(
                    current,
                    personType,
                    searchText,
                    chequeType,
                    selectedBranch
                );
            }
        }
    };

    useEffect(() => {
        getData(current, personType, searchText, chequeType, selectedBranch);
    }, [current, personType, selectedBranch, chequeType]);

    useEffect(() => {
        getBranches();
    }, []);

    const getBranches = async () => {
        let result = await axiosInstance.get(
            `/${userType == "bank" ? "BankBranch/" : "EntityBranch/"}`
        );
        console.log(result);
        setBranches(result.data.data);
    };

    const search = (e) => {
        setSearchText(e.target.value);
    };
    const sendRequest = useCallback(
        async (e) => {
            console.log("send");
            if (e.key === "Enter") {
                console.log("here");
                getData(
                    current,
                    personType,
                    searchText,
                    chequeType,
                    selectedBranch
                );
            } else {
                console.log(e.key);
            }
        },
        [searchText, selectedBranch]
    );

    const changeUserStatus = async (id, action) => {
        setStateLoading(true);
        //
        if (action == "2") {
            let result = await axiosInstance.put(
                `/${endpoint.split("/")[1]}/${id}`,
                {
                    user_state: action,
                }
            );
            console.log(result);
            if (result) {
                setShowConfirmUser(false);
                getData(
                    current,
                    personType,
                    searchText,
                    chequeType,
                    selectedBranch
                );
                setStateLoading(false);
            }
        } else {
            setID(id);
            setShowRejectionUser(false);
            setShowUserRejectionReason(true);
            setStateLoading(false);
        }
    };

    const toggleActive = async (e, id) => {
        await axiosInstance
            .put(`/${isEntity ? "ListEntityUser" : "ListBankUser"}/${id}`, {
                is_active: e,
            })
            .catch((err) => console.log(err));
    };

    const rowSelection = {
        selectedRow,

        onChange: (selectedRowKeys, selectedRows) => {
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );

            setSelectedRow(selectedRowKeys);
        },
        getCheckboxProps: (record) => {
            return {
                // console.log("test", record);
                disabled: disableRowSelection
                    ? disableRowSelection(record)
                    : type === "accepted_cheques" || type === "rejected_cheques"
                    ? true
                    : record.state !== "3",
            };
        },
    };

    const RejectAllSelected = async () => {
        setLoading(true);
        console.log(selectedRow);

        let newObject =
            type === "pending_cheques"
                ? selectedRow.map((item) => ({
                      id: item,
                      state: 2,
                      beneficiary_state: 2,
                  }))
                : type === "pending_drawer"
                ? selectedRow.map((item) => ({ id: item, state: 2 }))
                : selectedRow.map((item) => ({
                      id: item,
                      beneficiary_state: 2,
                  }));
        console.log(newObject);

        let result = await axiosInstance
            .put(`/ChequeState/`, newObject)
            .catch((err) => console.log(err));

        if (result) {
            setSelectedRow([]);
            getData(
                current,
                personType,
                searchText,
                chequeType,
                selectedBranch
            );
            setLoading(false);
        }
    };

    const AcceptAllSelected = async () => {
        setLoading(true);

        console.log(selectedRow);

        let newObject =
            type === "pending_cheques"
                ? selectedRow.map((item) => ({
                      id: item,
                      state: 1,
                      beneficiary_state: 1,
                  }))
                : type === "pending_drawer"
                ? selectedRow.map((item) => ({ id: item, state: 1 }))
                : selectedRow.map((item) => ({
                      id: item,
                      beneficiary_state: 1,
                  }));
        console.log(newObject);

        let result = await axiosInstance
            .put(`/ChequeState/`, newObject)
            .catch((err) => console.log(err));

        if (result) {
            setSelectedRow([]);

            getData(
                current,
                personType,
                searchText,
                chequeType,
                selectedBranch
            );
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log("chequetype", type);
    });

    return (
        <div>
            <ChequesFilter
                current={current}
                personType={personType}
                selectedBranch={selectedBranch}
                setType={setType}
                setBranch={setBranch}
                setChequeType={setChequeType}
                branches={branches}
                search={search}
                sendRequest={(e) => sendRequest(e)}
                title={title}
                getData={() =>
                    getData(
                        current,
                        personType,
                        searchText,
                        chequeType,
                        selectedBranch
                    )
                }
                chequeType={chequeType}
                cheque={cheque}
                user={user}
                dispute={dispute}
                searchText={searchText}
                setSearchText={setSearchText}
                changeType="egyptian"
                setCurrent={setCurrent}
            />
            <div className="formWrapper">
                <p className="description">{description}</p>
                {loading ? (
                    "loading"
                ) : (
                    <>
                        <ConfigProvider
                            renderEmpty={() => (
                                <Empty description={t("noData")} />
                            )}
                        >
                            {cheque ? (
                                <div className="d-flex align-items-start justify-content-between">
                                    <Tabs
                                        onChange={changeType}
                                        value={type}
                                        defaultActiveKey={1}
                                    >
                                        <TabPane
                                            tab={t("egyptianPerson")}
                                            key="egyptian"
                                        ></TabPane>
                                        <TabPane
                                            tab={t("foreignPerson")}
                                            key="foreign"
                                        ></TabPane>
                                        <TabPane
                                            tab={t("entityType")}
                                            key="entity"
                                        ></TabPane>
                                        {type ===
                                        "pending_cheques" ? undefined : (
                                            <TabPane
                                                tab={t("noDrawee")}
                                                key="no_drawee"
                                            ></TabPane>
                                        )}
                                    </Tabs>
                                    {modifyPermission ?? true ? (
                                        <div>
                                            <button
                                                onClick={() =>
                                                    RejectAllSelected()
                                                }
                                                className={`RejectSelectedBtn ${
                                                    selectedRow.length === 0
                                                        ? "dis"
                                                        : ""
                                                }`}
                                                disabled={
                                                    selectedRow.length === 0
                                                }
                                            >
                                                {t("RejectSelected")}{" "}
                                                {selectedRow.length !== 0 &&
                                                    selectedRow.length}
                                            </button>
                                            <button
                                                onClick={() =>
                                                    AcceptAllSelected()
                                                }
                                                className={`AcceptSelectedBtn ${
                                                    selectedRow.length === 0
                                                        ? "dis"
                                                        : ""
                                                }`}
                                                disabled={
                                                    selectedRow.length === 0
                                                }
                                            >
                                                {t("AcceptSelected")}{" "}
                                                {selectedRow.length !== 0 &&
                                                    selectedRow.length}
                                            </button>
                                        </div>
                                    ) : undefined}
                                </div>
                            ) : null}

                            <Table
                                rowSelection={
                                    disableCheck
                                        ? undefined
                                        : {
                                              ...rowSelection,
                                          }
                                }
                                pagination={false}
                                dataSource={data}
                                rowKey={(row) => row.id}
                                expandable={
                                    type.includes("disputes") ||
                                    type === "under_review"
                                        ? {
                                              expandedRowKeys: expandedRowKeys,
                                              onExpand: (expanded, record) => {
                                                  const keys = [];
                                                  if (expanded) {
                                                      keys.push(record.id);
                                                  }
                                                  setExpandedRowKeys(keys);
                                              },
                                              expandedRowRender: (record) => {
                                                  return (
                                                      <>
                                                          <span className="mx-3">
                                                              {t("notes")} :{" "}
                                                              {record.comments?.map(
                                                                  (comment) =>
                                                                      `${comment.comment}, by:${comment.changed_by.name}`
                                                              )}
                                                          </span>
                                                          <span className="mx-3">
                                                              {t(
                                                                  "form.chequeNumber"
                                                              )}{" "}
                                                              :{" "}
                                                              {
                                                                  record.cheque
                                                                      ?.cheque_number
                                                              }
                                                          </span>
                                                      </>
                                                  );
                                              },
                                          }
                                        : permission.includes(
                                              "user_create_maker"
                                          ) && type === "rejected_users"
                                        ? {
                                              expandedRowRender: (record) => {
                                                  return (
                                                      <>
                                                          <span className="mx-3">
                                                              {t(
                                                                  "rejectionReason"
                                                              )}{" "}
                                                              :{" "}
                                                              {
                                                                  record.reject_reason
                                                              }
                                                          </span>
                                                      </>
                                                  );
                                              },
                                          }
                                        : false
                                }
                            >
                                {cheque ? (
                                    <>
                                        {" "}
                                        <Column
                                            width={150}
                                            title={t("form.transID")}
                                            dataIndex="id"
                                        />
                                        <Column
                                            title={t("form.name")}
                                            render={(row) =>
                                                row.drawee_egyptian
                                                    ? row?.drawee_egyptian?.name
                                                    : row.drawee_entity
                                                    ? row.drawee_entity?.name
                                                    : row?.drawee_foreign?.name
                                            }
                                        />
                                        <Column
                                            title={t("form.draweeType")}
                                            render={(row) =>
                                                row.drawee_egyptian
                                                    ? t("egyptianPerson")
                                                    : row.drawee_entity
                                                    ? t("entityType")
                                                    : t("foreignPerson")
                                            }
                                        />
                                        <Column
                                            title={t("form.draweeID")}
                                            render={(row) =>
                                                row.drawee_egyptian
                                                    ? row?.drawee_egyptian
                                                          ?.national_id
                                                    : row.drawee_entity
                                                    ? row.drawee_entity?.tax_id
                                                    : row?.drawee_foreign
                                                          ?.passport_id
                                            }
                                        />
                                        <Column
                                            title={t("form.chequeType")}
                                            dataIndex="cheque_type"
                                            width={250}
                                            render={(row) =>
                                                row === "normal cheque"
                                                    ? t("normalCheque")
                                                    : row === "corporate cheque"
                                                    ? t("corporateCheque")
                                                    : t("certifiedCheque")
                                            }
                                        />
                                        <Column
                                            title={t("form.chequeAmount")}
                                            width={280}
                                            dataIndex="cheque_amount"
                                        />
                                        <Column
                                            width={200}
                                            title={t("form.paidAmount")}
                                            dataIndex="paid_amount"
                                        />
                                        <Column
                                            title={t("form.currency")}
                                            dataIndex="currency"
                                            width={200}
                                            render={(row) => row.name_expanded}
                                        />
                                        <Column
                                            title={t("form.dueDate")}
                                            dataIndex="due_date"
                                        />
                                        <Column
                                            title={t("form.paymentDate")}
                                            dataIndex="payment_date"
                                        />
                                        <Column
                                            title={t("form.presentedDate")}
                                            dataIndex="presented_date"
                                        />
                                        <Column
                                            title={t("createdBy")}
                                            dataIndex="created_by"
                                            render={(row) => row?.name}
                                        />
                                        <Column
                                            title={t("dateCreated")}
                                            dataIndex="create_date"
                                            render={(row) => row.split("T")[0]}
                                        />
                                        {type === "drafts" ? (
                                            <Column
                                                fixed={
                                                    lang === "en"
                                                        ? "right"
                                                        : "left"
                                                }
                                                title={t("action")}
                                                render={(row) => {
                                                    return (
                                                        <Link
                                                            to={`/draft_cheque/${row.id}`}
                                                        >
                                                            {t("viewDraft")}
                                                        </Link>
                                                    );
                                                }}
                                            />
                                        ) : type === "pending_beneficiary" ? (
                                            <Column
                                                fixed={
                                                    lang === "en"
                                                        ? "right"
                                                        : "left"
                                                }
                                                title={t("action")}
                                                render={(row) => {
                                                    return (
                                                        <Link
                                                            to={`/edit_cheque/${row.id}`}
                                                        >
                                                            {t("viewDraft")}
                                                        </Link>
                                                    );
                                                }}
                                            />
                                        ) : type !== "drafts" &&
                                          permission.includes(
                                              "entity_checker"
                                          ) &&
                                          type === "pending_cheques" ? (
                                            <Column
                                                fixed={
                                                    lang === "en"
                                                        ? "right"
                                                        : "left"
                                                }
                                                title={t("action")}
                                                render={(row) => {
                                                    return (
                                                        <Space size="middle">
                                                            <Link
                                                                to={`/check_cheque/${row.id}`}
                                                                style={{
                                                                    color: "#1A5785",
                                                                }}
                                                            >
                                                                {t("view")}
                                                            </Link>
                                                            <a
                                                                style={{
                                                                    color: "#52C41A",
                                                                }}
                                                                onClick={() => {
                                                                    setID(
                                                                        row.id
                                                                    );
                                                                    setShowConfirm(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                {t("accept")}
                                                            </a>{" "}
                                                            <a
                                                                style={{
                                                                    color: "#FF4D4F",
                                                                }}
                                                                onClick={() => {
                                                                    setID(
                                                                        row.id
                                                                    );
                                                                    setShowRejectionUser(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                {t("reject")}
                                                            </a>
                                                        </Space>
                                                    );
                                                }}
                                            />
                                        ) : permission.includes(
                                              "entity_maker"
                                          ) && type === "rejected_cheques" ? (
                                            <Column
                                                fixed={
                                                    lang === "en"
                                                        ? "right"
                                                        : "left"
                                                }
                                                title={t("action")}
                                                render={(row) => {
                                                    return (
                                                        <Space size="middle">
                                                            <Link
                                                                to={`/reject_cheque_details/${row.id}`}
                                                                style={{
                                                                    color: "#1A5785",
                                                                }}
                                                            >
                                                                {t("view")}
                                                            </Link>
                                                        </Space>
                                                    );
                                                }}
                                            />
                                        ) : null}
                                    </>
                                ) : dispute ? (
                                    <>
                                        <Column
                                            title={t("form.disputeType")}
                                            dataIndex="type"
                                            render={(row) =>
                                                row === "1"
                                                    ? t("name_Dispute")
                                                    : row === "2"
                                                    ? t("invalidCheque")
                                                    : t("disputeType3")
                                            }
                                        />
                                        <Column
                                            title={t("form.date")}
                                            dataIndex="create_date"
                                            render={(row) =>
                                                row.split("T")[0] +
                                                "/" +
                                                row?.split("T")[1].split(".")[0]
                                            }
                                        />
                                        <Column
                                            title={t("form.disputeID")}
                                            dataIndex="id"
                                        />
                                        <Column
                                            title={t("form.changedBy")}
                                            dataIndex="changed_by"
                                            render={(row) => row?.name}
                                        />

                                        {(permission.includes(
                                            "dispute_maker"
                                        ) ||
                                            permission.includes(
                                                "dispute_checker"
                                            )) &&
                                        type === "pending_disputes" ? (
                                            <Column
                                                title={t("action")}
                                                render={(record) => (
                                                    <Space size="middle">
                                                        {permission.includes(
                                                            "dispute_maker"
                                                        ) ||
                                                        permission.includes(
                                                            "dispute_checker"
                                                        ) ? (
                                                            <a
                                                                onClick={() =>
                                                                    navigate(
                                                                        `/view_dispute_details/${record.id}`
                                                                    )
                                                                }
                                                                style={{
                                                                    color: "#1A5785",
                                                                }}
                                                            >
                                                                {t("view")}
                                                            </a>
                                                        ) : null}
                                                    </Space>
                                                )}
                                            />
                                        ) : null}
                                    </>
                                ) : user ? (
                                    <>
                                        <Column
                                            title={t("id")}
                                            dataIndex="id"
                                        />
                                        <Column
                                            title={t("form.userName")}
                                            dataIndex="user"
                                            render={(row) => row?.name}
                                        />

                                        <Column
                                            title={t("email")}
                                            dataIndex="user"
                                            render={(row) => row?.email}
                                        />
                                        <Column
                                            title={t("actions")}
                                            render={(record) => (
                                                <Space size="middle">
                                                    {record.user_state == "1" &&
                                                    permission.includes(
                                                        "user_create_checker"
                                                    ) ? (
                                                        <>
                                                            {" "}
                                                            <a
                                                                onClick={() => {
                                                                    setShowConfirmUser(
                                                                        true
                                                                    );
                                                                    setID(
                                                                        record.id
                                                                    );
                                                                }}
                                                                style={{
                                                                    color: "green",
                                                                }}
                                                            >
                                                                {t("accept")}
                                                            </a>
                                                            <a
                                                                onClick={() => {
                                                                    setShowRejectionUser(
                                                                        true
                                                                    );
                                                                    setID(
                                                                        record.id
                                                                    );
                                                                }}
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                {t("reject")}
                                                            </a>
                                                        </>
                                                    ) : null}
                                                    {record.user_state == "2" &&
                                                    permission.includes(
                                                        "user_create_checker"
                                                    ) ? (
                                                        <Switch
                                                            defaultChecked={
                                                                record.user
                                                                    ?.is_active
                                                            }
                                                            onChange={(e) =>
                                                                toggleActive(
                                                                    e,
                                                                    record?.id
                                                                )
                                                            }
                                                        />
                                                    ) : null}
                                                    <a
                                                        onClick={() =>
                                                            navigate(
                                                                `/${
                                                                    isEntity
                                                                        ? "viewEntityUser"
                                                                        : "viewBankUser"
                                                                }/${record.id}`
                                                            )
                                                        }
                                                        style={{
                                                            color: "#1A5785",
                                                        }}
                                                    >
                                                        {t("view")}
                                                    </a>
                                                </Space>
                                            )}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Column
                                            title={t("id")}
                                            dataIndex="id"
                                        />

                                        <Column
                                            title={t("dateCreated")}
                                            dataIndex="create_date"
                                            render={(row) => row.split("T")[0]}
                                        />
                                        <Column
                                            title={t("createdBy")}
                                            dataIndex="created_by"
                                            render={(row) => row?.name ?? ""}
                                        />
                                    </>
                                )}
                            </Table>
                        </ConfigProvider>
                        <Pagination
                            current={current}
                            defaultCurrent={current}
                            onChange={(e) =>
                                getData(
                                    e,
                                    personType,
                                    searchText,
                                    chequeType,
                                    selectedBranch
                                )
                            }
                            total={total}
                        />{" "}
                    </>
                )}
            </div>
            <RejectionModal
                show={showReject}
                getAll={() =>
                    getData(
                        current,
                        personType,
                        searchText,
                        chequeType,
                        selectedBranch
                    )
                }
                id={id}
                hide={() => setShowRejectModal(false)}
                cheque={false}
                userChangeState={false}
            />
            <RejectionModal
                show={showChequeReject}
                getAll={() =>
                    getData(
                        current,
                        personType,
                        searchText,
                        chequeType,
                        selectedBranch
                    )
                }
                id={id}
                hide={() => setShowChequeReject(false)}
                cheque={true}
                userChangeState={false}
            />
            <RejectionModal
                show={showUserRejectionReason}
                getAll={() =>
                    getData(
                        current,
                        personType,
                        searchText,
                        chequeType,
                        selectedBranch
                    )
                }
                id={id}
                hide={() => setShowUserRejectionReason(false)}
                userChangeState={true}
                endPoint={endpoint.split("/")[1]}
            />
            <ConfirmRequest
                show={showConfirm}
                text={t("acceptChequeTxt")}
                btnTxt={t("form.confirm")}
                hide={() => setShowConfirm(false)}
                request={() => selectAction("1", id)}
            />

            <ConfirmRequest
                show={
                    showConfirmUser
                        ? showConfirmUser
                        : showRejectionUser
                        ? showRejectionUser
                        : ""
                }
                text={
                    showConfirmUser
                        ? t("acceptUserTxt")
                        : "Are you sure that you want to reject this user?"
                }
                btnTxt={t("form.confirm")}
                hide={() =>
                    showConfirmUser
                        ? setShowConfirmUser(false)
                        : setShowRejectionUser(false)
                }
                request={() =>
                    showConfirmUser
                        ? changeUserStatus(id, "2")
                        : changeUserStatus(id, "3")
                }
                stateLoading={stateLoading}
            />
            <SuccessMsg
                show={showSuccess}
                hide={() => setShowSuccess(false)}
                text={t("succesMsg")}
                btnTxt={t("close")}
            />
        </div>
    );
}

export default ErrorHandler(DataList, axiosInstance);
