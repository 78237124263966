import React, { useEffect, useState } from "react";
import { Pagination, Table, Spin } from "antd";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance, { BASE_URL } from "../../../api/axios";
import { Row, Col } from "react-bootstrap";
function UploadHistoryTable() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState();
  const [current, setCurrent] = useState(1);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: t("id"),
      dataIndex: "id",
    },
    {
      title: t("dateCreated"),
      dataIndex: "create_date",
      render: (row) => <span>{row?.split("T")[0]}</span>,
    },
    {
      title: t("dateModified"),
      dataIndex: "modify_date",
      render: (row) => <span>{row?.split("T")[0]}</span>,
    },
    {
      title: t("form.name"),
      dataIndex: "file",
      render: (row) => <span>{row?.split("/")[5]}</span>,
    },
    {
      title: t("sheetType"),
      dataIndex: "sheet_type",
      render: (row) => (
        <span>
          {row == "1"
            ? "Transaction"
            : row == "2"
            ? "Cheque Book"
            : "Beneficiary"}
        </span>
      ),
    },
    {
      title: t("file"),
      dataIndex: "file",
      render: (row) => (
        <a target="_blank" href={`${row}`}>
          {t("download")}
        </a>
      ),
    },
  ];
  const getHistory = async () => {
    try {
      setLoading(true);
      let result = await axiosInstance.get(`/UploadSession/?page=${current}`);
      console.log(result);
      setData(result.data.data);
      setTotal(result.data.count);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleChagne = (page) => {
    setCurrent(page);
  };
  useEffect(() => {
    getHistory();
  }, [current]);
  return (
    <>
      <Row>
        <p className="title mb-4">{t("uploadHistory")}</p>
      </Row>
      {loading ? (
        <Row>
          {" "}
          <Col sm="12" className="text-center">
            {" "}
            <Spin tip="Loading...." />
          </Col>
        </Row>
      ) : (
        <>
          {" "}
          <Table pagination={false} dataSource={data} columns={columns} />
          <Pagination
            onChange={handleChagne}
            defaultCurrent={current}
            current={current}
            total={total}
            showSizeChanger={false}
          />
        </>
      )}
    </>
  );
}

export default ErrorHandler(UploadHistoryTable, axiosInstance);
