import React from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import { Steps, Button, Row, Form, Input, Spin } from "antd";
import CheckAuth from "../../HOC/CheckAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import fileDownload from "js-file-download";

import { ToastContainer, toast } from "react-toastify";

const { Step } = Steps;

function ReferenceNumberStep({ setStep }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const onFinish = async (values) => {
    setLoading(true);
    let result = await axiosInstance
      .get(`/ReportSession/${values.id}`)
      .catch((err) => {
        console.log(err.response);
        setLoading(false);
        if (err.response?.status !== 500) {
          for (const error in err.response.data) {
            setTimeout(() => {
              toast.error(`${error + " : " + err.response.data[error]}`);
            }, 500);
          }
        }
      });
    if (result) {
      console.log(result);
      handleDownload(result.data?.file, "Report.pdf");
    }
  };
  const handleDownload = async (url, filename) => {
    let result = await axiosInstance
      .get(url, {
        responseType: "blob",
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 404) {
          setTimeout(() => {
            toast.error(
              "Report not found check your reference ID or go back and create new one"
            );
          }, 500);
        }
      });
    if (result) {
      setLoading(false);
      fileDownload(result.data, filename);
      setTimeout(() => {
        setStep("3");
      }, 2500);
    }
  };
  return (
    <CheckAuth
      Permissions={["report_viewer", "dispute_starter"]}
      userType={["iscore", "entity", "bank"]}
    >
      <div className="inquiry">
        <p className="title pb-2">{t("createNewDispute")}</p>
        <p className="subHeader">{t("disputeSubText")}</p>
        <p className="title">{t("chequeInquiryReport")}</p>
        <Steps size="small" current={0}>
          <Step
            icon={<FontAwesomeIcon icon={faCircle} />}
            title={t("enterRefNum")}
          />
          <Step
            icon={<FontAwesomeIcon icon={faCircle} />}
            title={t("viewReport")}
          />
        </Steps>

        <div className="formWrapper">
          <p className="subTitle">{t("chequeInquiryData")}</p>
          <p className="subText">{t("validRefNum")}</p>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item
              label={t("enterRefNum")}
              name="id"
              rules={[
                {
                  required: true,
                  message: t("form.requiredTaxId"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Row className="text-end">
              {" "}
              <Button className="cancelBtn" onClick={() => setStep("1")}>
                {t("backInquiry")}
              </Button>
              <Button
                color="primary"
                htmlType="submit"
                style={{ marginBottom: "1rem" }}
                className="formBtn"
              >
                {loading ? <Spin /> : t("viewReport")}
              </Button>
            </Row>
          </Form>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </CheckAuth>
  );
}
export default ErrorHandler(ReferenceNumberStep, axiosInstance);
