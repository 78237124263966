import "./CurrencyPages.css";
import { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import CurrencyForm from "./CurrencyForm";
import axiosInstance from "../../../api/axios";

import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
function EditCurrency() {
  const { t } = useTranslation();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);

  const [currencies, setCurrencies] = useState(false);

  let navigate = useNavigate();
  const editCurrency = async (formData) => {
    let newCurrency = {
      currency: data.currency.id,
      rate: formData.currencyExchangeRate,
      operation_type: "edit",
      real_id: id.split("-")[0],
    };

    try {
      setLoading(true);
      let result = await axiosInstance.post(
        `/ConversionRateTemp/`,
        newCurrency
      );
      toast.success(t("success"), {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      setLoading(false);
      setTimeout(() => {
        navigate("/currency_configuration");
      }, 1000);
    } catch {
      toast.error(t("failed"), {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });

      setLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      let result = await axiosInstance.get(
        `/ConversionRate/${id.split("-")[0]}`
      );
      console.log("Currency data item", result.data);
      setdata(result.data);
      setCurrencies([result.data]);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section>
      <ToastContainer />

      <h1 className="pageTitle"> {t("editCurrency")}</h1>
      <div className="sectionHolder p-5">
        <h2 className="currancyFormTitle">{t("currencySettings")}</h2>
        <p className="currancyFormsubTitle">{t("currencySettingsSubtitle")} </p>
        {!loading ? (
          <CurrencyForm
            data={currencies && currencies[0].currency}
            formId="EditCurrency"
            add={false}
            loading={loading}
            func={editCurrency}
            currencies={currencies}
            edit={true}
            rate={currencies && currencies[0].rate}
          />
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <Spin />
          </div>
        )}
      </div>
      <div className="formBtnsHolders">
        <button className="formBtn" disabled>
          <FontAwesomeIcon className="px-1" icon={faTrash} />
        </button>
        <button
          className="formBtn"
          form="EditCurrency"
          key="submit"
          htmlType="submit"
        >
          {!loading ? (
            <>
              <FontAwesomeIcon className="px-2" icon={faCheck} />

              {t("saveThisCurrency")}
            </>
          ) : (
            <Spin />
          )}
        </button>
      </div>
    </section>
  );
}

export default ErrorHandler(EditCurrency, axiosInstance);
