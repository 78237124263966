import React from "react";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import "../../style-sheets/header.css";
import { DownOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LogOut } from "../../global-state/actions/authAction";
const { Header } = Layout;

export default function HeaderComp({ setDirection, setLanguage }) {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const menu = (
    <Menu>
      <Menu.Item onClick={(e) => changeLanguage(e)} key="en">
        English
      </Menu.Item>
      <Menu.Item onClick={(e) => changeLanguage(e)} key="ar">
        Arabic
      </Menu.Item>
    </Menu>
  );

  const changeLanguage = (e) => {
    if (e.key === "en") {
      setDirection("ltr");
      setLanguage("en");
    } else {
      setDirection("rtl");
      setLanguage("ar");
    }
  };
  return (
    <Header className="isCollapse">
      <Link to={`/personal_profile`}>
        {/* <Button className="mx-2">Profile</Button> */}
        {/* <img src={profile} alt="profile" /> */}
        <Avatar
          style={{ backgroundColor: "rgba(63, 147, 211, 1)" }}
          icon={<UserOutlined />}
        />
      </Link>
      <p className="mb-0 mx-3 userName">{user.username}</p>
      <Dropdown className="cursor" overlay={menu} trigger={["click"]}>
        <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {localStorage.getItem("i18nextLng").toUpperCase()} <DownOutlined />
        </p>
      </Dropdown>
      <Link className="d-flex" to="/">
        <LogoutOutlined onClick={() => dispatch(LogOut())} className="mx-3" />
      </Link>
    </Header>
  );
}
