import { Switch } from "antd";
import "./ParamEditorPagesStyles.css";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function ReportParametersPage() {
  const { t } = useTranslation();

  const switchOnAndOff = (e, card) => {
    console.log("change", e);
    if (e === true) {
      setShowSwitchOn(true);
    } else {
      setShowSwitchOff(true);
    }
  };

  const [showSwitchOn, setShowSwitchOn] = useState(false);
  const [showSwitchOff, setShowSwitchOff] = useState(false);

  return (
    <main>
      <div className="ReportParametersPage w-50 mx-auto">
        <div className="d-flex align-items-center  mb-2">
          <h2 className="reportTitle">{t("ReportParameters")}</h2>
          <div className="reportTypeStandard">{t("stander")}</div>
          {/* <div className="reportTypeCustomize">{t"Customize"}</div> */}
        </div>
        <h1 className="reportBankName">Bank Name</h1>

        {[1, 2, 3, 4].map((card) => (
          <div className="d-flex align-items-center justify-content-between py-2">
            <div>{card}</div>
            <Switch
              onChange={(e) => switchOnAndOff(e, card)}
              onClick={(e) => console.log("click", e)}
              //   checked={true}
              //   value={true}
            />
          </div>
        ))}
      </div>
      <Modal
        show={showSwitchOff}
        onHide={() => setShowSwitchOff(false)}
        centered
      >
        <Modal.Body className="text-center">
          <ExclamationCircleOutlined
            style={{ fontSize: "46px", color: "#FF4D4F" }}
          />
          <div className="pt-2 pb-3">
            <p className="mb-0"> {t("SwitchOff")}</p>
            <p className="mb-1">
              {t("Parameter")}: <strong>Search details</strong>
            </p>
            <p className="mb-0">
              {t("AreYouSureToOff")} National Bank Of Egypt.
            </p>
          </div>

          <div className="d-flex justify-content-around align-items-center">
            <button
              className="cancleBtn"
              onClick={() => setShowSwitchOff(false)}
            >
              {t("form.cancel")}
            </button>
            <button className="SwitchOffBtn">{t("SwitchOff")}</button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showSwitchOn} onHide={() => setShowSwitchOn(false)} centered>
        <Modal.Body className="text-center">
          <CheckCircleOutlined style={{ fontSize: "46px", color: "#52C41A" }} />
          <div className="pt-2 pb-3">
            <p className="mb-0"> {t("SwitchOn")}</p>
            <p className="mb-1">
              {t("Parameter")}: <strong>Search details</strong>
            </p>
            <p className="mb-0">
              {t("AreYouSureToOn")} National Bank Of Egypt.
            </p>
          </div>

          <div className="d-flex justify-content-around align-items-center">
            <button
              className="cancleBtn"
              onClick={() => setShowSwitchOn(false)}
            >
              {t("form.cancel")}
            </button>
            <button className="SwitchOnBtn">{t("SwitchOn")}</button>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
}
