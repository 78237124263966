import { useTranslation } from "react-i18next";
import { Space, Table, Input, Pagination, Select } from "antd";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CheckAuth from "../../HOC/CheckAuth";

const { Search } = Input;

function BankerEscalationUsers() {
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [type, setType] = useState("");

  const getData = async () => {
    setLoading(true);
    // Currency
    let result = await axiosInstance.get(
      `/EscalationMatrix/?search=${searchKeyword}&type=${type}&page=${current}`
    );
    console.log("all Escalation Matrix", result.data.data);
    setdata(result.data.data);
    setCount(result.data.count);
    setLoading(false);
  };

  const handlePages = (page) => {
    setCurrent(page);
  };
  useEffect(() => {
    getData();
  }, [current, searchKeyword, type]);

  const onSearch = (value) => setSearchKeyword(value);

  const handleType = (value) => setType(value);

  const columns = [
    {
      title: t("no"),
      dataIndex: "id",
      key: "id",
      fixed: lang === "en" ? "left" : "right",
    },

    {
      title: t("form.userName"),
      dataIndex: "user",
      render: (row) => <span>{row?.name}</span>,
    },

    {
      title: t("email"),
      render: (row) => <span>{row?.email}</span>,
    },
    {
      title: t("Transactions"),
      render: (row) => (
        <span>{row?.transactions_days ? row.transactions_days : "-"}</span>
      ),
    },
    {
      title: t("disputes"),
      render: (row) => (
        <span>{row?.dispute_days ? row.dispute_days : "-"}</span>
      ),
    },

    {
      title: t("actions"),
      key: "operation",
      fixed: lang === "en" ? "right" : "left",
      render: (row) => (
        <Space>
          <Link
            to={`/edit_escalation/${row.id}`}
            className="actions"
            // onClick={() => {
            //   console.log(e.id);
            // }}
          >
            {t("form.edit")}
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <section>
      <h1 className="pageTitle"> {t("EntityUserEsclations")}</h1>

      <div className="sectionHolder">
        <div className="sectionHeader">
          <p className="pageSubTitle"> {t("ViewAllUsersEsclation")}</p>

          <div className="tableOptions">
            <Select
              style={{
                width: 200,
              }}
              placeholder={t("escType")}
              onChange={(e) => handleType(e)}
            >
              <Select.Option value={""}>{t("all")}</Select.Option>

              <Select.Option value={"1"}>{t("disputeOnly")}</Select.Option>
              <Select.Option value={"2"}>{t("transactionOnly")}</Select.Option>
              <Select.Option value={"3"}>{t("transactionDis")}</Select.Option>
              <Select.Option value={"4"}>{t("off")}</Select.Option>
            </Select>

            <Search
              placeholder={t("inputSearch")}
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
            <CheckAuth
              Permissions={["account_manager"]}
              userType={["entity", "bank"]}
            >
              <Link to="/add_escalation" className="addNewCurrencyLink">
                <FontAwesomeIcon className="px-1" icon={faPlus} />
                {t("AddEsclationUser")}
              </Link>
            </CheckAuth>
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
        />
        <Pagination
          defaultCurrent={1}
          total={count}
          onChange={(page) => handlePages(page)}
          showSizeChanger={false}
        />
      </div>
    </section>
  );
}

export default ErrorHandler(BankerEscalationUsers, axiosInstance);
