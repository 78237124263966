import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

export default function RejectionReasonName({ details, permission }) {
    const { t } = useTranslation();

    const nationality =
        details.egyptian != undefined
            ? "egyptian"
            : details.foreign != undefined
            ? "foreign"
            : "entity";

    const number =
        details.egyptian != undefined
            ? "national_id"
            : details.foreign != undefined
            ? "passport_id"
            : "tax_id";

    const numberName =
        details.egyptian != undefined
            ? t("form.nationalID")
            : details.foreign != undefined
            ? t("form.passportNumber")
            : t("form.taxID");
    return (
        <>
            <Row className="disputeInfoRow">
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{t("oldName")}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.[nationality]?.name}
                        </p>
                    </div>
                </Col>
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{t("newName")}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">{details?.new_name}</p>
                    </div>
                </Col>
            </Row>

            <Row className="disputeInfoRow">
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{t("id")}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.[nationality]?.id}
                        </p>
                    </div>
                </Col>
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    {nationality === "entity" ? (
                        <>
                            <div className="w-50 d-flex align-items-center">
                                <p className="no-margin bold">{t("Bank")}</p>
                            </div>
                            <div className="w-50 d-flex align-items-center">
                                <p className="no-margin">
                                    {details?.[nationality]?.bank}
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="w-50 d-flex align-items-center">
                                <p className="no-margin bold">
                                    {t("nationality")}
                                </p>
                            </div>
                            <div className="w-50 d-flex align-items-center">
                                <p className="no-margin">{t(nationality)}</p>
                            </div>
                        </>
                    )}
                </Col>
            </Row>
            <Row className="disputeInfoRow">
                <Col
                    className="d-flex justify-content-center align-items-center"
                    span={12}
                >
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin bold">{numberName}</p>
                    </div>
                    <div className="w-50 d-flex align-items-center">
                        <p className="no-margin">
                            {details?.[nationality]?.[number]}
                        </p>
                    </div>
                </Col>
            </Row>
        </>
    );
}
