import "./CurrencyPages.css";
import { useTranslation } from "react-i18next";
import { Table, Input, Pagination } from "antd";

import { Link } from "react-router-dom";
import axiosInstance from "../../../api/axios";

import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
const { Search } = Input;

function CurrenciesLogs() {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [showOldData, setShowOldData] = useState(false);
  const [showNewData, setShowNewData] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [newData, setNewData] = useState([]);

  const getOldData = async (id) => {
    try {
      let result = await axiosInstance.get(`/HistoricalCurrency/${id}`);
      console.log("old Currency logs data ", result.data);
      setOldData(result.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getNewData = async (id) => {
    try {
      let result = await axiosInstance.get(`/HistoricalCurrency/${id}`);
      console.log("new Currency logs data ", result.data);
      setNewData(result.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handelOldData = (id) => {
    console.log(id);

    getOldData(id);

    setShowOldData(true);
  };

  const handelNewData = (id) => {
    console.log(id);
    getNewData(id);
    setShowNewData(true);
  };

  const { t } = useTranslation();
  const onSearch = (value) => console.log(value);

  const columns = [
    {
      title: t("userID"),
      dataIndex: "user_id",
      key: "user_id",
      fixed: "left",
      render: (text) => <>{text ? text : ""}</>,
    },

    {
      title: t("changedBy"),
      dataIndex: "user_name",
      key: "user_name",
      render: (text) => <>{text ? text : ""}</>,
    },

    {
      title: t("typeOfChange"),
      dataIndex: "history_type",
      key: "history_type",
      render: (text) => (
        <>
          {text === "+"
            ? "Created"
            : text === "~"
            ? "Changed"
            : text === "-"
            ? "Deleted"
            : null}
        </>
      ),
    },
    {
      title: t("changedFrom"),
      dataIndex: "old_history_id",
      key: "old_history_id",
      render: (id) => (
        <>
          {id !== null ? (
            <button onClick={() => handelOldData(id)} className="logsChangeBtn">
              {id}
            </button>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: t("ChangedTo"),
      dataIndex: "history_id",
      key: "history_id",
      render: (id) => (
        <>
          {id !== null ? (
            <button onClick={() => handelNewData(id)} className="logsChangeBtn">
              {id}
            </button>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: t("timeStamp"),
      dataIndex: "modify_date",
      key: "modify_date",
      render: (text) => <>{text?.split("T")[0]}</>,
    },
  ];

  const getData = async () => {
    setLoading(true);
    // Currency
    try {
      let result = await axiosInstance.get(`/HistoricalCurrency/`);
      console.log("Currency logs data ", result.data);
      setdata(result.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePages = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    getData();
  }, [current]);

  return (
    <section>
      <Modal
        show={showOldData}
        onHide={() => setShowOldData(false)}
        centered
        className="logsSectionModal"
        dir={localStorage.getItem("i18nextLng") === "en" ? "ltr " : "rtl"}
      >
        <Modal.Header
          closeButton
          className="d-flex align-items-center kustify-content-center"
        >
          <Modal.Title>{t("oldData")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong> {t("CurrencyCode")} : </strong> {oldData.name}
          </p>
          <p>
            <strong>{t("CurrencyNameEn")} : </strong> {oldData.name_expanded}
          </p>
          <p>
            <strong>{t("CurrencyNameAr")} : </strong>
            {oldData.name_expanded_ar}
          </p>
          <p>
            <strong>{t("AmountInEGP")} : </strong>
            {oldData.name_expanded_ar}
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={showNewData}
        onHide={() => setShowNewData(false)}
        centered
        className="logsSectionModal"
        dir={localStorage.getItem("i18nextLng") === "en" ? "ltr " : "rtl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("newData")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong> {t("CurrencyCode")} : </strong> {newData.name}
          </p>
          <p>
            <strong>{t("CurrencyNameEn")} : </strong> {newData.name_expanded}
          </p>
          <p>
            <strong>{t("CurrencyNameAr")} : </strong>
            {newData.name_expanded_ar}
          </p>
          <p>
            <strong>{t("AmountInEGP")} : </strong>
            {newData.name_expanded_ar}
          </p>
        </Modal.Body>
      </Modal>

      <h1 className="pageTitle"> {t("sidemenu.CurrencyConfiguration")}</h1>
      <Link to="/currency_configuration" className="backtoAllBtn">
        <FontAwesomeIcon
          icon={
            localStorage.getItem("i18nextLng") === "en"
              ? faAngleRight
              : faAngleLeft
          }
        />

        {t("viewAllCurrencies")}
      </Link>

      <div className="sectionHolder">
        <div className="sectionHeader">
          <p className="pageSubTitle"> {t("configurationLogsTable")}</p>
          <div className="tableOptions">
            <Search placeholder={t("inputSearch")} onSearch={onSearch} />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
        />
        <Pagination
          defaultCurrent={1}
          total={count}
          onChange={(page) => handlePages(page)}
          showSizeChanger={false}
        />{" "}
      </div>
    </section>
  );
}

export default ErrorHandler(CurrenciesLogs, axiosInstance);
