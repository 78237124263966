import React from "react";
import { Row, Col } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function InfoCard() {
  const { t } = useTranslation();

  return (
    <div className="informationCard">
      {/* <Row> */}
      <div sm="1">
        {" "}
        <InfoCircleOutlined />
      </div>
      <div sm="11">
        <p className="informationCardTitle">{t("disputeCardTitle")}</p>
        <p className="informationCardSubtitle">
          {t("disputeInfoStep1")}
        </p>
        <p className="informationCardSubtitle">
       
          {t("disputeInfoStep2")}

        </p>
      </div>
      {/* </Row> */}
    </div>
  );
}
